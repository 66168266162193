import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CategoryOption, ITemplateCategory } from 'src/app/pages/template/models/template.models';
import { TemplateService } from 'src/app/pages/template/services/template.service';
import { IDropdownElement } from 'src/app/shared/models/dropdown.models';
import { UtilsService } from '../../services/utils.service';
import { ModalAddUpdateCategoryComponent } from '../modal-add-update-category/modal-add-update-category.component';

@Component({
    selector: 'app-sidebar-template',
    templateUrl: './sidebar-template.component.html',
    styleUrls: ['./sidebar-template.component.scss']
})
export class SidebarTemplateComponent implements OnInit {

    @Input() design!: string;
    @Input() userCategories!: Array<ITemplateCategory>;
    @Input() sharedCategories!: Array<ITemplateCategory>;
    @Output() menuSelected = new EventEmitter();

    public sidebarActionDropdown: Array<IDropdownElement> = [
        {
            label: 'Editar',
            icon: 'icon-edit-2',
            id: CategoryOption.ChangeName
        }, {
            label: 'Eliminar',
            type: 'endRed',
            icon: 'icon-trash',
            id: CategoryOption.Remove
        }
    ];
    public loadingSidebar: boolean = false;

    constructor(
        private matDialog: MatDialog,
        private templateService: TemplateService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
    }
    
    public actionSidebar(category: ITemplateCategory, action: CategoryOption | string): void {
        switch (action) {
            case CategoryOption.ChangeName:
                this.matDialog
                    .open(ModalAddUpdateCategoryComponent, {
                        disableClose: true,
                        panelClass: ["tc-modal"],
                        maxWidth: "800px",
                        data: {
                            userCategories: this.userCategories,
                            category: category
                        },
                    });
                break;
            case CategoryOption.Remove:
                this.utilsService.openModalGeneric({
                    type: 'error',
                    title: `¿Seguro que quieres eliminar ${category.name}?`,
                    text: 'Una vez eliminado no podrás volver a recuperarla. Las plantillas asociadas no se perderán',
                    textButtonConfirm: 'Eliminar',
                    textButtonCancel: 'Cancelar'
                })
                .afterClosed()
                .subscribe((result: boolean) => {
                    if (result) {
                        this.removeCategory(category);
                    }
                });
                break;
        
            default:
                break;
        }
    }

    // Eliminamos una categoría
    private removeCategory(category: ITemplateCategory): void {
        this.loadingSidebar = true;
        this.templateService.deleteCategory(category.id)
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        const foundIndex = this.userCategories.findIndex((userCategory: ITemplateCategory) => category.id === userCategory.id);
                        if (foundIndex > -1) {
                            this.userCategories[this.userCategories.length-1].amount += this.userCategories[foundIndex].amount;
                            this.userCategories.splice(foundIndex, 1);
                        }
                        if (category.selected) {
                            this.selectedElement(this.userCategories[0]);
                        }
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.utilsService.openModalGeneric({
                        type: 'error',
                        title: `Error al eliminar ${category.name}`,
                        text: 'Por favor, inténtelo de nuevo más tarde'
                    });
                    this.loadingSidebar = false;
                },
                complete: () => {
                    this.loadingSidebar = false;
                }
            });
    }

    // Abrir modal para añadir categoría
    public addCategory(): void {
        this.matDialog
            .open(ModalAddUpdateCategoryComponent, {
                disableClose: true,
                panelClass: ["tc-modal"],
                maxWidth: "800px",
                data: {
                    userCategories: this.userCategories
                },
            });
    }

    public selectedElement(element :ITemplateCategory): void {
        this.userCategories.map((category: ITemplateCategory) => {
            category.selected = false;
        });
        this.sharedCategories.map((category: ITemplateCategory) => {
            category.selected = false;
        });

        element.selected = true;
        this.menuSelected.emit(element);
    }

}
