export enum ActionNameTypes {
    SetUrl = 'url',
    SetFields = 'fields',
    Recording = 'recording'
}

export enum Action {
    OPEN_URL = 'ACTION_OPEN_URL',
    FORM_FILL = 'ACTION_FORM_FILL',
    FORM_SUBMIT = 'ACTION_FORM_SUBMIT',
    CLICK = 'ACTION_CLICK',
    DOUBLE_CLICK = 'ACTION_DOUBLE_CLICK',
    CLICK_BY_COORDINATES = 'ACTION_CLICK_BY_COORDINATES',
    WAIT_FOR_ELEMENT = 'ACTION_WAIT_FOR_ELEMENT',
    RETURN_NAV_STATE = 'ACTION_RETURN_NAV_STATE',
    SLEEP = 'ACTION_SLEEP'
}

export enum TranslateField {
    querySelector = 'QuerySelector',
    value = 'Valor',
    description = 'Descripción',
    fields = 'Campos principales',
    url = 'Url de la web',
    user = 'Usuario',
    pass = 'Contraseña',
    basicAuth = 'Autenticación',
    frameName = 'Nombre del frame',
    frameUrl = ' Url del frame',
    visible = 'Visible',
    sleep = 'Esperar (ms)',
    pasteText = 'Pegar texto',
    urlNeeded = 'Url necesaria',
    closeWindows = 'Cerrar ventana',
    textContent = 'Contiene el texto',
    screenshotNeeded = 'Devolver captura de pantalla'
}

export interface IActionConf {
    action: Action;
    description: string;
    icon: string;
    aux_description?: string;
}

export interface IActionStep {
    type: ActionNameTypes;
    onComplete?: boolean;
    title?: string;
    collapse?: boolean;
}

export interface IActionJobs {
    fields: Array<IActionField>;
    name: string; // Id de la accion
    description?: string; // Descripcion de la accion
    icon?: string;
    aux_description?: string; // Descripcion previa a tener la descripcion del usuario. Cada accion debe tener una
    user_description?: string; // Nombre que pone el usuario a la acción
}

export interface IActionField {
    name: any;
    required: boolean;
    type: 'string' | 'integer' | 'compoundParameter' | 'arrayParameter' | 'boolean';
    allowDatasource?: boolean;
    value?: any;
    parameters?: Array<IActionField>;
    collapse?: boolean;
}

export interface IActionExtension {
    action: Action;
    description: string;
    querySelector: string;
    value?: any;
}