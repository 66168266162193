import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";

/**
 * Base comun a servicios que vayan a llamar al api
 */

export class ApiService {
    protected auth: AuthService;
    protected http: HttpClient;

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        this.auth = auth;
        this.http = http;
    }

    public getHeaders(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken(),
            'Content-Type': 'application/json'
        });
    }

    public getHeadersFormData(): HttpHeaders {
        return new HttpHeaders({
            Authorization: 'Bearer ' + this.auth.getToken()
        });
    }
}
