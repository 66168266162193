<div class="modal">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <div class="content__header">
                <div class="content__header__title">
                    <h3>Configuración</h3>
                </div>
                <app-button-icon 
                    *ngIf="data.name && data.type"
                    icon="icon-close" 
                    (buttonClicked)="closeModal()">
                </app-button-icon>
            </div>
            <div class="content__body">
                <div class="content__body__section" [formGroup]="form">
                    <div class="row m-b-32">
                        <div class="col-12">
                            <div class="input-group">
                                <label for="name">Nombre de la automatización</label>
                                <input type="text" class="input" 
                                    [formControlName]="'name'" 
                                    [ngClass]="{'input--error': form.get('name')?.invalid && form.get('name')?.touched}">
                                <span class="input-group__error-message" *ngIf="form.get('name')?.errors?.['required'] && form.get('name')?.touched">
                                    Campo obligatorio
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <label>Tipo de automatización</label>
                        <div class="card__flex-wrapper">
                            <div class="card" 
                                [ngClass]="{'card--selected': form.get('type')?.value === automationType.Manual}" 
                                (click)="form.get('type')?.patchValue(automationType.Manual)">
                                <div class="card__header">
                                    <img src="../../../../assets/resources/manual.svg" alt="">
                                    <div class="card__header__title">
                                        <h5>
                                            Manual
                                        </h5>
                                    </div>
                                    <div class="card__header__info">
                                        <div>Se incia manualmente cuando tú decidas</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" 
                                [ngClass]="{'card--selected': form.get('type')?.value === automationType.Programada}" 
                                (click)="form.get('type')?.patchValue(automationType.Programada)">
                                <div class="card__header">
                                    <img src="../../../../assets/resources/programmed.svg" alt="">
                                    <div class="card__header__title">
                                        <h5>
                                            Programada
                                        </h5>
                                    </div>
                                    <div class="card__header__info">
                                        <div>Se inica con la frecuencia que desees</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card card--disabled" 
                                [ngClass]="{'card--selected': form.get('type')?.value === automationType.Desencadenada}" 
                                (click)="form.get('type')?.patchValue(automationType.Desencadenada)">
                                <div class="card__header">
                                    <img src="../../../../assets/resources/desencadenada.svg" alt="">
                                    <div class="card__header__title">
                                        <h5>Desencadenada</h5>
                                    </div>
                                    <div class="card__header__info">
                                        <div>Se inicia cuando sucede un desencadenante</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="input-group__error-message" *ngIf="form.get('type')?.errors?.['required'] && form.get('type')?.touched">
                            Seleccione un tipo de automatización
                        </span>
                    </div>
                </div>
            </div>
            <div class="content__footer">
                <app-button 
                    *ngIf="data.name && data.type"
                    design="button--secondary" 
                    (buttonClicked)="closeModal()">
                    Cancelar
                </app-button>
                <app-button (buttonClicked)="setNameTypeAutomation()">
                    Guardar
                </app-button>
            </div>
        </div>
    </div>
</div>
