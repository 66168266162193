import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { ModalCreateAutomationComponent } from '../../components/modal-create-automation/modal-create-automation.component';
import { ISidebar } from '../../models/sidebar.models';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    @Input() design!: string;
    @Input() confSidebar!: ISidebar;

    public currentUrl: string = '';
    public openSidebar: boolean = true;

    constructor(
        private router: Router,
        private matDialog: MatDialog
    ) {
        this.currentUrl = this.router.url;

        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentUrl = event.url;
            }
        });
    }

    ngOnInit(): void {
    }

    public openModalCreateAutomation(): void {
        this.matDialog
            .open(ModalCreateAutomationComponent, {
                disableClose: true,
                panelClass: ["tc-modal"],
                maxWidth: "1000px"
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result) {
                    console.log(`Dialog result: ${result}`);
                }
            });
    }

}
