import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutomationType, IAutomationConf } from 'src/app/pages/automation/models/automations.models';

@Component({
    selector: 'app-modal-conf-automation',
    templateUrl: './modal-conf-automation.component.html',
    styleUrls: ['./modal-conf-automation.component.scss']
})
export class ModalConfAutomationComponent implements OnInit {

    public automationType: any = AutomationType;
    public form: FormGroup = new FormGroup({
        name: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required)
    });

    constructor(
        public dialogRef: MatDialogRef<ModalConfAutomationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IAutomationConf,
    ) { }

    ngOnInit(): void {
        this.form.get('name')?.patchValue(this.data.name);
        this.form.get('type')?.patchValue(this.data.type);
    }

    public setNameTypeAutomation() {
        if (this.form.valid) {
            this.dialogRef.close(this.form.getRawValue());
        } else {
            this.form.markAllAsTouched();
        }
    }

    public closeModal(): void {
        if (this.form.valid) {
            this.dialogRef.close();
        }
    }
}
