import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent implements OnInit {

    @Input() title!: string;
    @Input() subTitle!: string;
    @Input() hideButton!: boolean;

    constructor() { }

    ngOnInit(): void {
    }

    public reload(): void {
        window.location.reload();
    }

}
