import { ICollaborator } from "src/app/shared/models/collaborators.models";
import { IScheduledProgramed } from "../../workspace/models/workspace.models";

export interface IAutomationsListResponse {
    current_page: number;
    total: number;
    jobs: Array<IAutomationListDetail>;
}

export interface IAutomationTreeResponse {
    id: string;
    name: string;
    execution_type: string;
    status: AutomationStatus;
    tasks: Array<ITasks>;
    last_execution: string;
    completed_at: string;
    streaming_url: string;
    schendule?: IScheduledProgramed;
}

export interface IAutomationExecuteResponse {
    KEY_RESPONSE_CODE: string;
    typeId: string;
    defaultResponse: {
        screenshot: string;
    };
}

export interface IAutomationDownloadResponse {
    url: string;
}

export interface IAutomationTreeRequest {
    name: string;
    status: AutomationStatus;
    instructions: IActionBody;
    execution_type: number;
    start_at?: string;
    schendule_type?: number;
    custom_schendule?: {
        count_repeat: number;
        type_interval: number;
        interval: string | null | number;
        finish_repeat: null | string | number;
    }
}

export interface ITasks {
    id: string;
    actions: IActionBody
}

export interface IActionBody {
    action: any;
    children: Array<IActionBody>;
}

export interface IAutomationListDetail {
    id: string;
    name: string;
    status: AutomationStatus;
    execution_type: AutomationType;
    collaborators: Array<ICollaborator>;
    last_execution: string;
    completed_at: string;
}

export interface IAutomationsListRequest {
    status?: AutomationStatus | string;
    page: number;
    quantity: number;
    order_by?: any;
    filter?: string;
    execution_type?: string;
    datasource_id?: number
}

export interface IAutomationsDetailRequest {
    status?: AutomationStatus | string;
    page: number;
    quantity: number;
    order_by?: any;
}

export interface IAutomationDetailResponse {
    id: string;
    name: string;
    execution_type: string;
    status: AutomationStatus;
    collaborators: Array<ICollaborator>;
    current_page: number;
    total: number;
    sessions: Array<IAutomationSession> | null;
}

export interface IAutomationSession {
    status: string;
    description: string;
    created_at: string;
    completed_at: string;
    files: Array<string> | null;
    screenshots: Array<string> | null;
    session_id: string;
    downloaded?: boolean;
}

export interface IAutomationSummaryResponse {
    time: {
        total: number;
        delta: number;
    };
    executed: {
        total: number;
        delta: number;
    };
}

export interface IAutomationActivityResponse {
    name: string;
    current_page: number;
    total: number;
    activities: Array<IAutomationActivity> | null;
}

export interface IAutomationActivityRequest {
    page: number;
    quantity: number;
    filter?: string;
    order_by?: any;
}

export interface IAutomationActivity {
    collaborator: {
        avatar: string | null;
        email: string;
        full_name: string;
        id: number;
        type: string;
    };
    description: string;
    created_at: string;
}

export interface IAutomationTotal {
    ongoing: number;
    error: number;
    completed: number;
    draft: number;
    archived: number;
}

export enum AutomationOption {
    Execute = 'execute',
    ChangeName = 'changeName',
    Edit = 'edit',
    Duplicate = 'duplicate',
    MoveToStore = 'moveToStore',
    MoveToDraft = 'moveToDraft',
    Share = 'share',
    MoveToTemplate = 'moveToTemplate',
    Remove = 'remove'
}

export enum AutomationStatus {
    Completed = 'completed',
    Error = 'error',
    Ongoing = 'ongoing',
    Draft = 'draft',
    Archived = 'archived',
    Published = 'published',
    Inactive = 'inactive',
    Active = 'active',
    Total = 'total'
}

export enum TranslateAutomationStatus {
    completed = 'Completado',
    error = 'Error',
    ongoing = 'En ejecución',
    draft = 'Borrador',
    archived = 'Archivada',
    published = 'Publicado',
    inactive = 'Inactivo',
    active = 'Activo',
    total = 'Todas'
}

export enum ColorTagAutomationStatus {
    completed = 'tag--success',
    error = 'tag--error',
    ongoing = 'tag--warning',
    draft = 'tag--neutral',
    archived = 'tag--neutral',
    inactive = 'tag--neutral',
    active = 'tag--success',
    total = 'tag--neutral'
}

export enum AutomationType {
    Programada = 'Programada',
    Manual = 'Manual',
    Desencadenada = 'Desencadenada'
}

export enum AutomationSummaryTab {
    Today = 'day',
    Week = 'week',
    Month = 'month'
}

export interface IAutomationConf {
    type: AutomationType;
    name: string;
}