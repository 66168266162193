import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { IDropdownElement } from 'src/app/shared/models/dropdown.models';
import { IDropdownSelectOption } from 'src/app/shared/models/dropdownSelect.models';
import { ITabElement } from 'src/app/shared/models/tabs.models';
import { environment } from 'src/environments/environment';
import { AutomationOption, AutomationStatus, AutomationSummaryTab, IAutomationActivityRequest, IAutomationActivityResponse, IAutomationExecuteResponse, IAutomationDetailResponse, IAutomationsDetailRequest, IAutomationsListRequest, IAutomationsListResponse, IAutomationSummaryResponse, IAutomationTotal, IAutomationTreeRequest, IAutomationTreeResponse, IAutomationDownloadResponse } from '../models/automations.models';

@Injectable({
    providedIn: 'root'
})
export class AutomationService extends ApiService {

    private automationTab: BehaviorSubject<AutomationStatus | string>;

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
        this.automationTab = new BehaviorSubject<AutomationStatus | string>('');
    }

    public setAutomationTab(data: AutomationStatus | string) {
        this.automationTab.next(data);
    }

    public getAutomationTab(): Observable<AutomationStatus | string> {
        return this.automationTab.asObservable();
    }

    public getAutomationDetailFromService(id: any, filters: IAutomationsDetailRequest): Observable<IAutomationDetailResponse> {
        return this.http.get<IAutomationDetailResponse>(environment.dxBotApiUrl + `/v2/jobs/${id}/detail`, {
            params: this.getParamsAutomationDetail(filters),
            headers: this.getHeaders()
        });
    }

    public getAutomationFromService(id: any): Observable<IAutomationTreeResponse> {
        return this.http.get<IAutomationTreeResponse>(environment.dxBotApiUrl + `/v2/jobs/${id}`, {headers: this.getHeaders()});
    }

    public executeAutomationFromService(id: any): Observable<IAutomationExecuteResponse> {
        return this.http.post<IAutomationExecuteResponse>(environment.dxBotApiUrl + `/v2/jobs/${id}/execute`, null, {headers: this.getHeaders()});
    }

    public getStreamingAutomationFromService(id: any): Observable<any> {
        return this.http.get<any>(environment.dxBotApiUrl + `/v2/jobs/${id}/streaming`, {headers: this.getHeaders()});
    }

    public putAutomationName(id: any, name: string): Observable<any> {
        return this.http.put<any>(environment.dxBotApiUrl + `/v2/jobs/${id}/rename`, {name: name}, {headers: this.getHeaders()});
    }

    public putAutomationStatus(id: any, status: string): Observable<any> {
        return this.http.put<any>(environment.dxBotApiUrl + `/v2/jobs/${id}/status`, {status: status}, {headers: this.getHeaders()});
    }

    public deleteAutomation(id: any): Observable<any> {
        return this.http.delete<any>(environment.dxBotApiUrl + `/v2/jobs/${id}/delete`, {headers: this.getHeaders()});
    }

    public cloneAutomation(id: any): Observable<any> {
        return this.http.post<any>(environment.dxBotApiUrl + `/v2/jobs/${id}/clone`, null, {headers: this.getHeaders()});
    }

    public updateAutomation(id: any, body: IAutomationTreeRequest): Observable<any> {
        return this.http.put<any>(environment.dxBotApiUrl + `/v2/jobs/${id}`, body, {headers: this.getHeaders()});
    }

    public createAutomation(body: IAutomationTreeRequest): Observable<any> {
        return this.http.post<any>(environment.dxBotApiUrl + `/v2/jobs`, body, {headers: this.getHeaders()});
    }

    public downloadFilesAutomation(id: any, file: string): Observable<IAutomationDownloadResponse> {
        return this.http.get<IAutomationDownloadResponse>(environment.dxBotApiUrl + `/v2/jobs/${id}/attach-url?file=${file}`, {headers: this.getHeaders()});
    }

    public getAutomationActivityFromService(id: any, filters: IAutomationActivityRequest): Observable<IAutomationActivityResponse> {
        return this.http.get<IAutomationActivityResponse>(environment.dxBotApiUrl +  `/v2/jobs/${id}/activity-log`, {
            params: this.getParamsAutomationActivity(filters), 
            headers: this.getHeaders()
        });
    }

    public getAutomationDetailStatsFromService(id: any, type: AutomationSummaryTab): Observable<IAutomationSummaryResponse> {
        return this.http.get<IAutomationSummaryResponse>(environment.dxBotApiUrl + `/v2/jobs/${id}/summary?interval=` + type, {headers: this.getHeaders()});
    }

    public getAutomationsListFromService(filters: IAutomationsListRequest): Observable<IAutomationsListResponse> {
        return this.http.get<IAutomationsListResponse>(environment.dxBotApiUrl + '/v2/jobs', {
            params: this.getParamsAutomationList(filters), 
            headers: this.getHeaders()
        });
    }

    public getTotalAutomationsFromService(): Observable<IAutomationTotal> {
        return this.http.get<IAutomationTotal>(environment.dxBotApiUrl + '/v2/jobs/totals', {headers: this.getHeaders()});
    }

    private getParamsAutomationList(filters: IAutomationsListRequest): HttpParams {
        let params = new HttpParams();
        params = params.set('page', filters.page);
        params = params.set('quantity', filters.quantity);

        if (filters.status) {
            params = params.set('status', filters.status);
        }
        if (filters.order_by) {
            params = params.set('order_by', filters.order_by);
        }
        if (filters.filter) {
            params = params.set('filter', filters.filter);
        }
        if (filters.execution_type) {
            params = params.set('execution_type', +filters.execution_type);
        }
        if (filters.datasource_id) {
            params = params.set('datasource_id', +filters.datasource_id);
        }
        return params;
    }

    private getParamsAutomationDetail(filters: IAutomationsDetailRequest): HttpParams {
        let params = new HttpParams();
        params = params.set('page', filters.page);
        params = params.set('quantity', filters.quantity);

        if (filters.status) {
            params = params.set('status', filters.status)
        }
        if (filters.order_by) {
            params = params.set('order_by', filters.order_by);
        }
        return params;
    }

    private getParamsAutomationActivity(filters: IAutomationActivityRequest): HttpParams {
        let params = new HttpParams();
        params = params.set('page', filters.page);
        params = params.set('quantity', filters.quantity);

        if (filters.order_by) {
            params = params.set('order_by', filters.order_by);
        }
        if (filters.filter) {
            params = params.set('filter', filters.filter);
        }
        return params;
    }

    public automationCanBeExecute(status: AutomationStatus): boolean {
        switch (status) {
            case AutomationStatus.Active:
            case AutomationStatus.Completed:
            case AutomationStatus.Published:
                return true;
            default:
                return false
        }
    }

    public getAutomationListActions(): Array<IDropdownElement> {
        return [
            {
                label: 'Ver a Alice automatizar',
                icon: 'icon-eye',
                id: AutomationOption.Execute
            }, {
                label: 'Editar nombre',
                icon: 'icon-edit-2',
                id: AutomationOption.ChangeName
            }, {
                label: 'Duplicar',
                icon: 'icon-copy',
                id: AutomationOption.Duplicate
            }, {
                label: 'Archivar',
                icon: 'icon-folder',
                id: AutomationOption.MoveToStore
            }, {
                label: 'Mover a borradores',
                icon: 'icon-move',
                id: AutomationOption.MoveToDraft
            }, {
                label: 'Invitar a colaborar',
                icon: 'icon-plus',
                id: AutomationOption.Share
            }, {
                label: 'Añadir a mis plantillas',
                icon: 'icon-layout',
                id: AutomationOption.MoveToTemplate
            }, {
                label: 'Eliminar',
                type: 'endRed',
                icon: 'icon-trash',
                id: AutomationOption.Remove
            }
        ];
    }

    public getAutomationDetailActions(): Array<IDropdownElement> {
        return [
            {
                label: 'Ver a Alice automatizar',
                icon: 'icon-eye',
                id: AutomationOption.Execute
            }, {
                label: 'Editar',
                icon: 'icon-edit-2',
                id: AutomationOption.Edit
            }, {
                label: 'Archivar',
                icon: 'icon-folder',
                id: AutomationOption.MoveToStore
            }, {
                label: 'Mover a borradores',
                icon: 'icon-move',
                id: AutomationOption.MoveToDraft
            }, {
                label: 'Eliminar',
                type: 'endRed',
                icon: 'icon-trash',
                id: AutomationOption.Remove
            }
        ];
    }

    public getAutomationDetailFilterDates(): Array<IDropdownSelectOption> {
        return [
            {
                name: 'Hoy',
                id: AutomationSummaryTab.Today
            }, {
                name: 'Esta semana',
                id: AutomationSummaryTab.Week
            }, {
                name: 'Este mes',
                id: AutomationSummaryTab.Month
            }
        ];
    }

    public getConfTabsAutomation(): Array<ITabElement> {
        return [
            {
                title: 'Todas',
                selected: true,
                id: AutomationStatus.Total
            }, {
                title: 'Con errores',
                selected: false,
                notifications: 0,
                id: AutomationStatus.Error
            }, {
                title: 'Borradores',
                selected: false,
                id: AutomationStatus.Draft
            }, {
                title: 'Archivadas',
                selected: false,
                id: AutomationStatus.Archived
            }
        ];
    }
}
