import { IMasterElement } from "src/app/core/models/masters.models";
import { IActionBody } from "../../automation/models/automations.models";

export interface ITemplatesListResponse {
    current_page: number;
    total: number;
    templates: Array<ITemplate>;
}

export interface ITemplatesCategoriesResponse {
    user_categories: Array<ITemplateCategory>;
    shared_categories: Array<ITemplateCategory>;
}

export interface ITemplate {
    category: string;
    category_id?: any;
    connections: Array<any>;
    created_at: string;
    updated_at?: string;
    deleted_at?: string;
    deleted?: boolean;
    id: string;
    name: string;
    owner: string;
    public: boolean;
    rating: number;
    description: string;
    instructions: IActionBody;
    user_id?: number;
    selected?: boolean;
}

export interface ITemplatesListRequest {
    category?: string;
    page: number;
    quantity: number;
    order_by?: any;
    filter?: string;
    user_templates?: boolean;
}

export interface ITemplateCreateBody {
    job_id: string;
    name: string;
    description: string;
    category: any;
    public: boolean;
}

export interface ITemplateUpdateBody {
    name: string;
    description: string;
    category: any;
    public: boolean;
}

export interface ITemplateShareBody {
    category?: string;
    public?: boolean;
}

export interface ITemplateTotal {
    total: number;
}

export interface ITemplateCategory extends IMasterElement {
    amount: number;
    editable?: boolean;
    selected?: boolean;
}

export enum CategoryOption {
    ChangeName = 'changeName',
    Remove = 'remove'
}

export enum TemplateOption {
    Edit = 'edit',
    ChangeToPublic = 'changeToPublic',
    ChangeToPrivate = 'changeToPrivate',
    Share = 'share',
    Remove = 'remove'
}