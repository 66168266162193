import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDataSourceForm } from 'src/app/pages/connection/models/dataSource.models';
import { ConnectionService } from 'src/app/pages/connection/services/connection.service';
import { IPagination } from '../../models/pagination.models';
import { SkeletonTypes } from '../../models/skeleton.models';
import { ConnectionStatus, IConnectionDetail, IConnectionsListResponse } from 'src/app/pages/connection/models/connection.models';

@Component({
    selector: 'app-modal-data-source',
    templateUrl: './modal-data-source.component.html',
    styleUrls: ['./modal-data-source.component.scss']
})
export class ModalDataSourceComponent implements OnInit {

    public QUANTITY: number = 6;
    public dataSources: IConnectionsListResponse | null = null;
    public dataSourcePagination: IPagination = {
        page: 1,
        limit: this.QUANTITY,
        total: 0
    };
    public selectedColumn: FormControl = new FormControl('', Validators.required);
    public searchForm: FormControl = new FormControl('', [Validators.minLength(3)]);
    public loadingDataSource: boolean = false;
    public errorDataSource: string = '';
    public skeletonTypes = SkeletonTypes;

    constructor(
        public dialogRef: MatDialogRef<ModalDataSourceComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {data: IDataSourceForm},
        private connectionService: ConnectionService
    ) { }

    ngOnInit(): void {
        // Si nos viene un origen de datos ya metido en el formulario
        if (typeof this.data.data === 'object' && this.data.data.datasourceName) {
            this.searchForm.patchValue(this.data.data.datasourceName);
        }
        this.getDataSource();
    }

    public getDataSource(pagination?: IPagination): void {
        if (!this.searchForm.valid) {
            this.searchForm.markAllAsTouched();
            return;
        }
        this.searchForm.disable();
        this.loadingDataSource = true;
        this.errorDataSource = '';
        this.dataSources = null;
        this.connectionService.getConnectionsListFromService({
                filter: this.searchForm.value,
                page: pagination ? pagination.page : 1,
                quantity: this.QUANTITY,
                status: ConnectionStatus.Connected,
                // provisional le metemos el type id de un excel
                type_id: [1, 2]
            }).subscribe({
                next: (response: IConnectionsListResponse) => {
                    if (response) {
                        this.dataSources = response;
                        this.dataSourcePagination.page = +response.page;
                        this.dataSourcePagination.total = +response.total;
                        this.dataSourcePagination.limit = this.QUANTITY;
                        if (this.dataSources.datasources.length) {
                            this.dataSources.datasources.forEach((data: IConnectionDetail) => {
                                data.columnsSelect = data.info?.columns?.length ? data.info.columns.map((column: string) => {return { id: column, name: column }}) : [];
                            })
                        }
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.loadingDataSource = false;
                    this.searchForm.enable();
                    this.errorDataSource = error?.error?.error || 'Parece que no encontramos el origen de datos. <br>Inténtelo de nuevo más tarde';
                },
                complete: () => {
                    this.loadingDataSource = false;
                    this.searchForm.enable();
                    
                    // Si nos viene un origen de datos ya metido en el formulario
                    if (this.dataSources?.datasources.length === 1 && typeof this.data.data === 'object') {
                        this.dataSources.datasources[0].selected = true;
                        const foundIndex = this.dataSources.datasources[0].columnsSelect.findIndex((column: any) => column.id === this.data.data.header);
                        if (foundIndex !== -1) {
                            this.selectedColumn.patchValue(this.dataSources.datasources[0].columnsSelect[foundIndex]);
                        }
                    } else {
                        this.selectedColumn.patchValue(null);
                    }
                }
            });
    }

    public anyDataSourceSelected(): boolean {
        if (this.dataSources?.datasources?.length) {
            return Boolean(this.dataSources?.datasources.find((data: IConnectionDetail) => data.selected));
        }
        return false;
    }

    public selectDatasourceCard(datasource: IConnectionDetail): void {
        this.dataSources?.datasources.map((data: IConnectionDetail) => data.selected = false);
        datasource.selected = true;
    }

    public confirmDataSource(): void {
        if (this.selectedColumn.valid) {
            this.dialogRef.close({
                datasource: this.dataSources?.datasources.find((data: IConnectionDetail) => data.selected)?.id, 
                datasourceName: this.dataSources?.datasources.find((data: IConnectionDetail) => data.selected)?.name,
                header: this.selectedColumn.value.id
            });
        } else {
            this.selectedColumn.markAllAsTouched();
        }
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

    public timestampToDate(date: any, withHour: boolean = true): string {
        if (date) {
            return withHour ? new Date(+date * 1000).toISOString().substring(0, 16) : new Date(+date * 1000).toISOString().split('T')[0];
        }
        return '';
    }

}
