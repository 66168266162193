<ng-template #recursiveList let-tree>
    <div *ngFor="let leaf of tree" class="estructura">
        <div class="nodo" [ngClass]="{'lineal': leaf.children && leaf.children.length === 1, 'bifur': leaf.children && leaf.children.length > 1}">
            {{ leaf.name }}
            <button (click)="addLineal(leaf)">Lineal</button>
            <div>
                <button (click)="addBifur(leaf, leaf.numOfChildrenToCreate)">Bifurcacion</button>
                <input matInput type="number" min="2" [(ngModel)]="leaf.numOfChildrenToCreate" style="width: 30px;">
            </div>
        </div>
        <div *ngIf="leaf.children.length > 0" style="display: flex">
            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: leaf.children }"></ng-container>
        </div>
    </div>
</ng-template>
<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: tree }"></ng-container>

{{ tree | json}}