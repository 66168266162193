import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

    @Input() design!: string;
    @Input() value!: number;
    @Input() numStar: number = 5;
    @Output() valueChange = new EventEmitter<number>();

    public ratingForm: FormGroup = this.fb.group({
        stars: this.fb.array([])
    });
    public random: string = (Math.random() + 1).toString(36).substring(7);

    constructor(
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        if (this.numStar > 10) this.numStar = 10;
        if (this.numStar < 3) this.numStar = 3;
        [...Array(this.numStar)].forEach((star: any, index: number) => (this.ratingForm.get('stars') as FormArray).push(new FormControl(this.value > index)));
    }

    public getStars() {
        return this.ratingForm.controls['stars'] as FormArray;
    }

    public changeRating(indexStar: number) {
        this.getStars().controls.map((starForm: any) => starForm.patchValue(false));
        this.getStars().controls.forEach((starForm: any, index: number) => starForm.patchValue(indexStar >= index));
        this.value = indexStar + 1;
        this.valueChange.emit(this.value);
    }

}
