import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAlertFirstSteps, IAlertFirstStep } from '../../models/alert.models';

@Component({
    selector: 'app-alert, [app-alert]',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

    @Input() design!: string;
    @Input() icon!: string;
    @Input() actionButtonText!: string;
    @Input() bodyText!: any;
    @Input() reconect!: boolean;
    @Input() confFirstSteps: IAlertFirstSteps = {
        title: 'Primeros pasos',
        steps: [
            {
                title: 'Añade una conexión',
                text: 'Conecta Alice con otras aplicaciones para añadirlas a tus flujos y así automatizar tareas web con ellas.',
                textButton: 'Ir a conexiones',
                urlButton: '/conexiones',
                image: 'first-steps--connections.svg',
                checked: false 
            }, {
                title: 'Crea una base de datos',
                text: 'Configura una base de datos para consultar la información que Alice ha extraído de una automatización web.',
                textButton: 'Crear base de datos',
                urlButton: '',
                image: 'first-steps--data_base.svg',
                checked: false 
            }, {
                title: 'Descubre plantillas',
                text: 'Automatiza rápidamente creando tus plantillas o adapta las compartidas por la comunidad DxBot.',
                textButton: 'Descubrir plantillas',
                urlButton: '',
                image: 'first-steps--templates.svg',
                checked: false 
            }, {
                title: 'Invita a colaboradores',
                text: 'Agrega a las personas que colaborán contigo creando automatizaciones.',
                textButton: 'Invitar colaboradores',
                urlButton: '',
                image: 'first-steps--collaborators.svg',
                checked: false 
            }, {
                title: 'Crea una automatización',
                text: 'Desde cero o utilizando una plantilla, crea tu primer flujo de un proceso web que quieres automatizar.',
                textButton: 'Crea una automatización',
                urlButton: '',
                image: 'first-steps--workflows.svg',
                checked: false 
            }, {
                title: '¡Enhorabuena!',
                text: 'Ya conoces todo lo que necesta Alice para automatizar tareas repetitivas y así puedes ocuparte de lo realmente importante.',
                textButton: null,
                urlButton: null,
                image: 'first-steps--completed.svg',
                checked: false,
                onComplete: true
            },
        ],
        index: 0
    };

    @Output() reconectClicked = new EventEmitter();
    @Output() buttonClicked = new EventEmitter();
    @Output() closeClicked = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    public nextStep(step: IAlertFirstStep) {
        step.checked = true;
        this.confFirstSteps.index ++;
    }

}
