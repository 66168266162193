import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private showLoading: Subject<boolean>;

    constructor() {
        this.showLoading = new Subject<boolean>();
    }

    public setShowLoading(data: boolean): void {
        this.showLoading.next(data);
    }

    public getShowLoading(): Observable<boolean> {
        return this.showLoading.asObservable();
    }
}
