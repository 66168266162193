<div class="avatar-group">
    <ng-container *ngFor="let avatar of elements; index as i">
        <ng-container *ngIf="avatar.img && !avatar.showName">
            <div class="avatar" [ngClass]="design" *ngIf="i < MAX_ELEMENTS_AVATAR">
                <img [src]="avatar.img" alt="" (error)="avatar.showName = true">
            </div>
        </ng-container>
        <ng-container *ngIf="!avatar.img || avatar.showName">
            <div class="avatar" [ngClass]="design" *ngIf="i < MAX_ELEMENTS_AVATAR" [style]="'background-color:' + colors[getColors(avatar)].fill">
                <span [style]="'color:' + colors[getColors(avatar)].text">{{ getIniciales(avatar.name) }}</span>
            </div>
        </ng-container>
        <app-button-icon icon="icon-plus" *ngIf="design?.includes('avatar--big')" (buttonClicked)="changeImage.emit()"></app-button-icon>
    </ng-container>
    <div class="avatar__number" *ngIf="elements && elements.length > MAX_ELEMENTS_AVATAR">
        {{ elements.length }}
    </div>
</div>