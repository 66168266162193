<div class="modal modal--cards">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <div class="content__header">
                <div class="content__header__title">
                    <h3>Automatizaciones usadas en la conexión</h3>
                </div>
                <app-button-icon icon="icon-close" (buttonClicked)="closeModal()"></app-button-icon>
            </div>
            <div class="content__body">
                <!-- Toolbar -->
                <div class="toolbar">
                    <div class="toolbar__left">
                        <div class="input-group input-group--collapsed-input">
                            <app-icons design="icon-search"></app-icons>
                            <input class="input" 
                                [ngClass]="{'input--error': searchForm.invalid && searchForm.touched}"
                                type="text" 
                                placeholder="Búsqueda por nombre o colaborador" 
                                appSearchFocus 
                                [formControl]="searchForm"
                                (keyup.enter)="getAutomations()">
                            <span class="input-group__error-message" *ngIf="searchForm?.errors?.['minlength'] && searchForm?.touched">
                                Al menos 3 caracteres
                            </span>
                        </div>
                    </div>
                </div>
                <!-- Content -->
                <div class="content__body__section">
                    <!-- Con datos -->
                    <ng-container *ngIf="automationsData && automationsData.jobs?.length">
                        <table class="table table--clickable">
                            <thead>
                                <tr>
                                    <th>
                                        <div class="table__cell">
                                            Nombre
                                        </div>
                                    </th>
                                    <th>
                                        <div class="table__cell">
                                            Tipo
                                        </div>
                                    </th>
                                    <th>
                                        <div class="table__cell">
                                            Colaboradores
                                        </div>
                                    </th>
                                    <th>
                                        <div class="table__cell">
                                            Estado
                                        </div>
                                    </th>
                                    <th>
                                        <div class="table__cell"></div>
                                    </th>
                                    <th>
                                        <div class="table__cell"></div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let automation of automationsData.jobs" [routerLink]="'/automatizaciones/' + automation.id">
                                    <td>
                                        <div class="table__cell table__cell--column">
                                            {{ automation.name }}
                                            <span class="text--gray-dark fs-12" *ngIf="automation.last_execution">
                                                Últ. ejecución {{ automation.last_execution | date: 'dd/MM/yyyy, HH:mm' }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table__cell table__cell--tag">
                                            <app-tag>{{ automation.execution_type }}</app-tag>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table__cell">
                                            <app-avatar [elements]="getAvatarCollaborators(automation.collaborators)"></app-avatar>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table__cell table__cell--tag">
                                            <app-tag 
                                                *ngIf="automation.status"
                                                [design]="colorTagAutomationStatus[automation.status]">
                                                {{ translateAutomationStatus[automation.status] }}
                                            </app-tag>
                                        </div>
                                    </td>
                                    <td (click)="$event.stopPropagation()">
                                        <div class="table__cell">
                                            <app-button-icon *ngIf="automationService.automationCanBeExecute(automation.status)"
                                                icon="icon-play"
                                                (buttonClicked)="executeAutomation(automation)">
                                            </app-button-icon>
                                            <app-alice *ngIf="automation.status === automationStatus.Ongoing"
                                                design="alice--pulse alice--s">
                                            </app-alice>
                                        </div>
                                    </td>
                                    <td (click)="$event.stopPropagation()">
                                        <div class="table__cell table__cell--align-right">
                                            <app-button-icon 
                                                icon="icon-edit-2" 
                                                [routerLink]="'/area-trabajo/' +  automation.id"
                                                (buttonClicked)="closeModal()">
                                            </app-button-icon>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="width: 100%;">
                            <app-pagination 
                                [(pagination)]="automationPagination" 
                                (paginationChange)="getAutomations($event)"
                                design="pagination__modal">
                            </app-pagination>
                        </div>
                    </ng-container>

                    <!-- Sin datos -->
                    <ng-container *ngIf="automationsData && automationsData.jobs?.length === 0">
                        <div class="content__body__empty">
                            <img src="../../../../assets/resources/no-automatizaciones.svg" alt="">
                            <h5>{{ 'No hay ninguna automatización' }}</h5>
                        </div>
                    </ng-container>

                    <!-- Loading -->
                    <ng-container *ngIf="!automationsData && !automationsError">
                        <table class="table table--skeleton">
                            <tbody>
                                <tr *ngFor="let _ of [].constructor(QUANTITY)">
                                    <td *ngFor="let _ of [].constructor(6)">
                                        <div class="table__cell"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                    <!-- Error -->
                    <ng-container *ngIf="automationsError">
                        <app-error-message [subTitle]="automationsError" [hideButton]="true"></app-error-message>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
