import { Injectable } from '@angular/core';
import { ISidebar } from '../models/sidebar.models';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {

    public sidebar: ISidebar = {
        items: [
            {
                icon: 'icon-home',
                name: 'Inicio',
                url: '/',
                notification: false
            }, {
                icon: 'icon-workflow',
                name: 'Mis automatizaciones',
                url: '/automatizaciones',
                notification: false
            }, {
                icon: 'icon-conection',
                name: 'Conexiones',
                url: '/conexiones',
                notification: false
            }, {
                icon: 'icon-layout',
                name: 'Plantillas',
                url: '/plantillas',
                notification: false
            }, {
                icon: 'icon-database',
                name: 'Base de datos',
                url: '/base-de-datos',
                notification: false,
                hidden: true
            }, {
                icon: 'icon-help-circle',
                name: 'Área de trabajo',
                url: '/area-trabajo',
                notification: false,
                hidden: true
            }, {
                icon: 'icon-help-circle',
                name: 'Demo automatización',
                url: '/demo-area',
                notification: false,
                hidden: true
            }, {
                icon: 'icon-rocket',
                name: 'Helena componentes',
                url: '/list-desing',
                notification: false,
                hidden: true
            }, {
                icon: 'icon-package',
                name: 'Rafa componentes',
                url: '/components-list-desing',
                notification: false,
                hidden: true
            }
        ],
        showButton: true
    }

    constructor() { }
}
