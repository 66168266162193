<div class="modal">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <div class="content__header">
                <div class="content__header__title">
                    <app-tag-icon icon="icon-clock"></app-tag-icon>
                    <app-tag-icon icon="icon-clock"></app-tag-icon>
                    <h3>Modal Title</h3>
                </div>
                <app-button-icon icon="icon-close" (buttonClicked)="closeModal()"></app-button-icon>
            </div>
            <div class="content__body">
                <div class="content__body__section">
                    <div class="row">
                        <div class="col-12">
                            <div class="input-group">
                                <label for="">Label</label>
                                <input type="text" placeholder="Placeholder" class="input">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <label for="">Label</label>
                                <input type="text" placeholder="Placeholder" class="input">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content__body__section">
                    <div class="row">
                        <div class="col-12">
                            <div class="input-group">
                                <label for="">Label</label>
                                <input type="text" placeholder="Placeholder" class="input">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <label for="">Label</label>
                                <input type="text" placeholder="Placeholder" class="input">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__footer">
                <app-button *ngIf="data.textButtonCancel" design="button--secondary" (buttonClicked)="closeModal()">
                    {{ data.textButtonCancel }}
                </app-button>
                <app-button *ngIf="data.textButtonConfirm" (buttonClicked)="confirmModal()">
                    {{ data.textButtonConfirm }}
                </app-button>
            </div>
        </div>
    </div>
</div>
