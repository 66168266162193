import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { IAccountResponse } from 'src/app/core/models/account.models';
import { LoadingService } from '../../services/loading.service';

@Component({
    selector: 'app-onboarding-alice',
    templateUrl: './onboarding-alice.component.html',
    styleUrls: ['./onboarding-alice.component.scss']
})
export class OnboardingAliceComponent implements OnInit, AfterViewInit {

    @Input() accountData!: IAccountResponse;
    @Input() msg: string = '';
    
    public phases: Array<boolean> = [false, false, false, false, false];
    public underline: boolean = false;

    constructor(
        private loadingService: LoadingService
    ) { }

    ngOnInit(): void {
    }
    
    ngAfterViewInit(): void {
        this.delay(1000).then(() => {
            this.phases = [true, false, false, false, false];
        });
        this.delay(2500).then(() => {
            this.phases = [false, true, false, false, false];
        });
        this.delay(5000).then(() => {
            this.phases = [false, false, true, false, false];
        });
        this.delay(7500).then(() => {
            this.phases = [false, false, false, true, false];
        });
        this.delay(9000).then(() => {
            this.underline = true;
        });
        this.delay(10500).then(() => {
            this.phases = [false, false, false, false, true];
        });
    }

    private delay(ms: number): Promise<any> {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    public hideFirstTime() {
        this.loadingService.setShowLoading(false);
    }

}
