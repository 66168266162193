import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CUSTOM_SCHENDULE_TYPES, DAY_OF_WEEK, FinishOption, IMaster, IMasterElement, PROGRAMED_FINISH_OPTIONS, SchenduleIntervals } from 'src/app/core/models/masters.models';
import { MastersService } from 'src/app/core/services/masters/masters.service';
import { IScheduledProgramed } from 'src/app/pages/workspace/models/workspace.models';

@Component({
    selector: 'app-modal-conf-programed-automation',
    templateUrl: './modal-conf-programed-automation.component.html',
    styleUrls: ['./modal-conf-programed-automation.component.scss']
})
export class ModalConfProgramedAutomationComponent implements OnInit, OnDestroy {

    public currentDate: Date = new Date();
    public form: FormGroup = new FormGroup({
        dateToStart: new FormControl(this.currentDate.toISOString().substring(0, 16), Validators.required),
        // hourToStart: new FormControl('', Validators.required),
        schenduleTypes: new FormControl('', Validators.required),
        countRepeat: new FormControl('1'),
        customSchenduleIntervals: new FormControl(),
        intervalWeekRepeat: new FormControl(''),
        finishRepeatDay: new FormControl(this.currentDate.toISOString().split('T')[0]),
        finishCountRepeat: new FormControl('1'),
    });
    public masters!: IMaster | null;
    public CUSTOM_SCHENDULE_TYPES = CUSTOM_SCHENDULE_TYPES;
    public schenduleIntervals: any = SchenduleIntervals;
    public intervalWeekElemets: Array<IMasterElement> = DAY_OF_WEEK;
    public finishRepeatElements: Array<IMasterElement> = PROGRAMED_FINISH_OPTIONS;
    private allSubscription: Subscription[] = [];

    constructor(
        public dialogRef: MatDialogRef<ModalConfProgramedAutomationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            schendule: IScheduledProgramed
        },
        private mastersService: MastersService
    ) { }

    ngOnInit(): void {
        this.allSubscription.push(this.mastersService.getMasters()
            .subscribe((masters: IMaster | null) => {
                this.masters = masters;
                this.form.get('schenduleTypes')?.patchValue(this.masters?.schendule_types[0]);
                this.form.get('customSchenduleIntervals')?.patchValue(this.masters?.custom_schendule_intervals[1]);
            })
        );

        this.initIntervalWeek();
        this.initFinishOption();
        this.initProgramed(this.data.schendule);
    }

    // Iniciamos los formularios con los valores de la automatización
    private initProgramed(programedValues: IScheduledProgramed): void {
        if (programedValues) {
            this.form.get('dateToStart')?.patchValue(this.timestampToDate(programedValues.start_at));
            this.form.get('schenduleTypes')?.patchValue(this.masters?.schendule_types.find((schendule: IMasterElement) => programedValues.schendule_type ===  schendule.id));
            if (programedValues.custom_schendule?.count_repeat) {
                this.form.get('countRepeat')?.patchValue(programedValues.custom_schendule?.count_repeat);
            }
            if (programedValues.custom_schendule?.type_interval) {
                const found = this.masters?.custom_schendule_intervals.find((schendule: IMasterElement) => schendule.id === programedValues.custom_schendule?.type_interval);
                if (found) {
                    this.setCustomSchenduleIntervals(found);
                    this.form.get('customSchenduleIntervals')?.patchValue(found);
                }
            }

            if (programedValues.custom_schendule?.type_interval === SchenduleIntervals.Week) {
                if (Array.isArray(programedValues.custom_schendule?.interval)) {
                    this.intervalWeekElemets.map((intervalDay: IMasterElement) => {
                        const found = Array.isArray(programedValues.custom_schendule?.interval) && programedValues.custom_schendule?.interval?.find((interval: IMasterElement) => interval === intervalDay.id);
                        if (found) {
                            intervalDay.selected = true;
                        }
                    });
                    this.form.get('intervalWeekRepeat')?.patchValue(programedValues.custom_schendule?.interval);
                }
            }

            if (!programedValues.custom_schendule?.finish_repeat) {
                this.selectFinishOption(this.finishRepeatElements[0]);
            } else if (+programedValues.custom_schendule?.finish_repeat <= 99999) {
                this.selectFinishOption(this.finishRepeatElements[2]);
                this.form.get('finishCountRepeat')?.patchValue(programedValues.custom_schendule?.finish_repeat);
            } else {
                this.selectFinishOption(this.finishRepeatElements[1]);
                this.form.get('finishRepeatDay')?.patchValue(this.timestampToDate(programedValues.custom_schendule?.finish_repeat, false));
            }
        }
    }

    // Cambiar el valor semanal
    public changeValue(value: IMasterElement): void {
        value.selected = !value.selected;
        const found = this.intervalWeekElemets.find((intervalDay: IMasterElement) => intervalDay.selected);
        if (!found) {
            this.initIntervalWeek();
        }
        this.form.get('intervalWeekRepeat')?.patchValue(this.intervalWeekElemets.filter((intervalDay: IMasterElement) => intervalDay.selected));
    }

    // Seteamos el valor del formulario repetir cada
    public setSchenduleTypesValue(value: IMasterElement): void {
        this.form.get('schenduleTypes')?.patchValue(value);
    }

    // Cambiamos el valor del finish
    public selectFinishOption(option: IMasterElement): void {
        if (option) {
            this.finishRepeatElements.map((element: IMasterElement) => {
                element.selected = option.id === element.id;
            });
            const found = this.finishRepeatElements.find((element: IMasterElement) => element.selected);
            if (found) {
                switch (found.id) {
                    case 1:
                        this.form.get('finishRepeatDay')?.disable();
                        this.form.get('finishCountRepeat')?.disable();
                        this.clearValidators('finishRepeatDay');
                        this.clearValidators('finishCountRepeat');
                        break;
                    case 2:
                        this.form.get('finishRepeatDay')?.enable();
                        this.setRequiredValidators('finishRepeatDay');
                        this.form.get('finishCountRepeat')?.disable();
                        this.clearValidators('finishCountRepeat');
                        if (!this.form.get('finishRepeatDay')?.value) {
                            this.form.get('finishRepeatDay')?.patchValue(this.currentDate.toISOString().split('T')[0]);
                        }
                        break;
                    case 3:
                        this.form.get('finishRepeatDay')?.disable();
                        this.clearValidators('finishRepeatDay');
                        this.form.get('finishCountRepeat')?.enable();
                        this.setRequiredValidators('finishCountRepeat');
                        if (!this.form.get('finishCountRepeat')?.value) {
                            this.form.get('finishCountRepeat')?.patchValue('1');
                        }
                        break;
                }
            } else {
                this.initFinishOption();
            }
        }
    }

    // Seteamos el valor del formulario repeti intervalo
    public setCustomSchenduleIntervals(value: IMasterElement | undefined): void {
        this.form.get('customSchenduleIntervals')?.patchValue(value);
        this.form.get('intervalWeekRepeat')?.patchValue('');
        this.initIntervalWeek();
    }

    // Guardamos el valor de la programación
    public setProgramed(): void {
        if (this.form.valid) {
            this.dialogRef.close(this.getProgramedFormValues());
        } else {
            this.form.markAllAsTouched();
        }
    }

    // Formateamos el formulario para mandarselo al back
    private getProgramedFormValues(): IScheduledProgramed {
        let scheduledProgramed: IScheduledProgramed = {
            start_at: +Math.floor(+new Date(this.form.get('dateToStart')?.value) / 1000).toString(),
            schendule_type: this.form.get('schenduleTypes')?.value?.id
        }
        if (this.form.get('schenduleTypes')?.value?.id === CUSTOM_SCHENDULE_TYPES) {
            scheduledProgramed.custom_schendule = {
                count_repeat: +this.form.get('countRepeat')?.value,
                type_interval: this.form.get('customSchenduleIntervals')?.value?.id,
                interval: this.getIntervalValue(),
                finish_repeat: this.getFinishRepeatValue()
            }
        }
        return scheduledProgramed;
    }

    // Obtenemos el valor del interval dependiendo del valor del customSchenduleIntervals
    private getIntervalValue(): null | number {
        if (this.form.get('customSchenduleIntervals')?.value.id === SchenduleIntervals.Week) {
            return this.form.get('intervalWeekRepeat')?.value.map((days: IMasterElement) => days.id);
        }
        return null;
    }

    // Obtenemos el valor del finish_repeat dependiendo del valor del customSchenduleIntervals
    private getFinishRepeatValue(): null | string | number {
        const found = this.finishRepeatElements.find((element: IMasterElement) => element.selected);
        if (found) {
            switch (found.id) {
                case FinishOption.Never:
                    return null;
                case FinishOption.Day:
                    return Math.floor(+new Date(this.form.get('finishRepeatDay')?.value) / 1000).toString();
                case FinishOption.After:
                    return this.form.get('finishCountRepeat')?.value;
            }
        }
        return null;
    }

    private initIntervalWeek(): void {
        this.intervalWeekElemets.forEach((intervalDay: IMasterElement) => {
            if (+this.currentDate.getDay() === +intervalDay.id) {
                intervalDay.selected = true;
                this.form.get('intervalWeekRepeat')?.patchValue(intervalDay);
            } else {
                intervalDay.selected = false;
            }
            
        });
    }

    private initFinishOption(): void {
        this.form.get('finishRepeatDay')?.disable();
        this.form.get('finishCountRepeat')?.disable();
        this.finishRepeatElements[0].selected = true;
    }

    // Seteamos a un formulario el validador de requerido
    private setRequiredValidators(form: string): void {
        this.form.get(form)?.addValidators([Validators.required]);
    }

    // Limpiamos todos los validadores de un formulario
    private clearValidators(form: string): void {
        this.form.get(form)?.clearValidators();
        this.form.get(form)?.updateValueAndValidity();
    }

    private timestampToDate(date: any, withHour: boolean = true): string {
        if (date) {
            return withHour ? new Date(+date * 1000).toISOString().substring(0, 16) : new Date(+date * 1000).toISOString().split('T')[0];
        }
        return '';
    }

    // Cerrar el modal
    public closeModal(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map((subs: Subscription) => subs.unsubscribe());
        }
    }

}
