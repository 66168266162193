import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AutomationService } from 'src/app/pages/automation/services/automation.service';

@Component({
    selector: 'app-modal-rename-automation',
    templateUrl: './modal-rename-automation.component.html',
    styleUrls: ['./modal-rename-automation.component.scss']
})
export class ModalRenameAutomationComponent implements OnInit {

    public formName: FormControl = new FormControl('', Validators.required);
    public loadingRenameAutomation: boolean = false;
    public errorRenameAutomation: string = '';
    public successRenameAutomation: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ModalRenameAutomationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private automationService: AutomationService
    ) { }

    ngOnInit(): void {
        this.formName.patchValue(this.data.name);
    }

    public renameAutomation(): void {
        if (this.formName.valid) {
            this.loadingRenameAutomation = true;
            this.errorRenameAutomation = '';
            this.successRenameAutomation = false;
            this.automationService.putAutomationName(this.data.id, this.formName.value)
                .subscribe({
                    next: (response: any) => {
                        if (response) {
                            this.loadingRenameAutomation = false;
                            this.successRenameAutomation = true;
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        this.loadingRenameAutomation = false;
                        this.errorRenameAutomation = error?.error?.error || 'Ha ocurrido un error al crear la plantilla,<br> inténtelo de nuevo más tarde';
                    },
                    complete: () => {
                        this.loadingRenameAutomation = false;
                    }
                });
        } else {
            this.formName.markAsTouched();
        }
    }

    public sendNewNameAndModal(): void {
        this.dialogRef.close({name: this.formName.value});
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

}
