import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAvatar } from '../../models/avatar.models';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

    @Input() design!: string;
    @Input() elements!: Array<IAvatar>;
    @Output() changeImage = new EventEmitter();

    public MAX_ELEMENTS_AVATAR: number = 4;
    public colors: Array<any> = [
        {
            fill: '#EAE8F0',
            text: '#3F3D56'
        }, {
            fill: '#EAE8F0',
            text: '#3F3D56'
        }, {
            fill: '#EAE8F0',
            text: '#4A494D'
        }, {
            fill: '#FFE3E4',
            text: '#330009'
        }, {
            fill: '#E4DDF8',
            text: '#230243'
        }, {
            fill: '#EECDF3',
            text: '#4B414D'
        }, {
            fill: '#40235B',
            text: '#FFFFFF'
        }, {
            fill: '#7756DB',
            text: '#FFFFFF'
        }, {
            fill: '#7E99F8',
            text: '#FFFFFF'
        }, {
            fill: '#AB84FF',
            text: '#FFFFFF'
        }, {
            fill: '#412BA8',
            text: '#FFFFFF'
        }, {
            fill: '#4D74BF',
            text: '#FFFFFF'
        }, {
            fill: '#CFC4F2',
            text: '#230243'
        }, {
            fill: '#967EF8',
            text: '#FFFFFF'
        }, {
            fill: '#FAABB1',
            text: '#261A1B'
        }, {
            fill: '#F87E88',
            text: '#FFFFFF'
        }, {
            fill: '#F89B7E',
            text: '#FFFFFF'
        }, {
            fill: '#00A497',
            text: '#FFFFFF'
        }, {
            fill: '#18D6C8',
            text: '#FFFFFF'
        }, {
            fill: '#B0F1EC',
            text: '#004D46'
        }, {
            fill: '#6AFFFB',
            text: '#255958'
        }, {
            fill: '#4DA4BF',
            text: '#FFFFFF'
        }, {
            fill: '#006130',
            text: '#FFFFFF'
        }, {
            fill: '#F75F00',
            text: '#FFFFFF'
        }, {
            fill: '#F23E7D',
            text: '#FFFFFF'
        }, {
            fill: '#8E0753',
            text: '#FFFFFF'
        }, {
            fill: '#D9ABC5',
            text: '#4D042D'
        }, {
            fill: '#D5F5F3',
            text: '#434D4C'
        }, {
            fill: '#E5F6EC',
            text: '#474D49'
        }, {
            fill: '#FFF2DF',
            text: '#4D4943'
        }, {
            fill: '#E6E5EE',
            text: '#4A4A4D'
        }, {
            fill: '#FFFEE6',
            text: '#4D4C45'
        }, {
            fill: '#FFE9EE',
            text: '#4D4647'
        }, {
            fill: '#EDE7FA',
            text: '#49474D'
        }
    ];

    constructor() { }

    ngOnInit(): void {
    }

    public getIniciales(name: string): string {
        if (name) {
            const arrName = name.toLocaleUpperCase().split(' ');
            if (arrName.length > 1) {
                return arrName[0].substring(0, 1) + arrName[1].substring(0, 1);
            }
            if (arrName.length === 1) {
                return arrName[0].substring(0, 2);
            }
        }
        return 'SN';
    }

    public getColors(avatar: IAvatar): number {
        if (avatar.id) {
            return Math.floor((+avatar.id + this.colors.length) % this.colors.length);
        }
        return 0;
    }

}
