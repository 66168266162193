<div class="modal" [ngClass]="{' modal--confirmation': loadingRenameAutomation || successRenameAutomation}">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <ng-container *ngIf="!loadingRenameAutomation && !successRenameAutomation">
                <div class="content__header">
                    <div class="content__header__title">
                        <h3>Editar nombre</h3>
                    </div>
                    <app-button-icon icon="icon-close" (buttonClicked)="closeModal()"></app-button-icon>
                </div>
                <div class="content__body">
                    <div class="content__body__section">
                        <div class="row">
                            <div class="col-12">
                                <div class="input-group">
                                    <label>Nombre*</label>
                                    <input type="text" placeholder="Nombre de la automatización" class="input" [formControl]="formName" [ngClass]="{'input--error': formName.invalid && formName.touched}">
                                    <span class="input-group__error-message" *ngIf="formName.invalid && formName.touched">
                                        Campo obligatorio
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="errorRenameAutomation">
                            <div class="col-12">
                                {{ errorRenameAutomation }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content__footer">
                    <app-button design="button--secondary" (buttonClicked)="closeModal()">
                        Cancelar
                    </app-button>
                    <app-button (buttonClicked)="renameAutomation()">
                        Guardar
                    </app-button>
                </div>
            </ng-container>
            <ng-container *ngIf="loadingRenameAutomation">
                <div class="content__header">
                    <app-button-icon icon="icon-close" (buttonClicked)="closeModal()"></app-button-icon>
                </div>
                <div class="content__body">
                    <app-alice
                        design="alice--lg">
                    </app-alice>
                    <h4>Actualizando nombre de la automatización..</h4>
                </div>
                <div class="content__footer">
                </div>
            </ng-container>
            <ng-container *ngIf="successRenameAutomation">
                <div class="content__header">
                    <app-button-icon icon="icon-close" (buttonClicked)="sendNewNameAndModal()"></app-button-icon>
                </div>
                <div class="content__body">
                    <app-tag-icon
                        design="tag-icon--green" 
                        icon="icon-check">
                    </app-tag-icon>
                    <h4>El nombre se ha actualizado correctamente</h4>
                </div>
                <div class="content__footer">
                    <app-button (buttonClicked)="sendNewNameAndModal()">
                        Vale
                    </app-button>
                </div>
            </ng-container>
        </div>
    </div>
</div>