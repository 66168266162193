import { Component, Input, OnInit } from '@angular/core';
import { IBreadcrumbElement } from '../../models/breadcrumb.models';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
    
    @Input() design!: string;
    @Input() elements!: Array<IBreadcrumbElement>;
    
    constructor() { }

    ngOnInit(): void {
    }

}
