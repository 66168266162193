<div class="modal modal--cards">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <div class="content__header">
                <div class="content__header__title">
                    <h3>Colaboradores</h3>
                </div>
                <app-button-icon icon="icon-close" (buttonClicked)="closeModal()"></app-button-icon>
            </div>
            <div class="content__body">
                <div class="toolbar">
                    <div class="toolbar__left">
                        <div class="input-group input-group--collapsed-input">
                            <app-icons design="icon-search"></app-icons>
                            <input class="input" type="text" placeholder="Buscar colaboradores" (input)="onSearchChange($event)" appSearchFocus>
                        </div>
                    </div>
                    <div class="toolbar__right">
                        <app-button (buttonClicked)="openModalAddCollaborators()">Invitar</app-button>
                    </div>
                </div>
                <div class="content__body__section">
                    <ng-container *ngIf="filterCollaborators?.length">
                        <div class="card card--people" *ngFor="let collaborator of filterCollaborators">
                            <div class="card__body">
                                <app-avatar [elements]="[{ img: collaborator.img, name:collaborator.full_name, id: collaborator.id }]"></app-avatar>
                                <div class="card__body__name">{{ collaborator.full_name }}</div>
                                <a class="text--red" (click)="openModalError(collaborator)">Eliminar</a>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!filterCollaborators?.length">
                        <div class="content__body__empty">
                            <img src="../../../../assets/resources/no-automatizaciones.svg" alt="">
                            <h5>No hay ningún colaborador con ese nombre</h5>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
