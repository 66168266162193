<div class="modal" [ngClass]="{' modal--confirmation': loadingAddUpdateCategory || successAddUpdateCategory}">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <ng-container *ngIf="!loadingAddUpdateCategory && !successAddUpdateCategory">
                <div class="content__header">
                    <div class="content__header__title">
                        <h3>{{ category ? 'Editar categoría de plantilla' : 'Añadir una categoría de plantilla' }}</h3>
                    </div>
                    <app-button-icon icon="icon-close" (buttonClicked)="closeModal()"></app-button-icon>
                </div>
                <div class="content__body">
                    <div class="content__body__section">
                        <div class="row">
                            <div class="col-12">
                                <div class="input-group">
                                    <label for="">Nombre de la categoría*</label>
                                    <input type="text" placeholder="Ej: Banco de datos" class="input" [formControl]="nameCategory" [ngClass]="{'input--error': nameCategory.invalid && nameCategory.touched}">
                                    <span class="input-group__error-message" *ngIf="nameCategory.invalid && nameCategory.touched">Campo obligatorio</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="errorAddUpdateCategory">
                            <div class="col-12">
                                {{ errorAddUpdateCategory }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content__footer">
                    <app-button design="button--secondary" (buttonClicked)="closeModal()">
                        Cancelar
                    </app-button>
                    <app-button (buttonClicked)="addOrUpdateCategory()">
                        Guardar
                    </app-button>
                </div>
            </ng-container>
            <ng-container *ngIf="loadingAddUpdateCategory">
                <div class="content__header"></div>
                <div class="content__body">
                    <app-alice
                        design="alice--lg">
                    </app-alice>
                    <h4>{{ category ? 'Actualizando categoría' : 'Creando categoría...' }}</h4>
                </div>
                <div class="content__footer">
                </div>
            </ng-container>
            <ng-container *ngIf="successAddUpdateCategory">
                <div class="content__header"></div>
                <div class="content__body">
                    <app-tag-icon
                        design="tag-icon--green" 
                        icon="icon-check">
                    </app-tag-icon>
                    <h4>{{ category ? 'La categoría se ha editado correctamente' : 'La categoría se ha creado correctamente' }}</h4>
                </div>
                <div class="content__footer">
                    <app-button (buttonClicked)="closeModal()">
                        Vale
                    </app-button>
                </div>
            </ng-container>
        </div>
    </div>
</div>