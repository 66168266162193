<div class="modal">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <div class="content__header">
                <div class="content__header__title">
                    <h3>Programar automatización</h3>
                </div>
                <app-button-icon icon="icon-close" (buttonClicked)="closeModal()"></app-button-icon>
            </div>
            <div class="content__body">
                <div class="content__body__section" [formGroup]="form">
                    <div class="row">
                        <div class="col-6">
                            <div class="input-group">
                                <label for="dateToStart">Que se ejecute...</label>
                                <input type="datetime-local" class="input"
                                    [formControlName]="'dateToStart'" 
                                    [ngClass]="{'input--error': form.get('dateToStart')?.invalid && form.get('dateToStart')?.touched}"
                                    [min]="currentDate.toISOString().substring(0, 16)">
                                <span class="input-group__error-message" *ngIf="form.get('dateToStart')?.errors?.['required'] && form.get('dateToStart')?.touched">
                                    Campo obligatorio
                                </span>
                            </div>
                        </div>
                        <!-- <div class="col-3">
                            <div class="input-group">
                                <label for="hourToStart">A las...</label>
                                <input type="time" class="input"
                                    [formControlName]="'hourToStart'" 
                                    [ngClass]="{'input--error': form.get('hourToStart')?.invalid && form.get('hourToStart')?.touched}"
                                    [min]="currentDate | date:'yyyy-MM-dd'">
                                <span class="input-group__error-message" *ngIf="form.get('hourToStart')?.errors?.['required'] && form.get('hourToStart')?.touched">
                                    Campo obligatorio
                                </span>
                            </div>
                        </div> -->
                        <div class="col-6">
                            <div class="input-group">
                                <label for="schenduleTypes">Repetimos cada...</label>
                                <app-dropdown-select [options]="masters?.schendule_types"
                                    [value]="form.get('schenduleTypes')?.value"
                                    (valueChange)="setSchenduleTypesValue($event)"
                                    [ngClass]="{'input--error': form.get('schenduleTypes')?.invalid && form.get('schenduleTypes')?.touched}">
                                    Repetimos cada
                                </app-dropdown-select>
                                <span class="input-group__error-message" *ngIf="form.get('schenduleTypes')?.errors?.['required'] && form.get('schenduleTypes')?.touched">
                                    Campo obligatorio
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="form.get('schenduleTypes')?.value?.id === CUSTOM_SCHENDULE_TYPES">
                        <label>Periodicidad personalizada</label>
                        <label>Repetir cada</label>
                        <div class="col-2">
                            <div class="input-group">
                                <input type="number" class="input"
                                    [formControlName]="'countRepeat'"
                                    min="1"
                                    [ngClass]="{'input--error': form.get('countRepeat')?.invalid && form.get('countRepeat')?.touched}">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group">
                                <app-dropdown-select [options]="masters?.custom_schendule_intervals"
                                    [value]="form.get('customSchenduleIntervals')?.value"
                                    (valueChange)="setCustomSchenduleIntervals($event)"
                                    [ngClass]="{'input--error': form.get('customSchenduleIntervals')?.invalid && form.get('customSchenduleIntervals')?.touched}">
                                    Selecciona el intervalo
                                </app-dropdown-select>
                            </div>
                        </div>
                        <div class="col-7 pagination__navigation__pages" *ngIf="form.get('customSchenduleIntervals')?.value?.id === schenduleIntervals.Week">
                            <app-button *ngFor="let day of intervalWeekElemets"
                                [design]="'pages__page ' + (day.selected ? 'pages__page--current' : '')"
                                (buttonClicked)="changeValue(day)">
                                {{ day.name }}
                            </app-button>
                        </div>
                        <label>Termina</label>
                        <div class="col-12">
                            <div class="input-group">
                                <app-button [design]="'button--toggle ' + (finishRepeatElements[0].selected ? 'button--toggle--toggled' : '')"
                                    (buttonClicked)="selectFinishOption(finishRepeatElements[0])">
                                    {{ finishRepeatElements[0].name }}
                                </app-button>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group">
                                <app-button [design]="'button--toggle ' + (finishRepeatElements[1].selected ? 'button--toggle--toggled' : '')"
                                    (buttonClicked)="selectFinishOption(finishRepeatElements[1])">
                                    {{ finishRepeatElements[1].name }}
                                </app-button>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="input-group">
                                <input type="date" class="input"
                                    [formControlName]="'finishRepeatDay'" 
                                    [ngClass]="{'input--error': form.get('finishRepeatDay')?.invalid && form.get('finishRepeatDay')?.touched}"
                                    [min]="currentDate | date:'yyyy-MM-dd'">
                                <span class="input-group__error-message" *ngIf="form.get('finishRepeatDay')?.errors?.['required'] && form.get('finishRepeatDay')?.touched">
                                    Campo obligatorio
                                </span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group">
                                <app-button [design]="'button--toggle ' + (finishRepeatElements[2].selected ? 'button--toggle--toggled' : '')"
                                    (buttonClicked)="selectFinishOption(finishRepeatElements[2])">
                                    {{ finishRepeatElements[2].name }}
                                </app-button>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="input-group">
                                <input type="number" class="input"
                                    [formControlName]="'finishCountRepeat'"
                                    min="1"
                                    max="99999"
                                    [ngClass]="{'input--error': form.get('finishCountRepeat')?.invalid && form.get('finishCountRepeat')?.touched}">
                                <span class="input-group__error-message" *ngIf="form.get('finishCountRepeat')?.errors?.['required'] && form.get('finishCountRepeat')?.touched">
                                    Campo obligatorio
                                </span>
                            </div>
                        </div>
                        <div class="col-4" style="align-self: center">
                            <div class="input-group">
                                repeticiones
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__footer">
                <app-button design="button--secondary" (buttonClicked)="closeModal()">
                    Cancelar
                </app-button>
                <app-button (buttonClicked)="setProgramed()">
                    Guardar
                </app-button>
            </div>
        </div>
    </div>
</div>

