import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { IMasterElement } from 'src/app/core/models/masters.models';
import { IChipElement } from '../../models/chips.models';
import { IFilter, IListFilter } from '../../models/filters.models';
import { FiltersService } from '../../services/filters.service';

@Component({
    selector: 'app-chips',
    templateUrl: './chips.component.html',
    styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit, OnChanges {

    @Input() design!: string;
    @Input() chips!: Array<IChipElement>;
    @Input() filters!: IFilter;
    @Input() disabled!: boolean;
    @Output() chipsChange = new EventEmitter<Array<IChipElement>>();
    @Output() filtersChange = new EventEmitter<IFilter>();
    
    constructor(
        private filtersService: FiltersService
    ) { }

    ngOnInit(): void {
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['filters']?.currentValue && this.filters?.listFilter?.length) {
            this.updateChips();
        }
    }

    public removeChip(index: number, chip: IChipElement): void {
        if (this.filters?.listFilter?.length) {
            this.filters.listFilter.map((filter: IListFilter) => {
                if (filter.name === chip.name){
                    const foundIndex = filter.value.findIndex((value: any) => value === chip.value);
                    if (foundIndex !== -1) {
                        filter.value.splice(foundIndex, 1);
                    }
                }
            });
            this.filtersService.setApplyFilters(this.filters);
            this.filtersChange.emit(this.filters);
        }
        this.chips.splice(index, 1);
        this.chipsChange.emit(this.chips);
    }

    public updateChips() {
        this.chips = [];
        this.filters.listFilter.forEach((filter: IListFilter) => {
            if (filter.value && filter.value.length) {
                filter.value.map((valueFilter: any) => {
                    this.chips.push({
                        label: `${filter.title}: ${filter.options?.length ? filter.options.find((option: IMasterElement) => option.id === valueFilter || option.id === valueFilter?.id)?.name : valueFilter}`,
                        name: filter.name,
                        value: valueFilter
                    })
                })
            }
        });
        this.chipsChange.emit(this.chips);
    }

}
