<div 
    class="pagination" 
    *ngIf="pagination && totalPages > 1"
    (keyup.enter)="onSubmit()"
    [ngClass]="design">
    <div class="pagination__navigation">
        <app-button-icon icon="icon-chevron-left" [disabled]="+pagination.page === 1" (buttonClicked)="removePage()"></app-button-icon>
        <div class="pagination__navigation__pages">
            <ng-container *ngFor="let page of pagesToShow">
                <app-button 
                    *ngIf="page !== '...'" 
                    [design]="'pages__page ' + (page === +pagination.page ? 'pages__page--current' : '')"
                    (buttonClicked)="moveToPage(page)">
                    {{ page }}
                </app-button>
                <span *ngIf="page === '...'" class="pages__page">{{ page }}</span>
            </ng-container>
        </div>
        <app-button-icon icon="icon-chevron-right" [disabled]="+pagination.page === totalPages" (buttonClicked)="addPage()"></app-button-icon>
    </div>
    <div class="pagination__go">
        <span>Página</span>
        <input class="input" type="text" [(ngModel)]="inputPage">
    </div>
</div>
