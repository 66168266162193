import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-modal-add-collaborators',
    templateUrl: './modal-add-collaborators.component.html',
    styleUrls: ['./modal-add-collaborators.component.scss']
})
export class ModalAddCollaboratorsComponent implements OnInit {

    public loadingInviteCollaborator: boolean = false;
    public errorInviteCollaborator: string = '';

    constructor(
        public dialogRef: MatDialogRef<ModalAddCollaboratorsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
    }

    public confirmModal(): void {
        // TODO
        this.loadingInviteCollaborator = true;
        // this.openModalConfirmation()
    }

    public openModalConfirmation() {
        this.closeModal();
        this.utilsService.openModalGeneric({ 
            type: 'confirm',
            title: 'Se ha enviado la invitación correctamente',
            textButtonConfirm: 'Vale'
        });
    }

    public closeModal(): void {
        this.dialogRef.close();
    }
}
