import { Component, Input, OnInit } from '@angular/core';
import { SkeletonTypes } from '../../models/skeleton.models';

@Component({
    selector: 'app-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

    @Input() type!: SkeletonTypes;
    @Input() numColumnTable: number = 2;
    @Input() numRowTable: number = 1;
    @Input() numRowSidebar: number = 4;

    public skeletonTypes = SkeletonTypes;

    constructor() { }

    ngOnInit(): void {
    }

}
