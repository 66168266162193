<div class="card" [ngClass]="{'card--selected': template.selected}" *ngIf="template">
    <div class="card__header">
        <div class="card__header__title">
            {{ template.name }}
        </div>
        <div class="card__header__info">
            <div>{{ template.owner }} {{ template.owner && template.category ? '·' : '' }} {{ template.category }}</div>
            <app-tag design="tag--small tag--success" *ngIf="template.public">Pública</app-tag>
            <app-tag design="tag--small" *ngIf="!template.public">Privada</app-tag>
        </div>
    </div>
    <div class="card__body">
        <div class="flex ai-center">
            <ng-container *ngFor="let connection of template?.connections; index as i">
                <app-tag-icon icon="icon-capture-opened-window" *ngIf="i < 2 || (i === 2 && template.connections.length === 3)"></app-tag-icon>
                <app-tag-icon *ngIf="template.connections.length > 3 && i === 2">
                    +{{ template.connections.length - 2 }}
                </app-tag-icon>
            </ng-container>
        </div>
        <app-rating design="rating--disabled" 
            *ngIf="template.rating !== null && template.rating !== undefined && template.public"
            [value]="template.rating">
        </app-rating>
    </div>
</div>
