import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PruebaComponent } from './components/prueba/prueba.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertComponent } from './design-components/alert/alert.component';
import { ListDesignComponent } from './design-components/list-design/list-design.component';
import { AyudaComponent } from './components/ayuda/ayuda.component';
import { AliceComponent } from './design-components/alice/alice.component';
import { IconsComponent } from './design-components/icons/icons.component';
import { BadgeComponent } from './design-components/badge/badge.component';
import { BannerComponent } from './design-components/banner/banner.component';
import { BreadcrumbComponent } from './design-components/breadcrumb/breadcrumb.component';
import { CardComponent } from './design-components/card/card.component';
import { CheckboxComponent } from './design-components/checkbox/checkbox.component';
import { DropdownComponent } from './design-components/dropdown/dropdown.component';
import { FiltersComponent } from './design-components/filters/filters.component';
import { PaginationComponent } from './design-components/pagination/pagination.component';
import { ProgressComponent } from './design-components/progress/progress.component';
import { SidebarComponent } from './design-components/sidebar/sidebar.component';
import { TableComponent } from './design-components/table/table.component';
import { TabsComponent } from './design-components/tabs/tabs.component';
import { ButtonComponent } from './design-components/button/button.component';
import { ButtonIconComponent } from './design-components/button-icon/button-icon.component';
import { RouterModule } from '@angular/router';
import { ComponentsListDesignComponent } from './design-components/components-list-design/components-list-design.component';
import { AvatarComponent } from './design-components/avatar/avatar.component';
import { TagComponent } from './design-components/tag/tag.component';
import { TagIconComponent } from './design-components/tag-icon/tag-icon.component';
import { RatingComponent } from './design-components/rating/rating.component';
import { ChipsComponent } from './design-components/chips/chips.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DropdownSelectComponent } from './design-components/dropdown-select/dropdown-select.component';
import { ModalGenericComponent } from './design-components/modal-generic/modal-generic.component';
import { ModalExampleComponent } from './design-components/modal-example/modal-example.component';
import { SwitcherComponent } from './design-components/switcher/switcher.component';
import { HttpClientModule } from '@angular/common/http';
import { ModalCreateAutomationComponent } from './components/modal-create-automation/modal-create-automation.component';
import { SearchFocusDirective } from './directives/search-focus.directive';
import { ModalCollaboratorsComponent } from './components/modal-collaborators/modal-collaborators.component';
import { ModalAddCollaboratorsComponent } from './components/modal-add-collaborators/modal-add-collaborators.component';
import { SidebarTemplateComponent } from './components/sidebar-template/sidebar-template.component';
import { SkeletonComponent } from './design-components/skeleton/skeleton.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { CardTemplateComponent } from './components/card-template/card-template.component';
import { ModalAddUpdateCategoryComponent } from './components/modal-add-update-category/modal-add-update-category.component';
import { ModalConfAutomationComponent } from './components/modal-conf-automation/modal-conf-automation.component';
import { OnboardingAliceComponent } from './design-components/onboarding-alice/onboarding-alice.component';
import { ModalRenameAutomationComponent } from './components/modal-rename-automation/modal-rename-automation.component';
import { ModalConfProgramedAutomationComponent } from './components/modal-conf-programed-automation/modal-conf-programed-automation.component';
import { WatchDomTreeDirective } from './directives/watch-dom-tree.directive';
import { ModalDataSourceComponent } from './components/modal-data-source/modal-data-source.component';
import { InputDataSourceDirective } from './directives/input-data-source.directive';
import { ModalAutomationByConnectionComponent } from './components/modal-automation-by-connection/modal-automation-by-connection.component';

@NgModule({
    declarations: [
        PruebaComponent,
        AlertComponent,
        ListDesignComponent,
        AyudaComponent,
        AliceComponent,
        IconsComponent,
        BadgeComponent,
        BannerComponent,
        BreadcrumbComponent,
        CardComponent,
        CheckboxComponent,
        DropdownComponent,
        FiltersComponent,
        PaginationComponent,
        ProgressComponent,
        SidebarComponent,
        TableComponent,
        TabsComponent,
        ButtonComponent,
        ButtonIconComponent,
        ComponentsListDesignComponent,
        AvatarComponent,
        TagComponent,
        TagIconComponent,
        RatingComponent,
        ChipsComponent,
        DropdownSelectComponent,
        ModalGenericComponent,
        ModalExampleComponent,
        SwitcherComponent,
        ModalCreateAutomationComponent,
        SidebarTemplateComponent,
        ModalCollaboratorsComponent,
        ModalAddCollaboratorsComponent,
        SkeletonComponent,
        ErrorMessageComponent,
        CardTemplateComponent,
        ModalAddUpdateCategoryComponent,
        ModalConfAutomationComponent,
        OnboardingAliceComponent,
        ModalRenameAutomationComponent,
        ModalConfProgramedAutomationComponent,
        ModalDataSourceComponent,
        // Directives,
        SearchFocusDirective,
        WatchDomTreeDirective,
        InputDataSourceDirective,
        ModalAutomationByConnectionComponent
    ],
    exports: [
        // Modules
        DragDropModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        HttpClientModule,
        // Components
        PruebaComponent,
        // Directives,
        SearchFocusDirective,
        WatchDomTreeDirective,
        InputDataSourceDirective,
        // Design components
        AlertComponent,
        AliceComponent,
        IconsComponent,
        BadgeComponent,
        BannerComponent,
        BreadcrumbComponent,
        CardComponent,
        CheckboxComponent,
        DropdownComponent,
        FiltersComponent,
        PaginationComponent,
        ProgressComponent,
        SidebarComponent,
        TableComponent,
        TabsComponent,
        ButtonComponent,
        ButtonIconComponent,
        AvatarComponent,
        TagComponent,
        TagIconComponent,
        RatingComponent,
        ChipsComponent,
        DropdownSelectComponent,
        ModalGenericComponent,
        ModalExampleComponent,
        SwitcherComponent,
        ModalCreateAutomationComponent,
        SidebarTemplateComponent,
        SkeletonComponent,
        ErrorMessageComponent,
        CardTemplateComponent,
        ModalAddUpdateCategoryComponent,
        ModalConfAutomationComponent,
        OnboardingAliceComponent
    ],
    imports: [
        CommonModule, 
        DragDropModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatDialogModule,
        HttpClientModule
    ]
})
export class SharedModule { }
