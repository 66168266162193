<div class="flex" style="width: 100%;height: 100%;">
    <div class="sidebar-prov">
        <h5>Componentes</h5>
        <a routerLink="/components-list-desing/alert">Alert</a>
        <a routerLink="/components-list-desing/alice">Alice</a>
        <a routerLink="/components-list-desing/avatar">Avatar</a>
        <a routerLink="/components-list-desing/badge">Badge</a>
        <a routerLink="/components-list-desing/banner">Banner</a>
        <a routerLink="/components-list-desing/breadcrumb">Breadcrumb</a>
        <a routerLink="/components-list-desing/button">Button</a>
        <a routerLink="/components-list-desing/button-icon">Button icon</a>
        <a routerLink="/components-list-desing/progress">Progress</a>
        <a routerLink="/components-list-desing/sidebar">Sidebar</a>
        <a routerLink="/components-list-desing/tabs">Tabs</a>
        <a routerLink="/components-list-desing/tile">Tile</a>
        <a routerLink="/components-list-desing/tag">Tag</a>
        <a routerLink="/components-list-desing/tag-icon">Tag Icon</a>
        <a routerLink="/components-list-desing/table">Table</a> 
        <a routerLink="/components-list-desing/icons">Icons</a>
        <a routerLink="/components-list-desing/dropdown">Dropdown</a>
        <a routerLink="/components-list-desing/input">Input</a>
        <a routerLink="/components-list-desing/input-button">Input Button</a>
        <a routerLink="/components-list-desing/card">Card</a>
        <a routerLink="/components-list-desing/rating">Rating</a>
        <a routerLink="/components-list-desing/tooltip">Tooltip</a>
        <a routerLink="/components-list-desing/modal">Modal</a>
        <a routerLink="/components-list-desing/modal-confirmation">Modal Confirmation</a>
        <a routerLink="/components-list-desing/modal-cards">Modal with cards</a>
        <a routerLink="/components-list-desing/images">Images</a>
        <a routerLink="/components-list-desing/switcher">Switcher</a>
        <a routerLink="/components-list-desing/toolbar">Toolbar</a>
        <a routerLink="/components-list-desing/pagination">Pagination</a>
        <a routerLink="/components-list-desing/chips">Chips</a>
        <a routerLink="/components-list-desing/filters">Filters</a>
        <a routerLink="/components-list-desing/item">Item</a>
        <a routerLink="/components-list-desing/tree">Tree</a>
        <a routerLink="/components-list-desing/tree-node">Tree Node</a>
        <a routerLink="/components-list-desing/collapse-action">Collapse action</a>
        <a routerLink="/components-list-desing/sidebar-item">Sidebar item</a>
        <a routerLink="/components-list-desing/onboarding-alice">Onboarding Alice</a>
        <a routerLink="/components-list-desing/loader-alice">Loader Alice</a>
        <a routerLink="/components-list-desing/error-message">Error Message</a>
        <a routerLink="/components-list-desing/node-preview">Node preview</a>
    </div>
    
    <div class="fg-2" style="padding: 40px;">
        
        <!-- ALICE -->
        <ng-container *ngIf="component === 'alice'">
            <h2>Alice</h2>
            <h4 class="m-b-16 m-t-16">Default</h4>
            <app-alice></app-alice>

            <h4 class="m-b-16 m-t-16">Default Pulse</h4>
            <app-alice design="alice--pulse"></app-alice>

            <h4 class="m-b-16 m-t-16">Alice small</h4>
            <app-alice design="alice--sm"></app-alice>

            <h4 class="m-b-16 m-t-16">Alice Large</h4>
            <app-alice design="alice--lg"></app-alice>

            <h4 class="m-b-16 m-t-16">Alice Big</h4>
            <div class="alice-container" style="height: 400px;background:#230243;display:flex;align-items:center;justify-content:center;">
                <app-alice design="alice--big"></app-alice>
            </div>

            <h4 class="m-b-16 m-t-32">Alice Big Pulse</h4>
            <div class="alice-container" style="height: 400px;background:#230243;display:flex;align-items:center;justify-content:center;">
                <app-alice design="alice--big alice--big--pulse"></app-alice>
            </div>
        </ng-container>

        <!-- ALERT -->
        <ng-container *ngIf="component === 'alert'">
            <h2 class="m-b-16">Alert</h2>
            
            <h5 class="m-b-8">Default</h5>
            <app-alert>
                No hay ningún error en esta conexión
            </app-alert>
            
            <h5 class="m-b-8">Error</h5>
            <app-alert 
                design="alert--error" 
                icon="alice"
                actionButtonText="Ver" 
                (buttonClicked)="buttonClicked()">
                He detectado <b>2 errores</b> en las conexiones
            </app-alert>

            <h5 class="m-b-8">Default Expanded</h5>
            <app-alert 
                design="alert--expanded"
                actionButtonText="Ir a conexión" 
                bodyText="No hay ningún error en esta conexión" 
                (buttonClicked)="buttonClicked()">
                    No hay ningún error en esta conexión
            </app-alert>

            <h5 class="m-b-8">Error Expanded</h5>
            <app-alert 
                design="alert--expanded alert--error" 
                icon="alice"
                actionButtonText="Ir a conexión"
                bodyText="Hay 1 conexión con errores. Reconecta esa conexión" 
                (buttonClicked)="buttonClicked()">
                    He detectado <b>1 errores</b> en la automatización
            </app-alert>
            <app-alert 
                design="alert--expanded alert--error" 
                icon="alice"
                actionButtonText="Ir a conexión"
                bodyText="Hay 1 conexión con errores. Reconecta esa conexión"
                [reconect]="true"
                (buttonClicked)="buttonClicked()"
                (reconectClicked)="buttonClicked()">
                    He detectado <b>1 errores</b> en la automatización
            </app-alert>

            <h5 class="m-t-16 m-b-8">First steps</h5>
            <app-alert
                design="alert--first-steps"
                [confFirstSteps]="confFirstSteps">
            </app-alert>
        </ng-container>

        <!-- ICONS -->
        <ng-container *ngIf="component === 'icons'">
            <h2 class="m-b-16">Icons</h2>
            <h5 class="m-b-8 ">Sizes</h5>
            <div class="flex">
                <app-icons design="icon-bell i-xs"></app-icons>
                <app-icons design="icon-bell i-sm"></app-icons>
                <app-icons design="icon-bell i-md"></app-icons>
                <app-icons design="icon-bell i-lg"></app-icons>
                <app-icons design="icon-bell i-xl"></app-icons>
            </div>
            
            <h5 class="m-b-8 ">Catalog</h5>
            <table class="table">
                <tr>
                    <td>
                        <app-icons design="icon-activity"></app-icons>
                    </td>
                    <td>
                        icon-activity
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-alert"></app-icons>
                    </td>
                    <td>
                        icon-alert
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-arrow-double"></app-icons>
                    </td>
                    <td>
                        icon-arrow-double
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-arrow-down"></app-icons>
                    </td>
                    <td>
                        icon-arrow-down
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-arrow-left"></app-icons>
                    </td>
                    <td>
                        icon-arrow-left
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-arrow-right"></app-icons>
                    </td>
                    <td>
                        icon-arrow-right
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-back-ajax-result"></app-icons>
                    </td>
                    <td>
                        icon-back-ajax-result
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-bell"></app-icons>
                    </td>
                    <td>
                        icon-bell
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-book-open"></app-icons>
                    </td>
                    <td>
                        icon-book-open
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-bulb"></app-icons>
                    </td>
                    <td>
                        icon-bulb
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-calendar"></app-icons>
                    </td>
                    <td>
                        icon-calendar
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-capture-opened-window"></app-icons>
                    </td>
                    <td>
                        icon-capture-opened-window
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-capture-popup"></app-icons>
                    </td>
                    <td>
                        icon-capture-popup
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-change-user-agent"></app-icons>
                    </td>
                    <td>
                        icon-change-user-agent
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-change-window"></app-icons>
                    </td>
                    <td>
                        icon-change-window
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-check-circle"></app-icons>
                    </td>
                    <td>
                        icon-check-circle
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-check"></app-icons>
                    </td>
                    <td>
                        icon-check
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-chevron-down"></app-icons>
                    </td>
                    <td>
                        icon-chevron-down
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-chevron-right"></app-icons>
                    </td>
                    <td>
                        icon-chevron-right
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-clic-coordenates"></app-icons>
                    </td>
                    <td>
                        icon-clic-coordenates
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-click"></app-icons>
                    </td>
                    <td>
                        icon-click
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-clipboard"></app-icons>
                    </td>
                    <td>
                        icon-clipboard
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-clock"></app-icons>
                    </td>
                    <td>
                        icon-clock
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-close-window"></app-icons>
                    </td>
                    <td>
                        icon-close-window
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-close"></app-icons>
                    </td>
                    <td>
                        icon-close
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-columns"></app-icons>
                    </td>
                    <td>
                        icon-columns
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-conection"></app-icons>
                    </td>
                    <td>
                        icon-conection
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-copy"></app-icons>
                    </td>
                    <td>
                        icon-copy
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-corner-up-left"></app-icons>
                    </td>
                    <td>
                        icon-corner-up-left
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-corner-up-right"></app-icons>
                    </td>
                    <td>
                        icon-corner-up-right
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-database"></app-icons>
                    </td>
                    <td>
                        icon-database
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-double-click"></app-icons>
                    </td>
                    <td>
                        icon-double-click
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-download-file"></app-icons>
                    </td>
                    <td>
                        icon-download-file
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-download"></app-icons>
                    </td>
                    <td>
                        icon-download
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-edit-2"></app-icons>
                    </td>
                    <td>
                        icon-edit-2
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-evaluate-js"></app-icons>
                    </td>
                    <td>
                        icon-evaluate-js
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-extract-data"></app-icons>
                    </td>
                    <td>
                        icon-extract-data
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-eye-off"></app-icons>
                    </td>
                    <td>
                        icon-eye-off
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-eye"></app-icons>
                    </td>
                    <td>
                        icon-eye
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-file"></app-icons>
                    </td>
                    <td>
                        icon-file
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-fill-form"></app-icons>
                    </td>
                    <td>
                        icon-fill-form
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-filter"></app-icons>
                    </td>
                    <td>
                        icon-filter
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-folder"></app-icons>
                    </td>
                    <td>
                        icon-folder
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-group"></app-icons>
                    </td>
                    <td>
                        icon-group
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-hand"></app-icons>
                    </td>
                    <td>
                        icon-hand
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-help-circle"></app-icons>
                    </td>
                    <td>
                        icon-help-circle
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-home"></app-icons>
                    </td>
                    <td>
                        icon-home
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-info"></app-icons>
                    </td>
                    <td>
                        icon-info
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-inject-js"></app-icons>
                    </td>
                    <td>
                        icon-inject-js
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-intercept-ajax"></app-icons>
                    </td>
                    <td>
                        icon-intercept-ajax
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-layout"></app-icons>
                    </td>
                    <td>
                        icon-layout
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-level-0"></app-icons>
                    </td>
                    <td>
                        icon-level-0
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-log-out"></app-icons>
                    </td>
                    <td>
                        icon-log-out
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-maximize"></app-icons>
                    </td>
                    <td>
                        icon-maximize
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-menu"></app-icons>
                    </td>
                    <td>
                        icon-menu
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-message-circle"></app-icons>
                    </td>
                    <td>
                        icon-message-circle
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-message-square"></app-icons>
                    </td>
                    <td>
                        icon-message-square
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-minimize"></app-icons>
                    </td>
                    <td>
                        icon-minimize
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-more"></app-icons>
                    </td>
                    <td>
                        icon-more
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-move"></app-icons>
                    </td>
                    <td>
                        icon-move
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-open-web"></app-icons>
                    </td>
                    <td>
                        icon-open-web
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-package"></app-icons>
                    </td>
                    <td>
                        icon-package
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-pause"></app-icons>
                    </td>
                    <td>
                        icon-pause
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-play"></app-icons>
                    </td>
                    <td>
                        icon-play
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-plus"></app-icons>
                    </td>
                    <td>
                        icon-plus
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-refresh"></app-icons>
                    </td>
                    <td>
                        icon-refresh
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-resize-viewport"></app-icons>
                    </td>
                    <td>
                        icon-resize-viewport
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-return-nav-state"></app-icons>
                    </td>
                    <td>
                        icon-return-nav-state
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-rocket"></app-icons>
                    </td>
                    <td>
                        icon-rocket
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-scissors"></app-icons>
                    </td>
                    <td>
                        icon-scissors
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-scroll"></app-icons>
                    </td>
                    <td>
                        icon-scroll
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-search"></app-icons>
                    </td>
                    <td>
                        icon-search
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-selector"></app-icons>
                    </td>
                    <td>
                        icon-selector
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-settings"></app-icons>
                    </td>
                    <td>
                        icon-settings
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-star"></app-icons>
                    </td>
                    <td>
                        icon-star
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-star-filled"></app-icons>
                    </td>
                    <td>
                        icon-star-filled
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-steady-page"></app-icons>
                    </td>
                    <td>
                        icon-steady-page
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-trash"></app-icons>
                    </td>
                    <td>
                        icon-trash
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-trending-down"></app-icons>
                    </td>
                    <td>
                        icon-trending-down
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-trending-up"></app-icons>
                    </td>
                    <td>
                        icon-trending-up
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-ungroup"></app-icons>
                    </td>
                    <td>
                        icon-ungroup
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-upload-file"></app-icons>
                    </td>
                    <td>
                        icon-upload-file
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-upload-form"></app-icons>
                    </td>
                    <td>
                        icon-upload-form
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-upload"></app-icons>
                    </td>
                    <td>
                        icon-upload
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-wait-element"></app-icons>
                    </td>
                    <td>
                        icon-wait-element
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-icons design="icon-workflow"></app-icons>
                    </td>
                    <td>
                        icon-workflow
                    </td>
                </tr>
            </table>
        </ng-container>

        <!-- BUTTON -->
        <ng-container *ngIf="component === 'button'">
            <h2 class="m-b-16">Button</h2>
            <h5 class="m-b-8">Primary</h5>
            <div class="flex ai">
                <app-button class="m-r-16" (click)="buttonClicked()">
                    Default
                </app-button>
                <app-button class="m-r-16" [disabled]="true">
                    Disabled
                </app-button>
                <app-button [icon]="'icon-arrow-right'" (click)="buttonClicked()">
                    With icon
                </app-button>
            </div>
    
            <h5 class="m-t-16 m-b-8">Secondary</h5>
            <div class="flex ai-flex-start">
                <app-button design="button--secondary m-r-16" (buttonClicked)="buttonClicked()">
                    Default
                </app-button>
                <app-button design="button--secondary m-r-16" [disabled]="true">
                    Disabled
                </app-button>
                <app-button design="button--secondary m-r-16" [icon]="'icon-arrow-right'" (buttonClicked)="buttonClicked()">
                    With icon
                </app-button>
            </div>

            <h5 class="m-t-16 m-b-8">Soft</h5>
            <div class="flex ai-flex-start">
                <app-button design="button--soft m-r-16" [icon]="'icon-arrow-right'" (buttonClicked)="buttonClicked()">
                    Default
                </app-button>
                <app-button design="button--soft m-r-16" [disabled]="true" [icon]="'icon-arrow-right'">
                    Disabled
                </app-button>
            </div>

            <h5 class="m-t-16 m-b-8">Toggle Button</h5>
            <div class="button--toggle__wrapper">
                <app-button design="button--toggle button--toggle--toggled">
                    Toggle Button Toggled
                </app-button>
                <app-button design="button--toggle">
                    Toggle Button
                </app-button>
                <app-button design="button--toggle">
                    Toggle Button
                </app-button>
            </div>
    
            <h5 class="m-t-16 m-b-8">Sizes</h5>
            <div class="flex ai-flex-start">
                <app-button design="button--lg m-r-16" (click)="buttonClicked()">
                    Large
                </app-button>
                <app-button class="m-r-16" (click)="buttonClicked()">
                    Medium (default)
                </app-button>
                <app-button design="button--sm m-r-16" (click)="buttonClicked()">
                    Small
                </app-button>
                <app-button design="button--xs m-r-16" (click)="buttonClicked()">
                    Extra Small
                </app-button>
            </div>
            <div class="flex ai-flex-start m-t-16">
                <app-button design="button--secondary button--lg m-r-16" (click)="buttonClicked()">
                    Large
                </app-button>
                <app-button design="button--secondary m-r-16" (click)="buttonClicked()">
                    Medium (default)
                </app-button>
                <app-button design="button--secondary button--sm m-r-16" (click)="buttonClicked()">
                    Small
                </app-button>
                <app-button design="button--secondary button--xs m-r-16" (click)="buttonClicked()">
                    Extra Small
                </app-button>
            </div>
        </ng-container>

        <!-- AVATAR -->
        <ng-container *ngIf="component === 'avatar'">
            <h2 class="m-b-16">Avatar</h2>

            <h5 class="m-b-8">Default</h5>
            <app-avatar [elements]="[{
                id: '45',
                img: null,
                name: 'Rafa Cruz'
            }]"></app-avatar>
            
            <h5 class="m-b-8">Default with image</h5>
            <app-avatar [elements]="[{
                id: '64',
                img: '../../../../assets/resources/avatar.png',
                name: 'Rafa Cruz'
            }]"></app-avatar>

            <h5 class="m-t-16 m-b-8">Avatar group</h5>
            <app-avatar [elements]="confAvatar"></app-avatar>

            <h5 class="m-t-16 m-b-8">Change Avatar default</h5>
            <app-avatar design="avatar--big" [elements]="[{
                id: '12',
                img: null,
                name: 'Rafa Cruz'
            }]" (changeImage)="buttonClicked()"></app-avatar>

            <h5 class="m-t-16 m-b-8">Change Avatar with image</h5>
            <app-avatar design="avatar--big" [elements]="[{
                id: '2',
                img: '../../../../assets/resources/avatar.png',
                name: 'Rafa Cruz'
            }]" (changeImage)="buttonClicked()"></app-avatar>
        </ng-container>

        <!-- BUTTON-ICON -->
        <ng-container *ngIf="component === 'button-icon'">
            <h2 class="m-b-16">Button-icon</h2>
            <h5 class="m-b-8">Default</h5>
            <div class="flex ai">
                <app-button-icon design="m-r-8" icon="icon-bell"></app-button-icon>
                <app-button-icon icon="icon-bell" [disabled]="true"></app-button-icon>
            </div>
            <h5 class="m-b-8">With badge</h5>
            <div class="flex ai">
                <app-button-icon icon="icon-bell" [badge]="2"></app-button-icon>
                <app-button-icon icon="icon-bell" [badge]="2" [disabled]="true"></app-button-icon>
            </div>

            <h5 class="m-t-32 m-b-8">Status inside toolbar o sidebar</h5>
            <h6 class="m-b-8">Selected</h6>
            <app-button-icon design="button-icon--selected" icon="icon-bell"></app-button-icon>

            <h6 class="m-t-16 m-b-8">Error</h6>
            <app-button-icon design="button-icon--warning" icon="icon-bell" [badge]="2"></app-button-icon>

            <h6 class="m-t-16 m-b-8">Circle</h6>
            <app-button-icon design="button-icon--circle" icon="icon-plus"></app-button-icon>
        </ng-container>
    
        <!-- BREADCRUMB -->
        <ng-container *ngIf="component === 'breadcrumb'">
            <h2 class="m-b-16">Breadcrumb</h2>

            <app-breadcrumb [elements]="confBreadcrumb"></app-breadcrumb>
        </ng-container>
    
        <!-- PROGRESS -->
        <ng-container *ngIf="component === 'progress'">
            <h2 class="m-b-16">Progress</h2>
            <h5 class="m-b-8">Default</h5>

            <h6 class="m-b-8">Default</h6>
            <app-progress [value]="25"></app-progress>
            <h6 class="m-b-8">Green</h6>
            <app-progress design="progress--green" [value]="50"></app-progress>
            <h6 class="m-b-8">Orange</h6>
            <app-progress design="progress--orange" [value]="75"></app-progress>
            <h6 class="m-b-8">Red</h6>
            <app-progress design="progress--red" [value]="95"></app-progress>
    
            <h5 class="m-t-24 m-b-16">Big</h5>

            <h6 class="m-b-8">Default</h6>
            <app-progress design="progress--big" [value]="25"></app-progress>
            <h6 class="m-b-8">Green</h6>
            <app-progress design="progress--big progress--green" [value]="50"></app-progress>
            <h6 class="m-b-8">Orange</h6>
            <app-progress design="progress--big progress--orange" [value]="75"></app-progress>
            <h6 class="m-b-8">Red</h6>
            <app-progress design="progress--big progress--red" [value]="95"></app-progress>
            
        </ng-container>

        <!-- BADGE -->
        <ng-container *ngIf="component === 'badge'">
            
            <h2 class="m-b-16">Badge</h2>
    
            <h5 class="m-b-8">Default</h5>
            <app-badge>2</app-badge>

            <h5 class="m-t-16 m-b-8">Status</h5>
            <app-badge design="badge--warning">2</app-badge>
            <app-badge design="badge--error" icon="icon-alert"></app-badge>
    
            <h5 class="m-t-16 m-b-8">Small</h5>
            <app-badge design="badge--small"></app-badge>
    
        </ng-container>

        <!-- BANNER -->
        <ng-container *ngIf="component === 'banner'">
            <h2 class="m-b-16">Banner</h2>
            <app-banner
                design="m-b-24"
                icon="icon-folder i-xl"
                buttonText="Descubre cómo"
                (buttonClicked)="buttonClicked()">
                <ng-container title>
                    ¿Cómo conectarse a un FTP?
                </ng-container>
                <ng-container text>
                    Te enseñamos cómo conectarte a este protocolo de transferencia de archivos.
                </ng-container>
            </app-banner>

            <app-banner 
                design="banner--gray"
                [avatar]="confAvatar"
                buttonText="Descubre cómo"
                (buttonClicked)="buttonClicked()">
                <ng-container title>
                    ¿Cómo conectarse a un FTP?
                </ng-container>
                <ng-container text>
                    Te enseñamos cómo conectarte a este protocolo de transferencia de archivos.
                </ng-container>
            </app-banner>  
        </ng-container>

        <!-- TABS -->
        <ng-container *ngIf="component === 'tabs'">
            <h2 class="m-b-16">Tabs</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <app-tabs
                [tabs]="confTabs"
                (tabSelected)="tabSelected = $event">
            </app-tabs>
            <div *ngIf="tabSelected === 'tab1'">
                content tab 1
            </div>
            <div *ngIf="tabSelected === 'tab2'">
                content tab 2
            </div>
            <div *ngIf="tabSelected === 'tab3'">
                content tab 3
            </div>
            <div *ngIf="tabSelected === 'tab4'">
                content tab 4
            </div>

            <h5 class="m-b-8 m-t-16">Pills</h5>
            <app-tabs 
                design="tabs--pills"
                [tabs]="confTabsPills"
                (tabSelected)="tabSelected = $event">
            </app-tabs>
            <div *ngIf="tabSelected === 'tabpill1'">
                content tabpill 1
            </div>
            <div *ngIf="tabSelected === 'tabpill2'">
                content tabpill 2
            </div>
            <div *ngIf="tabSelected === 'tabpill3'">
                content tabpill 3
            </div>
            <div *ngIf="tabSelected === 'tabpill4'">
                content tabpill 4
            </div>

            <h5 class="m-b-8 m-t-16">Pills verticals</h5>
            
            <div class="row">
                <div class="col-4">
                    <app-tabs 
                        design="tabs--pills tabs--vertical"
                        [tabs]="confTabsPillsVertical"
                        (tabSelected)="tabSelected = $event">
                    </app-tabs>
                </div>
                <div class="col-8">
                    <div *ngIf="tabSelected === 'tabpillvertical1'">
                        content tabpill 1
                    </div>
                    <div *ngIf="tabSelected === 'tabpillvertical2'">
                        content tabpill 2
                    </div>
                    <div *ngIf="tabSelected === 'tabpillvertical3'">
                        content tabpill 3
                    </div>
                    <div *ngIf="tabSelected === 'tabpillvertical4'">
                        content tabpill 4
                    </div>
                </div>
            </div>
        </ng-container>
    
        <!-- TAG -->
        <ng-container *ngIf="component === 'tag'">
            <h2 class="m-b-16">Tag</h2>
            <h5 class="m-b-8">Default</h5>
            <div class="flex ai-center">
                <app-tag>
                    Default
                </app-tag>
                <app-tag design="tag--success">
                    Success
                </app-tag>    
                <app-tag design="tag--error">
                    Error
                </app-tag>
                <app-tag design="tag--neutral">
                    Neutral
                </app-tag>
                <app-tag design="tag--warning">
                    Warning
                </app-tag>
            </div>
            <h5 class="m-t-16 m-b-8">Sizes</h5>
            <div class="flex ai-center">
                <app-tag design="tag--small">
                    Default Small
                </app-tag>
                <app-tag design="tag--small tag--success">
                    Success Small
                </app-tag>
                <app-tag design="tag--small tag--error">
                    Error Small
                </app-tag>
                <app-tag design="tag--small tag--neutral">
                    Neutral Small
                </app-tag>
                <app-tag design="tag--small tag--warning">
                    Warning Small
                </app-tag>
            </div>
    
            <h6 class="m-t-8 m-b-8"></h6>
            <div class="flex ai-center">
                <app-tag design="tag--big">
                    Default Big
                </app-tag>
                <app-tag design="tag--big tag--success">
                    Success Big
                </app-tag>
                <app-tag design="tag--big tag--error">
                    Error Big
                </app-tag>
                <app-tag design="tag--big tag--neutral">
                    Neutral Big
                </app-tag>
                <app-tag design="tag--big tag--warning">
                    Warning Big
                </app-tag>
            </div>
    
            <h5 class="m-t-16 m-b-8">Icon & text</h5>
            <div class="flex ai-center">
                <app-tag design="tag--dashboard tag--success"
                    icon="icon-trending-up">
                    <b>5%</b>
                </app-tag>
                <app-tag design="tag--dashboard tag--warning"
                    icon="icon-trending-down">
                    <b>5%</b>
                </app-tag>
            </div>
        </ng-container>
    
        <!-- TAG ICON-->
        <ng-container *ngIf="component === 'tag-icon'">
            <h2 class="m-b-16">Tag Icon</h2>
            <h5 class="m-t-16 m-b-8">Size</h5>
            <div class="flex ai-center">
                <app-tag-icon 
                    design="tag-icon--small"
                    icon="icon-clock">
                </app-tag-icon>
                <app-tag-icon 
                    icon="icon-clock">
                </app-tag-icon>
                <app-tag-icon 
                    design="tag-icon--large"
                    icon="icon-clock">
                </app-tag-icon>
                <app-tag-icon 
                    design="tag-icon--xl"
                    icon="icon-clock">
                </app-tag-icon>
            </div>
    
            <h5 class="m-t-16 m-b-8">Colors</h5>
            <div class="flex ai-center">
                <app-tag-icon 
                    icon="icon-clock">
                </app-tag-icon>
                <app-tag-icon 
                    design="tag-icon--purple"
                    icon="icon-clock">
                </app-tag-icon>
                <app-tag-icon 
                    design="tag-icon--red"
                    icon="icon-clock">
                </app-tag-icon>
                <app-tag-icon 
                    design="tag-icon--orange"
                    icon="icon-clock">
                </app-tag-icon>
                <app-tag-icon 
                    design="tag-icon--green"
                    icon="icon-clock">
                </app-tag-icon>
            </div>

            <h5 class="m-t-16 m-b-8">Text</h5>
            <div class="flex ai-center">
                <app-tag-icon 
                    design="tag-icon--small">
                    +2
                </app-tag-icon>
                <app-tag-icon>
                    +2
                </app-tag-icon>
                <app-tag-icon 
                    design="tag-icon--large">
                    +2
                </app-tag-icon>
                <app-tag-icon 
                    design="tag-icon--xl">
                    +2
                </app-tag-icon>
            </div>
        </ng-container>

        <!-- RATING-->
        <ng-container *ngIf="component === 'rating'">
           <h2 class="m-b-16">Rating</h2>
           <app-rating [(value)]="star"></app-rating>
           <app-rating [numStar]="10" [(value)]="star"></app-rating>
           <app-rating [numStar]="20"></app-rating>
           <app-rating [numStar]="1"></app-rating>
           <app-rating [value]="2"></app-rating>
           <h5 class="m-b-8 m-t-16">Disabled</h5>
           <app-rating design="rating--disabled" [value]="2"></app-rating>
        </ng-container>

        <!-- TOOTLIP-->
        <ng-container *ngIf="component === 'tooltip'">
            <h2 class="m-b-16">Tooltip</h2>
            <h5 class="m-b-8 m-t-16">Tooltip without element</h5>
            <div class="flex">
                <span data-tooltip="Name Surname" class="m-r-24">Tooltip</span>
                <app-button-icon icon="icon-bell" data-tooltip="Action"></app-button-icon>
            </div>
            <h5 class="m-b-8 m-t-16">Tooltip with element</h5>
            <span data-tooltip="Tooltip!">
                <app-icons design="icon-info"></app-icons>
            </span>

            <span class="tooltip tooltip--right" data-tooltip="Tooltip!">
                <app-icons design="icon-info"></app-icons>
            </span>

            <span class="tooltip tooltip--left" data-tooltip="Tooltip!">
                <app-icons design="icon-info"></app-icons>
            </span>
        </ng-container>
    
        <!-- SIDEBAR -->
        <ng-container *ngIf="component === 'sidebar'">
            <h2 class="m-b-16">Sidebar</h2>
            <app-sidebar [confSidebar]="confSidebar">
                <!-- Podemos meter cualquier elemento y se pintará debajo del menú -->
                <span class="options__separator"></span>
                <app-button-icon icon="icon-clock" class="button-icon"></app-button-icon>
                <div class="tile">
                    <h6>Unlimited</h6>
                    <div class="fs-14 m-t-4 m-b-4">Has usado <b>80</b> de <b>160</b> min</div>                    
                    <app-progress design="progress--green progress--big" [value]="50"></app-progress>
                    <a class="m-t-32" href="">Ampliar servicios</a>
                </div>
            </app-sidebar>

            <h5 class="m-b-8 m-t-16">Sidebar templates</h5>
            <app-sidebar-template
                [userCategories]="sidebarElements"
                (menuSelected)="buttonClicked($event)">
            </app-sidebar-template>

            <h5 class="m-b-8 m-t-20">Sidebar templates skeleton</h5>
            <app-skeleton [type]="skeletonTypes.SidebarTemplate"></app-skeleton>
        </ng-container>

        <!-- CHIPS-->
        <ng-container *ngIf="component === 'chips'">
            <h2 class="m-b-16">Chips</h2>
            
            <h5 class="m-t-16 m-b-8">Default</h5>
            <app-chips [(chips)]="confChips"></app-chips>
            <h5 class="m-t-16 m-b-8">Disabled</h5>
            <app-chips [(chips)]="confChips" [disabled]="true"></app-chips>
        </ng-container>
    
        <!-- TILE -->
        <ng-container *ngIf="component === 'tile'">
            <h2 class="m-b-16">Tile</h2>
            <div class="tile m-b-16">
                <div class="tile__body">
                    <div>Tile without header</div>
                    <div class="tile m-t-16">
                        <div class="tile__body">
                            <div>Tile nested</div>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="tile m-b-16">
                <div class="tile__header">
                    <h4>Tile with title in header</h4>
                </div>
                <div class="tile__body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam sunt porro beatae. Vero aperiam blanditiis, quasi eius minima delectus porro officiis sed repudiandae! Dolor voluptatum veniam nisi error magnam placeat.</div>
            </div>
            <div class="tile m-b-16">
                <div class="tile__header">
                    <h4>Tile with title and tabs in header</h4>
                    <div class="tile__header__actions">
                        <app-tabs 
                            design="tabs--pills"
                            [tabs]="confTabsPills"
                            (tabSelected)="tabSelected = $event">
                        </app-tabs>
                    </div>
                </div>
                <div class="tile__body">
                    <div *ngIf="tabSelected === 'tabpill1'">
                        content tabpill 1
                    </div>
                    <div *ngIf="tabSelected === 'tabpill2'">
                        content tabpill 2
                    </div>
                    <div *ngIf="tabSelected === 'tabpill3'">
                        content tabpill 3
                    </div>
                    <div *ngIf="tabSelected === 'tabpill4'">
                        content tabpill 4
                    </div>
                </div>
            </div>
            <div class="tile m-b-16">
                <div class="tile__header">
                    <h4>Tile with title in header and footer</h4>
                </div>
                <div class="tile__body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam sunt porro beatae. Vero aperiam blanditiis, quasi eius minima delectus porro officiis sed repudiandae! Dolor voluptatum veniam nisi error magnam placeat.</div>
                <div class="tile__footer">
                    <div class="text--gray">Text gray</div>
                    <app-button design="button--soft" icon="icon-arrow-right">
                        Ver todas
                    </app-button>
                </div>
            </div>
            <div class="tile m-b-16">
                <div class="tile__header">
                    <h4>Tile with title and actions in header</h4>
                    <div class="tile__header__actions">
                        <div class="input-group input-group--collapsed-input">
                            <app-icons design="icon-search"></app-icons>
                            <input class="input" type="text" name="" id="" placeholder="Buscar" appSearchFocus>
                        </div>

                        <app-dropdown-select [options]="confDropdownSelect" [(value)]="valueDropdownSelect">
                            Dropdown
                        </app-dropdown-select>
                    </div>
                </div>
                <div class="tile__body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam sunt porro beatae. Vero aperiam blanditiis, quasi eius minima delectus porro officiis sed repudiandae! Dolor voluptatum veniam nisi error magnam placeat.</div>
            </div>
            <div class="tile tile--dashboard m-b-16">
                <div class="tile__information">
                    <div class="tile__information__title">Tile Dashboard</div>
                    <div class="tile__information__info">
                        <h2>10</h2>
                        <h5>min</h5>
                    </div>
                </div>
                <div class="tile__tags">
                    <app-tag-icon design="tag-icon--purple" icon="icon-clock"></app-tag-icon>
                    <app-tag design="tag--dashboard tag--warning" icon="icon-trending-down">
                        <b>5%</b>
                    </app-tag>
                </div>
            </div>
            <div class="tile tile--dashboard tile--dashboard--thin m-b-16">
                <div class="toolbar">
                    <div class="toolbar--left">
                        <app-tag-icon design="tag-icon--purple" icon="icon-workflow"></app-tag-icon>
                    </div>
                    <div class="toolbar--right">
                        <app-dropdown-select [options]="confDropdownSelect" [(value)]="valueDropdownSelect">
                            Dropdown
                        </app-dropdown-select>
                    </div>
                </div>
                <div class="tile">
                    <div class="tile__information">
                        <div class="tile__information__info">
                            <h4>10min</h4>
                            <span>automatizado</span>
                        </div>
                    </div>
                    <div class="tile__tags">
                        <app-tag design="tag--dashboard tag--success" icon="icon-trending-up">
                            <b>5%</b>
                        </app-tag>
                    </div>
                </div>
                <div class="tile">
                    <div class="tile__information">
                        <div class="tile__information__info">
                            <h4>100</h4>
                            <span>repeticiones</span>
                        </div>
                    </div>
                    <div class="tile__tags">
                        <app-tag design="tag--dashboard tag--success" icon="icon-trending-up">
                            <b>5%</b>
                        </app-tag>
                    </div>
                </div>
            </div>
            <div class="tile tile--profile m-b-16">
                <div class="tile__header">
                    <h4>Sobre mí</h4>
                    <div class="tile__header__actions">
                        <a href="">Editar</a>
                    </div>
                </div>
                <div class="tile__body">
                    <div class="row">
                        <div class="col-6">
                            <div class="input-group m-b-16">
                                <label for="">Label</label>
                                <input type="text" class="input" placeholder="Placeholder" disabled>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group m-b-16">
                                <label for="">Label</label>
                                <input type="text" class="input" placeholder="Placeholder" disabled>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tile tile--empty-states m-b-40">
                <div class="tile__header">
                    <h5>Title in header</h5>
                </div>
                <div class="tile__body">
                    <img src="../../../../assets/resources/no-automatizaciones.svg" alt="">
                    <h5>Title empty states</h5>
                    <div>Desde cero o utilizando una plantilla, crea tu primer flujo de un proceso web que quieras automatizar</div>
                    <app-button icon="icon-plus left">
                        Button
                    </app-button>
                </div>
            </div>

            <h5 class="m-t-16 m-b-8">Tile Dashboard Skeleton</h5>
            <app-skeleton [type]="skeletonTypes.TileDashboard" class="m-b-24"></app-skeleton>

            <h5 class="m-t-16 m-b-8">Tile Dashboard thin Skeleton</h5>
            <app-skeleton [type]="skeletonTypes.TileDashboardThin"></app-skeleton>

            <h5 class="m-t-16 m-b-8">Tile empty states thin Skeleton</h5>
            <app-skeleton [type]="skeletonTypes.TileEmptyStatesThin"></app-skeleton>

        </ng-container>

        <!-- TILE -->
        <ng-container *ngIf="component === 'card'">
            <h5 class="m-t-16 m-b-8">Default template</h5>
            <div class="flex ai-center fw-wrap">
                <app-card-template [template]="template"></app-card-template>
                <app-card-template [template]="template"></app-card-template>
                <app-card-template [template]="template"></app-card-template>
            </div>

            <h5 class="m-t-16 m-b-8">Card Skeleton</h5>
            <app-skeleton [type]="skeletonTypes.Card"></app-skeleton>
        </ng-container>

        <!-- INPUTS -->
        <ng-container *ngIf="component === 'input'">
            <h2 class="m-b-16">Input</h2>
            <h5 class="m-b-8">Default</h5>
            <input type="text" class="input m-b-8" placeholder="Placeholder">
            <input type="text" class="input m-b-8" placeholder="Placeholder" value="With value">
            <input type="text" class="input input--error m-b-8" placeholder="Placeholder" value="With value">
            <input type="text" class="input m-b-8" placeholder="Placeholder" disabled>

            <h5 class="m-t-16 m-b-8">With label</h5>
            <div class="input-group m-b-16">
                <label for="">Label</label>
                <input type="text" class="input" placeholder="Placeholder">
            </div>
            <div class="input-group m-b-16">
                <label for="">Label</label>
                <input type="text" class="input" placeholder="Placeholder" value="With value">
            </div>
            <div class="input-group m-b-16">
                <label for="">Label</label>
                <input type="text" class="input input--error" placeholder="Placeholder" value="With value">
                <span class="input-group__error-message">Error message</span>
            </div>
            <div class="input-group m-b-16">
                <label for="">Label</label>
                <input type="text" class="input" placeholder="Placeholder" disabled>
            </div>

            <h5 class="m-b-8">Collapsed input</h5>
            <div class="input-group input-group--collapsed-input m-b-8">
                <app-icons design="icon-search"></app-icons>
                <input class="input" type="text" name="" id="" placeholder="Buscar" appSearchFocus>
            </div>
        </ng-container>

        <!-- INPUT BUTTON-->
        <ng-container *ngIf="component === 'input-button'">
            <h2 class="m-b-16">Input button</h2>
            <h5 class="m-b-8">Default</h5>
            <div class="input-group">
                <label for="">Label</label>
                <div class="input-group__button">
                    <input class="input" type="text" placeholder="Responder">
                    <app-button-icon icon="icon-selector"></app-button-icon>
                </div>
            </div>
        </ng-container>

        <!-- DROPDOWN-->
        <ng-container *ngIf="component === 'dropdown'">
            <h2 class="m-b-16">Dropdown</h2>
            <h5 class="m-t-16 m-b-8">Dropdown with button icon/avatar trigger</h5>
            <app-dropdown [items]="confDropdown" [isOpen]="false" (itemClicked)="buttonClicked($event)">
                <app-button-icon design="dropdown__trigger" icon="icon-chevron-down right">
                    <div class="avatar">
                        <img src="../../../../assets/resources/avatar.png" alt="">
                    </div>
                </app-button-icon>
            </app-dropdown>

            <h5 class="m-t-16 m-b-8">Dropdown with button icon trigger</h5>
            <app-dropdown [items]="confDropdown" [isOpen]="false" (itemClicked)="buttonClicked($event)">
                <app-button-icon design="dropdown__trigger" icon="icon-bell">
                    <app-icons design="icon-chevron-down"></app-icons>
                </app-button-icon>
            </app-dropdown>

            <h5 class="m-t-16 m-b-8">Dropdown with button icon circle trigger</h5>
            <app-dropdown [items]="confDropdown" [isOpen]="false" (itemClicked)="buttonClicked($event)">
                <app-button-icon design="button-icon--circle dropdown__trigger" icon="icon-bell"></app-button-icon>
            </app-dropdown>

            <h5 class="m-t-16 m-b-8">Dropdown with input trigger</h5>
            <app-dropdown-select [options]="confDropdownSelect" [(value)]="valueDropdownSelect">
                Dropdown
            </app-dropdown-select>
        </ng-container>

        <!-- MODAL-->
        <ng-container *ngIf="component === 'modal'">
            <h2 class="m-b-16">Modal</h2>
            <app-button (click)="openModal()">Abrir modal</app-button>
        </ng-container>

        <!-- MODAL CONFIRMATION-->
        <ng-container *ngIf="component === 'modal-confirmation'">
            <h2 class="m-b-16">Modal Confirmation</h2>
            <app-button (click)="openModalConfirmation()">Abrir modal confirmación</app-button>
            <app-button (click)="openModalError()">Abrir modal error</app-button>
            <app-button (click)="openModalLoading()">Abrir modal cargando</app-button>
        </ng-container>

        <!-- SWITCHER-->
        <ng-container *ngIf="component === 'switcher'">
            <h2 class="m-b-16">Switcher</h2>
            <app-switcher [(value)]="valueSwitcher"></app-switcher>
            
            <h5 class="m-b-8 m-t-16">Small</h5>
            <app-switcher design="switcher--small" [(value)]="valueSwitcher"></app-switcher>
            
            <h5 class="m-b-8 m-t-16">Small with label</h5>
            <div class="switcher-group">
                <label for="">Label</label>
                <app-switcher design="switcher--small" [(value)]="valueSwitcher"></app-switcher>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <app-icons design="icon-info"></app-icons>
                </span>
            </div>
        </ng-container>

        <!-- FILTERS-->
        <ng-container *ngIf="component === 'filters'">
            <h2 class="m-b-16">Filters</h2>
            <app-button (click)="openFilter()">Mostrar filtros</app-button>
        </ng-container>

        <!-- TOOLBAR-->
        <ng-container *ngIf="component === 'toolbar'">
            <h2 class="m-b-16">Toolbar</h2>
            <div class="toolbar">
                <div class="toolbar__left">
                    <div class="input-group input-group--collapsed-input">
                        <app-icons design="icon-search"></app-icons>
                        <input class="input" type="text" name="" id="" placeholder="Buscar automatizaciones" appSearchFocus>
                    </div>
                </div>
                <div class="toolbar__right">
                        <app-dropdown-select [options]="confToolbarDropdownSelect" [(value)]="valueToolbarDropdownSelect">
                            Ordenar
                        </app-dropdown-select>
                    <app-button design="button--secondary" icon="icon-filter" (click)="openFilter()">Filtrar</app-button>
                </div>
            </div>

            <div class="toolbar">
                <div class="toolbar__left">
                    <div class="input-group input-group--collapsed-input">
                        <app-icons design="icon-search"></app-icons>
                        <input class="input" type="text" name="" id="" placeholder="Buscar automatizaciones" appSearchFocus>
                    </div>
                </div>
                <div class="toolbar__right">
                    <app-button>Invitar</app-button>
                </div>
            </div>

            <div class="toolbar">
                <div class="toolbar__left">
                    <h4>Title</h4>
                </div>
                <div class="toolbar__right">
                    <app-dropdown-select [options]="confToolbarDropdownSelect" [(value)]="valueToolbarDropdownSelect">
                        Ordenar
                    </app-dropdown-select>
                    <app-button design="button--secondary" icon="icon-filter" (click)="openFilter()">Filtrar</app-button>
                </div>
            </div>

            <div class="toolbar">
                <div class="toolbar__left">

                </div>
                <div class="toolbar__right">
                    <app-dropdown-select [options]="confToolbarDropdownSelect" [(value)]="valueToolbarDropdownSelect">
                        Ordenar
                    </app-dropdown-select>
                    <app-button design="button--secondary" icon="icon-filter" (click)="openFilter()">Filtrar</app-button>
                </div>
            </div>
        </ng-container>

        <!-- PAGINATION-->
        <ng-container *ngIf="component === 'pagination'">
            <h2 class="m-b-16">Pagination</h2>
            <app-pagination [(pagination)]="confPagination"></app-pagination>
        </ng-container>
        
        <!-- TABLE-->
        <ng-container *ngIf="component === 'table'">
            <h2 class="m-b-16">Table</h2>
            <h5 class="m-t-16 m-b-8">Table with header</h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>
                            <div class="table__cell">
                                Header
                            </div>
                        </th>
                        <th>
                            <div class="table__cell">
                                Header
                            </div>
                        </th>
                        <th>
                            <div class="table__cell table__cell--align-right">
                                Header align right
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <app-alice></app-alice>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <app-tag design="tag--success">Ejecutando...</app-tag>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <app-alice></app-alice>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <app-tag design="tag--success">Ejecutando...</app-tag>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <app-alice></app-alice>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <app-tag design="tag--success">Ejecutando...</app-tag>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h5 class="m-t-16 m-b-8">Table without header</h5>
            <table class="table">
                <tbody>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <app-alice></app-alice>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <app-tag design="tag--success">Ejecutando...</app-tag>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <app-alice></app-alice>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <app-tag design="tag--success">Ejecutando...</app-tag>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <app-alice></app-alice>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <app-tag design="tag--success">Ejecutando...</app-tag>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h5 class="m-t-16 m-b-8">Table clickable</h5>
            <table class="table table--clickable">
                <tbody>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <app-alice></app-alice>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <app-tag design="tag--success">Ejecutando...</app-tag>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <app-alice></app-alice>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <app-tag design="tag--success">Ejecutando...</app-tag>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <app-alice></app-alice>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <app-tag design="tag--success">Ejecutando...</app-tag>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h5 class="m-t-16 m-b-8">Table Elements</h5>
            <table class="table table--clickable">
                <tbody>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <app-alice></app-alice>
                                Cell with Alice
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <app-tag design="tag--success">With Tag</app-tag>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                                <app-switcher [(value)]="valueSwitcher"></app-switcher>  
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell table__cell--column">
                                Title
                                <span class="text--gray-dark fs-12">Details</span>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                                <span class="text--gray-dark fs-14">Plain text wiht color and size custom</span>
                            </div>
                        </td>
                        <td>
                            <div class=" table__cell--align-right">
                                <span class="text--gray-dark fs-14">Cell align right</span>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                                <app-dropdown [items]="confDropdownTable" (itemClicked)="buttonClicked($event)">
                                    <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                                </app-dropdown>
                            </div>
                        </td>
                    </tr>
                        <tr>
                            <td>
                                <div class="table__cell">
                                    <app-tag-icon icon="icon-clock" class="m-r-8"></app-tag-icon>
                                    <div class="flex fd-column">
                                        Tag with plain text 
                                        <span class="text--gray-dark fs-12">With details</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="table__cell">
                                    <app-avatar [elements]="confAvatar"></app-avatar>
                                </div>
                            </td>
                            <td>
                                <div class="table__cell">
                                    <app-switcher [(value)]="valueSwitcher"></app-switcher>  
                                </div>
                            </td>
                            <td>
                                <div class="table__cell table__cell--align-right">
                                    <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                                </div>
                            </td>
                        </tr>
                        <tr>
                        <td>
                            <div class="table__cell">
                                <app-avatar [elements]="[{
                                    id: '2',
                                    img: null,
                                    name: 'Rafa Vruz'
                                }]" class="m-r-8"></app-avatar>
                                <span class="text--gray-dark fs-14">Nombre Apellido</span>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                                <span class="text--gray-dark fs-14">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Obcaecati neque rem ex quisquam nulla modi similique id in consequatur esse. Quae, rem mollitia? Debitis deserunt quaerat nemo inventore vitae aliquam.</span>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                                <app-switcher [(value)]="valueSwitcher"></app-switcher>  
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <app-button-icon icon="icon-eye" class="button-icon"></app-button-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <h5 class="m-b-8 m-t-16">Skeleton</h5>
            <app-skeleton [type]="skeletonTypes.Table"></app-skeleton>
            <app-skeleton [type]="skeletonTypes.Table"></app-skeleton>
            <app-skeleton [type]="skeletonTypes.Table" [numColumnTable]="6"></app-skeleton>
            <app-skeleton [type]="skeletonTypes.Table" [numColumnTable]="3" [numRowTable]="4"></app-skeleton>
        </ng-container>

        <!-- ITEM-->
        <ng-container *ngIf="component === 'item'">
            <h2 class="m-b-16">Item</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <ng-container *ngFor="let notification of itemsNotifications">
                <div class="item" [ngClass]="{'item--open': notification.isOpen}">
                    <div class="item__header">
                        <div class="item__header__info" (click)="notification.isOpen = !notification.isOpen; notification.pending = false">
                            <div class="info__notification">
                                <app-badge *ngIf="notification.pending" design="badge--small"></app-badge>
                            </div>
                            <ng-container [ngSwitch]="notification.type">
                                <ng-container *ngSwitchCase="'1'">
                                    <app-tag-icon design="tag-icon--green" icon="icon-message-circle"></app-tag-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'2'">
                                    <app-tag-icon design="tag-icon--purple" icon="icon-workflow"></app-tag-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'3'">
                                    <app-tag-icon design="tag-icon--orange" icon="icon-level-0"></app-tag-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'4'">
                                    <app-tag-icon icon="icon-conection"></app-tag-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'5'">
                                    <app-tag-icon design="tag-icon--red" icon="icon-bell"></app-tag-icon>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <app-tag-icon design="tag-icon--green" icon="icon-message-circle"></app-tag-icon>
                                </ng-container>
                            </ng-container>
                            <h6>{{ notification.title }}</h6>
                            <span *ngIf="notification.date && notification.date !== ''" class="info__date">{{ notification.date }}</span>
                        </div>
                        <div class="item__header__actions">
                            <app-dropdown *ngIf="notification.confDropdown" [items]="notification.confDropdown" (itemClicked)="buttonClicked($event)">
                                <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                            </app-dropdown>
                            <app-button-icon icon="icon-chevron-right" (click)="notification.isOpen = !notification.isOpen; notification.pending = false"></app-button-icon>
                        </div>
                    </div>
                    <div class="item__body">
                        <div class="item__body__message" [innerHtml]="notification.bodyText"></div>
                        <div class="item__body__action">
                            <!-- TODO Action -->
                            <div class="button button--sm">{{ notification.bodyButton }}</div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <h5 class="m-b-8 m-t-16">Big</h5>
            <div class="item item--big">
                <div class="item__header">
                    <div class="item__header__info">
                        <h3>Title</h3>
                        <app-tag>Programada</app-tag>
                        <app-tag design="tag--error">Error</app-tag>
                    </div>
                    <div class="item__header__actions">
                        <app-switcher [(value)]="valueSwitcher"></app-switcher>
                        <app-dropdown [items]="confDropdownTable" (itemClicked)="buttonClicked($event)">
                            <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                        </app-dropdown>
                    </div>
                </div>
            </div>
            <h5 class="m-b-8 m-t-16">Skeleton</h5>
            <app-skeleton [type]="skeletonTypes.Item"></app-skeleton>
        </ng-container>
        
        <!-- ERROR MESSAGE-->
       <ng-container *ngIf="component === 'error-message'">
           <h2 class="m-b-16">Error message</h2>
           <h5 class="m-b-8 m-t-16">Default</h5>
           <app-error-message></app-error-message>
           <h5 class="m-b-8 m-t-16">With custom text</h5>
           <app-error-message title="Ha ocurrido un error" subTitle="Inténtelo de nuevo más tarde"></app-error-message>
           <h5 class="m-b-8 m-t-16">Hide button</h5>
           <app-error-message title="Ha ocurrido un error" subTitle="Inténtelo de nuevo más tarde" [hideButton]="true"></app-error-message>
       </ng-container>

       <!-- TREE-->
       <ng-container *ngIf="component === 'tree'">
            <h2 class="m-b-16">Tree</h2>
            <!-- Flexbox Testing -->
            <div class="flex-tree">
                <ul>
                    <li>
                        <div class="flex-tree__node-group">
                            <div class="flex-tree__node-group__phrase">
                                Que pase esto en la <b> web</b>
                            </div>
                            <div class="flex-tree__node-group__node">
                                <app-icons design="icon-open-web i-lg"></app-icons>
                                <div class="node__info">
                                    <h6>Abrir web</h6>
                                    <div class="node__info__link">
                                        <span>www.test.com</span>
                                    </div>
                                </div>
                                <div class="node__actions">
                                    <app-tag-icon design="tag-icon--green" icon="icon-check"></app-tag-icon>
                                    <app-dropdown
                                        [items]="confDropdownTable">
                                        <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                                    </app-dropdown>
                                </div>
                                <div class="node__add-below">
                                    <app-button-icon 
                                        design="button-icon--circle button-icon--circle--sm"
                                        icon="icon-plus">
                                    </app-button-icon>
                                </div>
                            </div>
                            <div class="flex-tree__node-group__dot">
                                <div class="dot__dot"></div>
                                <app-button-icon 
                                    design="button-icon--circle button-icon--circle--sm"
                                    icon="icon-plus">
                                </app-button-icon>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <div class="flex-tree__node-group">
                                    <div class="flex-tree__node-group__phrase">
                                        Que pase esto en la <b> web</b>
                                        <a class="phrase__condition phrase__condition--true">
                                            Selecciona la <b>condición</b> (True)
                                        </a>
                                    </div>
                                    <span class="flex-tree__node-group__node ">
                                        <div class="node__add-above">
                                            <app-button-icon 
                                                design="button-icon--circle button-icon--circle--sm"
                                                icon="icon-plus">
                                            </app-button-icon>
                                        </div>
                                        <app-icons design="icon-click i-lg"></app-icons>
                                        <div class="node__info">
                                            <h6>Clic</h6>
                                            <div class="node__info__link">
                                                <span>Botón de fichar</span>
                                            </div>
                                        </div>
                                        <div class="node__actions">
                                            <app-tag-icon design="tag-icon--orange" icon="icon-alert"></app-tag-icon>
                                            <app-dropdown
                                                [items]="confDropdownTable">
                                                <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                                            </app-dropdown>
                                        </div>
                                    </span>
                                    <div class="flex-tree__node-group__button">
                                        <app-button-icon 
                                            design="button-icon--circle"
                                            icon="icon-plus">
                                        </app-button-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="flex-tree__node-group flex-tree__node-group--disabled">
                                    <div class="flex-tree__node-group__phrase">
                                        Que pase esto en la <b> web</b>
                                        <a class="phrase__condition">Selecciona la condición</a>
                                    </div>
                                    <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                                        <span>Selecciona una acción</span>
                                        <div class="node__actions">
                                            <app-button-icon 
                                                design="button-icon--circle button-icon--circle--sm"
                                                icon="icon-trash">
                                            </app-button-icon>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </ng-container>

        <!-- NODE-->
        <ng-container *ngIf="component === 'tree-node'">
            <h2 class="m-b-16">Tree Node</h2>
            <h5 class="m-b-8 m-t-16">Empty</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Empty Active</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty  flex-tree__node-group__node--empty--active">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Empty Tutorial</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty  flex-tree__node-group__node--empty--tutorial">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Empty with phrase</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b>web</b>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>
            <h5 class="m-b-8 m-t-16">Empty with phrase and value</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b>
                    <a class="phrase__condition">Selecciona la condición (default)</a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b>
                    <a class="phrase__condition phrase__condition--focus">Selecciona la condición (Focus)</a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b>
                    <a class="phrase__condition phrase__condition--true">
                        Selecciona la <b>condición</b> (True)
                    </a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b>
                    <a class="phrase__condition phrase__condition--false">
                        Selecciona la <b>condición</b> (False)
                    </a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b>
                    <a class="phrase__condition phrase__condition--programed">todos los martes a las 09:00</a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b>
                    <a class="phrase__condition phrase__condition--selected">
                        <b>SI</b>[aquí va la condición]
                    </a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group flex-tree__node-group--disabled">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b>
                    <a class="phrase__condition phrase__condition--selected">
                        <b>SI</b>[aquí va la condición]
                    </a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <app-button-icon design="button-icon--circle button-icon--circle--sm" icon="icon-trash"></app-button-icon>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node">
                    <i class="icon-open-web i-lg"></i>
                    <div class="node__info">
                        <h6>Abrir web</h6>
                        <div class="node__info__link">
                            <a href="">Introduce la url de la web</a>
                        </div>
                    </div>
                    <div class="node__actions">
                        <app-tag-icon design="tag-icon--green" icon="icon-check"></app-tag-icon>
                        <app-dropdown
                            [items]="confDropdownTable">
                            <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                        </app-dropdown>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Default Focus</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node flex-tree__node-group__node--focus">
                    <i class="icon-open-web i-lg"></i>
                    <div class="node__info">
                        <h6>Abrir web</h6>
                        <div class="node__info__link">
                            <a href="">Introduce la url de la web</a>
                        </div>
                    </div>
                    <div class="node__actions">
                        <app-tag-icon design="tag-icon--green" icon="icon-check"></app-tag-icon>
                        <app-dropdown
                            [items]="confDropdownTable">
                            <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                        </app-dropdown>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Default add button</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node">
                    <i class="icon-open-web i-lg"></i>
                    <div class="node__info">
                        <h6>Abrir web</h6>
                        <div class="node__info__link">
                            <span>www.test.com</span>
                        </div>
                    </div>
                    <div class="node__actions">
                        <app-tag-icon design="tag-icon--green" icon="icon-check"></app-tag-icon>
                        <app-dropdown
                            [items]="confDropdownTable">
                            <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                        </app-dropdown>
                    </div>
                </div>
                <div class="flex-tree__node-group__button">
                    <app-button-icon 
                        design="button-icon--circle"
                        icon="icon-plus">
                    </app-button-icon>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Default add node below</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node">
                    <i class="icon-open-web i-lg"></i>
                    <div class="node__info">
                        <h6>Abrir web</h6>
                        <div class="node__info__link">
                            <span>www.test.com</span>
                        </div>
                    </div>
                    <div class="node__actions">
                        <app-tag-icon design="tag-icon--green" icon="icon-check"></app-tag-icon>
                        <app-dropdown
                            [items]="confDropdownTable">
                            <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                        </app-dropdown>
                    </div>
                    <div class="node__add-below">
                        <app-button-icon 
                            design="button-icon--circle button-icon--circle--sm"
                            icon="icon-plus">
                        </app-button-icon>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Default add node above</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node">
                    <div class="node__add-above">
                        <app-button-icon 
                            design="button-icon--circle button-icon--circle--sm"
                            icon="icon-plus">
                        </app-button-icon>
                    </div>
                    <i class="icon-open-web i-lg"></i>
                    <div class="node__info">
                        <h6>Abrir web</h6>
                        <div class="node__info__link">
                            <span>www.test.com</span>
                        </div>
                    </div>
                    <div class="node__actions">
                        <app-tag-icon design="tag-icon--green" icon="icon-check"></app-tag-icon>
                        <app-dropdown
                            [items]="confDropdownTable">
                            <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                        </app-dropdown>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Action between nodes</h5>
            <div class="flex-tree">
                <ul>
                    <li>
                        <div class="flex-tree__node-group">
                            <span class="flex-tree__node-group__node">
                                <i class="icon-open-web i-lg"></i>
                                <div class="node__info">
                                    <h6>Abrir web</h6>
                                    <div class="node__info__link">
                                        <span>www.test.com</span>
                                    </div>
                                </div>
                                <div class="node__actions">
                                    <app-tag-icon design="tag-icon--green" icon="icon-check"></app-tag-icon>
                                    <app-dropdown
                                        [items]="confDropdownTable">
                                        <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                                    </app-dropdown>
                                </div>
                            </span>
                            <div class="flex-tree__node-group__dot">
                                <div class="dot__dot"></div>
                                <app-button-icon 
                                    design="button-icon--circle button-icon--circle--sm"
                                    icon="icon-arrow-double">
                                </app-button-icon>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <div class="flex-tree__node-group">
                                    <span class="flex-tree__node-group__node ">
                                        <i class="icon-click i-lg"></i>
                                        <div class="node__info">
                                            <h6>Clic</h6>
                                            <div class="node__info__link">
                                                <span>Botón de fichar</span>
                                            </div>
                                        </div>
                                        <div class="node__actions">
                                            <app-tag-icon design="tag-icon--orange" icon="icon-alert"></app-tag-icon>
                                            <app-dropdown
                                                [items]="confDropdownTable">
                                                <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                                            </app-dropdown>
                                        </div>
                                    </span>
                                    <div class="flex-tree__node-group__button">
                                        <app-button-icon 
                                            design="button-icon--circle"
                                            icon="icon-plus">
                                        </app-button-icon>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <h5 class="m-b-8 m-t-16">Action between nodes tutorial</h5>
            <div class="flex-tree">
                <ul>
                    <li>
                        <div class="flex-tree__node-group">
                            <span class="flex-tree__node-group__node">
                                <i class="icon-open-web i-lg"></i>
                                <div class="node__info">
                                    <h6>Abrir web</h6>
                                    <div class="node__info__link">
                                        <span>www.test.com</span>
                                    </div>
                                </div>
                                <div class="node__actions">
                                    <app-tag-icon design="tag-icon--green" icon="icon-check"></app-tag-icon>
                                    <app-dropdown
                                        [items]="confDropdownTable">
                                        <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                                    </app-dropdown>
                                </div>
                            </span>
                            <div class="flex-tree__node-group__dot">
                                <div class="dot__dot dot__dot--tutorial"></div>
                                <app-button-icon 
                                    design="button-icon--circle button-icon--circle--sm"
                                    icon="icon-arrow-double">
                                </app-button-icon>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <div class="flex-tree__node-group">
                                    <span class="flex-tree__node-group__node ">
                                        <i class="icon-click i-lg"></i>
                                        <div class="node__info">
                                            <h6>Clic</h6>
                                            <div class="node__info__link">
                                                <span>Botón de fichar</span>
                                            </div>
                                        </div>
                                        <div class="node__actions">
                                            <app-tag-icon design="tag-icon--orange" icon="icon-alert"></app-tag-icon>
                                            <app-dropdown
                                                [items]="confDropdownTable">
                                                <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                                            </app-dropdown>
                                        </div>
                                    </span>
                                    <div class="flex-tree__node-group__button">
                                        <app-button-icon 
                                            design="button-icon--circle"
                                            icon="icon-plus">
                                        </app-button-icon>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <h5 class="m-b-8 m-t-16">Add node in bifurcation</h5>
            <div class="flex-tree">
                <ul>
                    <li>
                        <div class="flex-tree__node-group">
                            <span class="flex-tree__node-group__node">
                                <i class="icon-open-web i-lg"></i>
                                <div class="node__info">
                                    <h6>Abrir web</h6>
                                    <div class="node__info__link">
                                        <span>www.test.com</span>
                                    </div>
                                </div>
                                <div class="node__actions">
                                    <app-tag-icon design="tag-icon--green" icon="icon-check"></app-tag-icon>
                                    <app-dropdown
                                        [items]="confDropdownTable">
                                        <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                                    </app-dropdown>
                                </div>
                            </span>
                            <div class="flex-tree__node-group__dot">
                                <div class="dot__dot"></div>
                                <app-button-icon 
                                    design="button-icon--circle button-icon--circle--sm"
                                    icon="icon-plus">
                                </app-button-icon>
                                <app-button-icon 
                                    design="button-icon--circle button-icon--circle--sm"
                                    icon="icon-plus">
                                </app-button-icon>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <div class="flex-tree__node-group">
                                    <div class="flex-tree__node-group__phrase">
                                        Que pase esto en la <b> web</b><a class="phrase__condition">Selecciona la condición (default)</a>
                                    </div>
                                    <span class="flex-tree__node-group__node ">
                                        <i class="icon-click i-lg"></i>
                                        <div class="node__info">
                                            <h6>Clic</h6>
                                            <div class="node__info__link">
                                                <span>Botón de fichar</span>
                                            </div>
                                        </div>
                                        <div class="node__actions">
                                            <app-tag-icon design="tag-icon--orange" icon="icon-alert"></app-tag-icon>
                                            <app-dropdown
                                                [items]="confDropdownTable">
                                                <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                                            </app-dropdown>
                                        </div>
                                    </span>
                                    <div class="flex-tree__node-group__button">
                                        <app-button-icon 
                                            design="button-icon--circle"
                                            icon="icon-plus">
                                        </app-button-icon>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="flex-tree__node-group">
                                    <div class="flex-tree__node-group__phrase">
                                        Que pase esto en la <b> web</b><a class="phrase__condition">Selecciona la condición (default)</a>
                                    </div>
                                    <span class="flex-tree__node-group__node ">
                                        <i class="icon-click i-lg"></i>
                                        <div class="node__info">
                                            <h6>Clic</h6>
                                            <div class="node__info__link">
                                                <span>Botón de fichar</span>
                                            </div>
                                        </div>
                                        <div class="node__actions">
                                            <app-tag-icon design="tag-icon--orange" icon="icon-alert"></app-tag-icon>
                                            <app-dropdown
                                                [items]="confDropdownTable">
                                                <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                                            </app-dropdown>
                                        </div>
                                    </span>
                                    <div class="flex-tree__node-group__button">
                                        <app-button-icon 
                                            design="button-icon--circle"
                                            icon="icon-plus">
                                        </app-button-icon>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </ng-container>

        <!-- COLLAPSE ACTION-->
        <ng-container *ngIf="component === 'collapse-action'">
            <h2 class="m-b-16">Collapse actions</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="collapse-action" [ngClass]="{'collapse-action--open': variableBoolean}" (click)="variableBoolean = !variableBoolean">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            En este elemento
                        </div>
                        <div class="title__option-selected">
                           <b>https://life.tecalis.com</b>
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <app-icons design="icon-chevron-right"></app-icons>
                    </div>
                </div>
                <div class="collapse-action__body"></div>
           </div>

           <h5 class="m-b-8 m-t-16">Open</h5>
           <div class="collapse-action" [ngClass]="{'collapse-action--open': !variableBoolean}" (click)="variableBoolean = !variableBoolean">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            Selecciona o arrastra la acción
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <app-icons design="icon-chevron-right"></app-icons>
                    </div>
                </div>
                <div class="collapse-action__body">
                    <div class="input-group input-group--collapsed-input">
                        <app-icons design="icon-search"></app-icons>
                        <input class="input" type="text" name="" id="" placeholder="Buscar">
                    </div>
                    <div class="sidebar-item">
                        <div class="sidebar-item__icon">
                            <app-icons design="icon-folder i-md"></app-icons>
                        </div>
                        <div class="sidebar-item__text">
                            Lineal
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <app-icons design="icon-info"></app-icons>
                        </span>
                    </div>
                    <div class="sidebar-item sidebar-item--disabled">
                        <div class="sidebar-item__icon">
                            <app-icons design="icon-folder i-md"></app-icons>
                        </div>
                        <div class="sidebar-item__text">
                            Lineal
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <app-icons design="icon-info"></app-icons>
                        </span>
                    </div>
                    <div class="chip">
                        Nombre del input
                        <app-button-icon icon="icon-close"></app-button-icon>
                    </div>
                </div>
           </div>

            <h5 class="m-b-8 m-t-16">More than one Collapse action</h5>
            <div class="collapse-action" [ngClass]="{'collapse-action--open': variableBoolean}" (click)="variableBoolean = !variableBoolean">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            En este elemento
                        </div>
                        <div class="title__option-selected">
                        <b>https://life.tecalis.com</b>
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <app-icons design="icon-chevron-right"></app-icons>
                    </div>
                </div>
                
                <div class="collapse-action__body">

                </div>
            </div>

            <div class="collapse-action" [ngClass]="{'collapse-action--open': variableBoolean}" (click)="variableBoolean = !variableBoolean">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            Introduce la url de la web
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <app-icons design="icon-chevron-right"></app-icons>
                    </div>
                </div>
                <div class="collapse-action__body">
                    <input type="text" class="input" placeholder="Placeholder">
                    <div class="collapse-action__body__buttons">
                        <app-button design="button--sm">Siguiente</app-button>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">All body options</h5>
            <div class="collapse-action" [ngClass]="{'collapse-action--open': !variableBoolean}" (click)="variableBoolean = !variableBoolean">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            Introduce la url de la web
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <app-icons design="icon-chevron-right"></app-icons>
                    </div>
                </div>
                <div class="collapse-action__body">
                    <app-dropdown-select [options]="confDropdownSelect" [(value)]="valueDropdownSelect">
                        Dropdown
                    </app-dropdown-select>
                    <div class="collapse-action__body__buttons">
                        <app-button design="button--sm">Siguiente</app-button>
                    </div>
                </div>
            </div>
            <div class="collapse-action" [ngClass]="{'collapse-action--open': !variableBoolean}" (click)="variableBoolean = !variableBoolean">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            Y si hay algún problema...
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <app-icons design="icon-chevron-right"></app-icons>
                    </div>
                </div>
                <div class="collapse-action__body">
                    <div class="button--toggle__wrapper">
                        <app-button design="button--toggle button--toggle--toggled">
                            Parar automatización
                        </app-button>
                        <app-button design="button--toggle">
                            Continuar automatización
                        </app-button>
                    </div>
                    <div class="collapse-action__body__separator"></div>
                    <label for="">Opciones avanzadas</label>
                    <div class="switcher-group">
                        <label for="">Verificar contenido</label>
                        <app-switcher design="switcher--small" [(value)]="valueSwitcher"></app-switcher>
                        <span class="tooltip tooltip--left" 
                            data-tooltip="Copia y pega un titular o texto de la web para verificar que estoy en la página correcta al acceder a ella">
                            <app-icons design="icon-info"></app-icons>
                        </span>
                    </div>
                    <div class="collapse-action__body__buttons">
                        <app-button design="button--sm">Siguiente</app-button>
                    </div>
                </div>
            </div>

            <div class="collapse-action">
                <div class="collapse-action__body">
                    <app-alice design="alice--sm"></app-alice>
                    <span>Para verificar que la automatización funciona correctamente, haré la prueba de <b>abrir la web</b> de <b>https://life.tecalis.com</b></span>
                    <div class="collapse-action__body__buttons">
                        <app-button design="button--secondary">
                            Cancelar
                        </app-button>
                        <app-button>
                            Aceptar
                        </app-button>
                    </div>
                </div>
            </div>

            <div class="collapse-action" [ngClass]="{'collapse-action--open': !variableBoolean}" (click)="variableBoolean = !variableBoolean">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            Configurar bifurcación
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <app-icons design="icon-chevron-right"></app-icons>
                    </div>
                </div>
                <div class="collapse-action__body">
                    <div class="button--toggle__wrapper">
                        <app-button design="button--toggle button--toggle--toggled">
                            SI pasa esto...
                        </app-button>
                        <app-button design="button--toggle">
                            si NO pasa esto...
                        </app-button>
                    </div>
                    <div class="input-group input-group--collapsed-input">
                        <app-icons design="icon-search"></app-icons>
                        <input class="input" type="text" name="" id="" placeholder="Buscar">
                    </div>
                    <div class="sidebar-item sidebar-item--conditional">
                        <div class="sidebar-item__text">
                            Aquí va el tipo de condición
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <app-icons design="icon-info"></app-icons>
                        </span>
                    </div>
                    <div class="sidebar-item sidebar-item--conditional">
                        <div class="sidebar-item__text">
                            Aquí va el tipo de condición
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <app-icons design="icon-info"></app-icons>
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- SIDEBAR ITEM-->
        <ng-container *ngIf="component === 'sidebar-item'">
            <h2 class="m-b-16">Sidebar Item</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="sidebar-item" [ngClass]="{'sidebar-item--active': variableBoolean}" (click)="variableBoolean = !variableBoolean">
                <div class="sidebar-item__icon">
                    <app-icons design="icon-folder i-md"></app-icons>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <app-icons design="icon-info"></app-icons>
                </span>
            </div>
            <div class="sidebar-item" [ngClass]="{'sidebar-item--active': variableBoolean}" (click)="variableBoolean = !variableBoolean">
                <div class="sidebar-item__icon">
                    <app-tag-icon design="tag-icon--small" icon="icon-check"></app-tag-icon>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <app-icons design="icon-info"></app-icons>
                </span>
            </div>

            <h5 class="m-b-8 m-t-16">Conditional</h5>
            <div class="sidebar-item sidebar-item--conditional">
                <div class="sidebar-item__text">
                    Aquí va el tipo de condición
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <app-icons design="icon-info"></app-icons>
                </span>
            </div>

            <h5 class="m-b-8 m-t-16">Disabled</h5>
            <div class="sidebar-item sidebar-item--disabled">
                <div class="sidebar-item__icon">
                    <app-icons design="icon-folder i-md"></app-icons>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <app-icons design="icon-info"></app-icons>
                </span>
            </div>
            <div class="sidebar-item sidebar-item--disabled">
                <div class="sidebar-item__icon">
                    <app-tag-icon design="tag-icon--small" icon="icon-check"></app-tag-icon>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <app-icons design="icon-info"></app-icons>
                </span>
            </div>
            <div class="sidebar-item sidebar-item--disabled sidebar-item--conditional">
                <div class="sidebar-item__text">
                    Aquí va el tipo de condición
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <app-icons design="icon-info"></app-icons>
                </span>
            </div>

            <h5 class="m-b-8 m-t-16">Active</h5>
            <div class="sidebar-item sidebar-item--active">
                <div class="sidebar-item__icon">
                    <app-icons design="icon-folder i-md"></app-icons>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <app-icons design="icon-info"></app-icons>
                </span>
            </div>
            <div class="sidebar-item sidebar-item--active">
                <div class="sidebar-item__icon">
                    <app-tag-icon design="tag-icon--small" icon="icon-check"></app-tag-icon>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <app-icons design="icon-info"></app-icons>
                </span>
            </div>
            <div class="sidebar-item sidebar-item--active sidebar-item--conditional">
                <div class="sidebar-item__text">
                    Aquí va el tipo de condición
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <app-icons design="icon-info"></app-icons>
                </span>
            </div>
        </ng-container>

        <!-- NODE PREVIEW-->
        <ng-container *ngIf="component === 'node-preview'">
            <h2 class="m-b-16">Node preview</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="node-preview" [ngClass]="{'node-preview--selected': variableBoolean}" (click)="variableBoolean = !variableBoolean">
                <app-tag-icon icon="icon-double-click"></app-tag-icon>
                <div class="node-preview__text">
                    <h6>2.Click</h6>
                    <span>Botón de fichar</span>
                </div>
                <app-icons design="icon-arrow"></app-icons>
            </div>
            <h5 class="m-b-8 m-t-16">Selected</h5>
            <div class="node-preview node-preview--selected">
                <app-tag-icon icon="icon-double-click"></app-tag-icon>
                <div class="node-preview__text">
                    <h6>2.Click</h6>
                    <span>Botón de fichar</span>
                </div>
                <app-icons design="icon-arrow"></app-icons>
            </div>
        </ng-container>

        <!-- LOADER ALICE-->
        <ng-container *ngIf="component === 'loader-alice'">
            <h2 class="m-b-16">Loader Alice</h2>
            <div class="onboarding">
                <app-alice design="alice--big"></app-alice>
            </div>
        </ng-container>

        <!-- ONBOARDING ALICE-->
        <ng-container *ngIf="component === 'onboarding-alice'">
            <h2 class="m-b-16">Onboarding Alice</h2>
            <app-onboarding-alice></app-onboarding-alice>
        </ng-container>

    </div>
</div>