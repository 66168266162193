import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalGenericComponent } from '../design-components/modal-generic/modal-generic.component';
import { IModalGenericData } from '../models/modal-generic.models';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    private portWithExtension: BehaviorSubject<any>;
    private showMsgInstallExtension: BehaviorSubject<string>;

    constructor(
        private matDialog: MatDialog
    ) {
        this.portWithExtension = new BehaviorSubject<any>(null);
        this.showMsgInstallExtension = new BehaviorSubject<string>('');
    }

    // Es el navegador chrome
    public isChromeBrowser(): boolean {
        return navigator.userAgent.indexOf("Chrome") != -1;
    }

    // Abrir modal generico
    public openModalGeneric(genericData: IModalGenericData): MatDialogRef<ModalGenericComponent> {
        return this.matDialog
            .open(ModalGenericComponent, {
                disableClose: true,
                panelClass: ["tc-modal"],
                maxWidth: "800px",
                data: {
                    type: genericData.type,
                    title: genericData.title,
                    text: genericData.text,
                    textButtonConfirm: genericData.textButtonConfirm,
                    textButtonCancel: genericData.textButtonCancel
                }
            })
    }

    // Seteamos el puerto para conectarnos con la extension
    public setPortExtension(data: any): void {
        this.portWithExtension.next(data);
    }

    public getPortExtension(): Observable<any> {
        return this.portWithExtension.asObservable();
    }

    // Mensaje para instalar la extension
    public setMsgInstallExtension(data: string): void {
        this.showMsgInstallExtension.next(data);
    }

    public getMsgInstallExtension(): Observable<string> {
        return this.showMsgInstallExtension.asObservable();
    }
}
