import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IFilter, IListFilter } from '../../models/filters.models';
import { FiltersService } from '../../services/filters.service';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {

    @Input() design!: string;

    public filterForm: FormGroup = new FormGroup({});
    public showFilter: boolean = false;
    public filters: IFilter | null = null;
    private allSubscription: Subscription[] = [];

    constructor(
        private filtersService: FiltersService,
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.allSubscription.push(this.filtersService.getShowFilters()
            .subscribe((status: boolean) => {
                this.showFilter = status;
            })
        );

        this.allSubscription.push(this.filtersService.getFilters()
            .subscribe((filters: IFilter | null) => {
                this.filters = JSON.parse(JSON.stringify(filters));
                this.generateForm();
            })
        );
    }

    private generateForm() {
        if (this.filters && this.filters.listFilter?.length) {
            let fields: any = {};
            this.filters.listFilter.forEach((filter: IListFilter, index: number) => {
                if (filter.name && filter.name !== '') {
                    fields[filter.name] = new FormControl(filter.value);
                }
            })

            this.filterForm = new FormGroup(fields);
        }
    }

    public changeButtonToggle(item: any, isSelected: boolean, index: number) {
        if (item) {
            if (isSelected) {
                // Deseleccionamos el valor
                const indexValue = this.filters?.listFilter[index].value.findIndex((value: any) => value === (item.id || item.value));
                if (indexValue !==  undefined && indexValue !== -1) {
                    this.filters?.listFilter[index].value.splice(indexValue, 1);
                }
            } else {
                // Seleccionamos el valor
                this.filters?.listFilter[index].value.push(item.id || item.value);
            }
        }
    }

    public changeButtonToggleSimple(item: any, isSelected: boolean, index: number) {
        if (item) {
            // Deseleccionamos todos los valores
            this.filters?.listFilter.forEach((filter: IListFilter) => filter.value = []);
            
            if (!isSelected) {
                // Seleccionamos el valor
                this.filters?.listFilter[index].value.push(item.id || item.value);
            }
        }
    }

    public sendFilter() {
        for (let [key, value] of Object.entries(this.filterForm.value)) {
            this.filters?.listFilter.forEach((filter: IListFilter) => {
                if (filter.type !== 'button-toggle' && filter.type !== 'select-button-toggle') {
                    if (filter.name === key) {
                        filter.value = Array.isArray(value) ? value : [value];
                    }
                }
            })
        }

        this.filtersService.setShowFilters(false);
        this.filtersService.setApplyFilters(this.filters);
    }

    public removeFilters() {
        this.filters?.listFilter.forEach((filter: IListFilter) => {
            filter.value = filter.default_value || [];
        });
    }

    public closeFilters(): void {
        this.filters = null;
        this.filtersService.setShowFilters(false);
    }

    public hasValue(filterValues: IListFilter[], value: string): boolean {
        return Boolean(filterValues.find((filterValue: any) => filterValue === value));
    }

    ngOnDestroy(): void {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map((subs: Subscription) => subs.unsubscribe());
        }
    }
    
}
