<p style="margin-top: 20px;">Pasos a seguir para crear un componentes visuales y utilizarlos:</p>

<ol>
    <li>Creamos el compoente con: <b>ng g c [nombre del componente] --export</b> Con --export ayudamos a que el componente sea visible por otros módulos. <b>Importante: Crear los componentes de diseño en src\app\shared\design-components</b></li>
    <li>Una vez creado, nos aparecerá los ficheros ligados al componente como se ve en la imagen: </li>
    <img src="./assets/images-help/Captura2.JPG">
    <li>En el fichero .ts encontraremos la etiqueta que se le ha asignado al componente para poder reutilizarlo como se ve en la imagen. Esta etiqueta se puede cambiar sin problema</li>
    <img src="./assets/images-help/Captura.JPG">
    <li>En el .ts añadimos un Input para poder pasarle las clases que necesitemos: <b>@Input() design!: string;</b>. Recordar añadir el input a los Import</li>
    <li>En el .html añadimos <b>[ngClass]="design"</b> para que el componente obtenga las clases que le vamos a pasar</li>
    <li>Para poder usar este componente en otras parte del código solo tendremos que utilizar esa etiqueta. <b [innerText]="'Ej: <app-alert></app-alert>'"></b></li>
    <li>Utilizaremos el componente list-design para tener un listado de los componentes</li>
</ol>
