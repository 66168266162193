<div class="sidebar sidebar--template" [ngClass]="{'sidebar--template--skeleton': loadingSidebar}">
    <div class="sidebar__body">
        <div class="sidebar__body__title">
            Mis plantillas
        </div>
        <ul class="sidebar__body__options">
            <ng-container *ngFor="let category of userCategories">
                <li class="options__option" [ngClass]="{ 'options__option__link--active': category.selected }" (click)="selectedElement(category)">
                    <a class="options__option__link">
                        <span>{{ category.name }}</span>
                        <app-dropdown [items]="sidebarActionDropdown" (itemClicked)="actionSidebar(category, $event)" *ngIf="category.editable !== false">
                            <app-button-icon design="dropdown__trigger" icon="icon-more"></app-button-icon>
                        </app-dropdown>
                        <div class="link__counter">
                            <app-tag design="tag--neutral tag--small">
                                {{ category.amount || 0 }}
                            </app-tag>
                        </div>
                    </a>
                </li>
            </ng-container>
        </ul>
        <div class="sidebar__body__link">
            <a (click)="addCategory()">
                + Añadir categoría
            </a>
        </div>
        <div class="sidebar__body__title">
            Plantillas comunidad DxBot
        </div>
        <ul class="sidebar__body__options">
            <ng-container *ngFor="let category of sharedCategories">
                <li class="options__option" [ngClass]="{ 'options__option__link--active': category.selected }" (click)="selectedElement(category)">
                    <a class="options__option__link">
                        <span>{{ category.name }}</span>
                        <div class="link__counter">
                            <app-tag design="tag--neutral tag--small">
                                {{ category.amount || 0 }}
                            </app-tag>
                        </div>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>
