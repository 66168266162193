import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IFilter } from '../models/filters.models';

@Injectable({
    providedIn: 'root'
})
export class FiltersService {

    private filters: Subject<IFilter | null>;
    private applyFilters: Subject<IFilter | null>;
    private showFilters: Subject<boolean>;

    constructor() { 
        this.filters = new Subject<IFilter | null>();
        this.applyFilters = new Subject<IFilter | null>();
        this.showFilters = new Subject<boolean>();
    }

    // Para gestionar los filtros que entran en el configurador
    public setFilters(data: IFilter | null): void {
        this.filters.next(data);
    }

    public getFilters(): Observable<IFilter | null> {
        return this.filters.asObservable();
    }

    // Para mostrar o no los filtros
    public setShowFilters(status: boolean): void {
        this.showFilters.next(status);
    }

    public getShowFilters() {
        return this.showFilters.asObservable();
    }

    // Cuando aplicamos los filtros
    public setApplyFilters(data: IFilter | null): void {
        this.applyFilters.next(data);
    }

    public getApplyFilters(): Observable<IFilter | null> {
        return this.applyFilters.asObservable();
    }

}
