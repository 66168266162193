import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IMasterElement } from 'src/app/core/models/masters.models';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { ITemplate, ITemplateCategory, ITemplateCreateBody, ITemplatesCategoriesResponse, ITemplateShareBody, ITemplatesListRequest, ITemplatesListResponse, ITemplateTotal, ITemplateUpdateBody } from '../models/template.models';

@Injectable({
    providedIn: 'root'
})
export class TemplateService extends ApiService {

    private sidebarCategory!: BehaviorSubject<ITemplateCategory | null>;
    private templatesCategories!: BehaviorSubject<ITemplatesCategoriesResponse | null>;
    private templatesCategoriesError!: BehaviorSubject<string>;

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
        this.sidebarCategory = new BehaviorSubject<ITemplateCategory | null>(null);
        this.templatesCategories = new BehaviorSubject<ITemplatesCategoriesResponse | null>(null);
        this.templatesCategoriesError = new BehaviorSubject<string>('');
    }

    public setTemplatesCategories(data: ITemplatesCategoriesResponse | null) {
        this.templatesCategories.next(data);
    }

    public getTemplatesCategories(): Observable<ITemplatesCategoriesResponse | null> {
        return this.templatesCategories.asObservable();
    }

    public setTemplatesCategoriesError(data: string) {
        this.templatesCategoriesError.next(data);
    }

    public getTemplatesCategoriesError(): Observable<string> {
        return this.templatesCategoriesError.asObservable();
    }

    public setSidebarCategory(data: ITemplateCategory | null) {
        this.sidebarCategory.next(data);
    }

    public getSidebarCategory(): Observable<ITemplateCategory | null> {
        return this.sidebarCategory.asObservable();
    }

    // Detalle y listado de plantillas
    public getTemplatesListFromService(filters: ITemplatesListRequest): Observable<ITemplatesListResponse> {
        return this.http.get<ITemplatesListResponse>(environment.dxBotApiUrl + '/v2/templates', {
            params: this.getParamsTemplates(filters),
            headers: this.getHeaders()
        });
    }

    public getTemplatesDetailFromService(id: any): Observable<ITemplate> {
        return this.http.get<ITemplate>(environment.dxBotApiUrl + `/v2/templates/${id}`, {headers: this.getHeaders()});
    }

    public createTemplate(body: ITemplateCreateBody): Observable<any> {
        return this.http.post<any>(environment.dxBotApiUrl + '/v2/templates', body, {headers: this.getHeaders()});
    }

    public updateTemplate(id: any, body: ITemplateUpdateBody): Observable<any> {
        return this.http.put<any>(environment.dxBotApiUrl + `/v2/templates/${id}`, body, {headers: this.getHeaders()});
    }

    public deleteTemplate(id: any): Observable<any> {
        return this.http.delete<any>(environment.dxBotApiUrl + `/v2/templates/${id}`, {headers: this.getHeaders()});
    }

    public shareTemplate(id: any, body: ITemplateShareBody): Observable<any> {
        return this.http.post<any>(environment.dxBotApiUrl + `/v2/templates/${id}/share`, body, {headers: this.getHeaders()});
    }

    // Categorias
    public getTemplatesCategoriesFromService(): Observable<ITemplatesCategoriesResponse> {
        return this.http.get<ITemplatesCategoriesResponse>(environment.dxBotApiUrl + '/v2/templates/categories', {headers: this.getHeaders()});
    }
    public createCategory(name: string): Observable<any> {
        return this.http.post<any>(environment.dxBotApiUrl + '/v2/templates/categories', {name: name}, {headers: this.getHeaders()});
    }

    public updateNameCategory(id: any, name: string): Observable<any> {
        return this.http.put<any>(environment.dxBotApiUrl + `/v2/templates/categories/${id}`, {name: name}, {headers: this.getHeaders()});
    }

    public deleteCategory(id: any): Observable<any> {
        return this.http.delete<any>(environment.dxBotApiUrl + `/v2/templates/categories/${id}`, {headers: this.getHeaders()});
    }

    private getParamsTemplates(filters: ITemplatesListRequest): HttpParams {
        let params = new HttpParams();
        params = params.set('page', filters.page);
        params = params.set('quantity', filters.quantity);

        if (filters.user_templates) {
            params = params.set('user_templates', filters.user_templates);
        }
        if (filters.category && !filters.user_templates) {
            params = params.set('category', filters.category);
        }
        if (filters.order_by) {
            params = params.set('order_by', filters.order_by);
        }
        if (filters.filter) {
            params = params.set('filter', filters.filter);
        }
        return params;
    }
    
}
