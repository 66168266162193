<ng-container *ngIf="!showFullLoading">
    <header class="header" *ngIf="!router?.url?.includes('area-trabajo') && !router?.url?.includes('demo-area')">
        <div class="header__logo" routerLink="/"></div>
        <div class="header__options">
            <!-- <div class="header__options__level-info">
                <app-icons design="icon-level-0 text--purple-02"></app-icons>
                <div class="level-info__info">
                    <div class="level-info__info__title">Nivel 1</div>
                    <app-progress [value]="30"></app-progress>
                </div>
            </div> -->
            <app-button-icon icon="icon-bell" [badge]="noticationPending" routerLink="/notificaciones"></app-button-icon>

            <app-dropdown [items]="confDropdown" (itemClicked)="optionSelect($event)" *ngIf="accountData?.user">
                <app-button-icon design="dropdown__trigger" icon="icon-chevron-down right" [attr.data-tooltip]="accountData.user.email">
                    <app-avatar [elements]="[{ img: accountData.user.avatar, name: accountData.user.name, id: accountData.user.id }]"></app-avatar>
                </app-button-icon>
            </app-dropdown>
        </div>
    </header>

    <!-- Filtros -->
    <app-filters></app-filters>

    <div [class]="(router && router.url && !router.url.includes('area-trabajo') ? 'container' : '') + (router && router.url && router.url.includes('demo-area') ? ' container--demoarea' : '')">
        <!-- Sidebar app -->
        <app-sidebar [confSidebar]="sidebarConf" *ngIf="!router?.url?.includes('list-desing') && !router?.url?.includes('area-trabajo') && !router?.url?.includes('demo-area')">
            <!-- Podemos meter cualquier elemento y se pintará debajo del menú -->
            <!-- <span class="options__separator"></span>
            <app-button-icon icon="icon-clock" class="button-icon"></app-button-icon>
            <div class="tile">
                <h6>Unlimited</h6>
                <div class="fs-14 m-t-4 m-b-4">Has usado <b>80</b> de <b>160</b> min</div>
                <app-progress design="progress--green progress--big" [value]="50"></app-progress>
                <a class="m-t-32" href="">Ampliar servicios</a>
            </div> -->
        </app-sidebar>

        <!-- Main -->
        <div [ngClass]="{'content': router && router.url && !router.url.includes('list-desing') && !router.url.includes('area-trabajo'), 'content-component': router && router.url && !router.url.includes('area-trabajo'), 'content--demoarea': router && router.url && router.url.includes('demo-area') }">
            <router-outlet></router-outlet>
        </div>
    </div>
</ng-container>

<app-onboarding-alice *ngIf="showFullLoading"
    [accountData]="accountData">
</app-onboarding-alice>
