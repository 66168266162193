<ng-container [ngSwitch]="type">

    <ng-container *ngSwitchCase="skeletonTypes.TileDashboard">
        <div class="tile tile--dashboard tile--dashboard--skeleton">
            <div class="tile__information">
                <div class="tile__information__title"></div>
                <div class="tile__information__info">
                    <h2></h2>
                    <h5></h5>
                </div>
            </div>
            <div class="tile__tags">
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="skeletonTypes.TileDashboardThin">
        <div class="tile tile--dashboard tile--dashboard--thin tile--dashboard--thin--skeleton">
            <div class="toolbar">
                <div class="toolbar__left">
                </div>
            </div>
            <div class="tile">
                <div class="tile__information">
                    <div class="tile__information__info">
                    </div>
                </div>
                <div class="tile__tags">
                </div>
            </div>
            <div class="tile">
                <div class="tile__information">
                    <div class="tile__information__info">
                    </div>
                </div>
                <div class="tile__tags">
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="skeletonTypes.TileDashboardElementThin">
        <div class="tile--dashboard tile--dashboard--thin tile--dashboard--thin--skeleton">
            <div class="tile">
                <div class="tile__information">
                    <div class="tile__information__info">
                    </div>
                </div>
                <div class="tile__tags">
                </div>
            </div>
            <div class="tile">
                <div class="tile__information">
                    <div class="tile__information__info">
                    </div>
                </div>
                <div class="tile__tags">
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="skeletonTypes.Card">
        <div class="card card--skeleton">
            <div class="card__header">
                <div class="card__header__title">
                </div>
                <div class="card__header__info">
                </div>
            </div>
            <div class="card__body">
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="skeletonTypes.Item">
        <div class="item item--skeleton">
            <div class="item__header">
                <div class="item__header__info">
                    <div class="tag-icon">
                        <i class="icon-conection"></i>
                    </div>
                    <h6></h6>
                </div>
                <div class="item__header__actions">
                </div>
            </div>
            <div class="item__body">
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="skeletonTypes.Table">
        <table class="table table--skeleton">
            <tbody>
                <tr *ngFor="let _ of [].constructor(numRowTable)">
                    <td *ngFor="let _ of [].constructor(numColumnTable)">
                        <div class="table__cell"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </ng-container>

    <ng-container *ngSwitchCase="skeletonTypes.SidebarTemplate">
        <div class="sidebar sidebar--template sidebar--template--skeleton">
            <div class="sidebar__body">
                <div class="sidebar__body__title">
                </div>
                <ul class="sidebar__body__options">
                    <li class="options__option">
                        <a class="options__option__link"></a>
                    </li>
                    <li class="options__option">
                        <a class="options__option__link"></a>
                    </li>
                </ul>
                <div class="sidebar__body__link">
                    <a></a>
                </div>
                <div class="sidebar__body__title">
                </div>
                <ul class="sidebar__body__options">
                    <li class="options__option" *ngFor="let _ of [].constructor(numRowSidebar)">
                        <a class="options__option__link"></a>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="skeletonTypes.TileEmptyStatesThin">
        <div class="tile tile--empty-states tile--empty-states--skeleton m-b-40">
            <div class="tile__header"></div>
            <div class="tile__body">
                <h5>Title empty states</h5>
                <div></div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <div class="tile tile--dashboard tile--dashboard--skeleton">
            <div class="tile__information">
                <div class="tile__information__title"></div>
                <div class="tile__information__info">
                    <h2></h2>
                    <h5></h5>
                </div>
            </div>
            <div class="tile__tags">
            </div>
        </div>
    </ng-container>
</ng-container>