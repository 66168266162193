<div class="modal modal--cards">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <div class="content__header">
                <div class="content__header__title">
                    <h3>Origen de datos</h3>
                </div>
                <app-button-icon icon="icon-close" (buttonClicked)="closeModal()"></app-button-icon>
            </div>
            <div class="content__body">
                <!-- Toolbar -->
                <div class="toolbar">
                    <div class="toolbar__left">
                        <div class="input-group input-group--collapsed-input">
                            <app-icons design="icon-search"></app-icons>
                            <input class="input" 
                                [ngClass]="{'input--error': searchForm.invalid && searchForm.touched}"
                                type="text" 
                                placeholder="Buscar orígenes de datos" 
                                appSearchFocus 
                                [formControl]="searchForm"
                                (keyup.enter)="getDataSource()">
                            <span class="input-group__error-message" *ngIf="searchForm?.errors?.['minlength'] && searchForm?.touched">
                                Al menos 3 caracteres
                            </span>
                        </div>
                    </div>
                    <div class="toolbar__right">
                        <app-button 
                            icon="icon-plus" 
                            (buttonClicked)="closeModal()"
                            routerLink="/conexiones/nueva">
                            Añadir
                        </app-button>
                    </div>
                </div>
                <!-- Content -->
                <div class="content__body__section">
                    <!-- Hay datos -->
                    <ng-container *ngIf="dataSources?.datasources?.length">
                        <ng-container *ngFor="let dataSource of dataSources?.datasources">
                            <div class="card" *ngIf="(anyDataSourceSelected() && dataSource.selected) || !anyDataSourceSelected()" (click)="selectDatasourceCard(dataSource)">
                                <div class="card__header">
                                    <div class="card__header__title">
                                        <app-tag-icon 
                                            design="tag-icon--small tag-icon--green" 
                                            icon="icon-database">
                                        </app-tag-icon>
                                        {{ dataSource.name }}
                                        <app-button-icon 
                                            *ngIf="dataSource.selected"
                                            icon="icon-close" 
                                            (click)="$event.stopPropagation(); dataSource.selected = false; selectedColumn.patchValue(null); selectedColumn.markAsUntouched()"
                                            style="margin-left: auto;">
                                        </app-button-icon>
                                    </div>
                                    <div class="card__header__info">
                                        <div *ngIf="dataSource.info.rows"
                                            class="info__info">
                                            {{ dataSource.info.rows + ' filas' }}
                                        </div>
                                        <div *ngIf="dataSource.updated_at"
                                            class="info__data">
                                            {{ timestampToDate(dataSource.updated_at) | date: 'dd/MM/yyyy' }}
                                        </div>
                                    </div>
                                </div>
                                <div class="card__body" *ngIf="dataSource.selected" style="display: block;">
                                    <div class="m-b-8">Selecciona una columna</div>
                                    <app-dropdown-select [options]="dataSource.columnsSelect" 
                                        [value]="selectedColumn.value"
                                        (valueChange)="selectedColumn.patchValue($event)">
                                        Columnas
                                    </app-dropdown-select>
                                </div>
                            </div>
                            
                        </ng-container>

                        <div style="width: 100%;">
                            <app-pagination 
                                *ngIf="!anyDataSourceSelected()"
                                [(pagination)]="dataSourcePagination" 
                                (paginationChange)="getDataSource($event)"
                                design="pagination__modal">
                            </app-pagination>
                        </div>
                    </ng-container>

                    <!-- No hay datasource -->
                    <ng-container *ngIf="dataSources && dataSources.datasources?.length === 0 ">
                        <div class="content__body__empty">
                            <img src="../../../../assets/resources/no-automatizaciones.svg" alt="">
                            <h5>No hay ningún origen de datos con ese nombre</h5>
                        </div>
                    </ng-container>

                    <!-- Datasources loading -->
                    <ng-container *ngIf="!dataSources?.datasources && !errorDataSource">
                        <div class="card card--skeleton" *ngFor="let _ of [].constructor(QUANTITY)">
                            <div class="card__header">
                                <div class="card__header__title">
                                </div>
                                <div class="card__header__info">
                                </div>
                            </div>
                            <div class="card__body">
                            </div>
                        </div>
                    </ng-container>

                    <!-- Datasources error -->
                    <ng-container *ngIf="errorDataSource">
                        <app-error-message [subTitle]="errorDataSource" [hideButton]="true"></app-error-message>
                    </ng-container>
                </div>

                <div class="m-t-16" *ngIf="selectedColumn.touched && !selectedColumn.valid">
                    Debe seleccionar alguna columna
                </div>
            </div>
            <div class="content__footer">
                <app-button design="button--secondary" (buttonClicked)="closeModal()">
                    Cancelar
                </app-button>
                <app-button (buttonClicked)="confirmDataSource()">
                    Seleccionar
                </app-button>
            </div>
        </div>
    </div>
</div>
