export interface IConnectionsListRequest {
    status?: ConnectionStatus | string;
    page: number;
    quantity: number;
    order_by?: any;
    filter?: any;
    type_id?: Array<any>;
}

export interface IConnectionsListResponse {
    datasources: Array<IConnectionDetail>;
    page: number;
    total: number;
}

export interface IConnectionDetail {
    id: number;
    name: string;
    type: string;
    type_id: any;
    status: ConnectionStatus | string;
    info: {
        columns: Array<string>;
        rows: number;
        delimiter:  string;
        headers: boolean;
    };
    created_at: any;
    updated_at: any;
    used_on: any;
    columnsSelect?: any;
    selected?: boolean;
}

export interface IConnectionsDetailRequest {
    status?: ConnectionStatus | string;
    page: number;
    quantity: number;
    order_by?: any;
}

export interface IConnectionTotal {
    error: number;
    completed: number;
    archived: number;
}

export interface IConnectionAddResponse {
    datasource_types: Array<IConnectionAddDetail>;
    total: number;
    page: number;
}

export interface IConnectionAddDetail {
    id: number;
    name: string;
    description: string;
    new: boolean;
    config: any;
}

export interface IConnectionAddRequest {
    page: number;
    quantity: number;
    filter?: string;
}

export interface IConnectionCreateRequest {
    name: string;
    config: any;
    type_id: any;
}

export enum ConnectionStatus {
    Total = '',
    Connected = 1,
    Error = 2,
    Archived = 3,
    Testing = 'testing'
}

export enum TranslateConnectionStatus {
    total = 'Total',
    connected = 'Conectada',
    error = 'Error',
    archived = 'Archivada',
    testing = 'Probando...'
}

export enum ColorTagConnectionStatus {
    connected = 'tag--success',
    error = 'tag--error',
    archived = 'tag--neutral',
    testing = 'tag--neutral'
}

export enum ConnectionType {
    FTP = 3,
    CSV = 1,
    Excel = 2
}

export enum ConnectionOption {
    Test = 'test',
    Edit = 'edit',
    MoveToStore = 'moveToStore',
    Remove = 'remove'
}