import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prueba',
  templateUrl: './prueba.component.html',
  styleUrls: ['./prueba.component.scss']
})
export class PruebaComponent implements OnInit {

    public value = '2';
    public tree = [{
        name: 'Principal',
        children: [],
        numOfChildrenToCreate: 2
    }];

    constructor() { }

    ngOnInit(): void {
    }

    public addLineal(item: any) {
        item.children = [{
            name: item.name + '.1',
            children: [],
            numOfChildrenToCreate: 2
        }]
    }

    public addBifur(item: any, numOfChildrenToCreate = 2) {
        item.children = [];
        for (var i = 0; i < numOfChildrenToCreate; i++) {
            item.children.push({
                name: `${item.name + '.' + (i+1)}`,
                children: [],
                numOfChildrenToCreate: 2
            });
        }
    }

}
