import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IDropdownElement } from '../../models/dropdown.models';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

    @Input() design!: string;
    @Input() items!: Array<IDropdownElement>;
    @Input() isOpen: boolean = false;
    @Output() itemClicked = new EventEmitter<string>();

    @HostListener('document:click', ['$event'])
        clickout(event: any) {
            if(!this.eRef.nativeElement.contains(event.target)) {
                this.showDropdown = false;
            }
        }
    
    public showDropdown: boolean = false;

    constructor(
        private router: Router,
        private eRef: ElementRef
    ) { }

    ngOnInit(): void {
        this.showDropdown = this.isOpen;
    }

    public openDropdown() {
        this.showDropdown = !this.showDropdown;
    }

    public clickItem(item: IDropdownElement) {
        this.items.map((item: IDropdownElement) => item.selected = false);
        item.selected = true;
        if (item.url && item.url !== '') {
            this.router.navigate([item.url]);
        } else {
            this.itemClicked.emit(item.id);
        }
    }

}
