import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IDropdownSelectOption } from '../../models/dropdownSelect.models';

@Component({
    selector: 'app-dropdown-select',
    templateUrl: './dropdown-select.component.html',
    styleUrls: ['./dropdown-select.component.scss']
})
export class DropdownSelectComponent implements OnInit {

    @Input() design!: string;
    @Input() options!: Array<IDropdownSelectOption> | undefined;
    @Input() value!: IDropdownSelectOption | null;
    @Input() disabled!: boolean;
    @Output() valueChange = new EventEmitter<IDropdownSelectOption>();

    @HostListener('document:click', ['$event'])
        clickout(event: any) {
            if(!this.eRef.nativeElement.contains(event.target)) {
                this.openDropdownSelect = false;
            }
        }

    public openDropdownSelect: boolean = false;

    constructor(
        private eRef: ElementRef
    ) { }

    ngOnInit(): void {
        let asd = this.value
    }

    public changeSelect(dataSelected: IDropdownSelectOption) {
        this.openDropdownSelect = false;
        this.disabled = true;
        setTimeout(() => {
            this.disabled = false;
        }, 50);
        this.value = dataSelected;
        this.valueChange.emit(this.value);
    }

    public clickInDropdown(): void {
        if (!this.disabled) {
            this.openDropdownSelect = !this.openDropdownSelect;
        }
    }
}
