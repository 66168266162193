import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITabElement } from '../../models/tabs.models';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
    
    @Input() design!: string;
    @Input() tabs!: Array<ITabElement>;
    @Output() tabSelected = new EventEmitter<any>();

    constructor() { }

    ngOnInit(): void {
    }

    public changeSelected(index: number) {
        this.tabs.map((tab: ITabElement) => tab.selected = false);
        this.tabs[index].selected = true;
        this.tabSelected.emit(this.tabs[index].id);
    }

}
