<div class="modal modal--cards">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <div class="content__header">
                <div class="content__header__title">
                    <h3>Crear automatización</h3>
                </div>
                <app-button-icon icon="icon-close" (buttonClicked)="closeModal()"></app-button-icon>
            </div>
            <div class="content__body" [formGroup]="form">
                <div class="content__body__section">
                    <div class="tile m-b-32">
                        <h5>Empezar de cero</h5>
                        <div class="fs-14 text--gray-dark m-b-24">Inicia un automatización en blanco según el tipo de inicio</div>
                        <div class="card__flex-wrapper">
                            <div class="card" 
                                [ngClass]="{'card--selected': form.get('type')?.value === automationType.Manual}" 
                                (click)="form.get('type')?.patchValue(automationType.Manual)">
                                <div class="card__header">
                                    <img src="../../../../assets/resources/manual.svg" alt="">
                                    <div class="card__header__title">
                                        <h5>
                                            Manual
                                        </h5>
                                    </div>
                                    <div class="card__header__info">
                                        <div>Indica los pasos e iníciala manualmente cuando lo necesites</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card" 
                                [ngClass]="{'card--selected': form.get('type')?.value === automationType.Programada}" 
                                (click)="form.get('type')?.patchValue(automationType.Programada)">
                                <div class="card__header">
                                    <img src="../../../../assets/resources/programmed.svg" alt="">
                                    <div class="card__header__title">
                                        <h5>
                                            Programada
                                        </h5>
                                    </div>
                                    <div class="card__header__info">
                                        <div>Indica la frecuencia con la que quieres que se ejecute</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card card--disabled" 
                                [ngClass]="{'card--selected': form.get('type')?.value === automationType.Desencadenada}" 
                                (click)="form.get('type')?.patchValue(automationType.Desencadenada)">
                                <div class="card__header">
                                    <img src="../../../../assets/resources/desencadenada.svg" alt="">
                                    <div class="card__header__title">
                                        <h5>Desencadenada</h5>
                                    </div>
                                    <div class="card__header__info">
                                        <div>Se inicia cuando ocurre un suceso definido por ti</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="input-group__error-message m-t-12" *ngIf="form.get('type')?.errors?.['required'] && form.get('type')?.touched">
                            Seleccione un tipo de automatización
                        </span>
                    </div>
                    <div class="tile fg-2">
                        <div class="tile__header">
                            <h5>Empezar con una plantilla</h5>
                            <div class="tile__header__actions">
                                <div class="input-group input-group--collapsed-input">
                                    <app-icons design="icon-search"></app-icons>
                                    <input class="input" 
                                        type="text" 
                                        placeholder="Búsqueda por nombre o propietario de plantilla"
                                        [ngClass]="{'input--error': searchForm.invalid && searchForm.touched}"
                                        [formControl]="searchForm"
                                        (keyup.enter)="getTemplates()"
                                        appSearchFocus>
                                    <span class="input-group__error-message" *ngIf="searchForm?.errors?.['minlength'] && searchForm?.touched">
                                        Al menos 3 caracteres
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card__flex-wrapper">
                            <ng-container *ngIf="templateData">
                                <ng-container *ngIf="templateData?.templates?.length">
                                    <app-card-template 
                                        *ngFor="let template of templateData?.templates"
                                        [template]="template"
                                        (click)="selectTemplate(template)">
                                    </app-card-template>
                                </ng-container>
                                <ng-container *ngIf="!templateData?.templates?.length">
                                    <div class="content__body__empty">
                                        <img src="../../../../assets/resources/no-automatizaciones.svg" alt="">
                                        <h5>No hay plantillas con estos filtros</h5>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <!-- Templates loading -->
                            <ng-container *ngIf="!templateData && !templateError">
                                <app-skeleton [type]="skeletonTypes.Card" *ngFor="let _ of [].constructor(QUANTITY_TEMPLATE)"></app-skeleton>
                            </ng-container>
                            <!-- Templates error -->
                            <ng-container *ngIf="templateError">
                                <app-error-message [subTitle]="templateError" [hideButton]="true"></app-error-message>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content__footer">
                <app-button design="button--secondary" (buttonClicked)="closeModal()">
                    Cancelar
                </app-button>
                <app-button (buttonClicked)="createAutomation()">
                    Siguiente
                </app-button>
            </div>
        </div>
    </div>
</div>
