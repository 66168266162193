import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICollaborator } from '../../models/collaborators.models';
import { UtilsService } from '../../services/utils.service';
import { ModalAddCollaboratorsComponent } from '../modal-add-collaborators/modal-add-collaborators.component';

@Component({
    selector: 'app-modal-collaborators',
    templateUrl: './modal-collaborators.component.html',
    styleUrls: ['./modal-collaborators.component.scss']
})
export class ModalCollaboratorsComponent implements OnInit {

    public filterCollaborators: Array<ICollaborator>;

    constructor(
        public dialogRef: MatDialogRef<ModalCollaboratorsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private matDialog: MatDialog,
        private utilsService: UtilsService
    ) { 
        this.filterCollaborators = this.data.collaborators;
    }

    ngOnInit(): void {
    }

    public removeCollaborator(collaboratorToRemove: ICollaborator): void {
        const foundIndex = this.data.collaborators.findIndex((collaborator: ICollaborator) => collaborator.id === collaboratorToRemove.id);
        if (foundIndex !== -1) {
            this.data.collaborators.splice(foundIndex, 1);
        }
        const foundFilterIndex = this.filterCollaborators.findIndex((collaborator: ICollaborator) => collaborator.id === collaboratorToRemove.id);
        if (foundFilterIndex !== -1) {
            this.filterCollaborators.splice(foundFilterIndex, 1);
        }
    }

    public onSearchChange(event: any): void {
        const value = event.target.value;
        if (value && value !== '') {
            this.filterCollaborators = this.data.collaborators.filter((collaborator: ICollaborator) => collaborator.full_name.toLowerCase().includes(value.toLowerCase()));
        } else {
            this.filterCollaborators = this.data.collaborators;
        }
    }

    public openModalError(collaboratorToRemove: ICollaborator): void {
        this.utilsService.openModalGeneric({
            type: 'error',
            title: `¿Seguro que quieres eliminar a ${collaboratorToRemove.full_name} ?`,
            textButtonConfirm: 'Si, eliminar',
            textButtonCancel: 'Cancelar'
        })
        .afterClosed()
        .subscribe((result: any) => {
            if (result) {
                this.removeCollaborator(collaboratorToRemove);
            }
        });
    }

    public openModalAddCollaborators(): void {
        this.closeModal();
        const dialogRef = this.matDialog
            .open(ModalAddCollaboratorsComponent, {
                disableClose: true,
                panelClass: ["tc-modal"],
                maxWidth: "800px",
                data: {
                    textButtonConfirm: 'Si, cerrar sesión',
                    textButtonCancel: 'Cancelar'
                },
            });

        dialogRef
            .afterClosed()
            .subscribe((result: any) => {
                if (result) {
                    console.log(`Dialog result: ${result}`);
                }
            });
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

}
