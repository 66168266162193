import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAccountResponse } from '../../models/account.models';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class AccountService extends ApiService {

    private accountData!: BehaviorSubject<IAccountResponse | null>;

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
        this.accountData = new BehaviorSubject<IAccountResponse | null>(null);
    }

    public setAccountData(data: IAccountResponse) {
        this.accountData.next(data);
    }

    public getAccountData(): Observable<IAccountResponse | null> {
        return this.accountData.asObservable();
    }

    public getAccountDataFromService(): Observable<IAccountResponse> {
        return this.http.get<IAccountResponse>(environment.dxBotApiUrl + '/v2/account', {headers: this.getHeaders()});
    }

    public changeStatusFirstTime(status: boolean): Observable<any> {
        return this.http.post<any>(environment.dxBotApiUrl + '/v2/account/first-steps', {status: status}, {headers: this.getHeaders()});
    }
}
