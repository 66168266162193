<div class="alert" [ngClass]="design">
    <!-- Default -->
    <ng-container *ngIf="!design?.includes('first-step')">

        <div class="alert__header">
            <!-- Icono -->
            <ng-container *ngIf="icon; else iconCheck">
                <div *ngIf="icon === 'alice'" class="alice alice--pulse"></div>
                <div *ngIf="icon !== 'alice'" class="alert__header__icon">
                    <app-icons [design]="icon"></app-icons>
                </div>
            </ng-container>

            <!-- Texto de la alerta -->
            <div class="alert__header__text"> 
                <ng-content></ng-content>
            </div>

            <!-- Botón de acción -->
            <div class="alert__header__action" *ngIf="actionButtonText && actionButtonText !== '' && !design?.includes('alert--expanded')">
                <app-button design="button--secondary button--xs" (buttonClicked)="buttonClicked.emit()">
                    {{ actionButtonText }}
                </app-button>
            </div>
        </div>

        <div class="alert__body" *ngIf="design?.includes('alert--expanded')">
            <div [innerHtml]="bodyText"></div>
            <div class="alert__body__actions">
                <span class="tooltip tooltip--right" data-tooltip="Reconectar" *ngIf="reconect">
                    <app-button-icon icon="icon-refresh" (buttonClicked)="reconectClicked.emit()"></app-button-icon>
                </span>
                <app-button design="button--secondary button--xs" (buttonClicked)="buttonClicked.emit()">
                    {{ actionButtonText }}
                </app-button>
            </div>
        </div>
    </ng-container>

    <!-- First steps -->
    <ng-container *ngIf="design?.includes('first-step')">
        <div class="alert__body">
            <h5>{{ confFirstSteps.title }}</h5>
            <div class="list">
                <ng-container *ngFor="let step of confFirstSteps.steps; index as i">
                    <div *ngIf="!step.onComplete" class="list__item" [ngClass]="{'list__item--done': step.checked}">
                        <div class="tag-icon">
                            <app-icons design="icon-check" *ngIf="step.checked"></app-icons>
                            <ng-container *ngIf="!step.checked">{{ i+1 }}</ng-container>
                        </div>
                        <div class="list__item__text">
                            {{ step.title }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <ng-container *ngFor="let step of confFirstSteps.steps; index as i">
            <div class="alert__action" *ngIf="confFirstSteps.index === i">
                <div *ngIf="step.image && step.image !== ''" class="alert__action__image">
                    <img [src]="'../../../../assets/resources/' + step.image" alt="">
               </div>
                <div *ngIf="step.text && step.text !== ''" class="alet__action__step-info">
                    <h3 *ngIf="step.onComplete" class="m-b-24">
                        {{ step.title }}
                    </h3>
                    <div>
                        {{ step.text }}
                    </div> 
                    <app-button *ngIf="step.urlButton !== null && step.textButton !== null" [routerLink]="step.urlButton">
                        {{ step.textButton }}
                    </app-button>
                    <a *ngIf="!step.onComplete" (click)="nextStep(step)">Siguiente paso</a>
                </div>
            </div>
        </ng-container>
        <app-button-icon icon="icon-close" (click)="closeClicked.emit()"></app-button-icon>
    </ng-container>
</div>

<!-- Icono por defecto -->
<ng-template #iconCheck>
    <div class="alert__header__icon">
        <app-icons [design]="'icon-check'"></app-icons>
    </div>
</ng-template>
