import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDataSourceForm } from 'src/app/pages/connection/models/dataSource.models';

@Directive({
    selector: '[appInputDataSource]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputDataSourceDirective,
            multi: true,
        }
    ],
})
export class InputDataSourceDirective implements ControlValueAccessor {

    @HostListener("input", ["$event.target.value"])
    onInput = (_: any) => {};

    constructor(
        private _elementRef: ElementRef<HTMLInputElement>,
        private _renderer: Renderer2
    ) { }

    writeValue(value: string | IDataSourceForm): void {
        if (!value) {
            this._renderer.setAttribute(
                this._elementRef.nativeElement,
                "value",
                ''
            );
        } else if (typeof value === 'object') {
            this._renderer.setAttribute(
                this._elementRef.nativeElement,
                "value",
                value.datasourceName.toString() + ' - ' + value.header.toString()
            );
        } else {
            this._renderer.setAttribute(
                this._elementRef.nativeElement,
                "value",
                value.toString()
            );
        }
    }
    registerOnChange(fn: any): void {
        this.onInput = (value: any) => {
            if (!value) {
                fn('');
            } else if (typeof value === 'object') {
                fn({
                    datasource: value.datasource,
                    datasourceName: value.datasourceName,
                    header: value.header
                })
            } else {
                fn(value);
            }
        };
    }
    registerOnTouched(fn: any): void {
        // throw new Error('Method not implemented.');
    }
    setDisabledState?(isDisabled: boolean): void {
        // throw new Error('Method not implemented.');
    }

}
