<div class="filters" [formGroup]="filterForm" [ngClass]="design" [ngClass]="{'filters--open': showFilter}">
    <div class="filters__header">
        <div class="filters__header__title">Aplicar Filtros</div>
        <div class="filters__header__actions">
            <app-button-icon icon="icon-close" (buttonClicked)="closeFilters()"></app-button-icon>
        </div>
    </div>
    <div class="filters__body">
        <ng-container *ngIf="filters?.listFilter && filters?.listFilter?.length">
            <ng-container *ngFor="let filter of filters?.listFilter; index as i">
                <div class="filters__body__section" *ngIf="!filter.hidden">
                    <div class="section__title">
                        {{ filter.title }}
                    </div>

                    <div *ngIf="filter.type === 'button-toggle'" class="button--toggle__wrapper">
                        <app-button *ngFor="let option of filter.options" (buttonClicked)="changeButtonToggle(option, hasValue(filter.value, option.id), i)" [design]="hasValue(filter.value, option.id) ? 'button--toggle button--toggle--toggled': 'button--toggle'">
                            {{ option.name }}
                        </app-button>
                    </div>

                    <div *ngIf="filter.type === 'select-button-toggle'" class="button--toggle__wrapper">
                        <app-button *ngFor="let option of filter.options" (buttonClicked)="changeButtonToggleSimple(option, hasValue(filter.value, option.id), i)" [design]="hasValue(filter.value, option.id) ? 'button--toggle button--toggle--toggled': 'button--toggle'">
                            {{ option.name }}
                        </app-button>
                    </div>

                    <div *ngIf="filter.type === 'select'">
                        <app-dropdown-select [options]="filter.options"
                            [value]="filterForm.get(filter.name)?.value[0]"
                            (valueChange)="filterForm.get(filter.name)?.patchValue($event)">
                            {{ filter.title }}
                        </app-dropdown-select>
                    </div>

                    <div *ngIf="filter.type === 'text'">
                        <input type="text" class="input m-b-8" placeholder="Placeholder" [formControlName]="filter.name">
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="filters__footer">
        <app-button design="button--secondary" (buttonClicked)="removeFilters()">
            Borrar filtros
        </app-button>
        <app-button (buttonClicked)="sendFilter()">Aplicar</app-button>
    </div>
</div>
<div class="filters__backdrop" [hidden]="!showFilter" [ngClass]="{'show': showFilter}"></div>
