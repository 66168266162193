<div class="dropdown" [ngClass]="{'dropdown--open': showDropdown, design}" (click)="$event.stopPropagation(); openDropdown()">
    <ng-content></ng-content>
    <div class="dropdown__menu" *ngIf="items && items.length">
        <ng-container *ngFor="let item of items; index as i">
            <div *ngIf="item.type !== 'endRed'" class="dropdown__menu__item" (click)="clickItem(item)">
                <app-icons *ngIf="item.icon" [design]="item.icon"></app-icons>
                {{ item.label }}
            </div>
            <ng-container *ngIf="item.type === 'endRed'">
                <div class="dropdown__menu__separator"></div>
                <div class="dropdown__menu__item text--red" (click)="clickItem(item)">
                    <app-icons *ngIf="item.icon" [design]="item.icon"></app-icons>
                    {{ item.label }}
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>