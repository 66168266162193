import { Component, OnInit, Input } from '@angular/core';
import { ITemplate } from 'src/app/pages/template/models/template.models';

@Component({
    selector: 'app-card-template',
    templateUrl: './card-template.component.html',
    styleUrls: ['./card-template.component.scss']
})
export class CardTemplateComponent implements OnInit {

    @Input() template!: ITemplate;

    constructor(
    ) { }

    ngOnInit(): void {
    }

}
