import { Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[appSearchFocus]'
})
export class SearchFocusDirective {

    constructor(
        private el: ElementRef
    ) { }

    @HostListener('focus') onFocus() {
        this.el.nativeElement?.offsetParent?.classList.add('input-group--collapsed-input--expanded');
    }
    
    @HostListener('blur') onblur() {
        this.el.nativeElement?.offsetParent?.classList.remove('input-group--collapsed-input--expanded');
    }

}
