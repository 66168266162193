import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { allLocalStorage } from '../../models/masters.models';

@Component({
    selector: 'app-jwt',
    templateUrl: './jwt.component.html',
    styleUrls: ['./jwt.component.scss']
})
export class JwtComponent implements OnInit {

    constructor(
        private activatedRoute: ActivatedRoute, 
        private router: Router
    ) { }

    ngOnInit(): void {
        const jwt = this.activatedRoute.snapshot.paramMap.get('jwt');

        if (jwt !== undefined && jwt !== null && jwt !== '') {
            localStorage.clear();
            localStorage.setItem(allLocalStorage.accessToken, jwt);
        }
        this.router.navigate(['/']);
    }

}
