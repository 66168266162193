import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Action, ActionNameTypes, IActionConf, IActionField, IActionJobs } from '../../models/actions.models';
import { IMaster } from '../../models/masters.models';
import { ActionsService } from '../actions/actions.service';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class MastersService extends ApiService {

    private masters!: BehaviorSubject<IMaster | null>;

    constructor(
        auth: AuthService,
        http: HttpClient,
        private actionsService: ActionsService
    ) {
        super(auth, http);
        this.masters = new BehaviorSubject<IMaster | null>(null);
    }

    public setMasters(data: IMaster): void {
        this.setDataAction(data?.actions);
        this.masters.next(data);
    }

    public getMasters(): Observable<IMaster | null> {
        return this.masters.asObservable();
    }

    public getMastersFromService(): Observable<IMaster> {
        return this.http.get<IMaster>(environment.dxBotApiUrl + '/v2/master', {headers: this.getHeaders()});
    }

    public setDataAction(actions: Array<IActionJobs>): void {
        if (actions) {
            actions.map((actionMaster: IActionJobs) => {
                this.actionsService.actions.map((actionConf: IActionConf) => {
                    if (actionConf.action === actionMaster.name) {
                        actionMaster.fields.forEach((field: IActionField) => {
                            let value: any = {};
                            field.parameters?.forEach((parameter: IActionField) => {
                                value[parameter.name] =  null;
                            });
                            if (field.type === 'arrayParameter') {
                                field.value = [];
                                field.value.push(value);
                            } else if (field.type === 'compoundParameter') {
                                field.value = value;
                            } else {
                                field.value = null;
                            }
                        })
                        actionMaster.description = actionConf.description;
                        actionMaster.icon = actionConf.icon;
                        actionMaster.aux_description = actionConf.aux_description;
                        // La accion open url tenga un recording
                        if (actionMaster.name === Action.OPEN_URL) {
                            actionMaster.fields.push({name: ActionNameTypes.Recording, required: true, type: 'boolean', value: true});
                        }
                    }
                });
            })
        }
    }
    
}
