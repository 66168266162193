import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { AutomationStatus, ColorTagAutomationStatus, IAutomationExecuteResponse, IAutomationListDetail, IAutomationsListResponse, TranslateAutomationStatus } from 'src/app/pages/automation/models/automations.models';
import { AutomationService } from 'src/app/pages/automation/services/automation.service';
import { IPagination } from '../../models/pagination.models';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SkeletonTypes } from '../../models/skeleton.models';
import { IAvatar } from '../../models/avatar.models';
import { ICollaborator } from '../../models/collaborators.models';
import { IConnectionDetail } from 'src/app/pages/connection/models/connection.models';

@Component({
    selector: 'app-modal-automation-by-connection',
    templateUrl: './modal-automation-by-connection.component.html',
    styleUrls: ['./modal-automation-by-connection.component.scss']
})
export class ModalAutomationByConnectionComponent implements OnInit {

    public QUANTITY: number = 3;
    public automationsData: IAutomationsListResponse | null = null;
    public automationPagination: IPagination = {
        page: 1,
        limit: this.QUANTITY,
        total: 0
    };
    public searchForm: FormControl = new FormControl('', [Validators.minLength(3)]);
    public loadingAutomation: boolean = false;
    public automationsError: string = '';
    public skeletonTypes = SkeletonTypes;
    public automationStatus: any = AutomationStatus;
    public translateAutomationStatus: any = TranslateAutomationStatus;
    public colorTagAutomationStatus: any = ColorTagAutomationStatus;

    constructor(
        public dialogRef: MatDialogRef<ModalAutomationByConnectionComponent>,
        public automationService: AutomationService,
        @Inject(MAT_DIALOG_DATA) public data: {
            connection: IConnectionDetail
        }
    ) { }

    ngOnInit(): void {
        this.getAutomations();
    }

    public getAutomations(pagination?: IPagination): void {
        if (!this.searchForm.valid) {
            this.searchForm.markAsTouched();
            return;
        }
        this.searchForm.disable();
        this.automationsData = null;
        this.automationsError = '';
        this.automationService.getAutomationsListFromService({
                page: this.automationPagination.page || 1,
                quantity: this.QUANTITY,
                filter: this.searchForm.value,
                datasource_id: this.data.connection.id
            }).subscribe({
                next: (response: IAutomationsListResponse) => {
                    if (response) {
                        this.automationsData = response;
                        this.automationPagination.page = response.current_page;
                        this.automationPagination.total = response.total;
                        this.automationPagination.limit = this.QUANTITY;
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.automationsError = error?.error?.error || 'Ha ocurrido un error al obtener los datos,<br> inténtelo de nuevo más tarde';
                    this.searchForm.enable();
                },
                complete: () => {
                    this.searchForm.enable();
                }
            });
    }

    // Ejecutamos la automatizacion por detrás
    public executeAutomation(automation: IAutomationListDetail): void {
        const prevStatus = automation.status;
        automation.status = AutomationStatus.Ongoing;
        this.automationService.executeAutomationFromService(automation.id)
            .subscribe({
                next: (response: IAutomationExecuteResponse) => {
                    if (response) {
                        automation.status = prevStatus;
                    }
                },
                error: (error: HttpErrorResponse) => {
                    automation.status = AutomationStatus.Error;
                },
                complete: () => {
                }
            });
    }

    public getAvatarCollaborators(collaborators: Array<ICollaborator | any>): Array<IAvatar> {
        if (collaborators) {
            return collaborators.map((collaborator: ICollaborator) => {
                return {
                    id: collaborator.id,
                    img: collaborator.img,
                    name: collaborator.full_name
                }
            });
        }
        return [];
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

}
