import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ITemplate, ITemplateCategory } from 'src/app/pages/template/models/template.models';
import { IAlertFirstSteps } from '../../models/alert.models';
import { IAvatar } from '../../models/avatar.models';
import { IBreadcrumbElement } from '../../models/breadcrumb.models';
import { IChipElement } from '../../models/chips.models';
import { IDropdownElement } from '../../models/dropdown.models';
import { IDropdownSelectOption } from '../../models/dropdownSelect.models';
import { IFilter } from '../../models/filters.models';
import { INotificationElement } from '../../models/notification-element.models';
import { IPagination } from '../../models/pagination.models';
import { ISidebar } from '../../models/sidebar.models';
import { SkeletonTypes } from '../../models/skeleton.models';
import { ITabElement } from '../../models/tabs.models';
import { FiltersService } from '../../services/filters.service';
import { UtilsService } from '../../services/utils.service';
import { ModalExampleComponent } from '../modal-example/modal-example.component';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';

@Component({
    selector: 'app-components-list-design',
    templateUrl: './components-list-design.component.html',
    styleUrls: ['./components-list-design.component.scss']
})
export class ComponentsListDesignComponent implements OnInit, OnDestroy {

    public component: string = '';
    public tabSelected: string = '';
    public skeletonTypes = SkeletonTypes;

    // Alerta configuración
    public confFirstSteps: IAlertFirstSteps = {
        title: 'Primeros pasos',
        steps: [
            {
                title: 'Añade una conexión',
                text: 'Conecta Alice con otras aplicaciones para añadirlas a tus flujos y así automatizar tareas web con ellas.',
                textButton: 'Ir a conexiones',
                urlButton: '/conexiones',
                image: 'first-steps--connections.svg',
                checked: false 
            }, {
                title: 'Crea una base de datos',
                text: 'Configura una base de datos para consultar la información que Alice ha extraído de una automatización web.',
                textButton: 'Crear base de datos',
                urlButton: '',
                image: 'first-steps--data_base.svg',
                checked: false 
            }, {
                title: 'Descubre plantillas',
                text: 'Automatiza rápidamente creando tus plantillas o adapta las compartidas por la comunidad DxBot.',
                textButton: 'Descubrir plantillas',
                urlButton: '',
                image: 'first-steps--templates.svg',
                checked: false 
            }, {
                title: 'Invita a colaboradores',
                text: 'Agrega a las personas que colaborán contigo creando automatizaciones.',
                textButton: 'Invitar colaboradores',
                urlButton: '',
                image: 'first-steps--collaborators.svg',
                checked: false 
            }, {
                title: 'Crea una automatización',
                text: 'Desde cero o utilizando una plantilla, crea tu primer flujo de un proceso web que quieres automatizar.',
                textButton: 'Crea una automatización',
                urlButton: '',
                image: 'first-steps--workflows.svg',
                checked: false 
            }, {
                title: '¡Enhorabuena!',
                text: 'Ya conoces todo lo que necesta Alice para automatizar tareas repetitivas y así puedes ocuparte de lo realmente importante.',
                textButton: null,
                urlButton: null,
                image: 'first-steps--completed.svg',
                checked: false,
                onComplete: true
            },
        ],
        index: 0
    };

    // Avatar configuración
    public confAvatar: Array<IAvatar> = [
        {
            id: '34',
            img: '../../../../assets/resources/avatar.png',
            name: 'Rafa vuz'
        }, {
            id: '35',
            img: null,
            name: null
        }, {
            id: '36',
            img: null,
            name: 'gafa'
        }, {
            id: '37',
            img: '../../../../assets/resources/avatar.png',
            name: 'Rafa Cruz'
        }, {
            id: '38',
            img: null,
            name: null
        }, {
            id: '39',
            img: '../../../../assets/resources/avatar.png',
            name: 'Rafa Cruz'
        }];

    // Breadcrumb configuración
    public confBreadcrumb: Array<IBreadcrumbElement> = [
        {
            url: '',
            title: 'elemento 1'
        }, {
            url: '',
            title: 'elemento 2'
        }, {
            url: '',
            title: 'elemento 3'
        }
    ];

    // Tabs configuración
    public confTabs: Array<ITabElement> = [
        {
            title: 'Tab 1',
            selected: true,
            notifications: 2,
            id: 'tab1'
        }, {
            title: 'Tab 2',
            selected: false,
            notifications: 0,
            id: 'tab2'
        }, {
            title: 'Tab 3',
            selected: false,
            notifications: 0,
            id: 'tab3'
        }, {
            title: 'Tab 4',
            selected: false,
            notifications: 1,
            id: 'tab4'
        }
    ];

    public confTabsPills: Array<ITabElement> = [
        {
            title: 'Tab Pill 1',
            selected: false,
            notifications: 2,
            id: 'tabpill1'
        }, {
            title: 'Tab Pill 2',
            selected: true,
            notifications: 0,
            id: 'tabpill2'
        }, {
            title: 'Tab Pill 3',
            selected: false,
            notifications: 0,
            id: 'tabpill3'
        }, {
            title: 'Tab Pill 4',
            selected: false,
            notifications: 1,
            id: 'tabpill4'
        }
    ];

    public confTabsPillsVertical: Array<ITabElement> = [
        {
            title: 'Tab Active',
            selected: false,
            id: 'tabpillvertical1'
        }, {
            title: 'Tab Pill 2',
            selected: true,
            id: 'tabpillvertical2'
        }, {
            title: 'Tab Pill 3',
            selected: false,
            id: 'tabpillvertical3'
        }, {
            title: 'Tab Pill 4',
            selected: false,
            id: 'tabpillvertical4'
        }
    ];

    // Rating configuración
    public star: number = 5;

    // Sidebar configuración
    public confSidebar: ISidebar = {
        items: [
            {
                icon: 'icon-home',
                name: 'Inicio',
                url: '/',
                notification: false
            }, {
                icon: 'icon-workflow',
                name: 'Mis automatizaciones',
                url: '/',
                notification: false
            }, {
                icon: 'icon-conection',
                name: 'Conexiones',
                url: '/',
                notification: true
            }, {
                icon: 'icon-layout',
                name: 'Plantillas',
                url: '/',
                notification: false
            }, {
                icon: 'icon-database',
                name: 'Base de datos',
                url: '/',
                notification: false
            }, {
                icon: 'icon-help-circle',
                name: 'Ayuda',
                url: '/',
                notification: false
            }
        ],
        showButton: true
    }

    // Chips configuración
    public confChips: Array<IChipElement> = [
        {
            label: 'Chip 1'
        }, {
            label: 'Chip 2',
            isRemoved: false
        }, {
            label: 'Chip 3',
        }, {
            label: 'Chip 4',
        }, 
    ]

    // Dropdown configuración
    public confDropdown: Array<IDropdownElement> = [
        {
            selected: false,
            label: 'Option',
            type: null,
            url: '',
            id: 'option1'
        }, {
            selected: true,
            label: 'Option Selected',
            type: null,
            url: '',
            id: 'option2'
        }, {
            selected: false,
            label: 'Cerrar sesión',
            type: 'endRed',
            url: '',
            id: 'closeSession'
        }
    ];

    // Switcher configuración
    public valueSwitcher: boolean = true;

    // Filters configuración
    public filters: IFilter = {
        id: 'componentList',
        listFilter: [
            {
                type: 'button-toggle',
                title: 'Tipo',
                name: 'tipo',
                value: ['manual'],
                options: [
                    {
                        name: 'Desencadenada',
                        id: 'desencadenada'
                    }, {
                        name: 'Manual',
                        id: 'manual'
                    }, {
                        name: 'Programada',
                        id: 'programada'
                    }
                ]
            }, {
                type: 'text',
                title: 'Tipo',
                name: 'tipo',
                value: ['manual']
            }, {
                type: 'button-toggle',
                title: 'Estado',
                name: 'status',
                value: ['ejecutando', 'activa'],
                default_value: ['inactiva'],
                options: [
                    {
                        name: 'Activa',
                        id: 'activa'
                    }, {
                        name: 'Inactiva',
                        id: 'inactiva'
                    }, {
                        name: 'Con errores',
                        id: 'errores'
                    }, {
                        name: 'Ejecutando',
                        id: 'ejecutando'
                    }
                ]
            }
        ]
    }

    // Dropdown select configuración
    public confDropdownSelect: IDropdownSelectOption[] = [
        {
            name: 'Seleccione uno',
            id: null
        }, {
            name: 'prueba 1',
            id: 'valor1'
        }, {
            name: 'prueba 2',
            id: 'valor2'
        }
    ];

    public valueDropdownSelect!: IDropdownSelectOption;

    // Toolbar configuración
    public confToolbarDropdownSelect: IDropdownSelectOption[] = [
        {
            name: 'Ordenar de más a menos reciente',
            id: '1'
        }, {
            name: 'Ordenar de más a menos antiguo',
            id: '2'
        }
    ]

    public valueToolbarDropdownSelect: IDropdownSelectOption = {
        name: 'Ordenar de más a menos reciente',
        id: '1'
    };

    // Pagination configuración
    public confPagination: IPagination = {
        page: 1,
        limit: 10,
        total: 101
    }

    // Table configuración
    public confDropdownTable: Array<IDropdownElement> = [
        {
            selected: false,
            label: 'Ver a Alice automatizar',
            icon: 'icon-eye',
            id: 'option1'
        }, {
            selected: false,
            label: 'Editar nombre',
            icon: 'icon-edit-2',
            id: 'option2'
        }, {
            selected: false,
            label: 'Duplicar',
            icon: 'icon-copy',
            id: 'option2'
        }, {
            selected: false,
            label: 'Archivar',
            icon: 'icon-folder',
            id: 'option2'
        }, {
            selected: false,
            label: 'Mover a borradores',
            icon: 'icon-move',
            id: 'option2'
        }, {
            selected: false,
            label: 'Invitar a colaborar',
            icon: 'icon-plus',
            id: 'option2'
        }, {
            selected: false,
            label: 'Añadir a mis plantillas',
            icon: 'icon-layout',
            id: 'option2'
        }, {
            selected: false,
            label: 'Eliminar',
            type: 'endRed',
            icon: 'icon-trash',
            id: 'option2'
        }, 
    ];

    // Item configuración
    public confDropdownItem: Array<IDropdownElement> = [
        {
            selected: false,
            label: 'Ver a Alice automatizar',
            icon: 'icon-eye',
            id: 'option1'
        }, {
            selected: false,
            label: 'Editar',
            icon: 'icon-edit-2',
            id: 'option2'
        }, {
            selected: false,
            label: 'Archivar',
            icon: 'icon-folder',
            id: 'option2'
        }, {
            selected: false,
            label: 'Mover a borradores',
            icon: 'icon-move',
            id: 'option2'
        }, {
            selected: false,
            label: 'Eliminar',
            type: 'endRed',
            icon: 'icon-trash',
            id: 'option2'
        }, 
    ];

    public itemsNotifications: Array<INotificationElement> = [
        {
            pending: true,
            type: '1',
            title: 'Nuevos comentarios de Jaime en “Nombre de la automatización”',
            date: '12/12/22 - 09:30',
            confDropdown: this.confDropdownItem,
            id: 1234,
            bodyText: 'Jaime Quirós ha añadido 5 nuevos comentarios en la automatización <b>“Nombre de la automatización”</b>',
            bodyButton: 'Ver Comentario',
            isOpen: true
        }, {
            pending: true,
            type: '2',
            title: 'Nuevos comentarios de Jaime en “Nombre de la automatización”',
            date: '12/12/22 - 09:30',
            confDropdown: this.confDropdownItem,
            id: 1234,
            bodyText: 'Jaime Quirós ha añadido 5 nuevos comentarios en la automatización <b>“Nombre de la automatización”</b>',
            bodyButton: 'Ver Comentario'
        }, {
            pending: false,
            type: '3',
            title: 'Nuevos comentarios de Jaime en “Nombre de la automatización”',
            date: '12/12/22 - 09:30',
            confDropdown: this.confDropdownItem,
            id: 1234,
            bodyText: 'Jaime Quirós ha añadido 5 nuevos comentarios en la automatización <b>“Nombre de la automatización”</b>',
            bodyButton: 'Ver Comentario'
        }, {
            pending: false,
            type: '4',
            title: 'Nuevos comentarios de Jaime en “Nombre de la automatización”',
            date: '12/12/22 - 09:30',
            confDropdown: this.confDropdownItem,
            id: 1234,
            bodyText: 'Jaime Quirós ha añadido 5 nuevos comentarios en la automatización <b>“Nombre de la automatización”</b>',
            bodyButton: 'Ver Comentario'
        }, {
            pending: false,
            type: '5',
            title: 'Nuevos comentarios de Jaime en “Nombre de la automatización”',
            date: '12/12/22 - 09:30',
            confDropdown: this.confDropdownItem,
            id: 1234,
            bodyText: 'Jaime Quirós ha añadido 5 nuevos comentarios en la automatización <b>“Nombre de la automatización”</b>',
            bodyButton: 'Ver Comentario'
        }
    ]

    // Sidebar template 
    public sidebarElements: Array<ITemplateCategory> = [{
            name: 'Todas',
            amount: 50,
            id: 1
        }, {
            name: 'Compartidas por ti',
            amount: 50,
            id: 0
        }, {
            name: 'Apps',
            amount: 2,
            id: 0
        }, {
            name: 'Comercio',
            amount: 12,
            id: 0
        }, {
            name: 'Comunicación',
            amount: 3,
            id: 0
        }, {
            name: 'Contenido y archivos',
            amount: 10,
            id: 0
        }, {
            name: 'Recursos Humanos',
            amount: 5,
            id: 0
        }
    ];

    public template: ITemplate = {
        id: '1',
        name: 'Guardar CV adjuntos en Gmail en Google Drive',
        category: '3',
        rating: 1,
        owner: 'Tecalis',
        connections: ['1', '3'],
        created_at: '12/12/123',
        public: true,
        description: '',
        instructions: {action: null, children: []}
    }
    public variableBoolean: boolean =  false;
    private allSubscription: Subscription[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private matDialog: MatDialog,
        private fb: FormBuilder,
        private filterService: FiltersService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.activatedRoute.params
            .subscribe(
                (params: Params) => {
                    this.component = params['component'];
                }
            );

        this.allSubscription.push(this.filterService.getFilters()
            .subscribe((filters: IFilter | null) => {
                if (filters?.id === 'componentList') {
                    this.filters = filters;
                }
            })
        );
    }

    public buttonClicked(item?: any) {
        console.log('Boton clicked', item);
    }

    public openModalConfirmation() {
        this.utilsService.openModalGeneric({
            type: 'confirm',
            title: 'Se ha enviado la invitación correctamente',
            textButtonConfirm: 'Ok'
        });
    }

    public openModalError() {
        this.utilsService.openModalGeneric({
            type: 'error',
            title: '¿Seguro que quieres cerrar sesión?',
            text: 'Esta conexión se está utilizando en <b>2 automatizaciones</b>',
            textButtonConfirm: 'Si, cerrar sesión',
            textButtonCancel: 'Cancelar'
        });
    }

    public openModalLoading() {
        this.utilsService.openModalGeneric({
            type: 'loading',
            title: 'Cargando...',
            text: 'Conectando con la app'
        });
    }

    public openModal() {
        const dialogRef = this.matDialog
            .open(ModalExampleComponent, {
                disableClose: true,
                panelClass: ["tc-modal"],
                maxWidth: "800px",
                data: {
                    textButtonConfirm: 'Si, cerrar sesión',
                    textButtonCancel: 'Cancelar'
                },
            });

        dialogRef
            .afterClosed()
            .subscribe((result: any) => {
                if (result) {
                    console.log(`Dialog result: ${result}`);
                }
            });
    }

    public openFilter() {
        this.filterService.setFilters(this.filters);
        this.filterService.setShowFilters(true);
    }

    ngOnDestroy(): void {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map((subs: Subscription) => subs.unsubscribe());
        }
    }
}
