<label class="switcher" [ngClass]="design" *ngIf="controlGroup" [formGroup]="controlGroup">
    <input type="checkbox" *ngIf="!control" [checked]="value" (change)="changeStatus()">
    <input type="checkbox" *ngIf="control" [formControl]="control">
    <input type="checkbox" *ngIf="controlName" [formControlName]="controlName">
    <span class="switcher__control"></span>
</label> 

<label class="switcher" [ngClass]="design" *ngIf="!controlGroup">
    <input type="checkbox" *ngIf="!control" [checked]="value" (change)="changeStatus()">
    <input type="checkbox" *ngIf="control" [formControl]="control">
    <input type="checkbox" *ngIf="controlName" [formControlName]="controlName">
    <span class="switcher__control"></span>
</label> 
