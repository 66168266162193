import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITemplateCategory } from 'src/app/pages/template/models/template.models';
import { TemplateService } from 'src/app/pages/template/services/template.service';

@Component({
    selector: 'app-modal-add-update-category',
    templateUrl: './modal-add-update-category.component.html',
    styleUrls: ['./modal-add-update-category.component.scss']
})
export class ModalAddUpdateCategoryComponent implements OnInit {

    public nameCategory: FormControl = new FormControl('', Validators.required);
    public loadingAddUpdateCategory: boolean = false;
    public errorAddUpdateCategory: string = '';
    public successAddUpdateCategory: boolean = false;
    public category!: ITemplateCategory;
    private userCategories!: Array<ITemplateCategory>;

    constructor(
        public dialogRef: MatDialogRef<ModalAddUpdateCategoryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private templateService: TemplateService
    ) { }

    ngOnInit(): void {
        this.userCategories = this.data.userCategories;
        if (this.data.category) {
            this.category = this.data.category;
            this.nameCategory.patchValue(this.category.name);
        }
    }

    public addOrUpdateCategory(): void {
        if (this.nameCategory.valid) {
            this.loadingAddUpdateCategory = true;
            this.errorAddUpdateCategory = '';
            this.successAddUpdateCategory = false;
            if (this.category) {
                this.updateCategory();
            } else {
                this.addCategory();
            }
        } else {
            this.nameCategory.markAsTouched();
        }
    }

    // Añadir categoría
    private addCategory(): void {
        this.templateService.createCategory(this.nameCategory.value)
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.loadingAddUpdateCategory = false;
                        this.successAddUpdateCategory = true;
                        if (response.length) {
                            const foundIndex = this.userCategories.findIndex((category: ITemplateCategory) =>  category.name === 'Sin categorizar' || category.id === -2);
                            const newCategory = {
                                id: +response[0],
                                name: this.nameCategory.value,
                                amount: 0
                            };
                            if (foundIndex > -1) {
                                this.userCategories.splice(foundIndex, 0, newCategory);
                            } else {
                                this.userCategories.push(newCategory);
                            }
                        }
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.loadingAddUpdateCategory = false;
                    this.errorAddUpdateCategory = error?.error?.error || 'Ha ocurrido un error al crear la categoría,<br> inténtelo de nuevo más tarde';
                },
                complete: () => {
                    this.loadingAddUpdateCategory = false;
                }
            });
    }

    // Cambiar nombre categoría
    private updateCategory(): void {
        this.templateService.updateNameCategory(this.category.id, this.nameCategory.value)
            .subscribe({
                next: (response: any) => {
                    if (response) {
                        this.loadingAddUpdateCategory = false;
                        this.successAddUpdateCategory = true;
                        this.category.name = this.nameCategory.value;
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.loadingAddUpdateCategory = false;
                    this.errorAddUpdateCategory = error?.error?.error || 'Ha ocurrido un error al actualizar la categoría,<br> inténtelo de nuevo más tarde';
                },
                complete: () => {
                    this.loadingAddUpdateCategory = false;
                }
            });
    }

    // Cerrar modal
    public closeModal(): void {
        this.dialogRef.close();
    }

}
