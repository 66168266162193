<div class="onboarding">
    <app-alice design="alice--big"></app-alice>
    <ng-container *ngIf="accountData?.user?.first_steps">
        <ng-container *ngIf="phases[0]">
            <h1 class="fadeIn">
                Hola {{ accountData.user.name || 'Usuario' }}
            </h1>
        </ng-container>
        <ng-container *ngIf="phases[1]">
            <h1 class="fadeIn">
                Soy Alice
            </h1>
            <h1 class="fadeIn">
                tu asistente en automatizaciones
            </h1>
        </ng-container>
        <ng-container *ngIf="phases[2]">
            <h1 class="fadeIn">
                Haré por tí
            </h1>
            <h1 class="fadeIn">
                todas esas tareas web repetitivas
            </h1>
        </ng-container>
        <ng-container *ngIf="phases[3]">
            <h1 class="fadeIn">
                para que te centres
            </h1>
            <h1 class="fadeIn">
                en lo que realmente <span class="underline-item" [ngClass]="{'underline-item__underline': underline}">te importa</span>
            </h1>
        </ng-container>
        <ng-container *ngIf="phases[4]">
            <h1 class="fadeIn">
                ¿Empezamos?
            </h1>
            <h1>
                <app-button design="button--lg button--ghost" (buttonClicked)="hideFirstTime()">Iniciar</app-button>
            </h1>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="msg && !accountData?.user?.first_steps">
        <h1>
            {{ msg }}
        </h1>
    </ng-container>
</div>