import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-alice',
    templateUrl: './alice.component.html',
    styleUrls: ['./alice.component.scss']
})
export class AliceComponent implements OnInit {

    @Input() design!: string;

    constructor() { }

    ngOnInit(): void {
    }

}
