import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @Input() design!: string;
    @Input() disabled!: boolean;
    @Input() icon!: string;
    @Output() buttonClicked = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

}
