import { Injectable } from '@angular/core';
import { allLocalStorage } from '../models/masters.models';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private dialogTokenHasExpired: any = null;
    private developerToken: string = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9ob3N0LmRvY2tlci5pbnRlcm5hbDo4MDgwXC92MlwvbG9naW4iLCJpYXQiOjE2NzQ4MDMwMTYsImV4cCI6MjAxNjk0NzAxNiwibmJmIjoxNjc0ODAzMDE2LCJqdGkiOiJwdldPY3lFRmxSdGJycVVYIiwic3ViIjoxLCJwcnYiOiIxNDVjNmFkMmI0ZDA1NGZmNjIyMTk0Yzk5ZWZiMWQyNWI3NjcyNDZmIn0.jRQN24JLIeNSOdObM8JaTbZJbTQbMIrRg1iUD-S6fGs';

    constructor() { }

    private parseJwt(token: any): any {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        } catch(error) {
            return false;
        }
    }

    private checkIfTokenHasExpired(): boolean {
        let access_token: string | null = !environment.production ? this.developerToken : localStorage.getItem(allLocalStorage.accessToken);

        if (access_token && access_token !== '') {
            const jwt = this.parseJwt(access_token);
            if (!jwt) {
                return true;
            }
            const exp = jwt.exp * 1000;

            return new Date().getTime() >= exp;
        }

        return true;
    }

    public getToken(): any {
        if (this.checkIfTokenHasExpired()) {
            console.error('JWT expired');

            if (this.dialogTokenHasExpired == null) {
                // TODO 
                // Mostrar modal con que la sesion a expirado
                // this.dialogTokenHasExpired = this.utilsService.openModalGeneric({
                //     type: 'error',
                //     title: `La sesión ha caducado`,
                //     textButtonConfirm: 'Iniciar sesión'
                // })
                // o redirigimos a
                this.dialogTokenHasExpired = true;
                window.location.href = 'https://account.tecalis.com/home';
            }
            return;
        }
        return !environment.production ? this.developerToken : localStorage.getItem(allLocalStorage.accessToken);
    }
}
