import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-button-icon',
    templateUrl: './button-icon.component.html',
    styleUrls: ['./button-icon.component.scss']
})
export class ButtonIconComponent implements OnInit {

    @Input() design!: string;
    @Input() disabled!: boolean;
    @Input() icon!: string;
    @Input() badge!: number;
    @Output() buttonClicked = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

}
