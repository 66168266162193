import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appWatchDomTree]'
})
export class WatchDomTreeDirective {

    constructor(
        private elRef: ElementRef
    ) { }

    ngOnInit() {
        this.registerDomChangedEvent(this.elRef.nativeElement);
    }
      
    registerDomChangedEvent(el: any) {
        const observer = new MutationObserver(list => {
            const evt = new CustomEvent('dom-changed', {detail: list, bubbles: true});
            el.dispatchEvent(evt);
        });
        const attributes = true;
        const childList = true;
        const subtree = true;
        observer.observe(el, {attributes, childList, subtree});
    }
}
