import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { ConnectionOption, ConnectionStatus, ConnectionType, IConnectionAddRequest, IConnectionAddResponse, IConnectionsListRequest, IConnectionsListResponse, IConnectionTotal } from '../models/connection.models';
import { ITabElement } from 'src/app/shared/models/tabs.models';
import { IDropdownElement } from 'src/app/shared/models/dropdown.models';

@Injectable({
    providedIn: 'root'
})
export class ConnectionService extends ApiService {

    private connectionTab: BehaviorSubject<ConnectionStatus | null>;

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
        this.connectionTab = new BehaviorSubject<ConnectionStatus | null>(null);
    }

    public setConnectionTab(data: ConnectionStatus | null) {
        this.connectionTab.next(data);
    }

    public getConnectionTab(): Observable<ConnectionStatus | null> {
        return this.connectionTab.asObservable();
    }

    public putConnectionStatus(id: any, status: ConnectionStatus): Observable<any> {
        return this.http.post<any>(environment.dxBotApiUrl + `/v2/datasource/${id}/status`, {status: status}, {headers: this.getHeaders()});
    }

    public deleteConnection(id: any): Observable<any> {
        return this.http.delete<any>(environment.dxBotApiUrl + `/v2/datasource/${id}`, {headers: this.getHeaders()});
    }

    public testConnection(id: any): Observable<any> {
        return this.http.post<any>(environment.dxBotApiUrl + `/v2/datasource/${id}/test`, {}, {headers: this.getHeaders()});
    }

    public getTotalConnectionsFromService(): Observable<IConnectionTotal> {
        return this.http.get<IConnectionTotal>(environment.dxBotApiUrl + '/v2/datasource/totals', {headers: this.getHeaders()});
    }

    public getConnectionsToAdd(filters: IConnectionAddRequest): Observable<IConnectionAddResponse> {
        return this.http.get<IConnectionAddResponse>(environment.dxBotApiUrl + '/v2/datasource/add-new', {
            params: this.getParamsConnectionAdd(filters),
            headers: this.getHeaders()
        });
    }

    public createConnection(body: FormData): Observable<any> {
        return this.http.post<any>(environment.dxBotApiUrl + `/v2/datasource`, body, {headers: this.getHeadersFormData()});
    }

    public getConnectionsListFromService(filters: IConnectionsListRequest): Observable<IConnectionsListResponse> {
        return this.http.get<IConnectionsListResponse>(environment.dxBotApiUrl + '/v2/datasource', {
            params: this.getParamsConnection(filters),
            headers: this.getHeaders()
        });
    }

    private getParamsConnection(filters: IConnectionsListRequest): HttpParams {
        let params = new HttpParams();
        params = params.set('page', filters.page);
        params = params.set('quantity', filters.quantity);

        if (filters.status) {
            params = params.set('status', filters.status);
        }
        if (filters.order_by) {
            params = params.set('order_by', filters.order_by);
        }
        if (filters.type_id?.length) {
            filters.type_id.map((type: any) => {
                params = params.append('type_id[]', type);
            });
        }
        if (filters.filter) {
            params = params.set('filter', filters.filter);
        }
        return params;
    }

    private getParamsConnectionAdd(filters: IConnectionAddRequest): HttpParams {
        let params = new HttpParams();
        params = params.set('page', filters.page);
        params = params.set('quantity', filters.quantity);
        if (filters.filter) {
            params = params.set('filter', filters.filter);
        }
        return params;
    }

    public getConfTabsConnections(): Array<ITabElement> {
        return [
            {
                title: 'Todas',
                selected: true,
                id: ConnectionStatus.Total
            }, {
                title: 'Con errores',
                selected: false,
                notifications: 0,
                id: ConnectionStatus.Error
            }, {
                title: 'Archivadas',
                selected: false,
                id: ConnectionStatus.Archived
            }
        ];
    }

    public getConnectionsListActions(): Array<IDropdownElement> {
        return [
            {
                label: 'Probar conexión',
                icon: 'icon-check-circle',
                id: ConnectionOption.Test
            }, {
                label: 'Editar',
                icon: 'icon-edit-2',
                id: ConnectionOption.Edit
            }, {
                label: 'Archivar',
                icon: 'icon-folder',
                id: ConnectionOption.MoveToStore
            }, {
                label: 'Eliminar',
                type: 'endRed',
                icon: 'icon-trash',
                id: ConnectionOption.Remove
            }
        ]
    }

    public getIconByType(id: any): string {
        switch (id) {
            case ConnectionType.CSV:
                return 'icon-capture-opened-window';
            case ConnectionType.Excel:
                return 'icon-capture-popup';
            case ConnectionType.FTP:
                return 'icon-change-user-agent';
            default:
                return 'icon-clock';
        }
    }
}
