import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AyudaComponent } from './shared/components/ayuda/ayuda.component';
import { PruebaComponent } from './shared/components/prueba/prueba.component';
import { ComponentsListDesignComponent } from './shared/design-components/components-list-design/components-list-design.component';
import { ListDesignComponent } from './shared/design-components/list-design/list-design.component';
import { JwtComponent } from './core/components/jwt/jwt.component';

const routes: Routes = [
    { path: 'jwt/:jwt', component: JwtComponent },
    { path: 'prueba', component: PruebaComponent },
    { path: 'list-desing', component: ListDesignComponent },
    { path: 'list-desing/help', component: AyudaComponent },
    { path: 'list-desing/:component', component: ListDesignComponent },
    { path: 'components-list-desing', component: ComponentsListDesignComponent },
    { path: 'components-list-desing/:component', component: ComponentsListDesignComponent },
    { path: '', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
    { path: 'automatizaciones', loadChildren: () => import('./pages/automation/automation.module').then(m => m.AutomationModule) },
    { path: 'conexiones', loadChildren: () => import('./pages/connection/connection.module').then(m => m.ConnectionModule) },
    { path: 'plantillas', loadChildren: () => import('./pages/template/template.module').then(m => m.TemplateModule) },
    { path: 'base-de-datos', loadChildren: () => import('./pages/database/database.module').then(m => m.DatabaseModule) },
    { path: 'perfil', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },
    { path: 'notificaciones', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsModule) },
    { path: 'area-trabajo', loadChildren: () => import('./pages/workspace/workspace.module').then(m => m.WorkSpaceModule) },
    { path: 'demo-area', loadChildren: () => import('./pages/demo-area/demo-area.module').then(m => m.DemoAreaModule) },
    { path: 'ayuda', component: PruebaComponent },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
