<div class="flex" style="width: 100%;height: 100%;">
    <div class="sidebar-prov">
        <!-- <a routerLink="/list-desing/help">Ayuda</a> -->
        <a routerLink="/list-desing/alert">Alert</a>
        <a routerLink="/list-desing/alice">Alice</a>
        <a routerLink="/list-desing/avatar">Avatar</a>
        <a routerLink="/list-desing/badge">Badge</a>
        <a routerLink="/list-desing/banner">Banner</a>
        <a routerLink="/list-desing/breadcrumb">Breadcrumb</a>
        <a routerLink="/list-desing/button">Button</a>
        <a routerLink="/list-desing/button-icon">Button icon (DONE)</a>
        <a routerLink="/list-desing/progress">Progress</a>
        <a routerLink="/list-desing/sidebar">Sidebar (DONE)</a>
        <a routerLink="/list-desing/tabs">Tabs</a>
        <a routerLink="/list-desing/tile">Tile (DONE)</a>
        <a routerLink="/list-desing/tag">Tag</a>
        <a routerLink="/list-desing/tag-icon">Tag Icon</a>
        <a routerLink="/list-desing/table">Table</a>
        <a routerLink="/list-desing/icons">Icons</a>
        <a routerLink="/list-desing/dropdown">Dropdown (DONE)</a>
        <a routerLink="/list-desing/input">Input</a>
        <a routerLink="/list-desing/input-button">Input Button (DONE)</a>
        <a routerLink="/list-desing/card">Card</a>
        <a routerLink="/list-desing/rating">Rating</a>
        <a routerLink="/list-desing/tooltip">Tooltip</a>
        <a routerLink="/list-desing/modal">Modal</a>
        <a routerLink="/list-desing/modal-confirmation">Modal Confirmation</a>
        <a routerLink="/list-desing/modal-cards">Modal with cards</a>
        <a routerLink="/list-desing/images">Images</a>
        <a routerLink="/list-desing/switcher">Switcher</a>
        <a routerLink="/list-desing/toolbar">Toolbar</a>
        <a routerLink="/list-desing/pagination">Pagination</a>
        <a routerLink="/list-desing/chips">Chips</a>
        <a routerLink="/list-desing/filters">Filters</a>
        <a routerLink="/list-desing/item">Item</a>
        <a routerLink="/list-desing/tree">Tree (DONE)</a>
        <a routerLink="/list-desing/tree-node">Tree Node (DONE)</a>
        <a routerLink="/list-desing/workspace-sidebar">Workspace Sidebar (Fase 2)</a>
        <a routerLink="/list-desing/collapse-action">Collapse action (DONE)</a>
        <a routerLink="/list-desing/sidebar-item">Sidebar item (DONE)</a>
        <a routerLink="/list-desing/onboarding-alice">Onboarding Alice (DONE)</a>
        <a routerLink="/list-desing/error-message">Error Message</a>
        <a routerLink="/list-desing/comment">Comment (Fase 2)</a>
        <a routerLink="/list-desing/node-preview">Node preview (DONE)</a>
    </div>
    <div class="fg-2" style="padding: 40px; max-width: 1400px;margin: 0 auto;">
        
        <!-- ALICE -->
        <ng-container *ngIf="component === 'alice'">
            <h2>Alice</h2>
            <h4 class="m-b-16 m-t-16">Default</h4>
            <div class="alice"></div>
            <h4 class="m-b-16 m-t-16">Default Pulse</h4>
            <div class="alice alice--pulse"></div>

            <h4 class="m-b-16 m-t-16">Alice small</h4>
            <div class="alice alice--sm"></div>

            <h4 class="m-b-16 m-t-16">Alice Large</h4>
            <div class="alice alice--lg"></div>

            <h4 class="m-b-16 m-t-16">Alice Big</h4>
            <div class="alice-container" style="height: 400px;background:#230243;display:flex;align-items:center;">
                <div class="alice alice--big"></div>
            </div>
            <h4 class="m-b-16 m-t-32">Alice Big Pulse</h4>
            <div class="alice-container" style="height: 400px;background:#230243;display:flex;align-items:center;">
                <div class="alice alice--big alice--big--pulse"></div>
            </div>
        </ng-container>

        <!-- ALERT -->
        <ng-container *ngIf="component === 'alert'">
            <h2 class="m-b-16">Alert</h2>
            <h5 class="m-b-8">Default</h5>
            <div class="alert">
                <div class="alert__header">
                    <div class="alert__header__icon">
                        <i class="icon-check"></i>
                    </div>
                    <div class="alert__header__text"> No hay ningún error en esta conexión </div>
                </div>
            </div>

            <h5 class="m-b-8">Error</h5>
            <div class="alert alert--error">
                <div class="alert__header">
                    <div class="alice alice--pulse"></div>
                    <div class="alert__header__text"> He detectado <b>2 errores</b> en las conexiones </div>
                    <div class="alert__header__action">
                        <button class="button button--secondary button--xs">
                            Ver
                        </button>
                    </div>
                </div>
            </div>
            <h5 class="m-b-8">Error Expanded</h5>
            <div class="alert alert--expanded alert--error">
                <div class="alert__header">
                    <div class="alice alice--pulse"></div>
                    <div class="alert__header__text"> He detectado <b>1 errores</b> en la automatización</div>
                </div>
                <div class="alert__body">
                    Hay 1 conexión con errores. Reconecta esa conexión
                    <button class="button button--secondary button--xs">
                        Ir a conexión
                    </button>
                </div>
            </div>
            <div class="alert alert--expanded alert--error">
                <div class="alert__header">
                    <div class="alice alice--pulse"></div>
                    <div class="alert__header__text"> He detectado <b>1 errores</b> en la automatización</div>
                </div>
                <div class="alert__body">
                    Hay 1 conexión con errores. Reconecta esa conexión
                    <div class="alert__body__actions">
                        <span class="tooltip tooltip--right" data-tooltip="Reconectar">
                            <button class="button-icon">
                                <i class="icon-refresh"></i>
                            </button>
                        </span>
                        <button class="button button--secondary button--xs">
                            Ir a conexión
                        </button>
                    </div>
                </div>
            </div>

            <h5 class="m-t-16 m-b-8">First steps</h5>
            <div class="alert alert--first-steps">
                <div class="alert__body">
                    <h5>Primeros passos</h5>
                    <div class="list">
                        <div class="list__item list__item--done">
                            <div class="tag-icon">
                                <i class="icon-check"></i>
                            </div>
                            <div class="list__item__text">
                                Añade una conexión
                            </div>
                        </div>
                        <div class="list__item">
                            <div class="tag-icon">
                                2
                            </div>
                            <div class="list__item__text">
                                Crea una base de datos
                            </div>
                        </div>
                        <div class="list__item">
                            <div class="tag-icon">
                                3
                            </div>
                            <div class="list__item__text">
                                Descubre plantillas
                            </div>
                        </div>
                        <div class="list__item">
                            <div class="tag-icon">
                                4
                            </div>
                            <div class="list__item__text">
                                Invita a colaboradores
                            </div>
                        </div>
                        <div class="list__item">
                            <div class="tag-icon">
                                5
                            </div>
                            <div class="list__item__text">
                                Crea una automatización
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert__action">
                    <div class="alert__action__image">
                        <img src="../../../../assets/resources/first-steps--connections.svg" alt="">
                    </div>
                    <div class="alet__action__step-info">
                        <div>
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                        </div>
                        <button class="button">Button</button>
                        <a href="">Siguiente paso</a>
                    </div>
                </div>
                <button class="button-icon">
                    <i class="icon-close"></i>
                </button>
            </div>
        </ng-container>

        <!-- AVATAR -->
        <ng-container *ngIf="component === 'avatar'">
            <h2 class="m-b-16">Avatar</h2>

            <h5 class="m-b-8">Default</h5>
            <div class="avatar" style="background-color:#3CB372">
                <span style="color: #FFFFFF">CR</span>
            </div>

            <h5 class="m-b-8">Default with image</h5>
            <div class="avatar">
                <img src="../../../../assets/resources/avatar.png" alt="">
            </div>

            <h5 class="m-t-16 m-b-8">Avatar group</h5>
            <div class="avatar-group">
                <div class="avatar">
                    <img src="../../../../assets/resources/avatar.png" alt="">
                </div>
                <div class="avatar" style="background-color:#3CB372">
                    <span style="color: #FFFFFF">RB</span>
                </div>
                <div class="avatar" style="background-color:#EE3E03">
                    <span style="color: #FFFFFF">HS</span>
                </div>
                <div class="avatar" style="background-color:#7756DB">
                    <span style="color: #FFFFFF">RG</span>
                </div>
                <div class="avatar__number">
                    10
                </div>
            </div>

            <h5 class="m-t-16 m-b-8">Change Avatar</h5>
            <div class="avatar-group">
                <div class="avatar avatar--big" style="background-color:#E4DDF8">
                    <span style="color: #230243">CR</span>
                </div>
                <div class="button-icon">
                    <i class="icon-plus"></i>
                </div>
            </div>

        </ng-container>

        <!-- BUTTON -->
        <ng-container *ngIf="component === 'button'">
            <h2 class="m-b-16">Button</h2>
            <h5 class="m-b-8">Primary</h5>
            <div class="flex ai">
                <button class="button m-r-16">Default</button>
                <button class="button m-r-16" disabled>Disabled</button>
                <button class="button">
                    With icon
                    <i class="icon-arrow-right"></i>
                </button>
            </div>

            <h5 class="m-t-16 m-b-8">Secondary</h5>
            <div class="flex ai-flex-start">
                <button class="button button--secondary  m-r-16">Default</button>
                <button class="button button--secondary  m-r-16" disabled>Disabled</button>
                <button class="button button--secondary">
                    With icon
                    <i class="icon-arrow-right"></i>
                </button>
            </div>

            <h5 class="m-t-16 m-b-8">Soft</h5>
            <div class="flex ai-flex-start">
                <button class="button button--soft  m-r-16">
                    Default
                    <i class="icon-arrow-right"></i>
                </button>
                <button class="button button--soft" disabled>
                    Disabled
                    <i class="icon-arrow-right"></i>
                </button>
            </div>

            <h5 class="m-t-16 m-b-8">Toggle Button</h5>
            <div class="button--toggle__wrapper">
                <button class="button button--toggle button--toggle--toggled">Toggle Button Toggled</button>
                <button class="button button--toggle">Toggle Button</button>
                <button class="button button--toggle">Toggle Button</button>
            </div>

            <h5 class="m-t-16 m-b-8">Sizes</h5>
            <div class="flex ai-flex-start">
                <button class="button button--lg m-r-16">Large</button>
                <button class="button m-r-16">Medium (default)</button>
                <button class="button button--sm m-r-16">Small</button>
                <button class="button button--xs">Extra Small</button>
            </div>
            <div class="flex ai-flex-start m-t-16">
                <button class="button button--secondary button--lg m-r-16">Large</button>
                <button class="button button--secondary m-r-16">Medium (default)</button>
                <button class="button button--secondary button--sm m-r-16">Small</button>
                <button class="button button--secondary button--xs">Extra Small</button>
            </div>
        </ng-container>

        <!-- BUTTON-ICON -->
        <ng-container *ngIf="component === 'button-icon'">
            <h2 class="m-b-16">Button-icon</h2>
            <h5 class="m-b-8">Default</h5>
            <div class="flex ai">
                <button class="button-icon m-r-8">
                    <i class="icon-bell"></i>
                </button>
                <button class="button-icon" disabled>
                    <i class="icon-bell"></i>
                </button>
            </div>

            <h5 class="m-t-32 m-b-8">Status inside toolbar o sidebar</h5>
            <h6 class="m-b-8">Selected</h6>
            <button class="button-icon button-icon--selected">
                <i class="icon-bell"></i>
            </button>

            <h6 class="m-t-16 m-b-8">Error</h6>
            <button class="button-icon button-icon--warning">
                <i class="icon-bell"></i>
                <div class="badge">2</div>
            </button>

            <h6 class="m-t-16 m-b-8">Circle</h6>
            <button class="button-icon button-icon--circle">
                <i class="icon-plus"></i>
            </button>
            
        </ng-container>

        <!-- BADGE -->
        <ng-container *ngIf="component === 'badge'">

            <h2 class="m-b-16">Badge</h2>

            <h5 class="m-b-8">Default</h5>
            <div class="badge">2</div>

            <h5 class="m-t-16 m-b-8">Status</h5>
            <div class="badge badge--warning">2</div>
            <div class="badge badge--error">
                <i class="icon-alert"></i>
            </div>

            <h5 class="m-t-16 m-b-8">Small</h5>
            <div class="badge badge--small"></div>

        </ng-container>

        <!-- BANNER -->
        <ng-container *ngIf="component === 'banner'">
            <h2 class="m-b-16">Banner</h2>
            <div class="banner m-b-24">
                <i class="icon-folder i-xl"></i>
                <div class="banner__body">
                    <h5>¿Cómo conectarse a un FTP?</h5>
                    <div>Te enseñamos cómo conectarte a este protocolo de transferencia de archivos.</div>
                </div>
                <div class="banner__button">
                    <button class="button button--secondary">Descubre cómo</button>
                </div>
            </div>
            <div class="banner banner--gray">
                <div class="avatar-group">
                    <div class="avatar">
                        <img src="../../../../assets/resources/avatar.png" alt="">
                    </div>
                    <div class="avatar">
                        <img src="../../../../assets/resources/avatar.png" alt="">
                    </div>
                    <div class="avatar">
                        <img src="../../../../assets/resources/avatar.png" alt="">
                    </div>
                    <div class="avatar">
                        <img src="../../../../assets/resources/avatar.png" alt="">
                    </div>
                    <div class="avatar__number">
                        10
                    </div>
                </div>
                <div class="banner__body">
                    <h5>¿Cómo conectarse a un FTP?</h5>
                    <div>Te enseñamos cómo conectarte a este protocolo de transferencia de archivos.</div>
                </div>
                <div class="banner__button">
                    <button class="button button--secondary">Descubre cómo</button>
                </div>
            </div>
        </ng-container>

        <!-- BREADCRUMB -->
        <ng-container *ngIf="component === 'breadcrumb'">
            <h2 class="m-b-16">Breadcrumb</h2>
            <div class="breadcrumb">
                <a class="breadcrumb__item">
                    Breadcrumb item
                </a>
                <a class="breadcrumb__item">
                    Breadcrumb item
                </a>
                <div class="breadcrumb__item">
                    Breadcrumb item
                </div>
            </div>
        </ng-container>

        <!-- TABS -->
        <ng-container *ngIf="component === 'tabs'">
            <h2 class="m-b-16">Tabs</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="tabs">
                <div class="tabs__tab tabs__tab--active">Tab active</div>
                <div class="tabs__tab">Tab</div>
                <div class="tabs__tab">Tab <div class="badge">1</div>
                </div>
                <div class="tabs__tab">Tab</div>
            </div>

            <h5 class="m-b-8 m-t-16">Pills</h5>
            <div class="tabs tabs--pills">
                <div class="tabs__tab tabs__tab--active">Tab active</div>
                <div class="tabs__tab">Tab</div>
                <div class="tabs__tab">Tab</div>
            </div>

            <h5 class="m-b-8 m-t-16">Pills verticals</h5>
            <div class="tabs tabs--pills tabs--vertical">
                <div class="tabs__tab tabs__tab--active">Tab active <i class="icon-arrow-right"></i></div>
                <div class="tabs__tab">Tab <i class="icon-arrow-right"></i></div>
                <div class="tabs__tab">Tab <i class="icon-arrow-right"></i></div>
            </div>
        </ng-container>

        <!-- PROGRESS -->
        <ng-container *ngIf="component === 'progress'">
            <h2 class="m-b-16">Progress</h2>
            <h5 class="m-b-8">Default</h5>
            <h6 class="m-b-8">Default</h6>
            <div class="progress">
                <div class="progress__bar" style="width: 25%"></div>
            </div>
            <h6 class="m-b-8">Green</h6>
            <div class="progress progress--green">
                <div class="progress__bar" style="width: 50%"></div>
            </div>
            <h6 class="m-b-8">Orange</h6>
            <div class="progress progress--orange">
                <div class="progress__bar" style="width: 75%"></div>
            </div>
            <h6 class="m-b-8">Red</h6>
            <div class="progress progress--red">
                <div class="progress__bar" style="width: 95%"></div>
            </div>

            <h5 class="m-t-24 m-b-16">Big</h5>
            <h6 class="m-b-8">Default</h6>
            <div class="progress progress--big">
                <div class="progress__bar" style="width: 25%"></div>
            </div>
            <h6 class="m-b-8">Green</h6>
            <div class="progress progress--big progress--green">
                <div class="progress__bar" style="width: 50%"></div>
            </div>
            <h6 class="m-b-8">Orange</h6>
            <div class="progress progress--big progress--orange">
                <div class="progress__bar" style="width: 75%"></div>
            </div>
            <h6 class="m-b-8">Red</h6>
            <div class="progress progress--big progress--red">
                <div class="progress__bar" style="width: 95%"></div>
            </div>

        </ng-container>

        <!-- TILE -->
        <ng-container *ngIf="component === 'tile'">
            <h2 class="m-b-16">Tile</h2>
            <div class="tile m-b-16">
                <div class="tile__body">
                    <div>Tile without header</div>
                    <div class="tile m-t-16">
                        <div class="tile__body">
                            <div>Tile nested</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tile m-b-16">
                <div class="tile__header">
                    <h4>Tile with title in header</h4>
                </div>
                <div class="tile__body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam sunt porro beatae.
                    Vero aperiam blanditiis, quasi eius minima delectus porro officiis sed repudiandae! Dolor voluptatum
                    veniam nisi error magnam placeat.</div>
            </div>
            <div class="tile m-b-16">
                <div class="tile__header">
                    <h4>Tile with title and tabs in header</h4>
                    <div class="tile__header__actions">
                        <div class="tabs tabs--pills">
                            <div class="tabs__tab tabs__tab--active">Tab active</div>
                            <div class="tabs__tab">Tab</div>
                            <div class="tabs__tab">Tab</div>
                        </div>
                    </div>
                </div>
                <div class="tile__body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam sunt porro beatae.
                    Vero aperiam blanditiis, quasi eius minima delectus porro officiis sed repudiandae! Dolor voluptatum
                    veniam nisi error magnam placeat.</div>
            </div>
            <div class="tile m-b-16">
                <div class="tile__header">
                    <h4>Tile with title in header and footer</h4>
                </div>
                <div class="tile__body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam sunt porro beatae.
                    Vero aperiam blanditiis, quasi eius minima delectus porro officiis sed repudiandae! Dolor voluptatum
                    veniam nisi error magnam placeat.</div>
                <div class="tile__footer">
                    <div class="text--gray">Text gray</div>
                    <button class="button button--soft">Ver todas <i class="icon-arrow-right"></i></button>
                </div>
            </div>
            <div class="tile m-b-16">
                <div class="tile__header">
                    <h4>Tile with title and actions in header</h4>
                    <div class="tile__header__actions">
                        <div class="input-group input-group--collapsed-input">
                            <i class="icon-search"></i>
                            <input class="input" type="text" name="" id="" placeholder="Buscar">
                        </div>
                        <div class="dropdown">
                            <button class="dropdown__trigger input">
                                <span>Dropdown</span>
                                <i class="icon-chevron-down"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">Option</div>
                                <div class="dropdown__menu__item">Option 2</div>
                                <div class="dropdown__menu__item">Option 3</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tile__body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam sunt porro beatae.
                    Vero aperiam blanditiis, quasi eius minima delectus porro officiis sed repudiandae! Dolor voluptatum
                    veniam nisi error magnam placeat.</div>
            </div>
            <div class="tile m-b-16">
                <div class="tile__header">
                    <h4>Tile with title and actions in header</h4>
                    <div class="tile__header__actions">
                        <div class="input-group input-group--collapsed-input input-group--collapsed-input--expanded">
                            <i class="icon-search"></i>
                            <input class="input" type="text" name="" id="" placeholder="Buscar">
                        </div>
                        <div class="dropdown">
                            <button class="dropdown__trigger input">
                                <span>Dropdown</span>
                                <i class="icon-chevron-down"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">Option</div>
                                <div class="dropdown__menu__item">Option 2</div>
                                <div class="dropdown__menu__item">Option 3</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tile__body">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam sunt porro beatae.
                    Vero aperiam blanditiis, quasi eius minima delectus porro officiis sed repudiandae! Dolor voluptatum
                    veniam nisi error magnam placeat.</div>
            </div>
            <div class="tile tile--dashboard m-b-16">
                <div class="tile__information">
                    <div class="tile__information__title">Tile Dashboard</div>
                    <div class="tile__information__info">
                        <h2>10</h2>
                        <h5>min</h5>
                    </div>
                </div>
                <div class="tile__tags">
                    <div class="tag-icon tag-icon--purple">
                        <i class="icon-clock"></i>
                    </div>
                    <div class="tag tag--dashboard tag--warning">
                        <i class="icon-trending-down"></i>
                        <b>5%</b>
                    </div>
                </div>
            </div>
            <div class="tile tile--dashboard tile--dashboard--thin m-b-16">
                <div class="toolbar">
                    <div class="toolbar--left">
                        <div class="tag-icon tag-icon--purple">
                            <i class="icon-workflow "></i>
                        </div>
                    </div>
                    <div class="toolbar--right">
                        <div class="dropdown">
                            <button class="dropdown__trigger input">
                                <span>Esta semana</span>
                                <i class="icon-chevron-down"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">Option</div>
                                <div class="dropdown__menu__item">Option 2</div>
                                <div class="dropdown__menu__item">Option 3</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tile">
                    <div class="tile__information">
                        <div class="tile__information__info">
                            <h4>10min</h4>
                            <span>automatizado</span>
                        </div>
                    </div>
                    <div class="tile__tags">
                        <div class="tag tag--dashboard tag--success">
                            <i class="icon-trending-up"></i>
                            <b>5%</b>
                        </div>
                    </div>
                </div>
                <div class="tile">
                    <div class="tile__information">
                        <div class="tile__information__info">
                            <h4>100</h4>
                            <span>repeticiones</span>
                        </div>
                    </div>
                    <div class="tile__tags">
                        <div class="tag tag--dashboard tag--success">
                            <i class="icon-trending-up"></i>
                            <b>5%</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tile tile--profile m-b-16">
                <div class="tile__header">
                    <h4>Sobre mí</h4>
                    <div class="tile__header__actions">
                        <a href="">Editar</a>
                    </div>
                </div>
                <div class="tile__body">
                    <div class="row">
                        <div class="col-6">
                            <div class="input-group m-b-16">
                                <label for="">Label</label>
                                <input type="text" class="input" placeholder="Placeholder" disabled>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="input-group m-b-16">
                                <label for="">Label</label>
                                <input type="text" class="input" placeholder="Placeholder" disabled>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tile tile--empty-states m-b-40">
                <div class="tile__header">
                    <h5>Title in header</h5>
                </div>
                <div class="tile__body">
                    <img src="../../../../assets/resources/no-automatizaciones.svg" alt="">
                    <h5>Title empty states</h5>
                    <div>Desde cero o utilizando una plantilla, crea tu primer flujo de un proceso web que quieras
                        automatizar</div>
                    <button class="button">
                        <i class="icon-plus"></i>
                        Button
                    </button>
                </div>
            </div>

            <h5 class="m-t-16 m-b-8">Tile Dashboard Skeleton</h5>
            <div class="tile tile--dashboard tile--dashboard--skeleton m-b-24">
                <div class="tile__information">
                    <div class="tile__information__title"></div>
                    <div class="tile__information__info">
                        <h2></h2>
                        <h5></h5>
                    </div>
                </div>
                <div class="tile__tags">
                    <div class="tag-icon tag-icon--purple">
                        <i class="icon-clock"></i>
                    </div>
                    <div class="tag tag--dashboard tag--warning">
                        <i class="icon-trending-down"></i>
                        <b>patata</b>
                    </div>
                </div>
            </div>

            <h5 class="m-t-16 m-b-8">Tile Dashboard thin Skeleton</h5>
            <div class="tile tile--dashboard tile--dashboard--thin tile--dashboard--thin--skeleton">
                <div class="toolbar">
                    <div class="toolbar__left">
                        <div class="tag-icon tag-icon--purple">
                            <i class="icon-workflow "></i>
                        </div>
                    </div>
                    <div class="toolbar__right">
                        <div class="dropdown">
                            <button class="dropdown__trigger input">
                                <span>Esta semana</span>
                                <i class="icon-chevron-down"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">Option</div>
                                <div class="dropdown__menu__item">Option 2</div>
                                <div class="dropdown__menu__item">Option 3</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tile">
                    <div class="tile__information">
                        <div class="tile__information__info">
                        </div>
                    </div>
                    <div class="tile__tags">
                        <div class="tag tag--dashboard tag--success">
                            <i class="icon-trending-up"></i>
                            <b>5%</b>
                        </div>
                    </div>
                </div>
                <div class="tile">
                    <div class="tile__information">
                        <div class="tile__information__info">
                        </div>
                    </div>
                    <div class="tile__tags">
                        <div class="tag tag--dashboard tag--success">
                            <i class="icon-trending-up"></i>
                            <b>5%</b>
                        </div>
                    </div>
                </div>
            </div>

            <h5 class="m-t-16 m-b-8">Tile empty states thin Skeleton</h5>
            <div class="tile tile--empty-states tile--empty-states--skeleton m-b-40">
                <div class="tile__header">
                    <h5>Title in header</h5>
                </div>
                <div class="tile__body">
                    <img src="../../../../assets/resources/no-automatizaciones.svg" alt="">
                    <h5>Title empty states</h5>
                    <div>Desde cero o utilizando una plantilla, crea tu primer flujo de un proceso web que quieras
                        automatizar</div>
                    <button class="button">
                        <i class="icon-plus"></i>
                        Button
                    </button>
                </div>
            </div>
        </ng-container>

        <!-- ICONS -->
        <ng-container *ngIf="component === 'icons'">
            <h2 class="m-b-16">Icons</h2>
            <h5 class="m-b-8 ">Sizes</h5>
            <div class="flex">
                <i class="icon-bell i-xs"></i>
                <i class="icon-bell i-sm"></i>
                <i class="icon-bell i-md"></i>
                <i class="icon-bell i-lg"></i>
                <i class="icon-bell i-xl"></i>
            </div>

            <h5 class="m-b-8 ">Catalog</h5>
            <table class="table">
                <tr>
                    <td>
                        <i class="icon-alert-triangle"></i>
                    </td>
                    <td>
                        icon-alert-triangle
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-minus"></i>
                    </td>
                    <td>
                        icon-minus
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-pending-check"></i>
                    </td>
                    <td>
                        icon-pending-check
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-execution-error"></i>
                    </td>
                    <td>
                        icon-execution-error
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-arrow-up"></i>
                    </td>
                    <td>
                        icon-arrow-up
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-add-comment"></i>
                    </td>
                    <td>
                        icon-add-comment
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-comment-list"></i>
                    </td>
                    <td>
                        icon-comment-list
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-share"></i>
                    </td>
                    <td>
                        icon-share
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-steady-page1"></i>
                    </td>
                    <td>
                        icon-steady-page1
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-activity"></i>
                    </td>
                    <td>
                        icon-activity
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-alert"></i>
                    </td>
                    <td>
                        icon-alert
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-arrow-double"></i>
                    </td>
                    <td>
                        icon-arrow-double
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-arrow-down"></i>
                    </td>
                    <td>
                        icon-arrow-down
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-arrow-left"></i>
                    </td>
                    <td>
                        icon-arrow-left
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-arrow-right"></i>
                    </td>
                    <td>
                        icon-arrow-right
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-back-ajax-result"></i>
                    </td>
                    <td>
                        icon-back-ajax-result
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-bell"></i>
                    </td>
                    <td>
                        icon-bell
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-book-open"></i>
                    </td>
                    <td>
                        icon-book-open
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-bulb"></i>
                    </td>
                    <td>
                        icon-bulb
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-calendar"></i>
                    </td>
                    <td>
                        icon-calendar
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-capture-opened-window"></i>
                    </td>
                    <td>
                        icon-capture-opened-window
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-capture-popup"></i>
                    </td>
                    <td>
                        icon-capture-popup
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-change-user-agent"></i>
                    </td>
                    <td>
                        icon-change-user-agent
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-change-window"></i>
                    </td>
                    <td>
                        icon-change-window
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-check-circle"></i>
                    </td>
                    <td>
                        icon-check-circle
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-check"></i>
                    </td>
                    <td>
                        icon-check
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-chevron-down"></i>
                    </td>
                    <td>
                        icon-chevron-down
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-chevron-right"></i>
                    </td>
                    <td>
                        icon-chevron-right
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-chevron-left"></i>
                    </td>
                    <td>
                        icon-chevron-left
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-clic-coordenates"></i>
                    </td>
                    <td>
                        icon-clic-coordenates
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-click"></i>
                    </td>
                    <td>
                        icon-click
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-clipboard"></i>
                    </td>
                    <td>
                        icon-clipboard
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-clock"></i>
                    </td>
                    <td>
                        icon-clock
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-close-window"></i>
                    </td>
                    <td>
                        icon-close-window
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-close"></i>
                    </td>
                    <td>
                        icon-close
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-columns"></i>
                    </td>
                    <td>
                        icon-columns
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-conection"></i>
                    </td>
                    <td>
                        icon-conection
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-copy"></i>
                    </td>
                    <td>
                        icon-copy
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-corner-up-left"></i>
                    </td>
                    <td>
                        icon-corner-up-left
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-corner-up-right"></i>
                    </td>
                    <td>
                        icon-corner-up-right
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-database"></i>
                    </td>
                    <td>
                        icon-database
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-double-click"></i>
                    </td>
                    <td>
                        icon-double-click
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-download-file"></i>
                    </td>
                    <td>
                        icon-download-file
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-download"></i>
                    </td>
                    <td>
                        icon-download
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-edit-2"></i>
                    </td>
                    <td>
                        icon-edit-2
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-evaluate-js"></i>
                    </td>
                    <td>
                        icon-evaluate-js
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-extract-data"></i>
                    </td>
                    <td>
                        icon-extract-data
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-eye-off"></i>
                    </td>
                    <td>
                        icon-eye-off
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-eye"></i>
                    </td>
                    <td>
                        icon-eye
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-file"></i>
                    </td>
                    <td>
                        icon-file
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-fill-form"></i>
                    </td>
                    <td>
                        icon-fill-form
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-filter"></i>
                    </td>
                    <td>
                        icon-filter
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-folder"></i>
                    </td>
                    <td>
                        icon-folder
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-group"></i>
                    </td>
                    <td>
                        icon-group
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-hand"></i>
                    </td>
                    <td>
                        icon-hand
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-help-circle"></i>
                    </td>
                    <td>
                        icon-help-circle
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-home"></i>
                    </td>
                    <td>
                        icon-home
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-info"></i>
                    </td>
                    <td>
                        icon-info
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-inject-js"></i>
                    </td>
                    <td>
                        icon-inject-js
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-intercept-ajax"></i>
                    </td>
                    <td>
                        icon-intercept-ajax
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-layout"></i>
                    </td>
                    <td>
                        icon-layout
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-level-0"></i>
                    </td>
                    <td>
                        icon-level-0
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-log-out"></i>
                    </td>
                    <td>
                        icon-log-out
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-maximize"></i>
                    </td>
                    <td>
                        icon-maximize
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-menu"></i>
                    </td>
                    <td>
                        icon-menu
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-message-circle"></i>
                    </td>
                    <td>
                        icon-message-circle
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-message-square"></i>
                    </td>
                    <td>
                        icon-message-square
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-minimize"></i>
                    </td>
                    <td>
                        icon-minimize
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-more"></i>
                    </td>
                    <td>
                        icon-more
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-move"></i>
                    </td>
                    <td>
                        icon-move
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-open-web"></i>
                    </td>
                    <td>
                        icon-open-web
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-package"></i>
                    </td>
                    <td>
                        icon-package
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-pause"></i>
                    </td>
                    <td>
                        icon-pause
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-play"></i>
                    </td>
                    <td>
                        icon-play
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-plus"></i>
                    </td>
                    <td>
                        icon-plus
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-refresh"></i>
                    </td>
                    <td>
                        icon-refresh
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-resize-viewport"></i>
                    </td>
                    <td>
                        icon-resize-viewport
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-return-nav-state"></i>
                    </td>
                    <td>
                        icon-return-nav-state
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-rocket"></i>
                    </td>
                    <td>
                        icon-rocket
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-scissors"></i>
                    </td>
                    <td>
                        icon-scissors
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-scroll"></i>
                    </td>
                    <td>
                        icon-scroll
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-search"></i>
                    </td>
                    <td>
                        icon-search
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-selector"></i>
                    </td>
                    <td>
                        icon-selector
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-settings"></i>
                    </td>
                    <td>
                        icon-settings
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-star"></i>
                    </td>
                    <td>
                        icon-star
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-star-filled"></i>
                    </td>
                    <td>
                        icon-star-filled
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-steady-page"></i>
                    </td>
                    <td>
                        icon-steady-page
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-trash"></i>
                    </td>
                    <td>
                        icon-trash
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-trending-down"></i>
                    </td>
                    <td>
                        icon-trending-down
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-trending-up"></i>
                    </td>
                    <td>
                        icon-trending-up
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-ungroup"></i>
                    </td>
                    <td>
                        icon-ungroup
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-upload-file"></i>
                    </td>
                    <td>
                        icon-upload-file
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-upload-form"></i>
                    </td>
                    <td>
                        icon-upload-form
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-upload"></i>
                    </td>
                    <td>
                        icon-upload
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-wait-element"></i>
                    </td>
                    <td>
                        icon-wait-element
                    </td>
                </tr>
                <tr>
                    <td>
                        <i class="icon-workflow"></i>
                    </td>
                    <td>
                        icon-workflow
                    </td>
                </tr>
            </table>
        </ng-container>

        <!-- INPUTS -->
        <ng-container *ngIf="component === 'input'">
            <h2 class="m-b-16">Input</h2>
            <h5 class="m-b-8">Default</h5>
            <input type="text" class="input m-b-8" placeholder="Placeholder">
            <input type="text" class="input m-b-8" placeholder="Placeholder" value="With value">
            <input type="text" class="input input--error m-b-8" placeholder="Placeholder" value="With value">
            <input type="text" class="input m-b-8" placeholder="Placeholder" disabled>

            <h5 class="m-t-16 m-b-8">With label</h5>
            <div class="input-group m-b-16">
                <label for="">Label</label>
                <input type="text" class="input" placeholder="Placeholder">
            </div>
            <div class="input-group m-b-16">
                <label for="">Label</label>
                <input type="text" class="input" placeholder="Placeholder" value="With value">
            </div>
            <div class="input-group m-b-16">
                <label for="">Label</label>
                <input type="text" class="input input--error" placeholder="Placeholder" value="With value">
                <span class="input-group__error-message">Error message</span>
            </div>
            <div class="input-group m-b-16">
                <label for="">Label</label>
                <input type="text" class="input" placeholder="Placeholder" disabled>
            </div>

            <h5 class="m-b-8">Collapsed input</h5>
            <div class="input-group input-group--collapsed-input m-b-8">
                <i class="icon-search"></i>
                <input class="input" type="text" name="" id="" placeholder="Buscar">
            </div>
            <div class="input-group input-group--collapsed-input input-group--collapsed-input--expanded">
                <i class="icon-search"></i>
                <input class="input" type="text" name="" id="" placeholder="Buscar">
            </div>
        </ng-container>

        <!-- INPUT BUTTON-->
        <ng-container *ngIf="component === 'input-button'">
            <h2 class="m-b-16">Input button</h2>
            <h5 class="m-b-8">Default</h5>
            <div class="input-group">
                <label for="">Label</label>
                <div class="input-group__button">
                    <input class="input" type="text" placeholder="Responder">
                    <button class="button-icon">
                        <i class="icon-selector"></i>
                    </button>
                </div>
            </div>
        </ng-container>

        <!-- SIDEBAR -->
        <ng-container *ngIf="component === 'sidebar'">
            <h2 class="m-b-16">Sidebar</h2>
            <h5 class="m-b-8">Default</h5>
            <div class="sidebar">
                <div class="sidebar__button">
                    <button class="button-icon">
                        <i class="icon-chevron-left"></i>
                    </button>
                </div>
                <div class="sidebar__body">
                    <button class="button button--lg">
                        <i class="icon-plus"></i>Crear automatización
                    </button>
                    <ul class="sidebar__body__options">
                        <li class="options__option">
                            <a class="options__option__link options__option__link--active" href=""  data-tooltip="Inicio">
                                <i class="icon-home"></i>
                                <span>Inicio</span>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href=""  data-tooltip="Mis automatizaciones">
                                <i class="icon-workflow"></i>
                                <span>Mis automatizaciones</span>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href=""  data-tooltip="Conexiones">
                                <i class="icon-conection"></i>
                                <span>Conexiones</span>
                                <div class="badge badge--small"></div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href=""  data-tooltip="Plantillas">
                                <i class="icon-layout"></i>
                                <span>Plantillas</span>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href=""  data-tooltip="Base de datos">
                                <i class="icon-database"></i>
                                <span>Base de datos</span>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href=""  data-tooltip="Ayuda">
                                <i class="icon-help-circle"></i>
                                <span>Ayuda</span>
                            </a>
                        </li>
                        <span class="options__separator"></span>
                        <button class="button-icon" data-tooltip="Plan">
                            <i class="icon-clock"></i>
                        </button>
                        <div class="tile">
                            <h6>Unlimited</h6>
                            <div class="fs-14 m-t-4 m-b-4">Has usado <b>80</b> de <b>160</b> min</div>
                            <div class="progress progress--green progress--big">
                                <div class="progress__bar" style="width:50%"></div>
                            </div>
                            <a class="m-t-32" href="">Ampliar servicios</a>
                        </div>
                    </ul>
                </div>
            </div>

            <h5 class="m-b-8 m-t-20">Default Collapsed</h5>
            <div class="sidebar sidebar--collapsed">
                <div class="sidebar__button">
                    <button class="button-icon">
                        <i class="icon-chevron-left"></i>
                    </button>
                </div>
                <div class="sidebar__body">
                    <button class="button button--lg">
                        <i class="icon-plus"></i>Crear automatización
                    </button>
                    <ul class="sidebar__body__options">
                        <li class="options__option">
                            <a class="options__option__link options__option__link--active" href="" data-tooltip="Inicio">
                                <i class="icon-home"></i>
                                <span>Inicio</span>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href=""  data-tooltip="Mis automatizaciones">
                                <i class="icon-workflow"></i>
                                <span>Mis automatizaciones</span>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href=""  data-tooltip="Conexiones">
                                <i class="icon-conection"></i>
                                <span>Conexiones</span>
                                <div class="badge badge--small"></div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href=""  data-tooltip="Plantillas">
                                <i class="icon-layout"></i>
                                <span>Plantillas</span>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href=""  data-tooltip="Base de datos">
                                <i class="icon-database"></i>
                                <span>Base de datos</span>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href=""  data-tooltip="Ayuda">
                                <i class="icon-help-circle"></i>
                                <span>Ayuda</span>
                            </a>
                        </li>
                        <span class="options__separator"></span>
                        <button class="button-icon"  data-tooltip="Plan">
                            <i class="icon-clock"></i>
                        </button>
                        <div class="tile">
                            <h6>Unlimited</h6>
                            <div class="fs-14 m-t-4 m-b-4">Has usado <b>80</b> de <b>160</b> min</div>
                            <div class="progress progress--green progress--big">
                                <div class="progress__bar" style="width:50%"></div>
                            </div>
                            <a class="m-t-32" href="">Ampliar servicios</a>
                        </div>
                    </ul>
                </div>
            </div>
            
            <h5 class="m-b-8 m-t-20">Sidebar templates</h5>
            <div class="sidebar sidebar--template">
                <div class="sidebar__body">
                    <div class="sidebar__body__title">
                        Mis plantillas
                    </div>
                    <ul class="sidebar__body__options">
                        <li class="options__option">
                            <a class="options__option__link options__option__link--active" href="">
                                <span>Todas</span>
                                <div class="dropdown">
                                    <button class="button-icon dropdown__trigger">
                                        <i class="icon-more"></i>
                                    </button>
                                    <div class="dropdown__menu">
                                        <div class="dropdown__menu__item">
                                            <i class="icon-edit-2"></i>
                                            Editar
                                        </div>
                                        <div class="dropdown__menu__separator"></div>
                                        <div class="dropdown__menu__item text--red">
                                            <i class="icon-trash"></i>
                                            Eliminar
                                        </div>
                                    </div>
                                </div>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">2</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Mailing</span>
                                <div class="dropdown">
                                    <button class="button-icon dropdown__trigger">
                                        <i class="icon-more"></i>
                                    </button>
                                    <div class="dropdown__menu">
                                        <div class="dropdown__menu__item">
                                            <i class="icon-edit-2"></i>
                                            Editar
                                        </div>
                                        <div class="dropdown__menu__separator"></div>
                                        <div class="dropdown__menu__item text--red">
                                            <i class="icon-trash"></i>
                                            Eliminar
                                        </div>
                                    </div>
                                </div>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">2</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div class="sidebar__body__link">
                        <a href="">
                            + Añadir categoría
                        </a>
                    </div>
                    <div class="sidebar__body__title">
                        Plantillas comunidad DxBot
                    </div>
                    <ul class="sidebar__body__options">
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Todas</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">50</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Compartidas por ti</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">50</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Apps</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">2</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Comercio</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">12</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Comunicación</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">3</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Contenido y archivos</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">10</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Recursos Humanos</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">5</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <h5 class="m-b-8 m-t-20">Sidebar templates skeleton</h5>
            <div class="sidebar sidebar--template sidebar--template--skeleton">
                <div class="sidebar__body">
                    <div class="sidebar__body__title">
                        Mis plantillas
                    </div>
                    <ul class="sidebar__body__options">
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Todas</span>
                                <div class="dropdown">
                                    <button class="button-icon dropdown__trigger">
                                        <i class="icon-more"></i>
                                    </button>
                                    <div class="dropdown__menu">
                                        <div class="dropdown__menu__item">
                                            <i class="icon-edit-2"></i>
                                            Editar
                                        </div>
                                        <div class="dropdown__menu__separator"></div>
                                        <div class="dropdown__menu__item text--red">
                                            <i class="icon-trash"></i>
                                            Eliminar
                                        </div>
                                    </div>
                                </div>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">2</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Mailing</span>
                                <div class="dropdown">
                                    <button class="button-icon dropdown__trigger">
                                        <i class="icon-more"></i>
                                    </button>
                                    <div class="dropdown__menu">
                                        <div class="dropdown__menu__item">
                                            <i class="icon-edit-2"></i>
                                            Editar
                                        </div>
                                        <div class="dropdown__menu__separator"></div>
                                        <div class="dropdown__menu__item text--red">
                                            <i class="icon-trash"></i>
                                            Eliminar
                                        </div>
                                    </div>
                                </div>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">2</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div class="sidebar__body__link">
                        <a href="">
                            + Añadir categoría
                        </a>
                    </div>
                    <div class="sidebar__body__title">
                        Plantillas comunidad DxBot
                    </div>
                    <ul class="sidebar__body__options">
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Todas</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">50</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Compartidas por ti</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">50</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Apps</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">2</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Comercio</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">12</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Comunicación</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">3</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Contenido y archivos</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">10</div>
                                </div>
                            </a>
                        </li>
                        <li class="options__option">
                            <a class="options__option__link" href="">
                                <span>Recursos Humanos</span>
                                <div class="link__counter">
                                    <div class="tag tag--neutral tag--small">5</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </ng-container>

        <!-- TAG -->
        <ng-container *ngIf="component === 'tag'">
            <h2 class="m-b-16">Tag</h2>
            <h5 class="m-b-8">Default</h5>
            <div class="flex ai-center">
                <div class="tag">Default</div>
                <div class="tag tag--success">Success</div>

                <div class="tag tag--error">Error</div>
                <div class="tag tag--neutral">Neutral</div>
                <div class="tag tag--warning">Warning</div>
            </div>
            <h5 class="m-t-16 m-b-8">Sizes</h5>
            <div class="flex ai-center">
                <div class="tag tag--small">Default Small</div>
                <div class="tag tag--small tag--success">Success Small</div>
                <div class="tag tag--small tag--error">Error Small</div>
                <div class="tag tag--small tag--neutral">Neutral Small</div>
                <div class="tag tag--small tag--warning">Warning Small</div>
            </div>

            <h6 class="m-t-8 m-b-8"></h6>
            <div class="flex ai-center">
                <div class="tag tag--big">Default Big</div>
                <div class="tag tag--big tag--success">Success Big</div>
                <div class="tag tag--big tag--error">Error Big</div>
                <div class="tag tag--big tag--neutral">Neutral Big</div>
                <div class="tag tag--big tag--warning">Warning Big</div>
            </div>

            <h5 class="m-t-16 m-b-8">Icon & text</h5>
            <div class="flex ai-center">
                <div class="tag tag--dashboard tag--success">
                    <i class="icon-trending-up"></i>
                    <b>5%</b>
                </div>
                <div class="tag tag--dashboard tag--warning">
                    <i class="icon-trending-down"></i>
                    <b>5%</b>
                </div>
            </div>
        </ng-container>

        <!-- TAG ICON-->
        <ng-container *ngIf="component === 'tag-icon'">
            <h2 class="m-b-16">Tag Icon</h2>
            <h5 class="m-t-16 m-b-8">Size</h5>
            <div class="flex ai-center">
                <div class="tag-icon tag-icon--small">
                    <i class="icon-clock"></i>
                </div>
                <div class="tag-icon">
                    <i class="icon-clock"></i>
                </div>
                <div class="tag-icon tag-icon--large">
                    <i class="icon-clock"></i>
                </div>
                <div class="tag-icon tag-icon--xl">
                    <i class="icon-clock"></i>
                </div>
            </div>

            <h5 class="m-t-16 m-b-8">Colors</h5>
            <div class="flex ai-center">
                <div class="tag-icon">
                    <i class="icon-clock"></i>
                </div>
                <div class="tag-icon tag-icon--purple">
                    <i class="icon-clock"></i>
                </div>
                <div class="tag-icon tag-icon--red">
                    <i class="icon-clock"></i>
                </div>
                <div class="tag-icon tag-icon--orange">
                    <i class="icon-clock"></i>
                </div>
                <div class="tag-icon tag-icon--green">
                    <i class="icon-clock"></i>
                </div>
            </div>

            <h5 class="m-t-16 m-b-8">Text</h5>
            <div class="flex ai-center">
                <div class="tag-icon tag-icon--small">
                    +2
                </div>
                <div class="tag-icon">
                    +2
                </div>
                <div class="tag-icon tag-icon--large">
                    +2
                </div>
                <div class="tag-icon tag-icon--xl">
                    +2
                </div>
            </div>
        </ng-container>

        <!-- TABLE-->
        <ng-container *ngIf="component === 'table'">
            <h2 class="m-b-16">Table</h2>
            <h5 class="m-t-16 m-b-8">Table with header</h5>
            <table class="table">
                <thead>
                    <tr>
                        <th>
                            <div class="table__cell">
                                Header
                            </div>
                        </th>
                        <th>
                            <div class="table__cell">
                                Header
                            </div>
                        </th>
                        <th>
                            <div class="table__cell table__cell--align-right">
                                Header align right
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="alice"></div>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <div class="tag tag--success">Ejecutando...</div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="alice"></div>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <div class="tag tag--success">Ejecutando...</div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="alice"></div>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <div class="tag tag--success">Ejecutando...</div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h5 class="m-t-16 m-b-8">Table without header</h5>
            <table class="table">
                <tbody>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="alice"></div>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <div class="tag tag--success">Ejecutando...</div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="alice"></div>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <div class="tag tag--success">Ejecutando...</div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="alice"></div>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <div class="tag tag--success">Ejecutando...</div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h5 class="m-t-16 m-b-8">Table clickable</h5>
            <table class="table table--clickable">
                <tbody>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="alice"></div>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <div class="tag tag--success">Ejecutando...</div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="alice"></div>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <div class="tag tag--success">Ejecutando...</div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="alice"></div>
                                Nombre de automatización
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <div class="tag tag--success">Ejecutando...</div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h5 class="m-t-16 m-b-8">Table Elements</h5>
            <table class="table table--clickable">
                <tbody>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="alice"></div>
                                Cell with Alice
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--tag">
                                <div class="tag tag--success">With Tag</div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                                <label class="switcher">
                                    <input type="checkbox" checked="checked">
                                    <span class="switcher__control"></span>
                                </label>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell table__cell--column">
                                Title
                                <span class="text--gray-dark fs-12">Details</span>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                                <span class="text--gray-dark fs-14">Plain text wiht color and size custom</span>
                            </div>
                        </td>
                        <td>
                            <div class=" table__cell--align-right">
                                <span class="text--gray-dark fs-14">Cell align right</span>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                                <div class="dropdown">
                                    <button class="button-icon dropdown__trigger">
                                        <i class="icon-more"></i>
                                    </button>
                                    <div class="dropdown__menu">
                                        <div class="dropdown__menu__item">
                                            <i class="icon-eye"></i>
                                            Ver a Alice automatizar
                                        </div>
                                        <div class="dropdown__menu__item">
                                            <i class="icon-edit-2"></i>
                                            Editar nombre
                                        </div>
                                        <div class="dropdown__menu__item">
                                            <i class="icon-copy"></i>
                                            Duplicar
                                        </div>
                                        <div class="dropdown__menu__item">
                                            <i class="icon-folder"></i>
                                            Archivar
                                        </div>
                                        <div class="dropdown__menu__item">
                                            <i class="icon-move"></i>
                                            Mover a borradores
                                        </div>
                                        <div class="dropdown__menu__item">
                                            <i class="icon-plus"></i>
                                            Invitar a colaborar
                                        </div>
                                        <div class="dropdown__menu__item">
                                            <i class="icon-layout "></i>
                                            Añadir a mis plantillas
                                        </div>
                                        <div class="dropdown__menu__separator"></div>
                                        <div class="dropdown__menu__item text--red">
                                            <i class="icon-trash"></i>
                                            Eliminar
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="tag-icon m-r-8">
                                    <i class="icon-clock"></i>
                                </div>
                                <div class="flex fd-column">
                                    Tag with plain text
                                    <span class="text--gray-dark fs-12">With details</span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                                <div class="avatar-group">
                                    <div class="avatar">
                                        <img src="../../../../assets/resources/avatar.png" alt="">
                                    </div>
                                    <div class="avatar">
                                        <img src="../../../../assets/resources/avatar.png" alt="">
                                    </div>
                                    <div class="avatar">
                                        <img src="../../../../assets/resources/avatar.png" alt="">
                                    </div>
                                    <div class="avatar">
                                        <img src="../../../../assets/resources/avatar.png" alt="">
                                    </div>
                                    <div class="avatar__number">
                                        10
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                                <label class="switcher">
                                    <input type="checkbox" checked="checked">
                                    <span class="switcher__control"></span>
                                </label>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">
                                <div class="avatar m-r-8">
                                    <img src="../../../../assets/resources/avatar.png" alt="">
                                </div>
                                <span class="text--gray-dark fs-14">Nombre Apellido</span>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                                <span class="text--gray-dark fs-14">Lorem ipsum dolor sit amet consectetur, adipisicing
                                    elit. Obcaecati neque rem ex quisquam nulla modi similique id in consequatur esse.
                                    Quae, rem mollitia? Debitis deserunt quaerat nemo inventore vitae aliquam.</span>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                                <label class="switcher">
                                    <input type="checkbox" checked="checked">
                                    <span class="switcher__control"></span>
                                </label>
                            </div>
                        </td>
                        <td>
                            <div class="table__cell table__cell--align-right">
                                <button class="button-icon">
                                    <i class="icon-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h5 class="m-b-8 m-t-16">Skeleton</h5>
            <table class="table table--skeleton">
                <tbody>
                    <tr>
                        <td>
                            <div class="table__cell">
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                               
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">

                            </div>
                        </td>
                        <td>
                            <div class="table__cell">

                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="table__cell">

                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">
                            </div>
                        </td>
                        <td>
                            <div class="table__cell">

                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>

        <!-- DROPDOWN-->
        <ng-container *ngIf="component === 'dropdown'">
            <h2 class="m-b-16">Dropdown</h2>
            <h5 class="m-t-16 m-b-8">Dropdown with button icon/avatar trigger</h5>
            <div class="dropdown">
                <button class="button-icon dropdown__trigger">
                    <div class="avatar">
                        <img src="../../../../assets/resources/avatar.png" alt="">
                    </div>
                    <i class="icon-chevron-down"></i>
                </button>
                <div class="dropdown__menu">
                    <div class="dropdown__menu__item">
                        Option
                    </div>
                    <div class="dropdown__menu__item dropdown__menu__item--selected">
                        Option Selected
                    </div>
                    <div class="dropdown__menu__item">
                        <i class="icon-log-out"></i>
                        Cerrar sesión
                    </div>
                </div>
            </div>
            <h5 class="m-t-16 m-b-8">Dropdown with button icon/avatar trigger open</h5>
            <div class="dropdown dropdown--open" style="max-width: fit-content;">
                <button class="button-icon dropdown__trigger ">
                    <div class="avatar">
                        <img src="../../../../assets/resources/avatar.png" alt="">
                    </div>
                    <i class="icon-chevron-down"></i>
                </button>
                <div class="dropdown__menu">
                    <div class="dropdown__menu__item">
                        Option
                    </div>
                    <div class="dropdown__menu__item dropdown__menu__item--selected">
                        Option Selected
                    </div>
                    <div class="dropdown__menu__separator"></div>
                    <div class="dropdown__menu__item text--red">
                        <i class="icon-log-out"></i>
                        Cerrar sesión
                    </div>
                </div>
            </div>
            <h5 class="m-t-16 m-b-8" style="margin-top: 150px;">Dropdown with button icon trigger</h5>
            <div class="dropdown">
                <button class="button-icon dropdown__trigger">
                    <i class="icon-bell"></i>
                    <i class="icon-chevron-down"></i>
                </button>
                <div class="dropdown__menu">
                    <div class="dropdown__menu__item">
                        Option
                    </div>
                    <div class="dropdown__menu__item dropdown__menu__item--selected">
                        Option Selected
                    </div>
                    <div class="dropdown__menu__item">
                        <i class="icon-log-out"></i>
                        Cerrar sesión
                    </div>
                </div>
            </div>
            <h5 class="m-t-16 m-b-8">Dropdown with button icon trigger open</h5>
            <div class="dropdown dropdown--open" style="max-width: fit-content;">
                <button class="button-icon dropdown__trigger ">
                    <i class="icon-bell"></i>
                    <i class="icon-chevron-down"></i>
                </button>
                <div class="dropdown__menu">
                    <div class="dropdown__menu__item">
                        Option
                    </div>
                    <div class="dropdown__menu__item dropdown__menu__item--selected">
                        Option Selected
                    </div>
                    <div class="dropdown__menu__separator"></div>
                    <div class="dropdown__menu__item text--red">
                        <i class="icon-log-out"></i>
                        Cerrar sesión
                    </div>
                </div>
            </div>

            <h5 class="m-t-16 m-b-8" style="margin-top: 150px;">Dropdown with button icon circle trigger</h5>
            <div class="dropdown">
                <button class="button-icon button-icon--circle dropdown__trigger">
                    <i class="icon-bell"></i>
                </button>
                <div class="dropdown__menu">
                    <div class="dropdown__menu__item">
                        Option
                    </div>
                    <div class="dropdown__menu__item dropdown__menu__item--selected">
                        Option Selected
                    </div>
                    <div class="dropdown__menu__item">
                        <i class="icon-log-out"></i>
                        Cerrar sesión
                    </div>
                </div>
            </div>
            <h5 class="m-t-16 m-b-8">Dropdown with button icon circle trigger open</h5>
            <div class="dropdown dropdown--open">
                <button class="button-icon button-icon--circle dropdown__trigger">
                    <i class="icon-bell"></i>
                </button>
                <div class="dropdown__menu">
                    <div class="dropdown__menu__item">
                        Option
                    </div>
                    <div class="dropdown__menu__item dropdown__menu__item--selected">
                        Option Selected
                    </div>
                    <div class="dropdown__menu__item">
                        <i class="icon-log-out"></i>
                        Cerrar sesión
                    </div>
                </div>
            </div>

            <h5 class="m-b-8">Dropdown with input trigger</h5>
            <div class="dropdown">
                <button class="dropdown__trigger input">
                    <span>Dropdown</span>
                    <i class="icon-chevron-down"></i>
                </button>
                <div class="dropdown__menu">
                    <div class="dropdown__menu__item">Option</div>
                    <div class="dropdown__menu__item">Option 2</div>
                    <div class="dropdown__menu__item">Option 3</div>
                </div>
            </div>
            <h5 class="m-t-16 m-b-8">Dropdown with input trigger open</h5>
            <div class="dropdown dropdown--open">
                <button class="dropdown__trigger input">
                    <span>Dropdown</span>
                    <i class="icon-chevron-down"></i>
                </button>
                <div class="dropdown__menu">
                    <div class="dropdown__menu__item">Option</div>
                    <div class="dropdown__menu__item">Option 2</div>
                    <div class="dropdown__menu__item">Option 3</div>
                </div>
            </div>
            <h5 class="m-t-16 m-b-8" style="margin-top: 136px;">Dropdown with input trigger option selected</h5>
            <div class="dropdown dropdown--filled">
                <button class="dropdown__trigger input">
                    <span>Option 1</span>
                    <i class="icon-chevron-down"></i>
                </button>
                <div class="dropdown__menu">
                    <div class="dropdown__menu__item">Option</div>
                    <div class="dropdown__menu__item">Option 2</div>
                    <div class="dropdown__menu__item">Option 3</div>
                </div>
            </div>
        </ng-container>

        <!-- CARD-->
        <ng-container *ngIf="component === 'card'">
            <h2 class="m-b-16">Card</h2>
            <h5 class="m-t-16 m-b-8">Default</h5>
            <div class="flex ai-center fw-wrap">
                <div class="card card--selected">
                    <div class="card__header">
                        <div class="card__header__title">
                            Guardar CV adjuntos en Gmail en Google Drive
                        </div>
                        <div class="card__header__info">
                            <div class="info__info">Tecalis · Comercio</div>
                            <div class="tag tag--small tag--success">Pública</div>
                        </div>
                    </div>
                    <div class="card__body">
                        <div class="flex ai-center">
                            <div class="tag-icon">
                                <i class="icon-clock"></i>
                            </div>
                            <div class="tag-icon">
                                <i class="icon-clock"></i>
                            </div>
                            <div class="tag-icon">
                                <i class="icon-clock"></i>
                            </div>
                        </div>
                        <div class="rating">
                            <input type="radio" class="rating__input" id="0rc1">
                            <input type="radio" class="rating__input" id="0rc2">
                            <input type="radio" class="rating__input" id="0rc3">
                            <input type="radio" class="rating__input" id="0rc4">
                            <input type="radio" class="rating__input" id="0rc5">
                            <label for="0rc1" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="0rc2" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="0rc3" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="0rc4" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="0rc5" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card__header">
                        <div class="card__header__title">
                            Guardar CV adjuntos en Gmail en Google Drive
                        </div>
                        <div class="card__header__info">
                            <div class="info__info">Tecalis · Comercio</div>
                        </div>
                    </div>
                    <div class="card__body">
                        <div class="flex ai-center">
                            <div class="tag-icon">
                                <i class="icon-clock"></i>
                            </div>
                            <div class="tag-icon">
                                <i class="icon-clock"></i>
                            </div>
                            <div class="tag-icon">
                                <i class="icon-clock"></i>
                            </div>
                        </div>
                        <div class="rating">
                            <input type="radio" class="rating__input" id="1rc1">
                            <input type="radio" class="rating__input" id="1rc2">
                            <input type="radio" class="rating__input" id="1rc3">
                            <input type="radio" class="rating__input" id="1rc4">
                            <input type="radio" class="rating__input" id="1rc5">
                            <label for="1rc1" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="1rc2" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="1rc3" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="1rc4" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="1rc5" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card__header">
                        <div class="card__header__title">
                            Guardar CV adjuntos en Gmail en Google Drive
                        </div>
                        <div class="card__header__info">
                            <div class="info__info">Tecalis · Comercio</div>
                            <div class="tag tag--small tag--success">Pública</div>
                        </div>
                    </div>
                    <div class="card__body">
                        <div class="flex ai-center">
                            <div class="tag-icon">
                                <i class="icon-clock"></i>
                            </div>
                            <div class="tag-icon">
                                <i class="icon-clock"></i>
                            </div>
                            <div class="tag-icon">
                                <i class="icon-clock"></i>
                            </div>
                        </div>
                        <div class="rating">
                            <input type="radio" class="rating__input" id="2rc1">
                            <input type="radio" class="rating__input" id="2rc2">
                            <input type="radio" class="rating__input" id="2rc3">
                            <input type="radio" class="rating__input" id="2rc4">
                            <input type="radio" class="rating__input" id="2rc5">
                            <label for="2rc1" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="2rc2" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="2rc3" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="2rc4" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                            <label for="2rc5" class="rating__star">
                                <i class="icon-star-filled"></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex ai-center">
                <div class="card">
                    <div class="card__header">
                        <div class="card__header__title">
                            <div class="tag-icon tag-icon--small tag-icon--red">
                                <i class="icon-database"></i>
                            </div>
                            Nombre de la Base de datos
                        </div>
                        <div class="card__header__info">
                            <div class="info__info">10 tablas · 5G</div>
                            <div class="info__data">19/02/22</div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card__header">
                        <div class="card__header__title">
                            <div class="tag-icon tag-icon--small tag-icon--red">
                                <i class="icon-database"></i>
                            </div>
                            Nombre de la Base de datos
                        </div>
                        <div class="card__header__info">
                            <div class="info__info">10 tablas · 5G</div>
                            <div class="info__data">19/02/22</div>
                            <div class="tag tag--small tag--neutral">Archivada</div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card__header">
                        <div class="card__header__title">
                            <div class="tag-icon tag-icon--small tag-icon--red">
                                <i class="icon-database"></i>
                            </div>
                            Nombre de la Base de datos
                        </div>
                        <div class="card__header__info">
                            <div class="info__info">10 tablas · 5G</div>
                            <div class="info__data">19/02/22</div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 class="m-t-16 m-b-8">Card People</h5>
            <div class="flex ai-center fw-wrap">
                <div class="card card--people">
                    <div class="card__body">
                        <div class="avatar">
                            <img src="../../../../assets/resources/avatar.png" alt="">
                        </div>
                        <div class="card__body__name">Nombre apellido</div>
                        <a href="" class="text--red">Eliminar</a>
                    </div>
                </div>
            </div>

            <h5 class="m-t-16 m-b-8">Card Skeleton</h5>
            <div class="card card--skeleton">
                <div class="card__header">
                    <div class="card__header__title">
                    </div>
                    <div class="card__header__info">
                    </div>
                </div>
                <div class="card__body">
                </div>
            </div>
        </ng-container>

        <!-- RATING-->
        <ng-container *ngIf="component === 'rating'">
            <h2 class="m-b-16">Rating</h2>
            <div class="rating">
                <input type="radio" class="rating__input" id="rc1">
                <input type="radio" class="rating__input" id="rc2">
                <input type="radio" class="rating__input" id="rc3">
                <input type="radio" class="rating__input" id="rc4">
                <input type="radio" class="rating__input" id="rc5">
                <label for="rc1" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rc2" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rc3" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rc4" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rc5" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
            </div>
            <div class="rating">
                <input type="radio" class="rating__input" id="rcp1">
                <input type="radio" class="rating__input" id="rcp2">
                <input type="radio" class="rating__input" id="rcp3">
                <input type="radio" class="rating__input" id="rcp4">
                <input type="radio" class="rating__input" id="rcp5">
                <label for="rcp1" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rcp2" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rcp3" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rcp4" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rcp5" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
            </div>
            <div class="rating rating--disabled">
                <input type="radio" class="rating__input" id="rcp1">
                <input type="radio" class="rating__input" id="rcp2">
                <input type="radio" class="rating__input" id="rcp3" checked>
                <input type="radio" class="rating__input" id="rcp4">
                <input type="radio" class="rating__input" id="rcp5">
                <label for="rcp1" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rcp2" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rcp3" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rcp4" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
                <label for="rcp5" class="rating__star">
                    <i class="icon-star-filled"></i>
                </label>
            </div>
        </ng-container>

        <!-- TOOTLIP-->
        <ng-container *ngIf="component === 'tooltip'">
            <h2 class="m-b-16">Tooltip</h2>
            <h5 class="m-b-8 m-t-16">Tooltip without element</h5>
            <div class="flex">
                <span data-tooltip="Name Surname" class="m-r-24">Tooltip</span>
                <button data-tooltip="Action" class="button-icon">
                    <i class="icon-bell"></i>
                </button>
            </div>
            <h5 class="m-b-8 m-t-16">Tooltip with element</h5>
            <span class="tooltip" data-tooltip="Tooltip!">
                <i class="icon-info"></i>
            </span>
            <span class="tooltip tooltip--right" data-tooltip="Tooltip!">
                <i class="icon-info"></i>
            </span>
            <span class="tooltip tooltip--left" data-tooltip="Tooltip!">
                <i class="icon-info"></i>
            </span>
        </ng-container>

        <!-- MODAL-->
        <ng-container *ngIf="component === 'modal'">
            <h2 class="m-b-16">Modal</h2>
            <div class="modal m-b-24">
                <div class="modal__dialog">
                    <div class="modal__dialog__content">
                        <div class="content__header">
                            <div class="content__header__title">
                                <div class="tag-icon">
                                    <i class="icon-clock"></i>
                                </div>
                                <div class="tag-icon">
                                    <i class="icon-clock"></i>
                                </div>
                                <h3>Modal Title</h3>
                            </div>
                            <div class="button-icon">
                                <i class="icon-close"></i>
                            </div>
                        </div>
                        <div class="content__body">
                            <div class="content__body__section">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="input-group">
                                            <label for="">Label</label>
                                            <input type="text" placeholder="Placeholder" class="input">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="input-group">
                                            <label for="">Label</label>
                                            <input type="text" placeholder="Placeholder" class="input">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="content__body__section">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="input-group">
                                            <label for="">Label</label>
                                            <input type="text" placeholder="Placeholder" class="input">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group">
                                            <label for="">Label</label>
                                            <input type="text" placeholder="Placeholder" class="input">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="input-group">
                                            <label for="">Label</label>
                                            <input type="text" placeholder="Placeholder" class="input">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group">
                                            <label for="">Label</label>
                                            <input type="text" placeholder="Placeholder" class="input">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="input-group">
                                            <label for="">Label</label>
                                            <input type="text" placeholder="Placeholder" class="input">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content__footer">
                            <button type="button" class="button button--secondary">Cancelar</button>
                            <button class="button">Si, cerrar sesión</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- MODAL CONFIRMATION-->
        <ng-container *ngIf="component === 'modal-confirmation'">
            <h2 class="m-b-16">Modal Confirmation</h2>
            <div class="modal modal--confirmation">
                <div class="modal__dialog">
                    <div class="modal__dialog__content">
                        <div class="content__header">
                            <div class="button-icon">
                                <i class="icon-close"></i>
                            </div>
                        </div>
                        <div class="content__body">
                            <div class="tag-icon tag-icon--orange">
                                <i class="icon-alert"></i>
                            </div>
                            <h4>¿Seguro que quieres cerrar sesión?</h4>
                            <h6>Esta conexión se está utilizando en <b>2 automatizaciones</b></h6>
                        </div>
                        <div class="content__footer">
                            <button type="button" class="button button--secondary">Cancelar</button>
                            <button class="button">Si, cerrar sesión</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal modal--confirmation m-t-24">
                <div class="modal__dialog">
                    <div class="modal__dialog__content">
                        <div class="content__header">
                            <div class="button-icon">
                                <i class="icon-close"></i>
                            </div>
                        </div>
                        <div class="content__body">
                            <div class="tag-icon tag-icon--green">
                                <i class="icon-check"></i>
                            </div>
                            <h4>Se ha enviado la invitación correctamente</h4>
                        </div>
                        <div class="content__footer">
                            <button class="button">Ok</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal modal--confirmation m-t-24">
                <div class="modal__dialog">
                    <div class="modal__dialog__content">
                        <div class="content__header">
                            <div class="button-icon">
                                <i class="icon-close"></i>
                            </div>
                        </div>
                        <div class="content__body">
                            <div class="alice alice--lg"></div>
                            <h4>Cargando...</h4>
                            <h6>Conectando con la app</h6>
                        </div>
                        <div class="content__footer">
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- MODAL WITH CARDS-->
        <ng-container *ngIf="component === 'modal-cards'">
            <h2 class="m-b-16">Modal with cards</h2>
            <h5 class="m-b-8 m-t-16 text--red">Rafa éstos modales miden máximo 1000px, el resto tienen un máximo de
                800px</h5>
            <div class="modal modal--cards">
                <div class="modal__dialog">
                    <div class="modal__dialog__content">
                        <div class="content__header">
                            <div class="content__header__title">
                                <h3>Modal Title</h3>
                            </div>
                            <div class="button-icon">
                                <i class="icon-close"></i>
                            </div>
                        </div>
                        <div class="content__body">
                            <div class="toolbar">
                                <div class="toolbar__left">
                                    <div class="input-group input-group--collapsed-input">
                                        <i class="icon-search"></i>
                                        <input class="input" type="text" name="" id=""
                                            placeholder="Buscar colaboradores">
                                    </div>
                                </div>
                                <div class="toolbar__right">
                                    <button class="button">Invitar</button>
                                </div>
                            </div>
                            <div class="content__body__section">
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                                <div class="card card--people">
                                    <div class="card__body">
                                        <div class="avatar">
                                            <img src="../../../../assets/resources/avatar.png" alt="">
                                        </div>
                                        <div class="card__body__name">Nombre apellido</div>
                                        <a href="" class="text--red">Eliminar</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal modal--cards m-t-24">
                <div class="modal__dialog">
                    <div class="modal__dialog__content">
                        <div class="content__header">
                            <div class="content__header__title">
                                <h3>Modal Title</h3>
                            </div>
                            <div class="button-icon">
                                <i class="icon-close"></i>
                            </div>
                        </div>
                        <div class="content__body">
                            <div class="input-group input-group--collapsed-input">
                                <i class="icon-search"></i>
                                <input class="input" type="text" name="" id="" placeholder="Buscar plantilla">
                            </div>
                            <div class="content__body__section">
                                <div class="card">
                                    <div class="card__header">
                                        <div class="card__header__title">
                                            Guardar CV adjuntos en Gmail en Google Drive
                                        </div>
                                        <div class="card__header__info">
                                            <div>Tecalis · Comercio</div>
                                            <div class="tag tag--small tag--success">Pública</div>
                                        </div>
                                    </div>
                                    <div class="card__body">
                                        <div class="flex ai-center">
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                        </div>
                                        <div class="rating">
                                            <input type="radio" class="rating__input" id="0rc1">
                                            <input type="radio" class="rating__input" id="0rc2">
                                            <input type="radio" class="rating__input" id="0rc3">
                                            <input type="radio" class="rating__input" id="0rc4">
                                            <input type="radio" class="rating__input" id="0rc5">
                                            <label for="0rc1" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc2" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc3" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc4" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc5" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card__header">
                                        <div class="card__header__title">
                                            Guardar CV adjuntos en Gmail en Google Drive
                                        </div>
                                        <div class="card__header__info">
                                            <div>Tecalis · Comercio</div>
                                            <div class="tag tag--small tag--success">Pública</div>
                                        </div>
                                    </div>
                                    <div class="card__body">
                                        <div class="flex ai-center">
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                        </div>
                                        <div class="rating">
                                            <input type="radio" class="rating__input" id="0rc1">
                                            <input type="radio" class="rating__input" id="0rc2">
                                            <input type="radio" class="rating__input" id="0rc3">
                                            <input type="radio" class="rating__input" id="0rc4">
                                            <input type="radio" class="rating__input" id="0rc5">
                                            <label for="0rc1" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc2" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc3" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc4" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc5" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card--selected">
                                    <div class="card__header">
                                        <div class="card__header__title">
                                            Guardar CV adjuntos en Gmail en Google Drive
                                        </div>
                                        <div class="card__header__info">
                                            <div>Tecalis · Comercio</div>
                                        </div>
                                    </div>
                                    <div class="card__body">
                                        <div class="flex ai-center">
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                        </div>
                                        <div class="rating">
                                            <input type="radio" class="rating__input" id="0rc1">
                                            <input type="radio" class="rating__input" id="0rc2">
                                            <input type="radio" class="rating__input" id="0rc3">
                                            <input type="radio" class="rating__input" id="0rc4">
                                            <input type="radio" class="rating__input" id="0rc5">
                                            <label for="0rc1" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc2" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc3" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc4" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc5" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card__header">
                                        <div class="card__header__title">
                                            Guardar CV adjuntos en Gmail en Google Drive
                                        </div>
                                        <div class="card__header__info">
                                            <div>Tecalis · Comercio</div>
                                        </div>
                                    </div>
                                    <div class="card__body">
                                        <div class="flex ai-center">
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                        </div>
                                        <div class="rating">
                                            <input type="radio" class="rating__input" id="0rc1">
                                            <input type="radio" class="rating__input" id="0rc2">
                                            <input type="radio" class="rating__input" id="0rc3">
                                            <input type="radio" class="rating__input" id="0rc4">
                                            <input type="radio" class="rating__input" id="0rc5">
                                            <label for="0rc1" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc2" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc3" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc4" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc5" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card__header">
                                        <div class="card__header__title">
                                            Guardar CV adjuntos en Gmail en Google Drive
                                        </div>
                                        <div class="card__header__info">
                                            <div>Tecalis · Comercio</div>
                                            <div class="tag tag--small tag--success">Pública</div>
                                        </div>
                                    </div>
                                    <div class="card__body">
                                        <div class="flex ai-center">
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                        </div>
                                        <div class="rating">
                                            <input type="radio" class="rating__input" id="0rc1">
                                            <input type="radio" class="rating__input" id="0rc2">
                                            <input type="radio" class="rating__input" id="0rc3">
                                            <input type="radio" class="rating__input" id="0rc4">
                                            <input type="radio" class="rating__input" id="0rc5">
                                            <label for="0rc1" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc2" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc3" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc4" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc5" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card__header">
                                        <div class="card__header__title">
                                            Guardar CV adjuntos en Gmail en Google Drive
                                        </div>
                                        <div class="card__header__info">
                                            <div>Tecalis · Comercio</div>
                                        </div>
                                    </div>
                                    <div class="card__body">
                                        <div class="flex ai-center">
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                            <div class="tag-icon">
                                                <i class="icon-clock"></i>
                                            </div>
                                        </div>
                                        <div class="rating">
                                            <input type="radio" class="rating__input" id="0rc1">
                                            <input type="radio" class="rating__input" id="0rc2">
                                            <input type="radio" class="rating__input" id="0rc3">
                                            <input type="radio" class="rating__input" id="0rc4">
                                            <input type="radio" class="rating__input" id="0rc5">
                                            <label for="0rc1" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc2" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc3" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc4" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                            <label for="0rc5" class="rating__star">
                                                <i class="icon-star-filled"></i>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content__footer">
                            <button type="button" class="button button--secondary">Cancelar</button>
                            <button class="button">Siguiente</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal modal--cards m-t-24">
                <div class="modal__dialog">
                    <div class="modal__dialog__content">
                        <div class="content__header">
                            <div class="content__header__title">
                                <h3>Modal Title</h3>
                            </div>
                            <div class="button-icon">
                                <i class="icon-close"></i>
                            </div>
                        </div>
                        <div class="content__body">
                            <div class="content__body__section">
                                <div class="tile m-b-32">
                                    <h5>Empezar de cero</h5>
                                    <div class="fs-14 text--gray-dark m-b-24">Inicia un automatización en blanco según
                                        el tipo de inicio</div>
                                    <div class="card__flex-wrapper">
                                        <div class="card">
                                            <div class="card__header">
                                                <img src="../../../../assets/resources/desencadenada.svg" alt="">
                                                <div class="card__header__title">
                                                    <h5>Desencadenada</h5>
                                                </div>
                                                <div class="card__header__info">
                                                    <div>Se inicia cuando ocurre un suceso definido por ti</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card__header">
                                                <img src="../../../../assets/resources/manual.svg" alt="">
                                                <div class="card__header__title">
                                                    <h5>
                                                        Manual
                                                    </h5>
                                                </div>
                                                <div class="card__header__info">
                                                    <div>Indica los pasos e iníciala manualmente cuando lo necesites
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card__header">
                                                <img src="../../../../assets/resources/programmed.svg" alt="">
                                                <div class="card__header__title">
                                                    <h5>
                                                        Programada
                                                    </h5>
                                                </div>
                                                <div class="card__header__info">
                                                    <div>Indica la frecuencia con la que quieres que se ejecute</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tile">
                                    <div class="tile__header">
                                        <h5>Empezar con una plantilla</h5>
                                        <div class="tile__header__actions">
                                            <div class="input-group input-group--collapsed-input">
                                                <i class="icon-search"></i>
                                                <input class="input" type="text" name="" id="" placeholder="Buscar">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card__flex-wrapper">
                                        <div class="card card--selected">
                                            <div class="card__header">
                                                <div class="card__header__title">
                                                    Guardar CV adjuntos en Gmail en Google Drive
                                                </div>
                                                <div class="card__header__info">
                                                    <div>Tecalis · Comercio</div>
                                                    <div class="tag tag--small tag--success">Pública</div>
                                                </div>
                                            </div>
                                            <div class="card__body">
                                                <div class="flex ai-center">
                                                    <div class="tag-icon">
                                                        <i class="icon-clock"></i>
                                                    </div>
                                                    <div class="tag-icon">
                                                        <i class="icon-clock"></i>
                                                    </div>
                                                    <div class="tag-icon">
                                                        <i class="icon-clock"></i>
                                                    </div>
                                                </div>
                                                <div class="rating">
                                                    <input type="radio" class="rating__input" id="0rc1">
                                                    <input type="radio" class="rating__input" id="0rc2">
                                                    <input type="radio" class="rating__input" id="0rc3">
                                                    <input type="radio" class="rating__input" id="0rc4">
                                                    <input type="radio" class="rating__input" id="0rc5">
                                                    <label for="0rc1" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="0rc2" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="0rc3" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="0rc4" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="0rc5" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card__header">
                                                <div class="card__header__title">
                                                    Guardar CV adjuntos en Gmail en Google Drive
                                                </div>
                                                <div class="card__header__info">
                                                    <div>Tecalis · Comercio</div>
                                                </div>
                                            </div>
                                            <div class="card__body">
                                                <div class="flex ai-center">
                                                    <div class="tag-icon">
                                                        <i class="icon-clock"></i>
                                                    </div>
                                                    <div class="tag-icon">
                                                        <i class="icon-clock"></i>
                                                    </div>
                                                    <div class="tag-icon">
                                                        <i class="icon-clock"></i>
                                                    </div>
                                                </div>
                                                <div class="rating">
                                                    <input type="radio" class="rating__input" id="1rc1">
                                                    <input type="radio" class="rating__input" id="1rc2">
                                                    <input type="radio" class="rating__input" id="1rc3">
                                                    <input type="radio" class="rating__input" id="1rc4">
                                                    <input type="radio" class="rating__input" id="1rc5">
                                                    <label for="1rc1" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="1rc2" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="1rc3" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="1rc4" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="1rc5" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card__header">
                                                <div class="card__header__title">
                                                    Guardar CV adjuntos en Gmail en Google Drive
                                                </div>
                                                <div class="card__header__info">
                                                    <div>Tecalis · Comercio</div>
                                                    <div class="tag tag--small tag--success">Pública</div>
                                                </div>
                                            </div>
                                            <div class="card__body">
                                                <div class="flex ai-center">
                                                    <div class="tag-icon">
                                                        <i class="icon-clock"></i>
                                                    </div>
                                                    <div class="tag-icon">
                                                        <i class="icon-clock"></i>
                                                    </div>
                                                    <div class="tag-icon">
                                                        <i class="icon-clock"></i>
                                                    </div>
                                                </div>
                                                <div class="rating">
                                                    <input type="radio" class="rating__input" id="2rc1">
                                                    <input type="radio" class="rating__input" id="2rc2">
                                                    <input type="radio" class="rating__input" id="2rc3">
                                                    <input type="radio" class="rating__input" id="2rc4">
                                                    <input type="radio" class="rating__input" id="2rc5">
                                                    <label for="2rc1" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="2rc2" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="2rc3" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="2rc4" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                    <label for="2rc5" class="rating__star">
                                                        <i class="icon-star-filled"></i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content__footer">
                            <button type="button" class="button button--secondary">Cancelar</button>
                            <button class="button">Siguiente</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- IMAGES-->
        <ng-container *ngIf="component === 'images'">

            <h2 class="m-b-16">Images</h2>
            <h5 class="m-b-8 m-t-16">Automatizaciones Empty states</h5>
            <img src="../../../../assets/resources/empty-automatizaciones.svg" alt="">

            <h5 class="m-b-8 m-t-16">Conexiones Empty states</h5>
            <img src="../../../../assets/resources/empty-conexiones.svg" alt="">

            <h5 class="m-b-8 m-t-16">Plantillas Empty states</h5>
            <img src="../../../../assets/resources/empty-plantillas.svg" alt="">

            <h5 class="m-b-8 m-t-16">Base de datos Empty states</h5>
            <img src="../../../../assets/resources/empty-bd.svg" alt="">

            <h5 class="m-b-8 m-t-16">Alert First Steps Step 0 (Conexión)</h5>
            <img src="../../../../assets/resources/first-steps--connections.svg" alt="">
            <h5 class="m-b-8 m-t-16">Alert First Steps Step 1 (BD)</h5>
            <img src="../../../../assets/resources/first-steps--data_base.svg" alt="">
            <h5 class="m-b-8 m-t-16">Alert First Steps Step 2 (Plantillas)</h5>
            <img src="../../../../assets/resources/first-steps--templates.svg" alt="">
            <h5 class="m-b-8 m-t-16">Alert First Steps Step 3 (Colaboradores)</h5>
            <img src="../../../../assets/resources/first-steps--collaborators.svg" alt="">
            <h5 class="m-b-8 m-t-16">Alert First Steps Step 4 (Automatización)</h5>
            <img src="../../../../assets/resources/first-steps--workflows.svg" alt="">
            <h5 class="m-b-8 m-t-16">Alert First Steps Step 5 (Finished)</h5>
            <img src="../../../../assets/resources/first-steps--completed.svg" alt="">

        </ng-container>

        <!-- SWITCHER-->
        <ng-container *ngIf="component === 'switcher'">
            <h2 class="m-b-16">Switcher</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <label class="switcher">
                <input type="checkbox" checked="checked">
                <span class="switcher__control"></span>
            </label>

            <h5 class="m-b-8 m-t-16">Small</h5>
            <label class="switcher switcher--small">
                <input type="checkbox" checked="checked">
                <span class="switcher__control"></span>
            </label>

            <h5 class="m-b-8 m-t-16">Small with label</h5>
            <div class="switcher-group">
                <label for="">Label</label>
                <label class="switcher switcher--small">
                    <input type="checkbox" checked="checked">
                    <span class="switcher__control"></span>
                </label>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <i class="icon-info"></i>
                </span>
            </div>
        </ng-container>

        <!-- TOOLBAR-->
        <ng-container *ngIf="component === 'toolbar'">
            <h2 class="m-b-16">Toolbar</h2>
            <div class="toolbar">
                <div class="toolbar__left">
                    <div class="input-group input-group--collapsed-input">
                        <i class="icon-search"></i>
                        <input class="input" type="text" name="" id="" placeholder="Buscar automatizaciones">
                    </div>
                </div>
                <div class="toolbar__right">
                    <div class="dropdown">
                        <button class="dropdown__trigger input">
                            <span>Ordenar de más a menos reciente</span>
                            <i class="icon-chevron-down"></i>
                        </button>
                        <div class="dropdown__menu">
                            <div class="dropdown__menu__item dropdown__menu__item--selected">
                                Ordenar de más a menos reciente
                            </div>
                            <div class="dropdown__menu__item">
                                Ordenar de más a menos antiguo
                            </div>
                        </div>
                    </div>
                    <button class="button button--secondary">Filtrar <i class="icon-filter"></i></button>
                </div>
            </div>

            <div class="toolbar">
                <div class="toolbar__left">
                    <div class="input-group input-group--collapsed-input">
                        <i class="icon-search"></i>
                        <input class="input" type="text" name="" id="" placeholder="Buscar automatizaciones">
                    </div>
                </div>
                <div class="toolbar__right">
                    <button class="button">Invitar</button>
                </div>
            </div>

            <div class="toolbar">
                <div class="toolbar__left">
                    <h4>Title</h4>
                </div>
                <div class="toolbar__right">
                    <div class="dropdown">
                        <button class="dropdown__trigger input">
                            <span>Ordenar de más a menos reciente</span>
                            <i class="icon-chevron-down"></i>
                        </button>
                        <div class="dropdown__menu">
                            <div class="dropdown__menu__item dropdown__menu__item--selected">
                                Ordenar de más a menos reciente
                            </div>
                            <div class="dropdown__menu__item">
                                Ordenar de más a menos antiguo
                            </div>
                        </div>
                    </div>
                    <button class="button button--secondary">Filtrar <i class="icon-filter"></i></button>
                </div>
            </div>

            <div class="toolbar">
                <div class="toolbar__left">

                </div>
                <div class="toolbar__right">
                    <div class="input-group input-group--collapsed-input">
                        <i class="icon-search"></i>
                        <input class="input" type="text" name="" id="" placeholder="Buscar">
                    </div>
                    <div class="dropdown">
                        <button class="dropdown__trigger input">
                            <span>Ordenar de más a menos reciente</span>
                            <i class="icon-chevron-down"></i>
                        </button>
                        <div class="dropdown__menu">
                            <div class="dropdown__menu__item dropdown__menu__item--selected">
                                Ordenar de más a menos reciente
                            </div>
                            <div class="dropdown__menu__item">
                                Ordenar de más a menos antiguo
                            </div>
                        </div>
                    </div>
                    <button class="button button--secondary">Filtrar <i class="icon-filter"></i></button>
                </div>
            </div>
        </ng-container>

        <!-- PAGINATION-->
        <ng-container *ngIf="component === 'pagination'">
            <h2 class="m-b-16">Pagination</h2>
            <div class="pagination">
                <div class="pagination__navigation">
                    <button class="button-icon" disabled>
                        <i class="icon-chevron-left"></i>
                    </button>
                    <div class="pagination__navigation__pages">
                        <button class="pages__page pages__page--current button">1</button>
                        <button class="pages__page button">2</button>
                        <button class="pages__page button">3</button>
                        <button class="pages__page button">4</button>
                        <button class="pages__page button">5</button>
                        <span class="pages__page">...</span>
                        <button class="pages__page button">100</button>
                    </div>
                    <button class="button-icon">
                        <i class="icon-chevron-right"></i>
                    </button>
                </div>
                <div class="pagination__go">
                    <span>Página</span>
                    <input class="input" type="text" value="1">
                </div>
            </div>
        </ng-container>

        <!-- CHIPS-->
        <ng-container *ngIf="component === 'chips'">
            <h2 class="m-b-16">Chips</h2>
            <div class="chip-group">
                <div class="chip">
                    Chip
                    <button class="button-icon">
                        <i class="icon-close"></i>
                    </button>
                </div>
                <div class="chip">
                    Chip
                    <button class="button-icon">
                        <i class="icon-close"></i>
                    </button>
                </div>
                <div class="chip">
                    Chip
                    <button class="button-icon">
                        <i class="icon-close"></i>
                    </button>
                </div>
            </div>
        </ng-container>

        <!-- FILTERS-->
        <ng-container *ngIf="component === 'filters'">
            <h2 class="m-b-16">Filters</h2>
            <app-button (click)="changeFilter()">Mostrar filtro</app-button>
            <div class="filters" [ngClass]="{'filters--open': showFilter}">
                <div class="filters__header">
                    <div class="filters__header__title">Aplicar Filtros</div>
                    <div class="filters__header__actions">
                        <button type="button" class="button-icon" (click)="changeFilter()">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                </div>
                <div class="filters__body">
                    <div class="filters__body__section">
                        <div class="section__title">Tipo</div>
                        <div class="button--toggle__wrapper">
                            <button class="button button--toggle button--toggle--toggled">Desencadenada</button>
                            <button class="button button--toggle">Manual</button>
                            <button class="button button--toggle">Programada</button>
                        </div>
                    </div>
                    <div class="filters__body__section">
                        <div class="section__title">Colaboradores</div>
                        <div class="dropdown">
                            <button id="open-dropdown" class="dropdown__trigger input"
                                placeholder="Seleccionar"><span>Seleccionar </span><i
                                    class="icon-chevron-down"></i></button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">Option</div>
                                <div class="dropdown__menu__item">Option 2</div>
                                <div class="dropdown__menu__item">Option 3</div>
                            </div>
                        </div>
                    </div>
                    <div class="filters__body__section">
                        <div class="section__title">Estado</div>
                        <div class="button--toggle__wrapper">
                            <button class="button button--toggle button--toggle--toggled">Activa</button>
                            <button class="button button--toggle">Inactiva</button>
                            <button class="button button--toggle">Con errores</button>
                            <button class="button button--toggle button--toggle--toggled">Ejecutando</button>
                        </div>
                    </div>
                </div>
                <div class="filters__footer">
                    <button type="button" class="button">Aplicar</button>
                    <button type="button" class="button button--secondary">Borrar filtros</button>
                </div>
            </div>
            <div class="filters__backdrop" [hidden]="!showFilter" [ngClass]="{'show': showFilter}"></div>
        </ng-container>

        <!-- ITEM-->
        <ng-container *ngIf="component === 'item'">
            <h2 class="m-b-16">Item</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="item item--open">
                <div class="item__header">
                    <div class="item__header__info">
                        <div class="info__notification">
                            <div class="badge badge--small"></div>
                        </div>
                        <div class="tag-icon tag-icon--green">
                            <i class="icon-message-circle"></i>
                        </div>
                        <h6>Nuevos comentarios de Jaime en “Nombre de la automatización”</h6>
                        <span class="info__date">12/12/22 - 09:30</span>
                    </div>
                    <div class="item__header__actions">
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye"></i>
                                    Ver a Alice automatizar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-folder"></i>
                                    Archivar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-move"></i>
                                    Mover a borradores
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                        <div class="button-icon">
                            <i class="icon-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div class="item__body">
                    <div class="item__body__message">
                        Jaime Quirós ha añadido 5 nuevos comentarios en la automatización <b>“Nombre de la
                            automatización”</b>
                    </div>
                    <div class="item__body__action">
                        <div class="button button--sm">Ver Comentario</div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item__header">
                    <div class="item__header__info">
                        <div class="info__notification">
                            <div class="badge badge--small"></div>
                        </div>
                        <div class="tag-icon tag-icon--purple">
                            <i class="icon-workflow"></i>
                        </div>
                        <h6>Nuevos comentarios de Jaime en “Nombre de la automatización”</h6>
                        <span class="info__date">12/12/22 - 09:30</span>
                    </div>
                    <div class="item__header__actions">
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye"></i>
                                    Ver a Alice automatizar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-folder"></i>
                                    Archivar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-move"></i>
                                    Mover a borradores
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                        <div class="button-icon">
                            <i class="icon-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div class="item__body">
                    <div class="item__body__message">
                        Jaime Quirós ha añadido 5 nuevos comentarios en la automatización <b>“Nombre de la
                            automatización”</b>
                    </div>
                    <div class="item__body__action">
                        <div class="button button--sm">Ver Comentario</div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item__header">
                    <div class="item__header__info">
                        <div class="info__notification">
                        </div>
                        <div class="tag-icon tag-icon--orange">
                            <i class="icon-level-0"></i>
                        </div>
                        <h6>Nuevos comentarios de Jaime en “Nombre de la automatización”</h6>
                        <span class="info__date">12/12/22 - 09:30</span>
                    </div>
                    <div class="item__header__actions">
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye"></i>
                                    Ver a Alice automatizar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-folder"></i>
                                    Archivar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-move"></i>
                                    Mover a borradores
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                        <div class="button-icon">
                            <i class="icon-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div class="item__body">
                    <div class="item__body__message">
                        Jaime Quirós ha añadido 5 nuevos comentarios en la automatización <b>“Nombre de la
                            automatización”</b>
                    </div>
                    <div class="item__body__action">
                        <div class="button button--sm">Ver Comentario</div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item__header">
                    <div class="item__header__info">
                        <div class="info__notification">
                        </div>
                        <div class="tag-icon">
                            <i class="icon-conection"></i>
                        </div>
                        <h6>Nuevos comentarios de Jaime en “Nombre de la automatización”</h6>
                        <span class="info__date">12/12/22 - 09:30</span>
                    </div>
                    <div class="item__header__actions">
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye"></i>
                                    Ver a Alice automatizar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-folder"></i>
                                    Archivar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-move"></i>
                                    Mover a borradores
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                        <div class="button-icon">
                            <i class="icon-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div class="item__body">
                    <div class="item__body__message">
                        Jaime Quirós ha añadido 5 nuevos comentarios en la automatización <b>“Nombre de la
                            automatización”</b>
                    </div>
                    <div class="item__body__action">
                        <div class="button button--sm">Ver Comentario</div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="item__header">
                    <div class="item__header__info">
                        <div class="info__notification">
                        </div>
                        <div class="tag-icon tag-icon--red">
                            <i class="icon-bell"></i>
                        </div>
                        <h6>Nuevos comentarios de Jaime en “Nombre de la automatización”</h6>
                        <span class="info__date">12/12/22 - 09:30</span>
                    </div>
                    <div class="item__header__actions">
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye"></i>
                                    Ver a Alice automatizar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-folder"></i>
                                    Archivar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-move"></i>
                                    Mover a borradores
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                        <div class="button-icon">
                            <i class="icon-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div class="item__body">
                    <div class="item__body__message">
                        Jaime Quirós ha añadido 5 nuevos comentarios en la automatización <b>“Nombre de la
                            automatización”</b>
                    </div>
                    <div class="item__body__action">
                        <div class="button button--sm">Ver Comentario</div>
                    </div>
                </div>
            </div>
            <h5 class="m-b-8 m-t-16">Big</h5>
            <div class="item item--big">
                <div class="item__header">
                    <div class="item__header__info">
                        <h3>Title</h3>
                        <div class="tag">Programada</div>
                        <div class="tag tag--error">Error</div>
                    </div>
                    <div class="item__header__actions">
                        <label class="switcher">
                            <input type="checkbox" checked="checked">
                            <span class="switcher__control"></span>
                        </label>
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye"></i>
                                    Ver a Alice automatizar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-folder"></i>
                                    Archivar
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-move"></i>
                                    Mover a borradores
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Skeleton</h5>
            <div class="item item--skeleton">
                <div class="item__header">
                    <div class="item__header__info">
                        <div class="tag-icon">
                            <i class="icon-conection"></i>
                        </div>
                        <h6></h6>
                    </div>
                    <div class="item__header__actions">
                    </div>
                </div>
                <div class="item__body">
                </div>
            </div>
        </ng-container>

        <!-- NODE-->
        <ng-container *ngIf="component === 'tree-node'">
            <h2 class="m-b-16">Tree Node</h2>
            <h5 class="m-b-8 m-t-16">Empty</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <h5 class="m-b-8 m-t-16">Empty Active</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty  flex-tree__node-group__node--empty--active">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Empty Tutorial</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty  flex-tree__node-group__node--empty--tutorial">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Empty with phrase</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b>web</b>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <h5 class="m-b-8 m-t-16">Empty with phrase and value</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b><a class="phrase__condition">Selecciona la condición (default)</a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b><a class="phrase__condition phrase__condition--focus">Selecciona la condición (Focus)</a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b><a class="phrase__condition phrase__condition--true">Selecciona la <b>condición</b> (True)</a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b><a class="phrase__condition phrase__condition--false">Selecciona la <b>condición</b> (False)</a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b><a class="phrase__condition phrase__condition--programed">todos los martes a las 09:00</a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b><a class="phrase__condition phrase__condition--selected"><b>SI</b>[aquí va la condición]</a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex-tree__node-group flex-tree__node-group--disabled">
                <div class="flex-tree__node-group__phrase">
                    Que pase esto en la <b> web</b><a class="phrase__condition phrase__condition--selected"><b>SI</b>[aquí va la condición]</a>
                </div>
                <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                    <span>Selecciona una acción</span>
                    <div class="node__actions">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-trash"></i>
                        </button>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node">
                    <i class="icon-open-web i-lg"></i>
                    <div class="node__info">
                        <h6>Abrir web</h6>
                        <div class="node__info__link">
                            <a href="">Introduce la url de la web</a>
                        </div>
                    </div>
                    <div class="node__actions">
                        <div class="tag-icon tag-icon--green">
                            <i class="icon-check"></i>
                        </div>
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    Repetir en bucle
                                    <label class="switcher switcher--small">
                                        <input type="checkbox">
                                        <span class="switcher__control"></span>
                                    </label>
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar paso
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye-off"></i>
                                    Desactivar paso
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Default Focus</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node flex-tree__node-group__node--focus">
                    <i class="icon-open-web i-lg"></i>
                    <div class="node__info">
                        <h6>Abrir web</h6>
                        <div class="node__info__link">
                            <a href="">Introduce la url de la web</a>
                        </div>
                    </div>
                    <div class="node__actions">
                        <div class="tag-icon tag-icon--green">
                            <i class="icon-check"></i>
                        </div>
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    Repetir en bucle
                                    <label class="switcher switcher--small">
                                        <input type="checkbox">
                                        <span class="switcher__control"></span>
                                    </label>
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar paso
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye-off"></i>
                                    Desactivar paso
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Default add button</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node">
                    <i class="icon-open-web i-lg"></i>
                    <div class="node__info">
                        <h6>Abrir web</h6>
                        <div class="node__info__link">
                            <span>www.test.com</span>
                        </div>
                    </div>
                    <div class="node__actions">
                        <div class="tag-icon tag-icon--green">
                            <i class="icon-check"></i>
                        </div>
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    Repetir en bucle
                                    <label class="switcher switcher--small">
                                        <input type="checkbox">
                                        <span class="switcher__control"></span>
                                    </label>
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar paso
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye-off"></i>
                                    Desactivar paso
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-tree__node-group__button">
                    <button class="button-icon button-icon--circle">
                        <i class="icon-plus"></i>
                    </button>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Default add node below</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node">
                    <i class="icon-open-web i-lg"></i>
                    <div class="node__info">
                        <h6>Abrir web</h6>
                        <div class="node__info__link">
                            <span>www.test.com</span>
                        </div>
                    </div>
                    <div class="node__actions">
                        <div class="tag-icon tag-icon--green">
                            <i class="icon-check"></i>
                        </div>
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    Repetir en bucle
                                    <label class="switcher switcher--small">
                                        <input type="checkbox">
                                        <span class="switcher__control"></span>
                                    </label>
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar paso
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye-off"></i>
                                    Desactivar paso
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="node__add-below">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-plus"></i>
                        </button>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Default add node above</h5>
            <div class="flex-tree__node-group">
                <div class="flex-tree__node-group__node">
                    <div class="node__add-above">
                        <button class="button-icon button-icon--circle button-icon--circle--sm">
                            <i class="icon-plus"></i>
                        </button>
                    </div>
                    <i class="icon-open-web i-lg"></i>
                    <div class="node__info">
                        <h6>Abrir web</h6>
                        <div class="node__info__link">
                            <span>www.test.com</span>
                        </div>
                    </div>
                    <div class="node__actions">
                        <div class="tag-icon tag-icon--green">
                            <i class="icon-check"></i>
                        </div>
                        <div class="dropdown">
                            <button class="button-icon dropdown__trigger">
                                <i class="icon-more"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    Repetir en bucle
                                    <label class="switcher switcher--small">
                                        <input type="checkbox">
                                        <span class="switcher__control"></span>
                                    </label>
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-edit-2"></i>
                                    Editar paso
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-eye-off"></i>
                                    Desactivar paso
                                </div>
                                <div class="dropdown__menu__separator"></div>
                                <div class="dropdown__menu__item text--red">
                                    <i class="icon-trash"></i>
                                    Eliminar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">Action between nodes</h5>
            <div class="flex-tree">
                <ul>
                    <li>
                        <div class="flex-tree__node-group">
                            <span class="flex-tree__node-group__node">
                                <i class="icon-open-web i-lg"></i>
                                <div class="node__info">
                                    <h6>Abrir web</h6>
                                    <div class="node__info__link">
                                        <span>www.test.com</span>
                                    </div>
                                </div>
                                <div class="node__actions">
                                    <div class="tag-icon tag-icon--green">
                                        <i class="icon-check"></i>
                                    </div>
                                    <div class="dropdown">
                                        <button class="button-icon dropdown__trigger">
                                            <i class="icon-more"></i>
                                        </button>
                                        <div class="dropdown__menu">
                                            <div class="dropdown__menu__item">
                                                Repetir en bucle
                                                <label class="switcher switcher--small">
                                                    <input type="checkbox">
                                                    <span class="switcher__control"></span>
                                                </label>
                                            </div>
                                            <div class="dropdown__menu__separator"></div>
                                            <div class="dropdown__menu__item">
                                                <i class="icon-edit-2"></i>
                                                Editar paso
                                            </div>
                                            <div class="dropdown__menu__item">
                                                <i class="icon-eye-off"></i>
                                                Desactivar paso
                                            </div>
                                            <div class="dropdown__menu__separator"></div>
                                            <div class="dropdown__menu__item text--red">
                                                <i class="icon-trash"></i>
                                                Eliminar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <div class="flex-tree__node-group__dot">
                                <div class="dot__dot"></div>
                                <button class="button-icon button-icon--circle button-icon--circle--sm">
                                    <i class="icon-arrow-double"></i>
                                </button>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <div class="flex-tree__node-group">
                                    <span class="flex-tree__node-group__node ">
                                        <i class="icon-click i-lg"></i>
                                        <div class="node__info">
                                            <h6>Clic</h6>
                                            <div class="node__info__link">
                                                <span>Botón de fichar</span>
                                            </div>
                                        </div>
                                        <div class="node__actions">
                                            <div class="tag-icon tag-icon--orange">
                                                <i class="icon-alert"></i>
                                            </div>
                                            <div class="dropdown">
                                                <button class="button-icon dropdown__trigger">
                                                    <i class="icon-more"></i>
                                                </button>
                                                <div class="dropdown__menu">
                                                    <div class="dropdown__menu__item">
                                                        Repetir en bucle
                                                        <label class="switcher switcher--small">
                                                            <input type="checkbox">
                                                            <span class="switcher__control"></span>
                                                        </label>
                                                    </div>
                                                    <div class="dropdown__menu__separator"></div>
                                                    <div class="dropdown__menu__item">
                                                        <i class="icon-edit-2"></i>
                                                        Editar paso
                                                    </div>
                                                    <div class="dropdown__menu__item">
                                                        <i class="icon-eye-off"></i>
                                                        Desactivar paso
                                                    </div>
                                                    <div class="dropdown__menu__separator"></div>
                                                    <div class="dropdown__menu__item text--red">
                                                        <i class="icon-trash"></i>
                                                        Eliminar
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    <div class="flex-tree__node-group__button">
                                        <button class="button-icon button-icon--circle">
                                            <i class="icon-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <h5 class="m-b-8 m-t-16">Action between nodes tutorial</h5>
            <div class="flex-tree">
                <ul>
                    <li>
                        <div class="flex-tree__node-group">
                            <span class="flex-tree__node-group__node">
                                <i class="icon-open-web i-lg"></i>
                                <div class="node__info">
                                    <h6>Abrir web</h6>
                                    <div class="node__info__link">
                                        <span>www.test.com</span>
                                    </div>
                                </div>
                                <div class="node__actions">
                                    <div class="tag-icon tag-icon--green">
                                        <i class="icon-check"></i>
                                    </div>
                                    <div class="dropdown">
                                        <button class="button-icon dropdown__trigger">
                                            <i class="icon-more"></i>
                                        </button>
                                        <div class="dropdown__menu">
                                            <div class="dropdown__menu__item">
                                                Repetir en bucle
                                                <label class="switcher switcher--small">
                                                    <input type="checkbox">
                                                    <span class="switcher__control"></span>
                                                </label>
                                            </div>
                                            <div class="dropdown__menu__separator"></div>
                                            <div class="dropdown__menu__item">
                                                <i class="icon-edit-2"></i>
                                                Editar paso
                                            </div>
                                            <div class="dropdown__menu__item">
                                                <i class="icon-eye-off"></i>
                                                Desactivar paso
                                            </div>
                                            <div class="dropdown__menu__separator"></div>
                                            <div class="dropdown__menu__item text--red">
                                                <i class="icon-trash"></i>
                                                Eliminar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <div class="flex-tree__node-group__dot">
                                <div class="dot__dot dot__dot--tutorial"></div>
                                <button class="button-icon button-icon--circle button-icon--circle--sm">
                                    <i class="icon-arrow-double"></i>
                                </button>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <div class="flex-tree__node-group">
                                    <span class="flex-tree__node-group__node ">
                                        <i class="icon-click i-lg"></i>
                                        <div class="node__info">
                                            <h6>Clic</h6>
                                            <div class="node__info__link">
                                                <span>Botón de fichar</span>
                                            </div>
                                        </div>
                                        <div class="node__actions">
                                            <div class="tag-icon tag-icon--orange">
                                                <i class="icon-alert"></i>
                                            </div>
                                            <div class="dropdown">
                                                <button class="button-icon dropdown__trigger">
                                                    <i class="icon-more"></i>
                                                </button>
                                                <div class="dropdown__menu">
                                                    <div class="dropdown__menu__item">
                                                        Repetir en bucle
                                                        <label class="switcher switcher--small">
                                                            <input type="checkbox">
                                                            <span class="switcher__control"></span>
                                                        </label>
                                                    </div>
                                                    <div class="dropdown__menu__separator"></div>
                                                    <div class="dropdown__menu__item">
                                                        <i class="icon-edit-2"></i>
                                                        Editar paso
                                                    </div>
                                                    <div class="dropdown__menu__item">
                                                        <i class="icon-eye-off"></i>
                                                        Desactivar paso
                                                    </div>
                                                    <div class="dropdown__menu__separator"></div>
                                                    <div class="dropdown__menu__item text--red">
                                                        <i class="icon-trash"></i>
                                                        Eliminar
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    <div class="flex-tree__node-group__button">
                                        <button class="button-icon button-icon--circle">
                                            <i class="icon-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>

            <h5 class="m-b-8 m-t-16">Add node in bifurcation</h5>
            <div class="flex-tree">
                <ul>
                    <li>
                        <div class="flex-tree__node-group">
                            <span class="flex-tree__node-group__node">
                                <i class="icon-open-web i-lg"></i>
                                <div class="node__info">
                                    <h6>Abrir web</h6>
                                    <div class="node__info__link">
                                        <span>www.test.com</span>
                                    </div>
                                </div>
                                <div class="node__actions">
                                    <div class="tag-icon tag-icon--green">
                                        <i class="icon-check"></i>
                                    </div>
                                    <div class="dropdown">
                                        <button class="button-icon dropdown__trigger">
                                            <i class="icon-more"></i>
                                        </button>
                                        <div class="dropdown__menu">
                                            <div class="dropdown__menu__item">
                                                Repetir en bucle
                                                <label class="switcher switcher--small">
                                                    <input type="checkbox">
                                                    <span class="switcher__control"></span>
                                                </label>
                                            </div>
                                            <div class="dropdown__menu__separator"></div>
                                            <div class="dropdown__menu__item">
                                                <i class="icon-edit-2"></i>
                                                Editar paso
                                            </div>
                                            <div class="dropdown__menu__item">
                                                <i class="icon-eye-off"></i>
                                                Desactivar paso
                                            </div>
                                            <div class="dropdown__menu__separator"></div>
                                            <div class="dropdown__menu__item text--red">
                                                <i class="icon-trash"></i>
                                                Eliminar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <div class="flex-tree__node-group__dot">
                                <div class="dot__dot"></div>
                                <button class="button-icon button-icon--circle button-icon--circle--sm">
                                    <i class="icon-plus"></i>
                                </button>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <div class="flex-tree__node-group">
                                    <div class="flex-tree__node-group__phrase">
                                        Que pase esto en la <b> web</b><a class="phrase__condition">Selecciona la condición (default)</a>
                                    </div>
                                    <span class="flex-tree__node-group__node ">
                                        <i class="icon-click i-lg"></i>
                                        <div class="node__info">
                                            <h6>Clic</h6>
                                            <div class="node__info__link">
                                                <span>Botón de fichar</span>
                                            </div>
                                        </div>
                                        <div class="node__actions">
                                            <div class="tag-icon tag-icon--orange">
                                                <i class="icon-alert"></i>
                                            </div>
                                            <div class="dropdown">
                                                <button class="button-icon dropdown__trigger">
                                                    <i class="icon-more"></i>
                                                </button>
                                                <div class="dropdown__menu">
                                                    <div class="dropdown__menu__item">
                                                        Repetir en bucle
                                                        <label class="switcher switcher--small">
                                                            <input type="checkbox">
                                                            <span class="switcher__control"></span>
                                                        </label>
                                                    </div>
                                                    <div class="dropdown__menu__separator"></div>
                                                    <div class="dropdown__menu__item">
                                                        <i class="icon-edit-2"></i>
                                                        Editar paso
                                                    </div>
                                                    <div class="dropdown__menu__item">
                                                        <i class="icon-eye-off"></i>
                                                        Desactivar paso
                                                    </div>
                                                    <div class="dropdown__menu__separator"></div>
                                                    <div class="dropdown__menu__item text--red">
                                                        <i class="icon-trash"></i>
                                                        Eliminar
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    <div class="flex-tree__node-group__button">
                                        <button class="button-icon button-icon--circle">
                                            <i class="icon-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="flex-tree__node-group">
                                    <div class="flex-tree__node-group__phrase">
                                        Que pase esto en la <b> web</b><a class="phrase__condition">Selecciona la condición (default)</a>
                                    </div>
                                    <span class="flex-tree__node-group__node ">
                                        <i class="icon-click i-lg"></i>
                                        <div class="node__info">
                                            <h6>Clic</h6>
                                            <div class="node__info__link">
                                                <span>Botón de fichar</span>
                                            </div>
                                        </div>
                                        <div class="node__actions">
                                            <div class="tag-icon tag-icon--orange">
                                                <i class="icon-alert"></i>
                                            </div>
                                            <div class="dropdown">
                                                <button class="button-icon dropdown__trigger">
                                                    <i class="icon-more"></i>
                                                </button>
                                                <div class="dropdown__menu">
                                                    <div class="dropdown__menu__item">
                                                        Repetir en bucle
                                                        <label class="switcher switcher--small">
                                                            <input type="checkbox">
                                                            <span class="switcher__control"></span>
                                                        </label>
                                                    </div>
                                                    <div class="dropdown__menu__separator"></div>
                                                    <div class="dropdown__menu__item">
                                                        <i class="icon-edit-2"></i>
                                                        Editar paso
                                                    </div>
                                                    <div class="dropdown__menu__item">
                                                        <i class="icon-eye-off"></i>
                                                        Desactivar paso
                                                    </div>
                                                    <div class="dropdown__menu__separator"></div>
                                                    <div class="dropdown__menu__item text--red">
                                                        <i class="icon-trash"></i>
                                                        Eliminar
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    <div class="flex-tree__node-group__button">
                                        <button class="button-icon button-icon--circle">
                                            <i class="icon-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </ng-container>

        <!-- TREE-->
        <ng-container *ngIf="component === 'tree'">
            <h2 class="m-b-16">Tree</h2>
            <!-- Flexbox Testing -->
            <div class="flex-tree">
                <ul>
                    <li>
                        <div class="flex-tree__node-group">
                            <div class="flex-tree__node-group__phrase">
                                Que pase esto en la <b> web</b>
                            </div>
                            <div class="flex-tree__node-group__node">
                                <i class="icon-open-web i-lg"></i>
                                <div class="node__info">
                                    <h6>Abrir web</h6>
                                    <div class="node__info__link">
                                        <span>www.test.com</span>
                                    </div>
                                </div>
                                <div class="node__actions">
                                    <div class="tag-icon tag-icon--green">
                                        <i class="icon-check"></i>
                                    </div>
                                    <div class="dropdown">
                                        <button class="button-icon dropdown__trigger">
                                            <i class="icon-more"></i>
                                        </button>
                                        <div class="dropdown__menu">
                                            <div class="dropdown__menu__item">
                                                Repetir en bucle
                                                <label class="switcher switcher--small">
                                                    <input type="checkbox">
                                                    <span class="switcher__control"></span>
                                                </label>
                                            </div>
                                            <div class="dropdown__menu__separator"></div>
                                            <div class="dropdown__menu__item">
                                                <i class="icon-edit-2"></i>
                                                Editar paso
                                            </div>
                                            <div class="dropdown__menu__item">
                                                <i class="icon-eye-off"></i>
                                                Desactivar paso
                                            </div>
                                            <div class="dropdown__menu__separator"></div>
                                            <div class="dropdown__menu__item text--red">
                                                <i class="icon-trash"></i>
                                                Eliminar
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="node__add-below">
                                    <button class="button-icon button-icon--circle button-icon--circle--sm">
                                        <i class="icon-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="flex-tree__node-group__dot">
                                <div class="dot__dot"></div>
                                <button class="button-icon button-icon--circle button-icon--circle--sm">
                                    <i class="icon-plus"></i>
                                </button>
                            </div>
                        </div>
                        <ul>
                            <li>
                                <div class="flex-tree__node-group">
                                    <div class="flex-tree__node-group__phrase">
                                        Que pase esto en la <b> web</b><a class="phrase__condition phrase__condition--true">Selecciona la <b>condición</b> (True)</a>
                                    </div>
                                    <span class="flex-tree__node-group__node ">
                                        <div class="node__add-above">
                                            <button class="button-icon button-icon--circle button-icon--circle--sm">
                                                <i class="icon-plus"></i>
                                            </button>
                                        </div>
                                        <i class="icon-click i-lg"></i>
                                        <div class="node__info">
                                            <h6>Clic</h6>
                                            <div class="node__info__link">
                                                <span>Botón de fichar</span>
                                            </div>
                                        </div>
                                        <div class="node__actions">
                                            <div class="tag-icon tag-icon--orange">
                                                <i class="icon-alert"></i>
                                            </div>
                                            <div class="dropdown">
                                                <button class="button-icon dropdown__trigger">
                                                    <i class="icon-more"></i>
                                                </button>
                                                <div class="dropdown__menu">
                                                    <div class="dropdown__menu__item">
                                                        Repetir en bucle
                                                        <label class="switcher switcher--small">
                                                            <input type="checkbox">
                                                            <span class="switcher__control"></span>
                                                        </label>
                                                    </div>
                                                    <div class="dropdown__menu__separator"></div>
                                                    <div class="dropdown__menu__item">
                                                        <i class="icon-edit-2"></i>
                                                        Editar paso
                                                    </div>
                                                    <div class="dropdown__menu__item">
                                                        <i class="icon-eye-off"></i>
                                                        Desactivar paso
                                                    </div>
                                                    <div class="dropdown__menu__separator"></div>
                                                    <div class="dropdown__menu__item text--red">
                                                        <i class="icon-trash"></i>
                                                        Eliminar
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    <div class="flex-tree__node-group__button">
                                        <button class="button-icon button-icon--circle">
                                            <i class="icon-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="flex-tree__node-group flex-tree__node-group--disabled">
                                    <div class="flex-tree__node-group__phrase">
                                        Que pase esto en la <b> web</b><a class="phrase__condition">Selecciona la condición</a>
                                    </div>
                                    <div class="flex-tree__node-group__node flex-tree__node-group__node--empty">
                                        <span>Selecciona una acción</span>
                                        <div class="node__actions">
                                            <button class="button-icon button-icon--circle button-icon--circle--sm">
                                                <i class="icon-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </ng-container>

        <!-- WORKSPACE SIDEBAR-->
        <ng-container *ngIf="component === 'workspace-sidebar'">
            <h2 class="m-b-16">Workspace Sidebar</h2>
            <h5 class="m-t-16 m-b-8">Default</h5>
            <div class="workspace-sidebar workspace-sidebar--open" style="position: relative;top: inherit;height: auto;">
                <div class="workspace-sidebar__sidebar">
                    <div class="workspace-sidebar__sidebar__header">
                        <div class="header__title">Configurar acción</div>
                        <div class="header__actions">
                            <button class="button-icon">
                                <i class="icon-close"></i>
                            </button>
                        </div>
                    </div>
                    <div class="workspace-sidebar__sidebar__body">
                        <div class="collapse-action">
                            <div class="collapse-action__header">
                                <div class="collapse-action__header__title">
                                    <div class="title__title">
                                        En este elemento
                                    </div>
                                    <div class="title__option-selected">
                                       <b>https://life.tecalis.com</b>
                                    </div>
                                </div>
                                <div class="collapse-action__header__action">
                                    <i class="icon-chevron-right"></i>
                                </div>
                            </div>
                            <div class="collapse-action__body"></div>
                       </div>
                       <div class="collapse-action collapse-action--open">
                            <div class="collapse-action__header">
                                <div class="collapse-action__header__title">
                                    <div class="title__title">
                                        Selecciona la condición
                                    </div>
                                </div>
                                <div class="collapse-action__header__action">
                                    <i class="icon-chevron-right"></i>
                                </div>
                            </div>
                            <div class="collapse-action__body">
                                <div class="button--toggle__wrapper">
                                    <button class="button button--toggle button--toggle--toggled">SI pasa esto...</button>
                                    <button class="button button--toggle">si NO pasa esto...</button>
                                </div>
                                <div class="input-group input-group--collapsed-input">
                                    <i class="icon-search"></i>
                                    <input class="input" type="text" name="" id="" placeholder="Buscar">
                                </div>
                                <div class="sidebar-item sidebar-item--conditional">
                                    <div class="sidebar-item__text">
                                        Aquí va el tipo de condición
                                    </div>
                                    <span class="tooltip" data-tooltip="Tooltip!">
                                        <i class="icon-info"></i>
                                    </span>
                                </div>
                                <div class="sidebar-item sidebar-item--conditional">
                                    <div class="sidebar-item__text">
                                        Aquí va el tipo de condición
                                    </div>
                                    <span class="tooltip" data-tooltip="Tooltip!">
                                        <i class="icon-info"></i>
                                    </span>
                                </div>
                                <div class="sidebar-item sidebar-item--conditional">
                                    <div class="sidebar-item__text">
                                        Aquí va el tipo de condición
                                    </div>
                                    <span class="tooltip" data-tooltip="Tooltip!">
                                        <i class="icon-info"></i>
                                    </span>
                                </div>
                                <div class="sidebar-item sidebar-item--conditional">
                                    <div class="sidebar-item__text">
                                        Aquí va el tipo de condición
                                    </div>
                                    <span class="tooltip" data-tooltip="Tooltip!">
                                        <i class="icon-info"></i>
                                    </span>
                                </div>
                                <div class="sidebar-item sidebar-item--conditional">
                                    <div class="sidebar-item__text">
                                        Aquí va el tipo de condición
                                    </div>
                                    <span class="tooltip" data-tooltip="Tooltip!">
                                        <i class="icon-info"></i>
                                    </span>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
            <h5 class="m-t-16 m-b-8">With Icons (RIGHT)</h5>
            <h6 class="m-b-8">With Icons - Alerts</h6>
            <div class="workspace-sidebar workspace-sidebar--right workspace-sidebar--open" style="position: relative;top: inherit;height: auto;">
                <div class="workspace-sidebar__icons">
                    <button class="button-icon button-icon--warning button-icon--selected">
                        <i class="icon-alert-triangle"></i>
                        <div class="badge">2</div>
                    </button>
                    <button class="button-icon">
                        <i class="icon-search"></i>
                    </button>
                    <button class="button-icon">
                        <i class="icon-comment-list"></i>
                    </button>
                    <button class="button-icon">
                        <i class="icon-help-circle"></i>
                    </button>
                </div>
                <div class="workspace-sidebar__sidebar workspace-sidebar__sidebar--alerts">
                    <div class="workspace-sidebar__sidebar__header">
                        <div class="header__title">Alertas</div>
                        <div class="header__actions">
                            <button class="button-icon">
                                <i class="icon-close"></i>
                            </button>
                        </div>
                    </div>
                    <div class="workspace-sidebar__sidebar__body">
                        <div class="node-preview">
                            <div class="tag-icon">
                                <i class="icon-double-click"></i>
                            </div>
                            <div class="node-preview__text">
                                <h6>2.Click</h6>
                                <span>Botón de fichar</span>
                            </div>
                        </div>
                        <div class="sidebar-item">
                            <div class="sidebar-item__icon">
                                <i class="icon-pending-check i-sm text--orange-light"></i>
                            </div>
                            <div class="sidebar-item__text">
                                Debes comprobar el paso
                            </div>
                            <span class="tooltip" data-tooltip="Tooltip!">
                                <i class="icon-info"></i>
                            </span>
                        </div>
                        <div class="sidebar-item">
                            <div class="sidebar-item__icon">
                                <i class="icon-alert-triangle i-sm text--orange-light"></i>
                            </div>
                            <div class="sidebar-item__text">
                                Debes seleccionar una URL
                            </div>
                            <span class="tooltip" data-tooltip="Tooltip!">
                                <i class="icon-info"></i>
                            </span>
                        </div>
                        <div class="node-preview">
                            <div class="tag-icon">
                                <i class="icon-double-click"></i>
                            </div>
                            <div class="node-preview__text">
                                <h6>2.Click</h6>
                                <span>Botón de fichar</span>
                            </div>
                        </div>
                        <div class="sidebar-item">
                            <div class="sidebar-item__icon">
                                <i class="	icon-execution-error i-sm text--red-light"></i>
                            </div>
                            <div class="sidebar-item__text">
                                Error en su ejecución
                            </div>
                            <span class="tooltip" data-tooltip="Tooltip!">
                                <i class="icon-info"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <h6 class="m-b-8">With Icons - Búsqueda</h6>
            <div class="workspace-sidebar workspace-sidebar--right workspace-sidebar--open" style="position: relative;top: inherit;height: auto;">
                <div class="workspace-sidebar__icons">
                    <button class="button-icon button-icon--warning">
                        <i class="icon-alert-triangle"></i>
                        <div class="badge">2</div>
                    </button>
                    <button class="button-icon button-icon--selected">
                        <i class="icon-search"></i>
                    </button>
                    <button class="button-icon">
                        <i class="icon-comment-list"></i>
                    </button>
                    <button class="button-icon">
                        <i class="icon-help-circle"></i>
                    </button>
                </div>
                <div class="workspace-sidebar__sidebar">
                    <div class="workspace-sidebar__sidebar__header">
                        <div class="header__title">Búsqueda avanzada</div>
                        <div class="header__actions">
                            <button class="button-icon">
                                <i class="icon-close"></i>
                            </button>
                        </div>
                    </div>
                    <div class="workspace-sidebar__sidebar__actions">
                        <div class="input-group input-group--collapsed-input">
                            <i class="icon-search"></i>
                            <input class="input" type="text" name="" id="" placeholder="Buscar">
                        </div>
                        <div class="dropdown">
                            <button class="button-icon button-icon--circle dropdown__trigger">
                                <i class="icon-filter"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    Option
                                </div>
                                <div class="dropdown__menu__item dropdown__menu__item--selected">
                                    Option Selected
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-log-out"></i>
                                    Cerrar sesión
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="workspace-sidebar__sidebar__body">
                        <div class="node-preview">
                            <div class="tag-icon">
                                <i class="icon-double-click"></i>
                            </div>
                            <div class="node-preview__text">
                                <h6>2.Click</h6>
                                <span>Botón de fichar</span>
                            </div>
                            <i class="icon-arrow-right"></i>
                        </div>
                        <div class="node-preview node-preview--selected">
                            <div class="tag-icon">
                                <i class="icon-double-click"></i>
                            </div>
                            <div class="node-preview__text">
                                <h6>5.Click</h6>
                                <span>Botón de fichar</span>
                            </div>
                            <i class="icon-arrow-right"></i>
                        </div>
                        <div class="node-preview">
                            <div class="tag-icon">
                                <i class="icon-double-click"></i>
                            </div>
                            <div class="node-preview__text">
                                <h6>9.Click</h6>
                                <span>Botón de fichar</span>
                            </div>
                            <i class="icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
            <h6 class="m-b-8">With Icons - Comments</h6>
            <div class="workspace-sidebar workspace-sidebar--right workspace-sidebar--open" style="position: relative;top: inherit;height: auto;">
                <div class="workspace-sidebar__icons">
                    <button class="button-icon button-icon--warning">
                        <i class="icon-alert-triangle"></i>
                        <div class="badge">2</div>
                    </button>
                    <button class="button-icon">
                        <i class="icon-search"></i>
                    </button>
                    <button class="button-icon button-icon--selected">
                        <i class="icon-comment-list"></i>
                    </button>
                    <button class="button-icon">
                        <i class="icon-help-circle"></i>
                    </button>
                </div>
                <div class="workspace-sidebar__sidebar">
                    <div class="workspace-sidebar__sidebar__header">
                        <div class="header__title">Comentarios</div>
                        <div class="header__actions">
                            <button class="button-icon">
                                <i class="icon-close"></i>
                            </button>
                        </div>
                    </div>
                    <div class="workspace-sidebar__sidebar__actions">
                        <div class="input-group input-group--collapsed-input">
                            <i class="icon-search"></i>
                            <input class="input" type="text" name="" id="" placeholder="Buscar">
                        </div>
                        <div class="button-icon button-icon--circle">
                            <i class="icon-eye-off"></i>
                        </div>
                        <div class="dropdown">
                            <button class="button-icon button-icon--circle dropdown__trigger">
                                <i class="icon-filter"></i>
                            </button>
                            <div class="dropdown__menu">
                                <div class="dropdown__menu__item">
                                    Option
                                </div>
                                <div class="dropdown__menu__item dropdown__menu__item--selected">
                                    Option Selected
                                </div>
                                <div class="dropdown__menu__item">
                                    <i class="icon-log-out"></i>
                                    Cerrar sesión
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="workspace-sidebar__sidebar__body">
                        <div class="comments">
                            <div class="comments__comment">
                                <div class="comments__comment__info">
                                    <div class="info__name">
                                        <div class="avatar" style="background-color:#3CB372">
                                            <span style="color: #FFFFFF">CR</span>
                                        </div>
                                        <div class="info__name__name">Name Surname</div>
                                    </div>
                                    <div class="info__data">
                                        Hace 2 días
                                    </div>
                                </div>
                                <div class="comments__comment__node">2. Click - Botón de fichar</div>
                                <div class="comments__comment__body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sagittis, ipsum ut laoreet accumsan, mauris felis blandit sapien, dictum luctus diam leo in velitipsum ut laoreetdsaan...
                                </div>
                                <a href="">2 respuestas</a>
                            </div>
                        </div>
                        <div class="comments comments--extend">
                            <div class="comments__comment">
                                <div class="comments__comment__info">
                                    <div class="info__name">
                                        <div class="avatar" style="background-color: #7756DB">
                                            <span style="color: #FFFFFF">HE</span>
                                        </div>
                                        <div class="info__name__name">Name Surname</div>
                                    </div>
                                    <div class="info__data">
                                        Hace 2 días
                                    </div>
                                </div>
                                <div class="comments__comment__node">2. Click - Botón de fichar</div>
                                <div class="comments__comment__body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sagittis, ipsum ut laoreet accumsan, mauris felis blandit sapien, dictum luctus diam leo in velitipsum ut laoreetdsaan sagittis.
                                </div>
                            </div>
                            <div class="comments__comment">
                                <div class="comments__comment__info">
                                    <div class="info__name">
                                        <div class="avatar" style="background-color:#3CB372">
                                            <span style="color: #FFFFFF">CR</span>
                                        </div>
                                        <div class="info__name__name">Name Surname</div>
                                    </div>
                                    <div class="info__data">
                                        Hace 2 días
                                    </div>
                                </div>
                                <div class="comments__comment__node">2. Click - Botón de fichar</div>
                                <div class="comments__comment__body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </div>
                                <div class="comments__comment__answers">
                                    <div class="avatar" style="background-color:#3CB372">
                                        <span style="color: #FFFFFF">CR</span>
                                    </div>
                                    <div class="input-group">
                                        <div class="input-group__button">
                                            <input class="input" type="text">
                                            <button class="button-icon">
                                                <i class="icon-selector"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- COLLAPSE ACTION-->
        <ng-container *ngIf="component === 'collapse-action'">
            <h2 class="m-b-16">Collapse actions</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="collapse-action">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            En este elemento
                        </div>
                        <div class="title__option-selected">
                           <b>https://life.tecalis.com</b>
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <i class="icon-chevron-right"></i>
                    </div>
                </div>
                <div class="collapse-action__body"></div>
           </div>

           <h5 class="m-b-8 m-t-16">Open</h5>
           <div class="collapse-action collapse-action--open">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            Selecciona o arrastra la acción
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <i class="icon-chevron-right"></i>
                    </div>
                </div>
                <div class="collapse-action__body">
                    <div class="input-group input-group--collapsed-input">
                        <i class="icon-search"></i>
                        <input class="input" type="text" name="" id="" placeholder="Buscar">
                    </div>
                    <div class="sidebar-item">
                        <div class="sidebar-item__icon">
                            <i class="icon-folder i-md"></i>
                        </div>
                        <div class="sidebar-item__text">
                            Lineal
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                    <div class="sidebar-item">
                        <div class="sidebar-item__icon">
                            <i class="icon-folder i-md"></i>
                        </div>
                        <div class="sidebar-item__text">
                            Lineal
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                    <div class="sidebar-item">
                        <div class="sidebar-item__icon">
                            <i class="icon-folder i-md"></i>
                        </div>
                        <div class="sidebar-item__text">
                            Lineal
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                    <div class="sidebar-item">
                        <div class="sidebar-item__icon">
                            <i class="icon-folder i-md"></i>
                        </div>
                        <div class="sidebar-item__text">
                            Lineal
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                    <div class="sidebar-item sidebar-item--disabled">
                        <div class="sidebar-item__icon">
                            <i class="icon-folder i-md"></i>
                        </div>
                        <div class="sidebar-item__text">
                            Lineal
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                    <div class="chip">
                        Nombre del input
                        <button class="button-icon">
                            <i class="icon-close"></i>
                        </button>
                    </div>
                </div>
           </div>

            <h5 class="m-b-8 m-t-16">More than one Collapse action</h5>
            <div class="collapse-action">
            <div class="collapse-action__header">
                <div class="collapse-action__header__title">
                    <div class="title__title">
                        En este elemento
                    </div>
                    <div class="title__option-selected">
                       <b>https://life.tecalis.com</b>
                    </div>
                </div>
                <div class="collapse-action__header__action">
                    <i class="icon-chevron-right"></i>
                </div>
            </div>
            <div class="collapse-action__body"></div>
            </div>
            <div class="collapse-action collapse-action--open">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            Introduce la url de la web
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <i class="icon-chevron-right"></i>
                    </div>
                </div>
                <div class="collapse-action__body">
                    <input type="text" class="input" placeholder="Placeholder">
                    <div class="collapse-action__body__buttons">
                        <button class="button button--sm">Siguiente</button>
                    </div>
                </div>
            </div>

            <h5 class="m-b-8 m-t-16">All body options</h5>
            <div class="collapse-action collapse-action--open">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            Introduce la url de la web
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <i class="icon-chevron-right"></i>
                    </div>
                </div>
                <div class="collapse-action__body">
                    <div class="dropdown">
                        <button class="dropdown__trigger input">
                            <span>Dropdown</span>
                            <i class="icon-chevron-down"></i>
                        </button>
                        <div class="dropdown__menu">
                            <div class="dropdown__menu__item">Option</div>
                            <div class="dropdown__menu__item">Option 2</div>
                            <div class="dropdown__menu__item">Option 3</div>
                        </div>
                    </div>
                    <div class="collapse-action__body__buttons">
                        <button class="button button--sm">Siguiente</button>
                    </div>
                </div>
            </div>
            <div class="collapse-action collapse-action--open">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            Y si hay algún problema...
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <i class="icon-chevron-right"></i>
                    </div>
                </div>
                <div class="collapse-action__body">
                    <div class="button--toggle__wrapper">
                        <button class="button button--toggle button--toggle--toggled">Parar automatización</button>
                        <button class="button button--toggle">Continuar automatizando</button>
                    </div>
                    <div class="collapse-action__body__separator"></div>
                    <label for="">Opciones avanzadas</label>
                    <div class="switcher-group">
                        <label for="">Verificar contenido</label>
                        <label class="switcher switcher--small">
                            <input type="checkbox" checked="checked">
                            <span class="switcher__control"></span>
                        </label>
                        <span class="tooltip tooltip--left" data-tooltip="Copia y pega un titular o texto de la web para verificar
                        que estoy en la página correcta al acceder a ella">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                    <div class="collapse-action__body__buttons">
                        <button class="button button--sm">Siguiente</button>
                    </div>
                </div>
            </div>
            <div class="collapse-action collapse-action--open">
            <div class="collapse-action__body">
                <div class="alice alice--sm"></div>
                <span>Para verificar que la automatización funciona correctamente, haré la prueba de <b>abrir la web</b> de <b>https://life.tecalis.com</b></span>
                <div class="collapse-action__body__buttons">
                    <button class="button button--secondary">Cancelar</button>
                    <button class="button">Aceptar</button>
                </div>
            </div>
            </div>
            <div class="collapse-action collapse-action--open">
                <div class="collapse-action__header">
                    <div class="collapse-action__header__title">
                        <div class="title__title">
                            Configurar bifurcación
                        </div>
                    </div>
                    <div class="collapse-action__header__action">
                        <i class="icon-chevron-right"></i>
                    </div>
                </div>
                <div class="collapse-action__body">
                    <div class="button--toggle__wrapper">
                        <button class="button button--toggle button--toggle--toggled">SI pasa esto...</button>
                        <button class="button button--toggle">si NO pasa esto...</button>
                    </div>
                    <div class="input-group input-group--collapsed-input">
                        <i class="icon-search"></i>
                        <input class="input" type="text" name="" id="" placeholder="Buscar">
                    </div>
                    <div class="sidebar-item sidebar-item--conditional">
                        <div class="sidebar-item__text">
                            Aquí va el tipo de condición
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                    <div class="sidebar-item sidebar-item--conditional">
                        <div class="sidebar-item__text">
                            Aquí va el tipo de condición
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                    <div class="sidebar-item sidebar-item--conditional">
                        <div class="sidebar-item__text">
                            Aquí va el tipo de condición
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                    <div class="sidebar-item sidebar-item--conditional">
                        <div class="sidebar-item__text">
                            Aquí va el tipo de condición
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                    <div class="sidebar-item sidebar-item--conditional">
                        <div class="sidebar-item__text">
                            Aquí va el tipo de condición
                        </div>
                        <span class="tooltip" data-tooltip="Tooltip!">
                            <i class="icon-info"></i>
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- SIDEBAR ITEM-->
        <ng-container *ngIf="component === 'sidebar-item'">
            <h2 class="m-b-16">Sidebar Item</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="sidebar-item">
                <div class="sidebar-item__icon">
                    <i class="icon-folder i-md"></i>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <i class="icon-info"></i>
                </span>
            </div>
            <div class="sidebar-item">
                <div class="sidebar-item__icon">
                    <div class="tag-icon tag-icon--small">
                        <i class="icon-check"></i>
                    </div>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <i class="icon-info"></i>
                </span>
            </div>

            <h5 class="m-b-8 m-t-16">Conditional</h5>
            <div class="sidebar-item sidebar-item--conditional">
                <div class="sidebar-item__text">
                    Aquí va el tipo de condición
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <i class="icon-info"></i>
                </span>
            </div>

            <h5 class="m-b-8 m-t-16">Disabled</h5>
            <div class="sidebar-item sidebar-item--disabled">
                <div class="sidebar-item__icon">
                    <i class="icon-folder i-md"></i>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <i class="icon-info"></i>
                </span>
            </div>
            <div class="sidebar-item sidebar-item--disabled">
                <div class="sidebar-item__icon">
                    <div class="tag-icon tag-icon--small">
                        <i class="icon-check"></i>
                    </div>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <i class="icon-info"></i>
                </span>
            </div>
            <div class="sidebar-item sidebar-item--disabled sidebar-item--conditional">
                <div class="sidebar-item__text">
                    Aquí va el tipo de condición
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <i class="icon-info"></i>
                </span>
            </div>

            <h5 class="m-b-8 m-t-16">Active</h5>
            <div class="sidebar-item sidebar-item--active">
                <div class="sidebar-item__icon">
                    <i class="icon-folder i-md"></i>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <i class="icon-info"></i>
                </span>
            </div>
            <div class="sidebar-item sidebar-item--active">
                <div class="sidebar-item__icon">
                    <div class="tag-icon tag-icon--small">
                        <i class="icon-check"></i>
                    </div>
                </div>
                <div class="sidebar-item__text">
                    Lineal
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <i class="icon-info"></i>
                </span>
            </div>
            <div class="sidebar-item sidebar-item--active sidebar-item--conditional">
                <div class="sidebar-item__text">
                    Aquí va el tipo de condición
                </div>
                <span class="tooltip" data-tooltip="Tooltip!">
                    <i class="icon-info"></i>
                </span>
            </div>
        </ng-container>

        <!-- ONBOARDING ALICE-->
        <ng-container *ngIf="component === 'onboarding-alice'">
            <h2 class="m-b-16">Onboarding Alice</h2>
            <div class="onboarding">
                <div class="alice alice--big"></div>
                <h1>
                    Soy Alice
                </h1>
                <h1>
                    tu asistente en <span class="underline-item underline-item__underline">automatizaciones</span>
                </h1>
                <button class="button button--lg button--ghost">Iniciar</button>
            </div>
        </ng-container>
        
         <!-- ERROR MESSAGE-->
        <ng-container *ngIf="component === 'error-message'">
            <h2 class="m-b-16">Error message</h2>
            <div class="error-message">
                <img src="../../../../assets/resources/empty-state-error.svg" alt="">
                <h5>¡Oops!</h5>
                <div>Algo ha ido mal, pero tiene solución</div>
                <button class="button">
                    <i class="icon-refresh"></i>
                    Recargar
                </button>
            </div>
        </ng-container>

         <!-- COMMENT-->
        <ng-container *ngIf="component === 'comment'">
            <h2 class="m-b-16">Comment</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="comments">
                <div class="comments__comment">
                    <div class="comments__comment__info">
                        <div class="info__name">
                            <div class="avatar" style="background-color:#3CB372">
                                <span style="color: #FFFFFF">CR</span>
                            </div>
                            <div class="info__name__name">Name Surname</div>
                        </div>
                        <div class="info__data">
                            Hace 2 días
                        </div>
                    </div>
                    <div class="comments__comment__node">2. Click - Botón de fichar</div>
                    <div class="comments__comment__body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sagittis, ipsum ut laoreet accumsan, mauris felis blandit sapien, dictum luctus diam leo in velitipsum ut laoreetdsaan...
                    </div>
                    <a href="">2 respuestas</a>
                </div>
            </div>
            <div class="comments comments--extend">
                <div class="comments__comment">
                    <div class="comments__comment__info">
                        <div class="info__name">
                            <div class="avatar" style="background-color: #7756DB">
                                <span style="color: #FFFFFF">HE</span>
                            </div>
                            <div class="info__name__name">Name Surname</div>
                        </div>
                        <div class="info__data">
                            Hace 2 días
                        </div>
                    </div>
                    <div class="comments__comment__node">2. Click - Botón de fichar</div>
                    <div class="comments__comment__body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sagittis, ipsum ut laoreet accumsan, mauris felis blandit sapien, dictum luctus diam leo in velitipsum ut laoreetdsaan sagittis, ipsum ut laoreet accumsan.

Mauris felis blandit sapien, dictum luctus diam leo in velitipsum ut laoreetdsaasagittis, ipsum ut laoreet accumsan, mauris felis blandit sapien, dictum luctus diam leo in velitipsum ut laoreetdsaasagittis, ipsum ut laoreet accumsan, mauris felis blandit sapien, dictum luctus diam leo in velitipsum ut laoreetdsaa
                    </div>
                </div>
                <div class="comments__comment">
                    <div class="comments__comment__info">
                        <div class="info__name">
                            <div class="avatar" style="background-color:#3CB372">
                                <span style="color: #FFFFFF">CR</span>
                            </div>
                            <div class="info__name__name">Name Surname</div>
                        </div>
                        <div class="info__data">
                            Hace 2 días
                        </div>
                    </div>
                    <div class="comments__comment__node">2. Click - Botón de fichar</div>
                    <div class="comments__comment__body">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sagittis, ipsum ut laoreet accumsan, mauris felis blandit sapien, dictum luctus diam leo in velitipsum ut laoreetdsaan sagittis, ipsum ut laoreet accumsan. Mauris felis blandit sapien, dictum luctus diam leo in velitipsum ut laoreetdsaasagittis, ipsum ut laoreet accumsan, mauris felis blandit sapien, dictum luctus diam leo in velitipsum ut laoreetdsaasagittis, ipsum ut laoreet accumsan, mauris felis blandit sapien.
                    </div>
                    <div class="comments__comment__answers">
                        <div class="avatar" style="background-color:#3CB372">
                            <span style="color: #FFFFFF">CR</span>
                        </div>
                        <div class="input-group">
                            <div class="input-group__button">
                                <input class="input" type="text">
                                <button class="button-icon">
                                    <i class="icon-arrow-up"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

         <!-- NODE PREVIEW-->
        <ng-container *ngIf="component === 'node-preview'">
            <h2 class="m-b-16">Node preview</h2>
            <h5 class="m-b-8 m-t-16">Default</h5>
            <div class="node-preview">
                <div class="tag-icon">
                    <i class="icon-double-click"></i>
                </div>
                <div class="node-preview__text">
                    <h6>2.Click</h6>
                    <span>Botón de fichar</span>
                </div>
                <i class="icon-arrow-right"></i>
            </div>
            <h5 class="m-b-8 m-t-16">Selected</h5>
            <div class="node-preview node-preview--selected">
                <div class="tag-icon">
                    <i class="icon-double-click"></i>
                </div>
                <div class="node-preview__text">
                    <h6>2.Click</h6>
                    <span>Botón de fichar</span>
                </div>
                <i class="icon-arrow-right"></i>
            </div>
        </ng-container>


        <!-- COMPONENT-->
        <!-- <ng-container *ngIf="component === 'component'">
            <h2 class="m-b-16">Component</h2>
        </ng-container> -->

    </div>
</div>