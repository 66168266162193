import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IAccountResponse } from './core/models/account.models';
import { IMaster } from './core/models/masters.models';
import { AccountService } from './core/services/account/account.service';
import { MastersService } from './core/services/masters/masters.service';
import { INotificationsPending } from './pages/notifications/models/notifications.models';
import { NotificationsService } from './pages/notifications/services/notifications.service';
import { ITemplatesCategoriesResponse } from './pages/template/models/template.models';
import { TemplateService } from './pages/template/services/template.service';
import { IDropdownElement } from './shared/models/dropdown.models';
import { ISidebar } from './shared/models/sidebar.models';
import { LoadingService } from './shared/services/loading.service';
import { SidebarService } from './shared/services/sidebar.service';
import { UtilsService } from './shared/services/utils.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {
    public title = 'DxBot';
    public url = "";
    public sidebarConf: ISidebar;
    public accountData!: IAccountResponse;
    public noticationPending!: number;
    public showFullLoading: boolean = false;

    public confDropdown: Array<IDropdownElement> = [
        {
            label: 'Configuración',
            icon: '	icon-settings',
            url: '/perfil'
        }, {
            label: 'Cerrar sesión',
            icon: '	icon-log-out',
            type: 'endRed',
            id: 'closeSession'
        }
    ];
    private port: any;

    constructor(
        public router: Router,
        private sidebar: SidebarService,
        private accountService: AccountService,
        private notificationsService: NotificationsService,
        private mastersService: MastersService,
        private loadingService: LoadingService,
        private templateService: TemplateService,
        private utilsService: UtilsService
    ) { 
        this.sidebarConf = this.sidebar.sidebar;
    }

    ngOnInit(): void {
        this.url = this.router.url;

        this.loadingService.getShowLoading()
            .subscribe((status: boolean) => {
                this.showFullLoading = status;
            });

        this.notificationsService.getNotificationTotalPending()
            .subscribe((total: number) => {
                this.noticationPending = total;
            })

        this.getAccountData();
        this.getNotificationsPending();
        this.getMasters();
        this.getTemplatesCategories();
        this.connectWithExtension();
    }

    // Conectamos con la extension
    private connectWithExtension(): void {
        if (this.utilsService.isChromeBrowser()) {
            if (chrome.runtime) {
                this.port = chrome.runtime.connect(environment.extensionId, {name: "dxbot"});
                this.utilsService.setMsgInstallExtension('');
                this.utilsService.setPortExtension(this.port);
                this.port.onDisconnect.addListener((msg: any) => {
                    this.utilsService.setMsgInstallExtension('Para una mejor experiencia instala la extensión DxBot');
                });
            } else {
                this.utilsService.setMsgInstallExtension('Para una mejor experiencia instala la extensión DxBot');
            }
        } else {
            this.utilsService.setMsgInstallExtension('Para una mejor experiencia usa el navegador Chrome e instala la extensión DxBot');
        }
    }

    // Obtenemos la informacion de la cuenta
    private getAccountData(): void {
        this.loadingService.setShowLoading(true);
        this.accountService.getAccountDataFromService()
            .subscribe({
                next: (response: IAccountResponse) => {
                    if (response) {
                        this.accountData = response;
                        this.accountService.setAccountData(this.accountData);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    if (error.error.error !== 'Unauthorized.') {
                        this.loadingService.setShowLoading(false);
                    }
                },
                complete: () => {
                    if (!this.accountData.user.first_steps) {
                        this.loadingService.setShowLoading(false);
                    }
                }
            });
    }

    // Obtenemos las notificaciones pendientes de leer
    private getNotificationsPending(): void {
        this.notificationsService.getTotalNotificationsPendingFromService()
            .subscribe({
                next: (response: INotificationsPending) => {
                    if (response) {
                        this.noticationPending = response.total;
                        this.notificationsService.setNotificationTotalPending(response.total);
                    }
                },
                error: (error: HttpErrorResponse) => {
                },
                complete: () => {
                }
            });
    }

    // Llamada para obtener todos los masters
    private getMasters(): void {
        this.mastersService.getMastersFromService()
            .subscribe({
                next: (response: IMaster) => {
                    if (response) {
                        this.mastersService.setMasters(response);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    
                },
                complete: () => {

                }
            });
    }

    // Llamada para obtner las categorias de las plantillas
    private getTemplatesCategories() {
        this.templateService.getTemplatesCategoriesFromService()
            .subscribe({
                next: (response: ITemplatesCategoriesResponse) => {
                    if (response) {
                        this.templateService.setTemplatesCategories(response);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.templateService.setTemplatesCategoriesError(error?.error?.error || 'Ha ocurrido un error al obtener los datos,<br> inténtelo de nuevo más tarde');
                },
                complete: () => {
                }
            });
    }

    // Opciones del desplegable de usuario
    public optionSelect(element: string) {
        if (element === 'closeSession') {
            // TODO cerrar sesión
        }
    }
}
