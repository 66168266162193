<div 
    class="dropdown" 
    [ngClass]="design" 
    [ngClass]="{'dropdown--open': openDropdownSelect, design, 'dropdown--filled': value && value.id && value.id !== ''}" 
    (click)="clickInDropdown()">
    <button class="dropdown__trigger input" [disabled]="disabled">
        <span>
            <ng-container *ngIf="value && value.id !== null && value.id !== undefined && value.id !== ''">
                {{ value.name }}
            </ng-container>
            <ng-container *ngIf="value?.id === null || value?.id === undefined || value?.id === ''">
                <ng-content></ng-content>
            </ng-container>
        </span>
        <i class="icon-chevron-down"></i>
    </button>

    <div class="dropdown__menu" *ngIf="options && options.length">
        <ng-container *ngFor="let option of options; index as i">
            <div class="dropdown__menu__item" [ngClass]="{'dropdown__menu__item--selected': option.id === value?.id}" (click)="changeSelect(option)">
                {{ option.name }}
            </div>
        </ng-container>
    </div>
</div>