import { IActionJobs } from "./actions.models";

export interface IMaster {
    actions: Array<IActionJobs>;
    execution_types: Array<IMasterElement>;
    notifications_periodicity: Array<IMasterElement>;
    order_by: {
        jobs: Array<IMasterElement>;
        templates: Array<IMasterElement>;
        user_activity: Array<IMasterElement>;
        notifications: Array<IMasterElement>;
        datasources: Array<IMasterElement>;
    };
    schendule_intervals: Array<IMasterElement>;
    status_jobs: Array<IMasterElement>;
    schendule_types: Array<IMasterElement>;
    custom_schendule_intervals: Array<IMasterElement>;
    datasource_types: Array<IMasterElement>;
};

export interface IMasterElement {
    id: any;
    name: string;
    selected?: boolean;
};

export enum SchenduleIntervals {
    Day = 1,
    Week = 2,
    Month = 3,
    Year = 4
};

export const DAY_OF_WEEK: Array<IMasterElement> = [
    {
        id: 1,
        name: 'L'
    }, {
        id: 2,
        name: 'M'
    }, {
        id: 3,
        name: 'X'
    }, {
        id: 4,
        name: 'J'
    }, {
        id: 5,
        name: 'V'
    }, {
        id: 6,
        name: 'S'
    }, {
        id: 0,
        name: 'D'
    }
];

export enum FinishOption {
    Never = 1,
    Day = 2,
    After = 3
}

export const PROGRAMED_FINISH_OPTIONS: Array<IMasterElement> = [
    {
        id: FinishOption.Never,
        name: 'Nunca'
    }, {
        id: FinishOption.Day,
        name: 'El'
    }, {
        id: FinishOption.After,
        name: 'Después de'
    }
];

export const CUSTOM_SCHENDULE_TYPES: number = 6;

export const allLocalStorage: any = {
    accessToken: 'access_token'
}