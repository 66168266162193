import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IPagination } from '../../models/pagination.models';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

    @Input() design!: string;
    @Input() pagination!: IPagination;
    @Output() paginationChange = new EventEmitter<IPagination>();

    public MAX_ELEMENTS_PAGINATION: number = 5;
    public pagesToShow: Array<number | string> = [];
    public totalPages: number = 0;
    public inputPage: number = 1;

    constructor(
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.updatePaginator();
    }

    public onSubmit(): void {
        this.pagination.page = +this.inputPage <= this.totalPages ? +this.inputPage : this.totalPages;
        this.updatePaginator();
        this.paginationChange.emit(this.pagination);
    }

    public addPage(): void {
        this.pagination.page += 1;
        this.updatePaginator();
        this.paginationChange.emit(this.pagination);
    }

    public removePage(): void {
        this.pagination.page -= 1;
        this.updatePaginator();
        this.paginationChange.emit(this.pagination);
    }

    public moveToPage(page: number | string): void {
        this.pagination.page = Number(page);
        this.updatePaginator();
        this.paginationChange.emit(this.pagination);
    }

    public updatePaginator(): void {
        if (this.pagination) {
            this.inputPage = +this.pagination.page;
            this.pagesToShow = [];
            if (this.pagination.total && this.pagination.limit) {
                this.totalPages = Math.trunc(this.pagination.total / this.pagination.limit) + (this.pagination.total / this.pagination.limit % 1 != 0 ? 1 : 0);
            }
            if (+this.pagination.page <= (this.MAX_ELEMENTS_PAGINATION - 1)) {
                // Es alguna de las MAX_ELEMENTS_PAGINATION - 1 primeras páginas
                for (let index = 1; index <= this.MAX_ELEMENTS_PAGINATION && index < this.totalPages; index++) {
                    this.pagesToShow.push(index);
                }
                if (this.totalPages > this.MAX_ELEMENTS_PAGINATION + 1) {
                    this.pagesToShow.push('...');
                }
                this.pagesToShow.push(this.totalPages);
            } else if (+this.pagination.page > (this.totalPages - (this.MAX_ELEMENTS_PAGINATION - 1))) {
                // Es alguna de las páginas finales
                if (this.totalPages > this.MAX_ELEMENTS_PAGINATION) {
                    this.pagesToShow.push(1);
                    if (this.totalPages > this.MAX_ELEMENTS_PAGINATION + 1) {
                        this.pagesToShow.push('...');
                    }
                }
                for (let index = this.totalPages - (this.MAX_ELEMENTS_PAGINATION - 1); index <= this.totalPages; index++) {
                    this.pagesToShow.push(index);
                }
            } else {
                // Es alguna de las páginas de enmedio
                this.pagesToShow.push(1);
                this.pagesToShow.push('...');
                for (let index = +this.pagination.page-Math.trunc(this.MAX_ELEMENTS_PAGINATION / 2); index <= +this.pagination.page+Math.trunc(this.MAX_ELEMENTS_PAGINATION / 2); index++) {
                    this.pagesToShow.push(index);
                }
                this.pagesToShow.push('...');
                this.pagesToShow.push(this.totalPages);
            }
        }
    }

}
