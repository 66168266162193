import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAvatar } from '../../models/avatar.models';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  
    @Input() design!: string;
    @Input() icon!: string;
    @Input() buttonText!: string;
    @Input() avatar!: Array<IAvatar>;
    @Output() buttonClicked = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    public doAction() {
        this.buttonClicked.emit();
    }

}
