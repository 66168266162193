import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IActionBody, IAutomationConf } from 'src/app/pages/automation/models/automations.models';
import { Action, IActionConf } from '../../models/actions.models';

@Injectable({
    providedIn: 'root'
})
export class ActionsService {

    public actions: Array<IActionConf> = [
        {
            action: Action.OPEN_URL,
            description: 'Abrir URL',
            icon: 'icon-open-web',
            aux_description: 'Introduce la url de la web'
        }, {
            action: Action.FORM_FILL,
            description: 'Rellenar formulario',
            icon: 'icon-fill-form',
            aux_description: 'Agrega una descripción'
        }, {
            action: Action.FORM_SUBMIT,
            description: 'Enviar formulario',
            icon: 'icon-upload-form',
            aux_description: 'Agrega una descripción'
        }, {
            action: Action.CLICK,
            description: 'Clic',
            icon: 'icon-click',
            aux_description: 'Agrega una descripción'
        }, {
            action: Action.DOUBLE_CLICK,
            description: 'Doble clic',
            icon: 'icon-double-click',
            aux_description: 'Agrega una descripción'
        }, {
            action: Action.CLICK_BY_COORDINATES,
            description: 'Clic por coordenadas',
            icon: 'icon-clic-coordenates',
            aux_description: 'Agrega una descripción'
        }, {
            action: Action.WAIT_FOR_ELEMENT,
            description: 'Esperar a un elemento',
            icon: 'icon-wait-element',
            aux_description: 'Agrega una descripción'
        }, {
            action: Action.RETURN_NAV_STATE,
            description: 'Retornar al estado de navegación',
            icon: 'icon-return-nav-state'
        }, {
            action: Action.SLEEP,
            description: 'Pausar ejecución',
            icon: 'icon-pause',
            aux_description: 'Seleccione una cantidad'
        }
    ];

    private openActionSidebar!: BehaviorSubject<boolean>;
    private newAutomationOptions!: BehaviorSubject<any>;
    private instructionsTemplate!: BehaviorSubject<IActionBody | null>;
    
    constructor() {
        this.openActionSidebar = new BehaviorSubject<boolean>(false);
        this.newAutomationOptions = new BehaviorSubject<any>(null);
        this.instructionsTemplate = new BehaviorSubject<IActionBody | null>(null);
    }

    public setOpenActionSidebar(data: boolean): void {
        this.openActionSidebar.next(data);
    }

    public getOpenActionSidebar(): Observable<boolean> {
        return this.openActionSidebar.asObservable();
    }

    public setNewAutomationOptions(data: IAutomationConf): void {
        this.newAutomationOptions.next(data);
    }

    public getNewAutomationOptions(): Observable<IAutomationConf> {
        return this.newAutomationOptions.asObservable();
    }

    public setInstructionsFromTemplate(data: IActionBody | null): void {
        this.instructionsTemplate.next(data);
    }

    public getInstructionsFromTemplate(): Observable<IActionBody | null> {
        return this.instructionsTemplate.asObservable();
    }
}
