<div class="modal">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <div class="content__header">
                <div class="content__header__title">
                    <h3>Invita a tus compañeros a colaborar</h3>
                </div>
                <app-button-icon icon="icon-close" (buttonClicked)="closeModal()"></app-button-icon>
            </div>
            <ng-container *ngIf="!loadingInviteCollaborator && !errorInviteCollaborator">
                <div class="content__body">
                    <div class="content__body__section">
                        <div class="row">
                            <div class="col-12">
                                <div class="input-group">
                                    <label for="">Direcciones de email</label>
                                    <input type="text" placeholder="Placeholder" class="input">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="fs-14 text--gray-dark m-t-20">Tus compañeros recibirán un email que les dará acceso al equipo</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content__footer">
                    <app-button design="button--secondary" (buttonClicked)="closeModal()">
                        Cancelar
                    </app-button>
                    <app-button (buttonClicked)="confirmModal()">
                        Guardar
                    </app-button>
                </div>
            </ng-container>
            <div *ngIf="loadingInviteCollaborator" class="content__body">
                Loading
            </div>
            <div *ngIf="errorInviteCollaborator" class="content__body">
                <app-error-message [subTitle]="errorInviteCollaborator" [hideButton]="true"></app-error-message>
            </div>
        </div>
    </div>
</div>
