<div class="breadcrumb" *ngIf="elements && elements.length">
    <ng-container *ngFor="let breadcrumb of elements; let last = last;">
        <a *ngIf="!last" [routerLink]="breadcrumb.url" class="breadcrumb__item">
            {{ breadcrumb.title }}
        </a>
        <div *ngIf="last" class="breadcrumb__item">
            {{ breadcrumb.title }}
        </div>
    </ng-container>
</div>
