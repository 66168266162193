<div class="sidebar" [ngClass]="{'sidebar--collapsed': !openSidebar}">
    <div class="sidebar__button">
        <app-button-icon icon="icon-chevron-left" (buttonClicked)="openSidebar = !openSidebar"></app-button-icon>
    </div>
    <div class="sidebar__body">
        <app-button *ngIf="confSidebar?.showButton" design="button--lg" icon="icon-plus left" (buttonClicked)="openModalCreateAutomation()">
            Crear automatización
        </app-button>
        <ul class="sidebar__body__options">
            <ng-container *ngFor="let item of confSidebar?.items">
                <li class="options__option" *ngIf="!item.hidden">
                    <a class="options__option__link" 
                        [attr.data-tooltip]="item.name" 
                        [ngClass]="{'options__option__link--active': item.url === currentUrl}" 
                        [routerLink]="item.url">
                        <app-icons [design]="item.icon"></app-icons>
                        <span>{{ item.name }}</span>
                        <app-badge *ngIf="item.notification" design="badge--small"></app-badge>
                    </a>
                </li>
            </ng-container>
            <ng-container>
                <ng-content></ng-content>
            </ng-container>
        </ul>
    </div>
</div>