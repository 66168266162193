import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-tag-icon',
    templateUrl: './tag-icon.component.html',
    styleUrls: ['./tag-icon.component.scss']
})
export class TagIconComponent implements OnInit {
  
    @Input() design!: string;
    @Input() icon!: string;

    constructor() { }

    ngOnInit(): void {
    }

}
