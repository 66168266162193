import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModalGenericData } from '../../models/modal-generic.models';

@Component({
    selector: 'app-modal-generic',
    templateUrl: './modal-generic.component.html',
    styleUrls: ['./modal-generic.component.scss']
})
export class ModalGenericComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ModalGenericComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IModalGenericData
    ) { }

    ngOnInit(): void {
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

    public confirmModal(): void {
        this.dialogRef.close(true);
    }
}
