import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { INotificationsResponse, INotificationsPending, NotificationStatus, INotification, INotificationsListRequest } from '../models/notifications.models';
import { ITabElement } from 'src/app/shared/models/tabs.models';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService extends ApiService {

    private notificationTab: Subject<NotificationStatus>;
    private notificationTotalPending: BehaviorSubject<number>;

    constructor(
        auth: AuthService,
        http: HttpClient
    ) {
        super(auth, http);
        this.notificationTab = new Subject<NotificationStatus>();
        this.notificationTotalPending = new BehaviorSubject<number>(0);
    }

    public setNotificationTab(data: NotificationStatus) {
        this.notificationTab.next(data);
    }

    public getNotificationTab(): Observable<NotificationStatus> {
        return this.notificationTab.asObservable();
    }

    public setNotificationTotalPending(data: number) {
        this.notificationTotalPending.next(data);
    }

    public getNotificationTotalPending(): Observable<number> {
        return this.notificationTotalPending.asObservable();
    }

    public getNotificationsFromService(filters: INotificationsListRequest): Observable<INotificationsResponse> {
        return this.http.get<INotificationsResponse>(environment.dxBotApiUrl + '/v2/notifications', {
            params: this.getParamsNotifications(filters),
            headers: this.getHeaders()
        });
    }

    public getTotalNotificationsPendingFromService(): Observable<INotificationsPending> {
        return this.http.get<INotificationsPending>(environment.dxBotApiUrl + '/v2/notifications/pending', {headers: this.getHeaders()});
    }

    public getNotificationFromService(id: any): Observable<INotification> {
        return this.http.get<INotification>(environment.dxBotApiUrl + `/v2/notifications/${id}`, {headers: this.getHeaders()});
    }

    private getParamsNotifications(filters: INotificationsListRequest): HttpParams {
        let params = new HttpParams();
        params = params.set('page', filters.page);
        params = params.set('quantity', filters.quantity);

        if (filters.order_by) {
            params = params.set('order_by', filters.order_by);
        }
        if (filters.filter) {
            params = params.set('filter', filters.filter);
        }
        if (filters.unread) {
            params = params.set('unread', filters.unread);
        }
        return params;
    }

    public getConfTabsNotification(): Array<ITabElement> {
        return [
            {
                title: 'Sin leer',
                selected: true,
                notifications: 0,
                id: NotificationStatus.Unread
            }, {
                title: 'Todas',
                selected: false,
                notifications: 0,
                id: NotificationStatus.Total
            }
        ];
    }
}
