<div class="modal modal--confirmation">
    <div class="modal__dialog">
        <div class="modal__dialog__content">
            <div class="content__header">
                <app-button-icon icon="icon-close" 
                    (buttonClicked)="closeModal()"
                    *ngIf="data.type !== 'loading'">
                </app-button-icon>
            </div>
            <div class="content__body">
                <ng-container [ngSwitch]="data.type">
                    <app-tag-icon 
                        *ngSwitchCase="'confirm'" 
                        design="tag-icon--green" 
                        icon="icon-check">
                    </app-tag-icon>
                    <app-tag-icon 
                        *ngSwitchCase="'error'" 
                        design="tag-icon--orange" 
                        icon="icon-alert">
                    </app-tag-icon>
                    <app-alice
                        *ngSwitchCase="'loading'" 
                        design="alice--lg">
                    </app-alice>
                </ng-container>
                <h4 [innerHtml]="data.title"></h4>
                <h6 [innerHtml]="data.text"></h6>
            </div>
            <div class="content__footer">
                <app-button *ngIf="data.textButtonCancel" design="button--secondary" (buttonClicked)="closeModal()">
                    {{ data.textButtonCancel }}
                </app-button>
                <app-button *ngIf="data.textButtonConfirm" (buttonClicked)="confirmModal()">
                    {{ data.textButtonConfirm }}
                </app-button>
            </div>
        </div>
    </div>
</div>