import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-list-design',
    templateUrl: './list-design.component.html',
    styleUrls: ['./list-design.component.scss']
})
export class ListDesignComponent implements OnInit {

    public component: string = "";
    public showFilter: boolean = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        
    ) { }

    ngOnInit(): void {
        this.activatedRoute.params
            .subscribe(
                (params: Params) => {
                    this.component = params['component'];
                }
            );
        // this.component = this.activatedRoute.snapshot.paramMap.get('component') || '';
    }

    public changeFilter() {
        this.showFilter = !this.showFilter;
    }

}
