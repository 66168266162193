<div class="banner" [ngClass]="design" >
    <app-icons *ngIf="icon" [design]="icon"></app-icons>
    <app-avatar [elements]="avatar"></app-avatar>
    <div class="banner__body">
        <h5>
            <ng-content select="[title]"></ng-content>
        </h5>
        <div>
            <ng-content select="[text]"></ng-content>
        </div>
    </div>
    <div class="banner__button" *ngIf="buttonText && buttonText !== ''">
        <app-button design="button--secondary" (buttonClicked)="doAction()">{{ buttonText }}</app-button>
    </div>
</div>
