import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActionsService } from 'src/app/core/services/actions/actions.service';
import { AutomationType } from 'src/app/pages/automation/models/automations.models';
import { ITemplate, ITemplateCategory, ITemplatesListResponse } from 'src/app/pages/template/models/template.models';
import { TemplateService } from 'src/app/pages/template/services/template.service';
import { ModalGenericComponent } from '../../design-components/modal-generic/modal-generic.component';
import { SkeletonTypes } from '../../models/skeleton.models';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-modal-create-automation',
    templateUrl: './modal-create-automation.component.html',
    styleUrls: ['./modal-create-automation.component.scss']
})
export class ModalCreateAutomationComponent implements OnInit, OnDestroy {

    // General
    public QUANTITY_TEMPLATE: number = 4;
    public skeletonTypes = SkeletonTypes;
    public templateData: ITemplatesListResponse | null = null;
    public templateError: string = '';
    public templatesCategories!: Array<ITemplateCategory>;
    public automationType: any = AutomationType;
    public form: FormGroup = new FormGroup({
        type: new FormControl('', Validators.required),
        template: new FormControl('')
    });
    public searchForm: FormControl = new FormControl('', [Validators.minLength(3)]);

    private allSubscription: Subscription[] = [];

    constructor(
        public dialogRef: MatDialogRef<ModalCreateAutomationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private templateService: TemplateService,
        private actionsService: ActionsService,
        private utilsService: UtilsService
    ) { }

    ngOnInit(): void {
        this.getTemplates();
    }

    public getTemplates(): void {
        if (this.searchForm.valid) {
            this.templateData = null;
            this.templateError = '';
            this.searchForm.disable();
            this.templateService.getTemplatesListFromService({
                    filter: this.searchForm.value,
                    page: 1,
                    quantity: this.QUANTITY_TEMPLATE,
                    user_templates: true
                }).subscribe({
                    next: (response: ITemplatesListResponse) => {
                        if (response) {
                            this.templateData = response;
                        }
                    },
                    error: (error: HttpErrorResponse) => {
                        this.templateError = error?.error?.error || 'Ha ocurrido un error al obtener los datos,<br> inténtelo de nuevo más tarde';
                        this.searchForm.enable();
                    },
                    complete: () => {
                        this.searchForm.enable();
                    }
                });
        } else {
            this.searchForm.markAsTouched();
        }
    }

    // Cerramos modal
    public closeModal(): void {
        this.dialogRef.close();
    }

    // Inicializamos la automatización
    public createAutomation(): void {
        if (this.form.valid) {
            this.actionsService.setNewAutomationOptions({name: 'Nombre de la automatización', type: this.form.get('type')?.value});
            const found = this.templateData?.templates.find((template: ITemplate) => template.selected);
            if (found) {
                const loadingRef: MatDialogRef<ModalGenericComponent> = this.utilsService.openModalGeneric({
                    type: 'loading',
                    title: 'Obteniendo datos de la plantilla...'
                })
                this.dialogRef.close();
                this.templateService.getTemplatesDetailFromService(found.id)
                    .subscribe({
                        next: (response: ITemplate) => {
                            if (response) {
                                this.actionsService.setInstructionsFromTemplate(response.instructions);
                                this.router.navigate(['/area-trabajo']);
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            this.utilsService.openModalGeneric({
                                type: 'error',
                                title: 'Ha ocurrido un error al obtener los datos',
                                text: 'Inténtelo de nuevo más tarde'
                            });
                            loadingRef.close();
                        },
                        complete: () => {
                            loadingRef.close();
                        }
                    });
            } else {
                this.router.navigate(['/area-trabajo']);
                this.dialogRef.close();
            }
        } else {
            this.form.markAllAsTouched();
        }
    }

    // Seleccionar plantilla
    public selectTemplate(template: ITemplate): void {
        template.selected = !template.selected;
        this.templateData?.templates.forEach((temp: ITemplate) => {
            if (temp.id !== template.id) {
                temp.selected = false;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map((subs: Subscription) => subs.unsubscribe());
        }
    }
}
