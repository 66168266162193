export interface INotificationsResponse {
    current_page: number;
    total: number;
    notifications: Array<INotification>;    
}

export interface INotificationsListRequest {
    page: number;
    quantity: number;
    filter?: string;
    order_by?: any;
    unread?: NotificationStatus;
}

export interface INotification {
    id: string;
    readed: boolean;
    created_at: string;
    category: NotificationCategory;
    title: string;
    text: string;
    item_id: string;
    open?: boolean;
}

export interface INotificationsPending {
    total: number;
}

export enum NotificationStatus {
    Unread = 'true',
    Total = ''
}

export enum NotificationCategory {
    Comment = 'comment',
    Job = 'job'
}