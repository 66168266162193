import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-switcher',
    templateUrl: './switcher.component.html',
    styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent implements OnInit {

    @Input() design!: string;
    @Input() value!: boolean | undefined;
    @Input() control!: FormControl;
    @Input() controlName!: any;
    @Input() controlGroup!: any;
    @Output() valueChange = new EventEmitter<boolean>();

    constructor() {
        this.value = Boolean(this.value);
    }

    ngOnInit(): void {
    }

    public changeStatus() {
        this.value = !this.value;
        this.valueChange.emit(this.value);
    }

}
